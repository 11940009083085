import { Template } from '@pro4all/graphql';
import { useTemplatesInclude } from '@pro4all/metadata/data-access';
import { useRouting } from '@pro4all/shared/routing-utils';
import { OptimisticResponseProvider } from '@pro4all/shared/ui/table';

import { useMetaDataContext } from '../../views/MetaDataContext';
import { TemplateFrame } from '../form-template/TemplateFrame';

import { ScreenTBQTemplates } from './ScreenTBQTemplates';

export const ScreenTBQTemplatesProviderWrapper = () => {
  const {
    params: { tbqScopeId },
    searchParams,
  } = useRouting();
  const createContext = searchParams.is('action', 'createTemplate');
  const editContext = searchParams.is('action', 'editSet');
  const showTemplate = createContext || editContext;

  const { templateType, templateService } = useMetaDataContext();

  const { loading, templates, selectedTemplate, selectTemplate } =
    useTemplatesInclude({
      globalId: tbqScopeId,
      includeCreatedAt: true,
      includeCreatedBy: true,
      includeIcon: true,
      includeInstanceCount: true,
      includeOrgItems: false,
      includeScope: true,
      includeState: true,
      includeType: true, // We don't need this for the table, but we do need it for the export from the template editor.
      skip: !tbqScopeId,
      templateService,
      templateType,
    });

  return (
    <OptimisticResponseProvider<Template>>
      {showTemplate ? (
        <TemplateFrame selectedTemplate={selectedTemplate} />
      ) : (
        <ScreenTBQTemplates
          loading={loading}
          selectTemplate={selectTemplate}
          templates={templates}
        />
      )}
    </OptimisticResponseProvider>
  );
};
