import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';

export const FolderWithDocuments: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      d="M6.91667 13.1537C6.59583 13.1537 6.32118 13.0483 6.09271 12.8374C5.86424 12.6265 5.75 12.373 5.75 12.0768V4.53835C5.75 4.24219 5.86424 3.98867 6.09271 3.77777C6.32118 3.56687 6.59583 3.46143 6.91667 3.46143H15.0833C15.4042 3.46143 15.6788 3.56687 15.9073 3.77777C16.1358 3.98867 16.25 4.24219 16.25 4.53835V12.0768C16.25 12.373 16.1358 12.6265 15.9073 12.8374C15.6788 13.0483 15.4042 13.1537 15.0833 13.1537H6.91667ZM6.91667 12.0768H15.0833V4.53835H6.91667V12.0768ZM8.66667 10.9999H11.5833C11.7486 10.9999 11.8872 10.9483 11.999 10.8451C12.1108 10.7419 12.1667 10.614 12.1667 10.4614C12.1667 10.3089 12.1108 10.181 11.999 10.0778C11.8872 9.97457 11.7486 9.92296 11.5833 9.92296H8.66667C8.50139 9.92296 8.36285 9.97457 8.25104 10.0778C8.13924 10.181 8.08333 10.3089 8.08333 10.4614C8.08333 10.614 8.13924 10.7419 8.25104 10.8451C8.36285 10.9483 8.50139 10.9999 8.66667 10.9999ZM8.66667 8.84604H13.3333C13.4986 8.84604 13.6372 8.79444 13.749 8.69123C13.8608 8.58803 13.9167 8.46014 13.9167 8.30758C13.9167 8.15502 13.8608 8.02713 13.749 7.92393C13.6372 7.82072 13.4986 7.76912 13.3333 7.76912H8.66667C8.50139 7.76912 8.36285 7.82072 8.25104 7.92393C8.13924 8.02713 8.08333 8.15502 8.08333 8.30758C8.08333 8.46014 8.13924 8.58803 8.25104 8.69123C8.36285 8.79444 8.50139 8.84604 8.66667 8.84604ZM8.66667 6.69219H13.3333C13.4986 6.69219 13.6372 6.64059 13.749 6.53739C13.8608 6.43418 13.9167 6.3063 13.9167 6.15373C13.9167 6.00117 13.8608 5.87328 13.749 5.77008C13.6372 5.66687 13.4986 5.61527 13.3333 5.61527H8.66667C8.50139 5.61527 8.36285 5.66687 8.25104 5.77008C8.13924 5.87328 8.08333 6.00117 8.08333 6.15373C8.08333 6.3063 8.13924 6.43418 8.25104 6.53739C8.36285 6.64059 8.50139 6.69219 8.66667 6.69219Z"
      fill={customColors.grey900}
    />
    <path d="M4 22.1537C3.45 22.1537 2.97917 21.973 2.5875 21.6114C2.19583 21.2499 2 20.8153 2 20.3076V9.23066C2 8.72296 2.19583 8.28835 2.5875 7.92681C2.97917 7.56527 3.45 7.3845 4 7.3845H9.175C9.44167 7.3845 9.69583 7.43066 9.9375 7.52296C10.1792 7.61527 10.3917 7.74604 10.575 7.91527L12 9.23066H20C20.55 9.23066 21.0208 9.41143 21.4125 9.77296C21.8042 10.1345 22 10.5691 22 11.0768V20.3076C22 20.8153 21.8042 21.2499 21.4125 21.6114C21.0208 21.973 20.55 22.1537 20 22.1537H4Z" />
  </SvgIcon>
);
