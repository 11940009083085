import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { v4 as uuid } from 'uuid';

import { client } from '@pro4all/authentication/src/graph-ql';
import {
  MailStyle,
  OrganizationUsersIncludeDocument,
  useInviteUsersMutation,
  User,
  UserState,
} from '@pro4all/graphql';
import { useContextScopedOrganizationId } from '@pro4all/shared/identity';
import { useApplication } from '@pro4all/shared/themes';
import { useShowMessages } from '@pro4all/shared/ui/messages';
import { useOptimisticResponseContext } from '@pro4all/shared/ui/table';

import { organizationUsersIncludeProps } from '../organization/getIncludeProps';

interface Props {
  onClose?: () => void;
  refetch: () => void;
  setShowBackdrop: (show: boolean) => void;
}

interface Values {
  admins: Array<{ id: string; inputValue: string; label: string }>;
  doNotSendEmail: boolean;
  members: Array<{ id: string; inputValue: string; label: string }>;
  message: string;
}

export const useSubmitUsers = ({
  onClose,
  refetch,
  setShowBackdrop,
}: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [inviteUsers] = useInviteUsersMutation();
  const getContextScopedOrganizationId = useContextScopedOrganizationId();
  const organizationId = getContextScopedOrganizationId();
  const { isThemeTbq } = useApplication();

  const { showBatchErrors, showSingleError } = useShowMessages();
  const {
    state: { items },
  } = useOptimisticResponseContext<User>();

  const onSubmit = async (values: Values) => {
    const { admins, members, message, doNotSendEmail } = values;

    try {
      setShowBackdrop(true);
      const inviteUsersResponse = await inviteUsers({
        variables: {
          adminEmails: admins.map((admin) => admin.label),
          memberEmails: members.map((member) => member.label),
          message,
          sendEmail: !doNotSendEmail,
          style: isThemeTbq ? MailStyle.Tbq : MailStyle.Prostream,
        },
      });

      if (inviteUsersResponse.data) {
        const organization =
          items.find((user) => user.organizationId === organizationId)
            ?.organization ?? null;

        const genericProps = {
          __typename: 'User',
          active: false,
          invited: doNotSendEmail ? false : true,
          lastOnlineAt: '',
          organization,
          organizationId,
          state: doNotSendEmail
            ? UserState.InvitationNotSent
            : UserState.InvitationSent,
        };

        const createdAdmins = admins
          .map((admin) =>
            inviteUsersResponse?.data?.inviteUsers.includes(admin.label)
              ? {
                  ...genericProps,
                  displayName: admin.label,
                  email: admin.label,
                  id: uuid(),
                  roles: [{ id: '2', name: 'Administrator', permissions: [] }], // id must be added ts-wise, but is not used at table rendering
                }
              : null
          )
          .filter(Boolean);

        const createdMembers = members
          .map((member) =>
            inviteUsersResponse?.data?.inviteUsers.includes(member.label)
              ? {
                  ...genericProps,
                  displayName: member.label,
                  email: member.label,
                  id: uuid(),
                  roles: [{ id: '2', name: 'User', permissions: [] }], // id must be added ts-wise, but is not used at table rendering
                }
              : null
          )
          .filter(Boolean);

        client.writeQuery({
          data: {
            organizationUsers: [...items, ...createdAdmins, ...createdMembers],
          },
          query: OrganizationUsersIncludeDocument,
          variables: {
            ...organizationUsersIncludeProps,
          },
        });

        setShowBackdrop(false);
      }

      const invitedSuccessfully = inviteUsersResponse.data?.inviteUsers;
      const countSuccessful = invitedSuccessfully.length || 0;
      const countInvited = [...admins, ...members].length;
      const emailsFailed = [...admins, ...members].filter(
        (person) => !invitedSuccessfully.includes(person.label)
      );

      if (countSuccessful === countInvited) {
        if (countSuccessful === 1) {
          enqueueSnackbar(t('Invitation has been sent to 1 user.'));
        } else {
          enqueueSnackbar(
            t('Invitation has been sent to {{count}} users.', {
              count: countSuccessful,
            })
          );
        }
      } else {
        showBatchErrors({
          errorCode: 'ERROR_FAILED_TO_INVITE_USERS',
          identifierData: emailsFailed.map((emailFailed) => ({
            displayName: emailFailed.label,
            id: emailFailed.id,
          })),
        });
      }

      // We create an temporary id for the new user to update Apollo client cache, however BE is in charge of this id.
      // So we do a refetch to update the tabel with the new id we get from BE.
      // However because BE is slow sometimes. So we do not refetch instantly but after 5 seconds to give BE some time.
      setTimeout(() => {
        refetch();
      }, 5000);

      onClose();
    } catch (e) {
      showSingleError(e);
    }
  };
  return onSubmit;
};
