import { Route, Routes as RouterDomRoutes } from 'react-router-dom';

import { PrivateRoute } from '@pro4all/authentication/src/components';
import { FolderTreeProviderOuter } from '@pro4all/documents/ui/folders';
import { DocumentsContextProvider } from '@pro4all/documents/ui/share';
import { NotificationScope } from '@pro4all/graphql';
import { NotificationsPageProviderWrapper } from '@pro4all/notifications';
import { Routes } from '@pro4all/shared/config';

import { DocumentsBrowser } from './documents-browser/DocumentsBrowser';
import { Tabs } from './documents-browser/Tabs';

export const Documents = () => (
  <>
    <Tabs />
    <RouterDomRoutes>
      <Route
        element={
          <PrivateRoute
            appPermission="OrganizationRead"
            element={
              <NotificationsPageProviderWrapper
                scope={NotificationScope.Document}
              />
            }
          />
        }
        path={Routes.documentNotifications}
      />
      <Route
        element={
          <DocumentsContextProvider>
            <FolderTreeProviderOuter>
              <DocumentsBrowser />
            </FolderTreeProviderOuter>
          </DocumentsContextProvider>
        }
        path="*"
      />
    </RouterDomRoutes>
  </>
);
