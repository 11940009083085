import React from 'react';
import { useTranslation } from 'react-i18next';

import { Routes } from '@pro4all/shared/config';
import {
  generateRoute,
  updateLsLastVisitedProjectRoute,
  useRouting,
} from '@pro4all/shared/routing-utils';
import { SubTabs, Tab } from '@pro4all/shared/ui/general';

export const ProjectUsersGroupsTabs: React.FC = () => {
  const { t } = useTranslation();
  const { goTo, params } = useRouting();
  const { projectId } = params;

  const base = `${generateRoute('projectMembersGroupsUrl', {
    params: { projectId },
  })}/`;

  return (
    <SubTabs base={base}>
      <Tab
        data-testid="project-members-tab"
        label={t('Members')}
        onClick={() => {
          goTo('projectMembersUrl', {
            params: { projectId },
          });
          updateLsLastVisitedProjectRoute({
            overwriteCurrentValue: true,
            projectId,
            route: 'projectMembersUrl',
          });
        }}
        value={Routes.members}
      />
      <Tab
        data-testid="project-groups-tab"
        label={t('Groups')}
        onClick={() => {
          goTo('projectGroupsUrl', {
            params: { projectId },
          });
          updateLsLastVisitedProjectRoute({
            overwriteCurrentValue: true,
            projectId,
            route: 'projectGroupsUrl',
          });
        }}
        value={Routes.groups}
      />
    </SubTabs>
  );
};
