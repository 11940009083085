import { Routes } from '@pro4all/shared/config';
import { RouteOption } from '@pro4all/shared/types';

import { generateRoute } from '../generateRoute';

export const getDropdownRoutes = (projectId: string | undefined) => {
  const navRoutesLookUp = projectId ? projNavRoutesLookUp : orgNavRoutesLookUp;
  const generatedRoutes = Object.keys(navRoutesLookUp).map(
    (route: keyof typeof Routes) =>
      projectId
        ? generateRoute(route, {
            params: {
              projectId,
            },
          })
        : generateRoute(route)
  );

  const currentRouteIndex = generatedRoutes.findIndex(
    (route) => route === window.location.pathname
  );

  const routeOptionsForPage = Object.values(navRoutesLookUp)[currentRouteIndex];
  return routeOptionsForPage;
};

export const orgMetadataRouteOptions: RouteOption[] = [
  {
    label: 'Metadata templates',
    route: 'orgMetadataTemplates',
  },
  {
    label: 'Fields',
    route: 'orgMetadataFieldTemplates',
  },
];

export const projMetadataRouteOptions: RouteOption[] = [
  {
    label: 'Metadata templates',
    route: 'projectMetadataDocumentTemplatesUrl',
  },
  {
    label: 'Fields',
    route: 'projectMetadataDocumentFieldsUrl',
  },
];

export const projQCRouteOptions: RouteOption[] = [
  {
    label: 'Form templates',
    route: 'projectMetadataQualityForms',
  },
  { label: 'Snag templates', route: 'projectMetadataQualitySnagsUrl' },
  { label: 'Fields', route: 'projectMetadataQualityFieldsUrl' },
  {
    label: 'Report configurations',
    route: 'projectMetadataQualityReport',
  },
];

export const orgQCRouteOptions: RouteOption[] = [
  {
    label: 'Form templates',
    route: 'orgQualityFormTemplates',
  },
  { label: 'Snag templates', route: 'orgQualitySnagTemplates' },
  { label: 'Fields', route: 'orgQualityFieldTemplates' },
  {
    label: 'Report configurations',
    route: 'orgQualityReportConfigurations',
  },
];

//Here you defined which routes you would like to show in the navigation dropdown for each field.
const orgNavRoutesLookUp: {
  [key in keyof typeof Routes]?: RouteOption[];
} = {
  fieldTemplates: orgQCRouteOptions,
  metaDataQualityControl: orgQCRouteOptions,
  orgMetadataFieldTemplates: orgMetadataRouteOptions,
  orgMetadataTemplates: orgMetadataRouteOptions,
  orgQualityFieldTemplates: orgQCRouteOptions,
  orgQualityFormTemplates: orgQCRouteOptions,
  orgQualityReportConfigurations: orgQCRouteOptions,
  orgQualitySnagTemplates: orgQCRouteOptions,
};

const projNavRoutesLookUp: {
  [key in keyof typeof Routes]?: RouteOption[];
} = {
  projectMetadataDocumentFieldsUrl: projMetadataRouteOptions,
  projectMetadataDocumentTemplatesUrl: projMetadataRouteOptions,
  projectMetadataQualityFieldsUrl: projQCRouteOptions,
  projectMetadataQualityForms: projQCRouteOptions,
  projectMetadataQualityReport: projQCRouteOptions,
  projectMetadataQualitySnagsUrl: projQCRouteOptions,
};

export const dropdownNavRouteOptions = {
  orgMetadataRouteOptions,
  orgQCRouteOptions,
  projMetadataRouteOptions,
  projQCRouteOptions,
};
