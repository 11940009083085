import React from 'react';
import { Route, Routes as RouterDomRoutes } from 'react-router-dom';

import { createTabValue, Routes } from '@pro4all/shared/config';
import { Column, Main } from '@pro4all/shared/ui/layout';

import { OrganizationGroupProviderWrapper } from '../components/group-management/organization/OrganizationGroupProviderWrapper';
import { OrganizationUsersGroupsTabs } from '../components/tabs/OrganizationUsersGroupsTabs';
import { OrganizationUserProviderWrapper } from '../components/user-management/organization/OrganizationUserProviderWrapper';

export const OrganizationMembers = () => (
  <Main sx={{ overflow: 'hidden' }}>
    <Column>
      <OrganizationUsersGroupsTabs />
      <RouterDomRoutes>
        <Route
          element={<OrganizationGroupProviderWrapper />}
          path={createTabValue(Routes.groups)}
        />

        <Route
          element={<OrganizationUserProviderWrapper />}
          path={createTabValue(Routes.members)}
        />
      </RouterDomRoutes>
    </Column>
  </Main>
);
