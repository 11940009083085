// ! IMPORTANT !
// Flags for FE must be prefixed by NX_
// Flags for graphQL do not require NX prefix.

export const ApiConfig = {
  client_download_url_mac:
    process.env.NX_CLIENT_DOWNLOAD_URL_MAC ||
    process.env.CLIENT_DOWNLOAD_URL_MAC ||
    'https://www.apple.com/app-store/',
  client_download_url_windows:
    process.env.NX_CLIENT_DOWNLOAD_URL_WINDOWS ||
    process.env.CLIENT_DOWNLOAD_URL_WINDOWS ||
    'https://apps.microsoft.com/home',
  communicationApi:
    process.env.COMMUNICATION_API || 'https://communication.pro4all.io/api',
  documentsApi: process.env.DOCUMENTS_API || 'https://documents.pro4all.io/api',
  expressLimit: process.env.EXPRESS_BODY_LIMIT || '1mb',
  foundationApi:
    process.env.FOUNDATION_API || 'https://foundation.pro4all.io/api',
  graph: process.env.NX_GRAPH || 'https://graph.prostream.dev',
  graphql: process.env.NX_GRAPHQL_API || 'https://graph.prostream.dev/web',
  hyperLinkServiceApi: process.env.HLS_API || 'https://hls.pro4all.io/api',
  identityApi: process.env.IDENTITY_API || 'https://identity.pro4all.io/api',
  instanceSectionSaveCount: parseInt(
    process.env.InstanceSectionSaveCount || '20'
  ),
  integrationApi:
    process.env.INTEGRATION_API || 'https://integration.pro4all.io/api',
  isDevelopment: process.env.NODE_ENV === 'development',
  linkApi: process.env.LINK_API || 'https://link.pro4all.io/api',
  metaDataApi: process.env.META_DATA_API || 'https://metadata.pro4all.io/api',
  nonProductionTracing: process.env.NONPRODUCTIONTRACING || false,
  objectsApi: process.env.OBJECT_API || 'https://os.pro4all.io/api',
  opencdeUrl: process.env.NX_OPENCDE_URL || 'https://opencde.prostream.dev',
  pdftWS: process.env.PDFT_WS || 'https://pdftron.pro4all.io',
  pollEnabled: Number(
    process.env.NX_POLL_ENABLED ?? 'process.env.POLL_ENABLED'
  ),
  proceduresApi:
    process.env.PROCEDURES_API || 'https://procedures.pro4all.io/api',
  production: process.env.NODE_ENV === 'production',
  prostreamWeb: process.env.PROSTREAM || 'https://my.prostream.dev',
  qualityControlApi:
    process.env.QUALITY_CONTROL_API || 'https://qualitycontrol.pro4all.io/api',
  reportApi: process.env.REPORT_API || 'https://api.pro4all.io/report/api',
  reportRenderer:
    process.env.NX_REPORT_RENDERER || 'https://api.pro4all.io/report-renderer',
  searchApi: process.env.SEARCH_API || 'https://search.pro4all.io/api',
  searchNotificationApi:
    process.env.SEARCH_NOTIFICATION_API ||
    'https://api.pro4all.io/searchnotification/api',
  supportDays: Number(process.env.SUPPORT_DAYS || 90),
  threeDApi: process.env.THREE_D_API || 'https://graph.prostream.dev/3d',
  tracing: process.env.TRACING === 'true',
};
