import { useEffect } from 'react';

import type { Stamp } from '@pro4all/graphql';
import {
  StampStatus,
  StampType,
  useQrCodeStateQuery,
  useStampsQuery,
} from '@pro4all/graphql';
import { ApiConfig } from '@pro4all/shared/config';

import { useHandleDimensionsChange } from './stamper-actions/useHandleDimensionsChange';
import { useHandlePositionChange } from './stamper-actions/useHandlePositionChange';
import { useRevokeAll } from './stamper-actions/useRevokeAll';
import { useRevokeOne } from './stamper-actions/useRevokeOne';
import { useStampMultiple } from './stamper-actions/useStampMultiple';
import { useStampOne } from './stamper-actions/useStampOne';
import { Canvas } from './canvas';
import { useDocumentPreviews } from './data';
import type { StamperDocument } from './store';
import { Status, toShape, useStore } from './store';
import { Dimensions, Position } from './types';

export function useStamper(
  queue: StamperDocument[],
  applyToAllPages?: boolean
) {
  const [state, dispatch] = useStore({ queue });
  const { position, handlePositionChange } = useHandlePositionChange();
  const { dimensions, handleDimensionsChange } = useHandleDimensionsChange();

  const current = state.queue[state.index];
  const background = useDocumentPreviews(current, state.queue);

  const { data, loading, refetch } = useStampsQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    variables: { ids: queue.map(({ id }) => id) },
  });

  const { data: qrCode, loading: loadingQrState } = useQrCodeStateQuery({
    pollInterval: 5000 * ApiConfig.pollEnabled,
    skip: !background.src || [Status.Done, Status.Idle].includes(state.status),
    variables: { versionId: current?.id },
  });

  if (
    !loading &&
    current &&
    state.canvas &&
    qrCode?.qrCodeState !== StampStatus.Progress &&
    state.status !== Status.Idle &&
    !loadingQrState // For some reason, polling continues despite skip: true. Including loading state here stops it for reasons unknown.
  ) {
    dispatch({ type: 'init' });
  }

  const allStamps = (data?.stamps?.data ?? []) as Stamp[];
  const defaultStamps = [{ type: StampType.QrCode, versionId: current?.id }];
  const currentStamps = allStamps.filter(
    (stamp) => stamp && current?.id === stamp.versionId
  );

  const shapes = state.canvas
    ? (currentStamps.length ? currentStamps : defaultStamps).map((stamp) =>
        toShape(state.canvas)({
          ...stamp,
          currentDimensions: dimensions,
          currentPosition: position,
        })
      )
    : [];

  const { revokeAll } = useRevokeAll(queue, data, refetch);
  const { revokeOne } = useRevokeOne(queue, data, refetch, state, dispatch);
  const { stampOne } = useStampOne(
    queue,
    applyToAllPages,
    shapes,
    refetch,
    state,
    dispatch
  );
  const { stampAll } = useStampMultiple(
    queue,
    applyToAllPages,
    shapes,
    state,
    dispatch
  );

  // Set initial values only once when component mounts
  useEffect(() => {
    if (!position) {
      handlePositionChange({
        target: { name: 'x', value: '10' },
      } as React.ChangeEvent<HTMLInputElement>);
      handlePositionChange({
        target: { name: 'y', value: '10' },
      } as React.ChangeEvent<HTMLInputElement>);
    }
    if (!dimensions) {
      handleDimensionsChange({
        target: { name: 'height', value: '100' },
      } as React.ChangeEvent<HTMLInputElement>);
      handleDimensionsChange({
        target: { name: 'width', value: '100' },
      } as React.ChangeEvent<HTMLInputElement>);
    }
  }, []);

  // Use the current values or defaults
  const currentPosition: Position = position || { x: 10, y: 10 };
  const currentDimensions: Dimensions = dimensions || {
    height: 100,
    width: 100,
  };

  const handleCanvasPositionChange = (newPosition: Position) => {
    handlePositionChange({
      target: {
        name: 'x',
        value: newPosition.x.toString(),
      },
    } as React.ChangeEvent<HTMLInputElement>);
    handlePositionChange({
      target: {
        name: 'y',
        value: newPosition.y.toString(),
      },
    } as React.ChangeEvent<HTMLInputElement>);
  };

  const handleCanvasDimensionsChange = (newDimensions: Dimensions) => {
    handleDimensionsChange({
      target: {
        name: 'height',
        value: newDimensions.height.toString(),
      },
    } as React.ChangeEvent<HTMLInputElement>);
    handleDimensionsChange({
      target: {
        name: 'width',
        value: newDimensions.width.toString(),
      },
    } as React.ChangeEvent<HTMLInputElement>);
  };

  return {
    background,
    canvas: (
      <Canvas
        {...state.canvas}
        background={background}
        current={current}
        dimensions={currentDimensions}
        onDimensionsChange={handleCanvasDimensionsChange}
        onInit={(canvas) => dispatch({ canvas, type: 'update' })}
        onPositionChange={handleCanvasPositionChange}
        position={currentPosition}
        shapes={shapes}
      />
    ),
    count: state.index + 1,
    current,
    dimensions: currentDimensions,
    handleDimensionsChange,
    handlePositionChange,
    hasQRCode: shapes?.some(({ id, type }) => id && type === StampType.QrCode),
    next: () => dispatch({ type: 'next' }),
    position: currentPosition,
    revokeAll,
    revokeOne,
    stampAll,
    stampOne,
    status: state.status,
    total: state.queue.length,
  };
}
