import styled from 'styled-components';

import { LayoutProps } from '@pro4all/shared/types';

import { ItemList as InstanceList } from './InstanceElements';

export const FirstPage = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  margin: 5mm;

  /* The follow-up pages will start with this number plus 1 */
  counter-set: page 1;
`;

export const Logo = styled.div`
  position: absolute;
  left: 10mm;
  top: 0;
  width: 25mm;
  height: 25mm;

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

export const Content = styled.div`
  left: 10mm;
  top: 50mm;
  position: absolute;
  width: 165mm;
  height: 100%;
`;

export const List = styled(InstanceList)<LayoutProps>`
  --padding-top: 1mm;
  --padding-right: 0;
  --padding-left: 0;
  --padding-bottom: 2mm;
  --label-width: 50mm;
`;

export const ReportPageTitle = styled.h1`
  font-size: 24pt;
  line-height: 140%;
  margin-bottom: 4mm;
`;
