export enum DeleteVariant {
  DELETE_ALL = 'DELETE_ALL',
  DELETE_NONE = 'DELETE_NONE',
  DELETE_ONLY_OWN = 'DELETE_ONLY_OWN',
  DELETE_SELECTED = 'DELETE_SELECTED',
  EMPTY = '',
}

export type DrawingsMainProps = {
  projectId?: string;
};
