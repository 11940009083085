import { useTranslation } from 'react-i18next';

import {
  EntityFolderPermission,
  Folder,
  FolderPermission,
} from '@pro4all/graphql';
import { useGenericContext } from '@pro4all/shared/providers';
import { GroupTag, UserTag } from '@pro4all/shared/ui/identity-card';
import { ColumnProps, IconHeader, LabelHeader } from '@pro4all/shared/ui/table';
import { Tooltip } from '@pro4all/shared/ui/tooltip';

import { BreakInheritanceForEntitySwitchWrapper } from '../BreakInheritanceForEntitySwitchWrapper';
import { ExclusivePermissionEntitySelectorWrapper } from '../ExclusivePermissionEntitySelectorWrapper';
import { PermissionCellEntityWrapper } from '../PermissionCellEntityWrapper';
import { EXCLUDED_PERMISSIONS, PermissionHeader } from '../PermissionHeader';
import { SelectAllPermissionsForEntityButton } from '../SelectAllPermissionsForEntityButton';
import { ExclusivePermissionType } from '../types/types';

interface Props {
  folder: Folder;
}

export const useEntityPermissionColumns = ({
  folder,
}: Props): ColumnProps<EntityFolderPermission>[] => {
  const { t } = useTranslation();
  const { parentFolderId, projectId } = folder || {};

  const {
    state: { showFinalPermissions },
  } = useGenericContext();

  return [
    {
      disableSort: true,
      key: 'displayName',
      label: 'Name',
      minWidth: 100,
      render: (row) => {
        const { user, group } = row;
        if (!user && !group) {
          return null;
        }
        if (user) {
          return <UserTag user={user} />;
        }
        if (group) {
          return <GroupTag group={group} />;
        }
      },
      width: 300,
    },
    {
      headerComponent: <LabelHeader label="Documents" />, // i18n
      key: 'entityFolderPermissions.canView',
      render: (row) => (
        <ExclusivePermissionEntitySelectorWrapper
          currentPermissions={row}
          entityId={row.memberId}
          exclusivePermissionType={ExclusivePermissionType.Documents}
          folder={folder}
        />
      ),
    },
    {
      headerComponent: <LabelHeader label="Versions" />, // i18n
      key: 'entityFolderPermissions.canViewVersions',
      render: (row) => (
        <ExclusivePermissionEntitySelectorWrapper
          currentPermissions={row}
          entityId={row.memberId}
          exclusivePermissionType={ExclusivePermissionType.Versions}
          folder={folder}
        />
      ),
    },
    {
      headerComponent: <LabelHeader label="Delete" />, // i18n
      key: 'entityFolderPermissions.canDeleteDocuments',
      render: (row) => (
        <ExclusivePermissionEntitySelectorWrapper
          currentPermissions={row}
          entityId={row.memberId}
          exclusivePermissionType={ExclusivePermissionType.Delete}
          folder={folder}
        />
      ),
    },
    {
      headerComponent: <LabelHeader label="Update" />, // i18n
      key: 'entityFolderPermissions.canUpdateDocuments',
      render: (row) => (
        <ExclusivePermissionEntitySelectorWrapper
          currentPermissions={row}
          entityId={row.memberId}
          exclusivePermissionType={ExclusivePermissionType.Update}
          folder={folder}
        />
      ),
    },
    ...Object.values(FolderPermission)
      .filter(
        (permission: FolderPermission) =>
          !EXCLUDED_PERMISSIONS.includes(permission)
      )
      .map(
        (
          permission: FolderPermission
        ): ColumnProps<EntityFolderPermission> => ({
          disableSort: true,
          headerComponent: <PermissionHeader permission={permission} />,
          key: `entityFolderPermissions.${permission}`,
          render: (row) => (
            <PermissionCellEntityWrapper
              entityId={row.memberId}
              folder={folder}
              permission={permission}
            />
          ),
          width: 76,
        })
      ),
    {
      disableSort: true,
      headerComponent: <div></div>,
      key: 'entityFolderPermissions.selectAll',
      render: (row) =>
        showFinalPermissions ? null : (
          <SelectAllPermissionsForEntityButton
            currentPermissions={row}
            entityId={row.memberId}
            projectId={projectId}
          />
        ),
      width: 50,
    },
    {
      disableSort: true,
      headerComponent: showFinalPermissions ? null : (
        <Tooltip placement="bottom" title={t('Break permission inheritance')}>
          <IconHeader color="primary" iconName="breakInheritance" />
        </Tooltip>
      ),
      key: 'entityFolderPermissions.breakInheritance',
      render: (row) =>
        parentFolderId && !showFinalPermissions ? (
          <BreakInheritanceForEntitySwitchWrapper entityId={row.memberId} />
        ) : null,
      width: 50,
    },
  ];
};
