import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetThumbnail } from '@pro4all/documents/ui/utils';
import { Action, TrackingEvent } from '@pro4all/shared/config';
import { Box, Typography } from '@pro4all/shared/mui-wrappers';
import { DocumentAndVersionType } from '@pro4all/shared/types';
import { ImagePlaceHolder } from '@pro4all/shared/ui/image-placeholder';
import { Loader } from '@pro4all/shared/ui/loader';
import { isSupportedPdfViewerExtension } from '@pro4all/shared/utils';
import { useAnalytics } from '@pro4all/shared/vendor';

import { DocumentPreview } from './DocumentPreview';
import { ThumbnailContainer, ThumbnailImg } from './Thumbnail.styles';

type ThumbnailProps = {
  hide?: boolean;
  urn?: string;
} & DocumentAndVersionType;

export const Thumbnail: React.FC<ThumbnailProps> = ({
  document,
  hide,
  urn,
  version,
}) => {
  const { t } = useTranslation();
  const [showPreview, setShowPreview] = useState(false);
  const [imageLoadError, setImgLoadError] = useState(false);
  const [isPdfTronSupported, setShowPdfViewer] = useState(false);

  const documentId = document?.id || version?.documentId;
  const versionId = document?.versionId || version?.id;

  useEffect(() => {
    if (version && version.extension) {
      setShowPdfViewer(isSupportedPdfViewerExtension(version.extension));
      return;
    }

    if (document && document.extension) {
      setShowPdfViewer(isSupportedPdfViewerExtension(document.extension));
    }
  }, [document, version]);

  const { track } = useAnalytics();

  const { loading, thumbnailUrl } = useGetThumbnail({
    documentId: documentId,
    versionId: versionId,
  });

  const trackPreview = (action: Action) => {
    if (!isPdfTronSupported) {
      track(TrackingEvent.DocumentPreviewed, {
        action: action,
      });
    }
  };

  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent): void => {
      if (event.code === 'Space' && thumbnailUrl) {
        // Don't show preview modal if any input element has focus
        if (
          !(
            window.document.activeElement instanceof HTMLInputElement ||
            window.document.activeElement instanceof HTMLTextAreaElement ||
            window.document.activeElement?.getAttribute('data-slate-editor') ===
              'true'
          ) &&
          !isPdfTronSupported
        ) {
          trackPreview(Action.SpaceBarPressed);
          setShowPreview((showPreview) => !showPreview);
          event.preventDefault();
        }
      }
    };
    window.document.addEventListener('keydown', onKeyDown);

    return () => {
      window.document.removeEventListener('keydown', onKeyDown);
    };
  }, [thumbnailUrl, track]);

  const onThumbnailClick = () => {
    if (!urn) {
      trackPreview(Action.ThumbnailClicked);
      setShowPreview(true);
    }
  };

  if (hide) return null;
  if (loading || !thumbnailUrl)
    return (
      <ThumbnailContainer>
        {loading ? (
          <Loader />
        ) : (
          <Box>
            <Typography>{t('No preview available')}</Typography>
          </Box>
        )}
      </ThumbnailContainer>
    );

  return (
    <>
      <ThumbnailContainer>
        {imageLoadError ? (
          <ImagePlaceHolder
            actionButtonLabel={
              isPdfTronSupported ? t('Open document') : undefined
            }
            actionCallback={isPdfTronSupported ? onThumbnailClick : undefined}
            randomShape
            shadow={false}
          />
        ) : (
          <ThumbnailImg
            data-testid="preview-document"
            draggable="false"
            enablePreview={!urn}
            onClick={onThumbnailClick}
            onError={() => {
              setImgLoadError(true);
            }}
            src={thumbnailUrl}
          />
        )}
      </ThumbnailContainer>
      <DocumentPreview
        document={document}
        onClose={() => setShowPreview(false)}
        open={showPreview}
        version={version}
      />
    </>
  );
};

export default Thumbnail;
