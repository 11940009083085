import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Serie, useSeriesByProjectQuery } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';
import { FilterContextProvider } from '@pro4all/shared/ui/filtering';
import { Column, Main } from '@pro4all/shared/ui/layout';
import { BigMessageNoQCorDocTasks } from '@pro4all/shared/ui/messages';
import { ResponseWrapper } from '@pro4all/shared/ui/response-wrapper';
import {
  Table,
  TableContextProvider,
  useOptimisticResponseContext,
  useSetItemsInLocalState,
} from '@pro4all/shared/ui/table';
import { TaskSidebar } from '@pro4all/workflow/ui/task-sidebar';

import { useSeriesColumns } from '../useSeriesColumns';

import { SeriesSidebar } from './SeriesSidebar';

export const SeriesTable = () => {
  const { t } = useTranslation();
  const { projectId } = useParams<{ projectId: string }>();
  const { searchParams } = useRouting();
  const { data, loading } = useSeriesByProjectQuery({
    variables: { projectId },
  });

  const columns = useSeriesColumns();

  const seriesProvider = useMemo(
    () => data?.seriesByProject || [],
    [data?.seriesByProject]
  );

  const {
    state: { items },
  } = useOptimisticResponseContext<Serie>();
  useSetItemsInLocalState<Serie>(seriesProvider);

  const emptyRenderer = () => (
    <BigMessageNoQCorDocTasks
      text={t(
        'Create new series allows you to plan ahead tasks. You can set a max of 14 tasks. To get started, go to task creation and enable series.'
      )}
      title={t('No recurring tasks yet')}
    />
  );

  const onSelectSerie = (id: string) => {
    searchParams.set({ action: 'viewSerie', id });
  };

  const selectedId = searchParams.get('id') || undefined;
  const viewTask = searchParams.is('action', 'viewTask');

  return (
    <Main>
      <ResponseWrapper isLoading={loading}>
        <TableContextProvider
          checkable={false}
          columns={columns}
          id="view-series-table"
          items={items}
        >
          <FilterContextProvider<Serie>>
            <Column sx={{ overflow: 'hidden' }}>
              <Table<Serie>
                emptyRenderer={emptyRenderer}
                headerHeight={40}
                onRowClick={({ id }) => onSelectSerie(id)}
                selectedId={selectedId}
              />
              <SeriesSidebar series={items} />
              {viewTask && <TaskSidebar />}
            </Column>
          </FilterContextProvider>
        </TableContextProvider>
      </ResponseWrapper>
    </Main>
  );
};
