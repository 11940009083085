import { RouteParams, Routes } from '@pro4all/shared/config';

export function getDrawingRoute(params: RouteParams) {
  let drawingRoute: keyof typeof Routes = 'projectQualityControlDrawingUrl';
  if (params.objectId) {
    if (params.projectId) {
      drawingRoute = 'projectObjectDrawing';
    } else {
      drawingRoute = 'orgObjectDrawing';
    }
  }

  return drawingRoute;
}

export function getQCSResultRoute(params: RouteParams) {
  let drawingRoute: keyof typeof Routes = 'projectQualityControlResultsUrl';
  if (params.objectId) {
    if (params.projectId) {
      drawingRoute = 'projectObjectResults';
    } else {
      drawingRoute = 'orgObjectResults';
    }
  }

  return drawingRoute;
}
