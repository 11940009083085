import { useEffect, useRef, useState } from 'react';

import { AuthService } from '@pro4all/authentication/src/services/auth-service';
import { useOrganizationContext } from '@pro4all/organization/context';
import { ApiConfig } from '@pro4all/shared/config';
import { useRouting } from '@pro4all/shared/routing-utils';

import { ForgeViewer } from './viewers/forge/ForgeViewer';
import {
  NodeSelection,
  ViewerNode,
  ViewerPropertyGroup,
} from './viewers/Viewer.types';

type Use3DViewerProps = {
  loadingDocument: boolean;
  loadingFolder: boolean;
  urn?: string;
};

export const use3DViewer = ({
  loadingDocument,
  loadingFolder,
  urn,
}: Use3DViewerProps) => {
  const [viewer, setViewer] = useState<ForgeViewer | null>(null);
  const [activeNodes, setActiveNodes] = useState<ViewerNode[]>([]);
  const [viewerInitialized, setViewerInitialized] = useState<boolean>(false);
  const [treeRootNodes, setTreeRootNodes] = useState<ViewerNode[]>();
  const [nodeProperties, setNodeProperties] = useState<ViewerPropertyGroup[]>(
    []
  );

  const viewContainer = useRef<HTMLDivElement>(null);

  const { meData, userLanguage } = useOrganizationContext();
  const { searchParams } = useRouting();

  // Create a new viewer when document and folder are loaded
  useEffect(() => {
    if (!viewer && !loadingDocument && !loadingFolder && meData) {
      // Because we can not track viewContainer, and the loading might be faster than the
      // creation of the container in the DOM, we set an interval to check if it has been set
      const interval = window.setInterval(() => {
        if (viewContainer.current && !viewer) {
          setViewer(
            new ForgeViewer(
              {
                accessToken: AuthService.getRawAccessTokenFromLocalStorage(),
                api: 'derivativeV2_EU',
                language: userLanguage ? userLanguage : 'en-US',
              },
              viewContainer.current,
              `${ApiConfig.threeDApi}/autodeskforgeproxy`
            )
          );
          window.clearInterval(interval);
        }
      }, 500);
    }
  }, [
    loadingDocument,
    loadingFolder,
    meData,
    searchParams,
    userLanguage,
    viewer,
  ]); // Don't remove 'loading' and 'loadingFolder' as a dependancy, else reloading the page won't initialize the viewer for sure!

  // Initialize the viewer when the viewer is created, and destroy
  // it when the component gets unloaded to avoid unwanted memory
  // usage
  useEffect(() => {
    const initializeViewer = async () => {
      if (viewer && (urn || searchParams.get('urn'))) {
        await viewer.init();
        viewer.registerSelectionEventHandler(
          async (nodeSelection: NodeSelection[]) => {
            // Fetch activeNode list from NodeSelection
            const activeNodes: ViewerNode[] = [];
            for (const selection of nodeSelection) {
              for (const nodeId of selection.ids) {
                const node = await viewer.getNode(nodeId, selection.model);
                activeNodes.push(node);
              }
            }
            setActiveNodes(activeNodes);

            // Set properties if a single object was selected
            if (activeNodes.length === 1) {
              const properties: ViewerPropertyGroup[] =
                await viewer.getNodeProperties(activeNodes[0]);
              setNodeProperties(properties);
            } else {
              setNodeProperties([]);
            }
          }
        );
        setViewerInitialized(true);
      }
    };

    initializeViewer();

    return () => {
      viewer?.destroy();
    };
  }, [searchParams, urn, viewer]);

  // When the viewer is initialized, load the models
  useEffect(() => {
    const loadModels = async () => {
      const urns: string[] = urn
        ? [urn]
        : JSON.parse(searchParams.get('urn') || '{}');

      for (const urn of urns) {
        if (urn.length > 0) {
          await viewer?.loadModel(`urn:${urn}`);
          // Without Collapsible, the viewer needs a resize after loading the model
          setTimeout(() => {
            viewer?.resize();
          }, 500);
        }
      }

      if (viewer) {
        const entityTrees = await viewer.getNodeTrees();
        setTreeRootNodes(entityTrees);
      }
    };

    viewerInitialized && loadModels();
  }, [searchParams, viewer, viewerInitialized, urn]);

  return { activeNodes, nodeProperties, treeRootNodes, viewContainer, viewer };
};
