import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router-dom';

import { Project } from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import { ActionProps, useCentralActions } from '@pro4all/shared/actions';
import { Routes } from '@pro4all/shared/config';
import { useAppPermissions } from '@pro4all/shared/identity';
import { useRouting } from '@pro4all/shared/routing-utils';
import { useTableContextMenuRowContext } from '@pro4all/shared/ui/table';

import { isExpired } from '../utils';

export function useProjectActions() {
  const { ACTION_CREATE, ACTION_DELETE, ACTION_EDIT } = useCentralActions();
  const { hasAppPermission } = useAppPermissions();
  const { goTo, searchParams } = useRouting();
  const { contextMenuRow: project } = useTableContextMenuRowContext<Project>();

  const isMyProjectsRoute = Boolean(useMatch(Routes.myProjectsOverview));

  const { id, name, organization } = project || {};

  const { t } = useTranslation();

  const { userOrganizationId } = useOrganizationContext();

  const mainActions: ActionProps[] = [
    {
      ...ACTION_CREATE,
      ariaLabel: t('Create project'),
      dataTestId: 'create-project',
      disabled:
        !hasAppPermission('ProjectCreate') ||
        isMyProjectsRoute ||
        isExpired(organization?.expireDate),
      key: 'create-project',
      label: t('New project'),
      onClick: () => searchParams.set('action', 'createProject'),
      startIcon: 'projectCreate',
    },
  ];

  const editButton: ActionProps = {
    ...ACTION_EDIT,
    dataTestId: 'edit-project',
    disabled:
      !hasAppPermission('ProjectUpdate') ||
      isExpired(organization?.expireDate) ||
      organization?.id !== userOrganizationId,
    key: 'edit-project',
    onClick: () => goTo('projectSettingsUrl', { params: { projectId: id } }),
  };

  const deleteButton: ActionProps = {
    ...ACTION_DELETE,
    dataTestId: 'delete-project',
    disabled:
      !hasAppPermission('ProjectDelete') || isExpired(organization?.expireDate),
    key: 'delete-project',
    onClick: () => searchParams.set({ action: 'deleteProject', id, name }),
  };

  const altActions = [editButton, deleteButton];

  return { altActions, mainActions };
}
