import { Group } from '@pro4all/graphql';
import { OptimisticResponseProvider } from '@pro4all/shared/ui/table';

import { OrganizationGroupManagement } from './OrganizationGroupManagement';

export const OrganizationGroupProviderWrapper = () => (
  <OptimisticResponseProvider<Group>>
    <OrganizationGroupManagement />
  </OptimisticResponseProvider>
);
