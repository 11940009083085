import { useEffect, useMemo } from 'react';
import styled from 'styled-components';

import { Task, TaskType } from '@pro4all/graphql';
import { useObjectDetailContext } from '@pro4all/shared/contexts';
import { Box } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { FilterContextProvider } from '@pro4all/shared/ui/filtering';
import { BigMessageNoTasks } from '@pro4all/shared/ui/messages';
import {
  Table as UITable,
  TableContextProvider,
  useOptimisticResponseContext,
} from '@pro4all/shared/ui/table';
import { TBQTaskSidebar } from '@pro4all/workflow/ui/task-sidebar';

import { useTBQTaskColumns } from '../useTBQTaskColumns';

import { TasksActionBar } from './TasksActionBar';

interface Props {
  procedureId?: string;
  projectId?: string;
}

export const TBQTasks = ({ projectId, procedureId }: Props) => {
  const { searchParams } = useRouting();
  const { object } = useObjectDetailContext();
  const { tasks = [] } = object || {};

  const columns = useTBQTaskColumns();
  const taskId = searchParams.get('id') ?? undefined;

  const {
    replaceAllItems,
    state: { items, itemsInitial },
  } = useOptimisticResponseContext<Task>();

  const tasksFiltered = useMemo(
    () =>
      tasks?.filter(
        (t) => t.type === TaskType.Tbq || t.type === TaskType.TbqScan
      ) || [],
    [tasks]
  ) as Task[];

  useEffect(() => {
    if (items.length !== tasksFiltered.length) {
      replaceAllItems(tasksFiltered);
    }
  });

  if (!procedureId && !items.length && !itemsInitial.length)
    return (
      <Box sx={{ mt: '40px' }}>
        <BigMessageNoTasks />
      </Box>
    );

  return (
    <TableContextProvider
      checkable
      checkableSpacing
      columns={columns}
      id="table-tbq-tasks"
      items={items}
    >
      <FilterContextProvider<Task>>
        <TasksActionBar />
        <Table
          onRowClick={({ id }) => {
            searchParams.set({ action: 'viewTask', id });
          }}
          selectedId={taskId}
        />
        <TBQTaskSidebar
          procedureId={procedureId}
          projectId={projectId}
          tasks={items}
        />
      </FilterContextProvider>
    </TableContextProvider>
  );
};

const Table = styled(UITable)`
  && {
    margin-top: 2rem;
  }
`;
