/* <SaveQueryForm> is a react-hook-form implementation for saving and editing "searches"
 *  A "saved search" contains:
 *  - general fields like name, id
 *  - search parameters
 *  - notification settings
 *
 *  In the save/load context, facets are treated as filters because
 *  it allows us to ignore the order in which options are faceted
 * */
import React, { useEffect, useMemo } from 'react';
import { FormProvider, Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { useSaveQueryMutation } from '@pro4all/graphql';
import { SavedSearch } from '@pro4all/graphql';
import { useAutoFocus } from '@pro4all/shared/hooks';
import { useRouting } from '@pro4all/shared/routing-utils';
import {
  toApiSearchUriComponent,
  toScheduleTimeInput,
} from '@pro4all/shared/search';
import { toFilterArray } from '@pro4all/shared/search-utils';
import { Button } from '@pro4all/shared/ui/buttons';
import { useIsQCSearchRoute } from '@pro4all/shared/ui/filtering';
import { Footer } from '@pro4all/shared/ui/footer';
import {} from '@pro4all/shared/ui/general';
import { TextField } from '@pro4all/shared/ui/inputs';
import { BigMessage } from '@pro4all/shared/ui/messages';
import { isDefined } from '@pro4all/shared/utils';

import { calculateScope } from '../calculateScope';
import { DialogMode } from '../DialogMode';
import { useOptimisticEdit } from '../useOptimisticEdit';

import { ToggleNotifications } from './sections/enable-notifications/ToggleNotifications';
import { SearchNotificationForm } from './NotificationFormContext';
import * as Styled from './SaveQueryForm.styles';
import { SavedSearchFields, SaveQueryFormProps } from './SaveQueryForm.types';

export const defaultNotificationTimeZone =
  Intl.DateTimeFormat().resolvedOptions().timeZone;

export const SaveQueryForm: React.FC<SaveQueryFormProps> = ({
  id,
  mode,
  name = '',
  notificationDays,
  notificationTimes,
  notificationsEnabled,
  onClose,
  searchFilter,
  scope,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const isQcSearchRoute = useIsQCSearchRoute();
  const { searchParams, params } = useRouting();
  const projectId = params.projectId;
  const paramQuery = searchParams.get('search') || '';
  const paramFilters = searchParams.get('filters') || '';
  const nameInputRef = useAutoFocus();

  const [saveQueryMutation] = useSaveQueryMutation({
    onCompleted: () => {
      onClose();
      enqueueSnackbar(t('Search saved successfully'), { variant: 'success' });
    },
    onError: () =>
      enqueueSnackbar(t('Something went wrong'), { variant: 'error' }),
  });

  const editQueryMutation = useOptimisticEdit(id);

  const editQuery = async ({
    name,
    notificationDays,
    notificationTimes,
    notificationsEnabled,
  }: SavedSearch) => {
    const filterArray = toFilterArray(paramFilters);
    const searchUriComponent = toApiSearchUriComponent({
      filters: filterArray,
      query: paramQuery,
    });

    await editQueryMutation({
      variables: {
        documentType: isQcSearchRoute ? 'qualitycontrol' : 'document',
        filters: toFilterArray(searchFilter || null),
        id,
        name,
        notificationDays,
        notificationTimeZone: defaultNotificationTimeZone,
        notificationTimes: notificationTimes
          ?.filter(isDefined)
          ?.map(toScheduleTimeInput),
        notificationsEnabled,
        projectId,
        query: paramQuery,
        searchUriComponent,
      },
    });
    onClose();
  };

  const saveQuery = async ({
    name,
    notificationDays,
    notificationTimes,
    notificationsEnabled,
  }: SavedSearchFields) => {
    const filterArray = toFilterArray(paramFilters);
    const searchUriComponent = toApiSearchUriComponent({
      filters: filterArray,
      query: paramQuery,
    });
    await saveQueryMutation({
      variables: {
        documentType: isQcSearchRoute ? 'qualitycontrol' : 'document',
        filters: paramFilters,
        name,
        notificationDays,
        notificationTimeZone: defaultNotificationTimeZone,
        notificationTimes,
        notificationsEnabled,
        projectId,
        query: paramQuery,
        scope: calculateScope(scope),
        searchUriComponent,
      },
    });
  };

  const resolver: Resolver<SavedSearchFields> = async (values) => {
    const nameRequiredError =
      !values.name || !values.name.length
        ? {
            name: { message: t('Name is required'), type: 'required' },
          }
        : null;
    const daysRequiredError =
      values.notificationsEnabled && (values?.notificationDays || []).length < 1
        ? {
            notificationDays: {
              message: t('Select at least one day'),
              minLength: 1,
              type: 'min',
            },
          }
        : null;
    let errors = {};
    if (daysRequiredError) errors = { ...errors, ...daysRequiredError };
    if (nameRequiredError) errors = { ...errors, ...nameRequiredError };

    return {
      errors,
      values,
    };
  };

  const defaultValues = useMemo(
    () => ({
      name,
      notificationDays,
      notificationTimes,
      notificationsEnabled,
    }),
    [name, notificationDays, notificationTimes, notificationsEnabled]
  );

  const form = useForm<any>({
    defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver,
    shouldFocusError: true,
    shouldUnregister: false,
  });
  const { errors, isValid, isSubmitting } = form.formState;

  const mutation = mode === DialogMode.Create ? saveQuery : editQuery;

  /* Hack: for some reason the form is not initialized with default values in org routes.
   We reset the form when we detect a mismatch between initial values and form data. */
  useEffect(() => {
    if (!form.getValues('name') && Boolean(defaultValues.name)) {
      form.reset(defaultValues);
    }
  }, [defaultValues, form]);

  const noContent = mode === DialogMode.Edit && (!id?.length || !name.length);
  if (noContent)
    return <BigMessage shapeName="build" title={t('Search not found')} />;

  return (
    <FormProvider<any> {...form}>
      <Styled.Form
        aria-label="Save query form"
        onSubmit={form.handleSubmit(mutation)}
      >
        <TextField
          defaultValue={name}
          error={Boolean(errors?.name)}
          helperText={errors?.name?.message}
          inputRef={nameInputRef}
          label={`* ${t('Name')}`}
          name="name"
          onBlur={() => form.trigger('name')}
          onChange={(e) => {
            form.setValue('name', e.target.value, { shouldValidate: true });
            form.trigger('name');
          }}
        />
        <ToggleNotifications />
        <SearchNotificationForm />
        <Footer mt={2}>
          <Button
            color="inherit"
            data-testid="close-save-query-dialog"
            onClick={onClose}
            startIcon="close"
            type="button"
          >
            {t('Cancel')}
          </Button>
          <Button
            autoFocus
            data-testid="submit"
            disabled={!isValid || isSubmitting}
            startIcon="save"
            type="submit"
            variant="contained"
          >
            {t('Save')}
          </Button>
        </Footer>
      </Styled.Form>
    </FormProvider>
  );
};
