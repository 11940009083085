import React, { useEffect, useState } from 'react';
import { Route, Routes as RouterDomRoutes } from 'react-router-dom';

import {
  CollectionFolder,
  DocumentFolderTree,
  SmartFolders,
  UploadSpreadsheetContextProvider,
  useFolderTreeContextOuter,
} from '@pro4all/documents/ui/folders';
import { Document, Folder, SmartFolder } from '@pro4all/graphql';
import { Routes } from '@pro4all/shared/config';
import {
  Collapse,
  Divider,
  useMediaQuery,
  useTheme,
} from '@pro4all/shared/mui-wrappers';
import { breakpoints } from '@pro4all/shared/themes';
import {
  DataViewContextProvider,
  DataViewType,
} from '@pro4all/shared/ui/data-view';
import { Resizable } from '@pro4all/shared/ui/general';
import { ContentNavWrap, ContentRow, Main } from '@pro4all/shared/ui/layout';
import {
  OptimisticResponseProvider,
  TableContent,
  useOptimisticResponseContext,
} from '@pro4all/shared/ui/table';

import { TableRoutes } from './TableRoutes';
import TreeCollapseButton from './TreeCollapseButton';

export const DmsContent: React.FC = () => {
  const { resetInitialItems } = useOptimisticResponseContext<Document>();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { state: treeState } = useFolderTreeContextOuter();
  const path = treeState?.path;

  const [treeOpen, setTreeOpen] = useState(!isMobile);
  const [activeFolder, setActiveFolder] = useState<Folder | null>(null);
  const [collapseOrientation, setCollapseOrientation] = React.useState<
    'horizontal' | 'vertical'
  >(isMobile ? 'vertical' : 'horizontal');

  useEffect(() => {
    setTreeOpen(false);
  }, [activeFolder]);

  useEffect(() => {
    const newActiveFolder = treeState.folders.find(
      (item) => item.path === path
    );
    if (
      (newActiveFolder && !activeFolder) ||
      (!newActiveFolder && activeFolder) ||
      (newActiveFolder &&
        activeFolder &&
        newActiveFolder.id !== activeFolder.id)
    ) {
      setActiveFolder(newActiveFolder);
    }
  }, [treeState, path, activeFolder]);

  useEffect(() => {
    setTreeOpen(!isMobile);
    setCollapseOrientation(isMobile ? 'vertical' : 'horizontal');
  }, [isMobile]);

  const Folders = (
    <Resizable threshold={breakpoints?.values.md}>
      <FolderZone resetInitialItems={resetInitialItems} />
    </Resizable>
  );

  return (
    <>
      <Divider />
      <Main>
        <ContentRow>
          {isMobile ? (
            <>
              <Collapse
                in={treeOpen}
                orientation={collapseOrientation}
                sx={{ height: isMobile ? 'initial' : 'auto', paddingLeft: 1 }}
              >
                {Folders}
              </Collapse>
              <TreeCollapseButton
                onClick={() => {
                  setTreeOpen(!treeOpen);
                }}
                open={treeOpen}
              />
            </>
          ) : (
            Folders
          )}
          <TableContent>
            <DataViewContextProvider
              initialView={DataViewType.Table}
              viewKey="documents"
              views={[DataViewType.Table, DataViewType.Cards]}
            >
              <RouterDomRoutes>
                <Route element={<TableRoutes />} path={Routes.foldersPath} />
              </RouterDomRoutes>
            </DataViewContextProvider>
          </TableContent>
        </ContentRow>
      </Main>
    </>
  );
};

const FolderZone = ({
  resetInitialItems,
}: {
  resetInitialItems: () => void;
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <ContentNavWrap
      height={isMobile ? '400px' : null}
      maxHeight={isMobile ? '400px' : null}
    >
      <CollectionFolder />
      <OptimisticResponseProvider<SmartFolder>>
        <SmartFolders resetInitialItems={resetInitialItems} />
      </OptimisticResponseProvider>
      <UploadSpreadsheetContextProvider>
        <DocumentFolderTree />
      </UploadSpreadsheetContextProvider>
    </ContentNavWrap>
  );
};
