import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Chip, Collapse, Typography } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { customColors } from '@pro4all/shared/themes';
import { IconButton } from '@pro4all/shared/ui/buttons';
import { Icon } from '@pro4all/shared/ui/icons';
import { IconName } from '@pro4all/shared/ui/icons';

type CardToggleProps = {
  children: React.ReactNode;
  count?: number;
  icon: IconName;
  label: string;
};

export const useCardToggle = ({
  identifier,
  isFallback = false,
}: {
  identifier: string;
  isFallback?: boolean;
}) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);

  const { searchParams } = useRouting();

  const handleToggle = () => {
    if (!expanded) {
      searchParams.set('tab', identifier);
    } else {
      searchParams.delete('tab');
    }
    setExpanded(!expanded);
  };

  useEffect(() => {
    const currentSection = searchParams.get('tab');
    if (currentSection === identifier) {
      setExpanded(true);
    }
  }, [identifier, searchParams, setExpanded]);

  useEffect(() => {
    const currentSection = searchParams.get('tab');
    if (isFallback && !currentSection) {
      setExpanded(true);
    }
  }, []); // Empty dependancy array, because we want to trigger this useEffect only on mounting.

  const CardToggle = ({ count, icon, label, children }: CardToggleProps) => (
    <Box
      sx={{
        borderBottom: `1px solid ${customColors.grey400}`,
        overflow: 'hidden',
      }}
    >
      <Box
        onClick={handleToggle}
        sx={{
          alignItems: 'center',
          backgroundColor: customColors.grey100,
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'space-between',
          p: 2,
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <Box sx={{ mr: 1 }}>
            <Icon iconName={icon} />
          </Box>
          <Box sx={{ display: 'flex' }}>
            <Typography variant="body1">{t(label)}</Typography>
            {count || count === 0 ? (
              <Box sx={{ ml: 2 }}>
                <Chip label={count} size="small" />
              </Box>
            ) : null}
          </Box>
        </Box>
        <IconButton
          color="inherit"
          disableBorder
          iconName={expanded ? 'expandLess' : 'expandMore'}
        />
      </Box>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Box sx={{ backgroundColor: customColors.grey100 }}>{children}</Box>
      </Collapse>
    </Box>
  );

  return { CardToggle, expanded };
};
