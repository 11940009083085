import React from 'react';
import dayjs from 'dayjs';

import { Task as TaskType } from '@pro4all/graphql';
import { useTakePrimaryThemeColor } from '@pro4all/shared/themes';
import { Chip } from '@pro4all/shared/ui/general';
import { getFormattedDate } from '@pro4all/shared/ui/timestamp';

export const EndTimeChip: React.FC<Pick<TaskType, 'status' | 'endTime'>> = ({
  status,
  endTime,
}) => {
  const { takePrimaryThemeColor } = useTakePrimaryThemeColor();
  const isDone = status === 'Done';

  if (!endTime) {
    return (
      <Chip
        color="default"
        icon="calendar"
        label="No date"
        variant="outline"
      ></Chip>
    );
  }

  const { dayJsDate, label } = getFormattedDate(endTime);
  const now = dayjs();
  const difference = dayJsDate.diff(now, 'day');

  if (isDone || difference > 3) {
    return (
      <Chip
        color={takePrimaryThemeColor ? 'primary' : 'secondary'}
        icon="calendar"
        label={label}
        variant="outline"
      ></Chip>
    );
  }

  if (difference < 0) {
    return (
      <Chip
        color="error"
        icon="calendar"
        label={label}
        variant="outline"
      ></Chip>
    );
  }

  return (
    <Chip color="warning" icon="alarm" label={label} variant="outline"></Chip>
  );
};
