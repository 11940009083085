import { Navigate, useLocation, useParams } from 'react-router-dom';

const RedirectDeprecatedRoutes = ({ newRoute }: { newRoute: string }) => {
  const location = useLocation();
  const params = useParams();

  let targetPath = newRoute;
  Object.entries(params).forEach(([key, value]) => {
    if (value) {
      targetPath = targetPath.replace(`:${key}`, value);
    }
  });
  const sanitizedTargetPath = targetPath.replace(/\?/g, '');
  const searchString = location.search;
  const targetUrl = `${sanitizedTargetPath}${searchString}`;

  return <Navigate replace state={location.state} to={targetUrl} />;
};

export default RedirectDeprecatedRoutes;
