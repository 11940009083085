import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TbqRieResult, useGetTbqRieResultQuery } from '@pro4all/graphql';
import { Box } from '@pro4all/shared/mui-wrappers';
import { generateRoute, useRouting } from '@pro4all/shared/routing-utils';
import { OptimisticResponseProvider } from '@pro4all/shared/ui/table';

import { Main, StyledButton } from './ResultsUI.styles';
import { RieCardsList } from './RieCardsList';
import { RieTaskResults } from './TBQRieTaskResults';
import { useCountRieResultsByRiskLevel } from './useCountRieResultsByRiskLevel';

export function TBQRieResults() {
  const countRieResultsByRiskLevel = useCountRieResultsByRiskLevel();
  const { t } = useTranslation();
  const {
    params: { objectId = '', taskId = '' },
    goTo,
  } = useRouting();

  generateRoute('objectTBQRieResults', {
    params: { objectId, taskId },
  });

  const back = () => goTo('objectTasks', { params: { objectId } });

  const { data, error, loading } = useGetTbqRieResultQuery({
    fetchPolicy: 'no-cache',
    variables: { taskId },
  });

  const rieResult = data?.getTBQRieResult ?? undefined;

  const results = useMemo(() => rieResult || [], [rieResult]) as TbqRieResult[];

  const rieRiskQuestionsCount = countRieResultsByRiskLevel(results);

  const resultCounts = results.length > 0 ? rieRiskQuestionsCount : null;

  return (
    <Main>
      <StyledButton
        data-testid="close-instance"
        onClick={back}
        startIcon="arrowBack"
        variant="outlined"
      >
        {t('Go back')}
      </StyledButton>
      {Boolean(resultCounts) && <RieCardsList resultCount={resultCounts} />}
      <Box flex="1" mt={3} mx={-3}>
        <OptimisticResponseProvider<TbqRieResult>>
          <RieTaskResults
            error={error}
            loading={loading}
            rieResults={results}
          />
        </OptimisticResponseProvider>
      </Box>
    </Main>
  );
}
