import { useCallback } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { MessageFormFields } from '../types';

export const useAttachmentHandlers = (
  form: UseFormReturn<MessageFormFields>
) => {
  const handleDeleteAttachment = useCallback(
    ({ completed, id }: { completed: boolean; id: string }) => {
      const values = form.getValues();

      if (completed) {
        const filteredAttachments = values.attachments?.filter(
          (attachment) => attachment.fileId !== id
        );
        form.setValue('attachments', filteredAttachments, {
          shouldValidate: true,
        });
      }

      const name = values.attachments?.find(
        (attachment) => attachment.fileId === id
      )?.fileName;

      if (name) {
        const filteredFiles = values.newFiles?.filter(
          (newFile) => newFile.name !== decodeURIComponent(name)
        );
        form.setValue('newFiles', filteredFiles, { shouldValidate: true });
      }

      form.trigger();
    },
    [form]
  );

  return { handleDeleteAttachment };
};
