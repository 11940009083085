import { useTranslation } from 'react-i18next';

import { createTabValue, Routes } from '@pro4all/shared/config';
import { generateRoute, useRouting } from '@pro4all/shared/routing-utils';
import { SubTabs, Tab } from '@pro4all/shared/ui/general';

export const Tabs = () => {
  const { t } = useTranslation();
  const { params } = useRouting();
  const projectId = params.projectId;
  const objectId = params.objectId;

  const base = !projectId
    ? `${generateRoute('objectsBaseUrl', {
        params: { objectId },
      })}/`
    : `${generateRoute('projectObjectsUrl', {
        params: { objectId, projectId },
      })}/`;

  return (
    <SubTabs base={base}>
      <Tab
        data-testid="tbq-tab"
        label={t('TBQ')}
        value={createTabValue(Routes.tasks)}
      />
      <Tab
        data-testid="floor-plans-tab"
        label={t('Drawings')}
        value={createTabValue(Routes.drawings)}
      />
      <Tab
        data-testid="results"
        label={t('Results')}
        value={createTabValue(Routes.results)}
      />
      <Tab
        data-testid="tasks-tab"
        label={t('Tasks')}
        value={createTabValue(Routes.tbqResolveTasks)}
      />
      <Tab
        data-testid="osid-log-tab"
        label="Osid log"
        value={createTabValue(Routes.osid)}
      />
    </SubTabs>
  );
};
