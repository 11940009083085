import { deepmerge } from '@mui/utils';

import { colorsGeneric } from './colorsGeneric';
import { colorsPave } from './colorsPave';
import { colorsPro4all } from './colorsPro4all';
import { colorsTbq } from './colorsTbq';

// Define the individual types based on the color objects
type ColorsPave = typeof colorsPave;
type ColorsPro4all = typeof colorsPro4all;
type ColorsTbq = typeof colorsTbq;
type ColorsGeneric = typeof colorsGeneric;

// Combine all color types into one type
export type CustomColorsType = ColorsPave &
  ColorsPro4all &
  ColorsTbq &
  ColorsGeneric;

export const customColors = [
  colorsGeneric,
  colorsPave,
  colorsPro4all,
  colorsTbq,
].reduce(
  (result, current) => deepmerge(result, current),
  {}
) as CustomColorsType; // Make sure customColors has the right type

// Define CustomColor as a string literal union of all keys
export type CustomColor = keyof CustomColorsType;
