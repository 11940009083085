import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { EmailActions, Message, useMessageBranchQuery } from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import { StorageKeys } from '@pro4all/shared/config';
import { Box, useMediaQuery, useTheme } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Loader } from '@pro4all/shared/ui/loader';
import { Timestamp } from '@pro4all/shared/ui/timestamp';
import { Text } from '@pro4all/shared/ui/typography';
import { getInitials } from '@pro4all/shared/utils';

import { useOptimisticDeleteDraft } from '../../mutation-utils/apollo-cache/useOptimisticDeleteDraft';
import { useOptimisticMarkMessage } from '../../mutation-utils/apollo-cache/useOptimisticMarkMessage';
import { useSubmitMessage } from '../../mutation-utils/useSubmitMessage';
import { useIsDraft } from '../message-utils/useIsDraft';
import { MessageConfirmationDialog } from '../MessageConfirmationDialog';
import { MessageFormFields } from '../types';

import { CollapsibleFields } from './CollapsibleFields';
import { HeaderRow, HeaderWrap, Initials } from './Header.styles';
import { HeaderControls } from './HeaderControl';

export const Header: React.FC<{
  handleDebounceDraftSave: () => void;
  isSubmitting: boolean;
  isUploading: boolean;
  message: Message;
  setIsSubmitting: (isSubmitting: boolean) => void;
}> = ({
  message,
  isUploading,
  handleDebounceDraftSave,
  setIsSubmitting,
  isSubmitting,
}) => {
  const { t } = useTranslation();
  const { goTo, params, searchParams } = useRouting();
  const { projectId } = params;
  const navigate = useNavigate();
  const { meData, userDisplayName } = useOrganizationContext();
  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const actionParam = searchParams.get('action');

  const sendAction: EmailActions =
    EmailActions[actionParam as keyof typeof EmailActions] ?? null;

  const formContext = useFormContext<MessageFormFields>();
  const { getValues, formState } = formContext;
  //TODO: Look into using this for headers
  const { errors } = formState;
  const currentValues = getValues();
  const hasErrors = Object.values(errors).length > 0;

  const submitDisabled =
    hasErrors || !currentValues.to?.length || isUploading || isSubmitting;
  const discardDisabled = isUploading || isSubmitting;
  const shouldSkipCounterUpdate = true;
  const [markMessage] = useOptimisticMarkMessage(shouldSkipCounterUpdate);
  const [deleteDraftMessage] = useOptimisticDeleteDraft();

  const { refetch } = useMessageBranchQuery({
    fetchPolicy: 'cache-only',
    refetchWritePolicy: 'merge',
    variables: {
      authorName: userDisplayName || 'unknown',
      messageId: message.id,
      projectId,
    },
  });

  const submitNew = useSubmitMessage({
    action: sendAction,
    messageId: message.id,
    threadId: message.threadId,
  });
  const readOnly = message.id !== 'new';
  const isMain = message.id === params.messageId;

  const isDraft = useIsDraft(message);

  const [noSubjectDialogOpen, setNoSubjectDialogOpen] = useState(false);
  const [discardingDraft, setDiscardingDraft] = useState(false);

  if (!meData) return <Loader />;

  const { displayName } = message?.createdBy || {};
  const initials = getInitials({ fullName: displayName || '' });

  const submit = async () => {
    await submitNew({
      onError: () => {
        enqueueSnackbar(
          `${t('Something went wrong')}. ${t('Please try again')}.`
        );
      },
      onSuccess: (newMessageId) => {
        (async () => {
          goTo(params.projectId ? 'viewProjMessage' : 'messages', {
            params: {
              ...params,
              messageId: newMessageId,
            },
          });
        })();
      },
      values: getValues(),
    });
    refetch();
  };

  const discard = async () => {
    searchParams.set('submitting', 'true');
    await deleteDraftMessage({
      variables: {
        id: message.id,
        threadId: message.threadId,
      },
    });
    searchParams.delete('action');
    searchParams.delete('submitting');
    setIsSubmitting(false);
    const location = projectId ? 'projectMessagesDraft' : 'orgDraftMessages';

    goTo(location, {
      params: { projectId },
    });
  };

  const handleSubmit = async () => {
    searchParams.set('submitting', 'true');
    setIsSubmitting(true);
    if (!currentValues.subject) setNoSubjectDialogOpen(true);
    else await submit();
    setIsSubmitting(false);
    const fromDms = searchParams.get('dmsAttachment') === 'true';
    const storageKey = `${StorageKeys.MESSAGE_ATTACHMENTS}-${
      projectId ?? 'organization'
    }`;
    const sessionStorageEntry = sessionStorage.getItem(storageKey);
    if (fromDms && sessionStorageEntry) {
      sessionStorage.removeItem(storageKey);
    }
  };

  const handleDiscard = async () => {
    setIsSubmitting(true);
    setDiscardingDraft(true);
  };

  const initReply = (action: EmailActions) => {
    goTo(params.projectId ? 'projectMessagesBaseUrl' : 'organizationMessages', {
      params: { ...params, messageId: 'new' },
      searchParams: {
        action: action,
        replyId:
          action === EmailActions.Resend ? message.parentId || '' : message.id,
        resendId: action === EmailActions.Resend ? message.id : undefined,
      },
    });
  };

  return (
    <>
      <MessageConfirmationDialog
        bodyKey="Do you want to send this message without a subject?" //I18n
        confirm={submit}
        open={noSubjectDialogOpen}
        setOpen={setNoSubjectDialogOpen}
        titleKey="No subject" //I18n
      />

      <MessageConfirmationDialog
        bodyKey="Do you want to discard this draft?" //I18n
        confirm={discard}
        open={discardingDraft}
        setOpen={setDiscardingDraft}
        titleKey="Discarding draft" //I18n
      />
      <HeaderWrap>
        <HeaderRow>
          <Box
            sx={{ flexDirection: isMobile ? 'column' : 'row', width: '100%' }}
          >
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
              }}
            >
              {!isMobile && <Initials $size={24}>{initials}</Initials>}
              <Text variant="h5">{displayName}</Text>
            </Box>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
              }}
            >
              <Timestamp date={message.createdAt} format="L HH:mm" />
              <HeaderControls
                discardDisabled={discardDisabled}
                handleDiscard={handleDiscard}
                handleSubmit={handleSubmit}
                initReply={initReply}
                isDraft={isDraft}
                isMain={isMain}
                readOnly={readOnly}
                submitDisabled={submitDisabled}
              />
            </Box>
          </Box>
        </HeaderRow>
        <HeaderRow>
          <CollapsibleFields
            expanded
            handleDebounceDraftSave={handleDebounceDraftSave}
            message={message}
          />
        </HeaderRow>
      </HeaderWrap>
    </>
  );
};
