import React, { ReactElement } from 'react';

import { Task, User, useSerieByIdQuery } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Option } from '@pro4all/shared/types';

import { SeriesCard } from './SeriesCard';
import { SeriesCardWrapper } from './SeriesCard.styled';

interface TaskSeriesCardContainerProps {
  isViewMode?: boolean;
  onRemove?: () => void;
  task: Task;
}

export const TaskSeriesCardContainer: React.FC<
  TaskSeriesCardContainerProps
> = ({ task, isViewMode = true, onRemove }): ReactElement | null => {
  const { data, loading } = useSerieByIdQuery({
    skip: !task.serieId,
    variables: { serieId: task.serieId || '' },
  });
  const { goTo, params } = useRouting();

  if (loading || !data?.serieById || !task.serieId) {
    return null;
  }

  const serie = data.serieById;

  const user: User | null =
    task.assignment && task.assignment.length > 0
      ? task.assignment[0]
      : serie.taskTemplate?.assignment &&
        serie.taskTemplate.assignment.length > 0
      ? serie.taskTemplate.assignment[0]
      : null;

  const formatDate = (dateString?: string) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  const startDate = formatDate(serie.frequency?.StartAt);
  const endDate = formatDate(serie.frequency?.StopAt);
  const seriesTitle = serie.taskTemplate?.name || '';

  const frequencyOption: Option | null = serie.frequency?.frequencyType
    ? {
        id: serie.frequency.frequencyType,
        label: serie.frequency.frequencyType,
      }
    : null;

  const serieData = {
    completedTasks: serie.completedTasks || 0,
    endDate,
    frequency: frequencyOption,
    planAhead: serie.planAheadTasks || 0,
    seriesTitle,
    startDate,
  };

  const handleSeriesCardClick = () => {
    if (!isViewMode) return;
    goTo('projectQualityControlRecurringSeries', {
      params: { projectId: params.projectId },
      searchParams: { action: 'viewSerie', id: serie.id },
    });
  };

  return (
    <SeriesCardWrapper
      isClickable={isViewMode}
      onClick={isViewMode ? handleSeriesCardClick : undefined}
    >
      <SeriesCard
        onRemove={isViewMode ? undefined : onRemove}
        serieData={serieData}
        user={user}
      />
    </SeriesCardWrapper>
  );
};
