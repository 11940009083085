import { useTranslation } from 'react-i18next';

import { FinalizeAction } from '@pro4all/documents/ui/finalize';
import { ActionProps, useCentralActions } from '@pro4all/shared/actions';
import { useDocumentActionsLabels } from '@pro4all/shared/label-config';

import { DocumentActionProps } from '../../types';

import { useHandleFinalizeOrUnfinalize } from './useHandleFinalizeOrUnfinalize';

export const useFinalizeActions = ({
  allDocumentsExpected,
  allDocumentsFinalized,
  allDocumentsUnfinalized,
  folderId,
  hasFolderPermission,
  isLocked,
  organizationId,
  position,
  projectId,
  selection,
  selectionVersion,
  trackingPosition,
  versionIsNotCurrentVersion,
  uncheckAllRows,
  userId,
}: Pick<
  DocumentActionProps,
  | 'allDocumentsExpected'
  | 'allDocumentsFinalized'
  | 'allDocumentsUnfinalized'
  | 'folderId'
  | 'hasFolderPermission'
  | 'isLocked'
  | 'organizationId'
  | 'position'
  | 'projectId'
  | 'selection'
  | 'selectionVersion'
  | 'trackingPosition'
  | 'versionIsNotCurrentVersion'
  | 'uncheckAllRows'
  | 'userId'
>) => {
  const { t } = useTranslation();
  const { ACTION_FINALIZE, ACTION_FINALIZE_WITHDRAW } = useCentralActions();

  const { finalize_action_label } = useDocumentActionsLabels();

  const handleFinalizeOrUnfinalize = useHandleFinalizeOrUnfinalize({
    folderId,
    organizationId,
    position,
    projectId,
    selection,
    selectionVersion,
    trackingPosition,
    uncheckAllRows,
    userId,
  });

  const hideFinalizationActions = allDocumentsExpected || allDocumentsFinalized;

  const hideUnfinalizationActions =
    allDocumentsExpected || allDocumentsUnfinalized;

  const finalizeAction: ActionProps = {
    ...ACTION_FINALIZE,
    ariaLabel: finalize_action_label,
    disabled:
      !hasFolderPermission('FinalizeContent') ||
      hideFinalizationActions ||
      isLocked ||
      versionIsNotCurrentVersion,
    label: finalize_action_label,
    onClick: () => handleFinalizeOrUnfinalize(FinalizeAction.Finalize),
  };

  const unfinalizeAction: ActionProps = {
    ...ACTION_FINALIZE_WITHDRAW,
    ariaLabel: t('Undo {{name}}', {
      name: finalize_action_label,
    }),
    disabled:
      !hasFolderPermission('FinalizeContent') ||
      hideUnfinalizationActions ||
      isLocked ||
      versionIsNotCurrentVersion,
    label: t('Undo {{name}}', { name: finalize_action_label }),
    onClick: () => handleFinalizeOrUnfinalize(FinalizeAction.Unfinalize),
  };

  return { finalizeAction, unfinalizeAction };
};
