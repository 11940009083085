import styled from 'styled-components';

import { customColors } from '@pro4all/shared/themes';
import { FloatingModal } from '@pro4all/shared/ui/floating-modal';

export const StyledFloatingModal = styled(FloatingModal)`
  &&& {
    .MuiDialog-paper {
      min-width: ${({ theme }) => theme.spacing(93.33)};
      min-height: ${({ theme }) => theme.spacing(66.67)};
    }
  }
`;

export const StyledDialogContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow: visible;
  padding: ${({ theme }) => theme.spacing(1.33)};
  gap: ${({ theme }) => theme.spacing(2.67)};
  margin-bottom: ${({ theme }) => theme.spacing(3.33)};
`;

export const Description = styled.p`
  font-size: 14px;
`;

export const FormRow = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2.67)};
`;

export const FormControl = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`;

export const Label = styled.label`
  font-size: 13px;
  margin-bottom: ${({ theme }) => theme.spacing(0.67)};
  color: ${customColors.grey900};
`;

export const FooterArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
