export enum ActionType {
  FILL_INITIAL_KEYWORDS = 'FILL_INITIAL_KEYWORDS',
  PARK_KEYWORDS = 'PARK_KEYWORDS',
  RESET_KEYWORDS = 'RESET_KEYWORDS',
}

export type DocumentKeyword = {
  id: string;
  oldText: string;
  score: number;
  text: string;
};

export type State = {
  keywords: DocumentKeyword[];
  keywordsForReset: DocumentKeyword[];
};

type ActionFillInitialPayload = {
  payload: DocumentKeyword[];
  type: ActionType.FILL_INITIAL_KEYWORDS;
};
type ActionParkKeywordsPayload = {
  type: ActionType.PARK_KEYWORDS;
};
type ActionResetKeywordsPayload = {
  type: ActionType.RESET_KEYWORDS;
};

export type Action =
  | ActionFillInitialPayload
  | ActionParkKeywordsPayload
  | ActionResetKeywordsPayload;

export function documentKeywordsReducer(state: State, action: Action) {
  switch (action.type) {
    case ActionType.FILL_INITIAL_KEYWORDS: {
      return {
        ...state,
        keywords: action.payload,
        keywordsEditMode: action.payload,
      };
    }
    case ActionType.PARK_KEYWORDS: {
      return {
        ...state,
        keywordsForReset: state.keywords,
      };
    }
    case ActionType.RESET_KEYWORDS: {
      return {
        ...state,
        keywords: state.keywordsForReset,
      };
    }
    default:
      return state;
  }
}
