import styled, { css, keyframes } from 'styled-components';

import { customColors } from '@pro4all/shared/themes';

export const CommentContainer = styled.div<{
  $hasFocus: boolean;
  $readOnly?: boolean;
}>`
  display: flex;
  flex-direction: column;
  min-height: 122px;
  padding: 0.5rem;
  border: 1px solid;
  border-color: ${({ $hasFocus }) =>
    $hasFocus
      ? ({ theme }) => theme.palette.primary.main
      : `${customColors.grey400}`};
  border-radius: 5px;
  &:hover {
    border-color: ${({ $hasFocus }) =>
      $hasFocus
        ? ({ theme }) => theme.palette.primary.main
        : `${customColors.grey400}`};
  }
  position: relative;
  gap: 0.5rem;
  animation-name: ${({ $hasFocus, $readOnly }) =>
    $hasFocus && $readOnly ? focusAnimation : 'none'};
  animation-duration: 1s;
  animation-delay: 0.5s;
`;

export const Left = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 1.5rem;
  align-items: center;
`;

export const Right = styled.div`
  display: flex;
  color: ${customColors.grey700};
  justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.75rem;
`;

export const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  time {
    font-size: 0.75rem;
  }
`;

const footer = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const EditCommentFooter = styled.div`
  ${footer}
  padding: 0.5rem 0;
`;

export const NewCommentFooter = styled.div`
  ${footer}
  padding: 0.5rem 0.5rem 0;
  display: flex;
  justify-content: flex-end;
`;

// TODO: Move background color to theme primary light
const focusAnimation = keyframes`
  0%    { background: none}
  50%   { background: ${customColors.pendingBg}}
  100%  { background: none}
`;
