import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Box } from '@pro4all/shared/mui-wrappers';
import { SelectDays, SelectTime } from '@pro4all/shared/ui/inputs';

import { SavedSearchFields } from './SaveQueryForm.types';

export const SearchNotificationForm: React.FC = () => {
  const { t } = useTranslation();
  const { control } = useFormContext<SavedSearchFields>();
  const notificationsEnabled =
    useWatch({ control, name: 'notificationsEnabled' }) || undefined;

  return (
    <>
      <Box>
        <Box fontWeight="bold" mb="8px">
          {t(
            'Select on which day(s) you would like to receive a notification.'
          )}
        </Box>
        <SelectDays enabled={notificationsEnabled} />
      </Box>
      <Box>
        <Box fontWeight="bold" mb="8px">
          {t(
            'Select at which time(s) you would like to receive a notification.'
          )}
        </Box>
        <SelectTime enabled={notificationsEnabled} />
      </Box>
    </>
  );
};
