import { Status, Task } from '@pro4all/graphql';
import { Option } from '@pro4all/shared/types';
import { CardStyle } from '@pro4all/shared/ui/general';

export const isTaskExpired = (task: Task): boolean => {
  if (!task.endTime) return false;
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const endDate = new Date(task.endTime);
  endDate.setHours(0, 0, 0, 0);

  return endDate < today;
};

export const getStatusOptions = (t: (key: string) => string): Option[] => [
  { id: 'all', label: t('All') },
  { id: Status.ToDo, label: t('To Do') },
  { id: Status.InProgress, label: t('In Progress') },
  { id: Status.Done, label: t('Done') },
];

export const getSortOptions = (t: (key: string) => string): Option[] => [
  { id: 'asc', label: t('Date (Ascending)') },
  { id: 'desc', label: t('Date (Descending)') },
];

export const filterTasksByStatus = (
  tasks: Task[] = [],
  selectedStatus: string
): Task[] => {
  if (selectedStatus === 'all') return [...tasks];
  return tasks.filter((task) => task.status === selectedStatus);
};

export const sortTasksByDate = (
  tasks: Task[] = [],
  sortOrder: 'asc' | 'desc'
): Task[] =>
  [...tasks].sort((a, b) => {
    const dateA = a.endTime ? new Date(a.endTime).getTime() : Infinity;
    const dateB = b.endTime ? new Date(b.endTime).getTime() : Infinity;
    return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
  });

export const filterTasks = (
  tasks: Task[] = [],
  selectedStatus: string,
  sortOrder: 'asc' | 'desc'
): Task[] => {
  const filteredByStatus = filterTasksByStatus(tasks, selectedStatus);
  return sortTasksByDate(filteredByStatus, sortOrder);
};

export const filterPlannedTasks = (tasks: Task[] | undefined): Task[] => {
  if (!tasks) return [];
  return tasks
    .filter((task) => {
      if (task.status === Status.Done) return false;
      return (
        task.status === Status.ToDo ||
        task.status === Status.InProgress ||
        isTaskExpired(task)
      );
    })
    .sort((a, b) => {
      const dateA = a.endTime ? new Date(a.endTime).getTime() : Infinity;
      const dateB = b.endTime ? new Date(b.endTime).getTime() : Infinity;
      return dateA - dateB;
    });
};

export const getCardStyle = (status: Status | null | undefined): CardStyle =>
  status === Status.Done ? 'disabled' : 'default';
