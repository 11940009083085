import { Project } from '@pro4all/graphql';
import { ProjectContextProvider } from '@pro4all/projects/ui/context';
import { OptimisticResponseProvider } from '@pro4all/shared/ui/table';

import { Projects } from './Projects';

export const ProjectsProviderWrapper = () => (
  <ProjectContextProvider>
    <OptimisticResponseProvider<Project>>
      <Projects />
    </OptimisticResponseProvider>
  </ProjectContextProvider>
);
