import React from 'react';

import { Box } from '@pro4all/shared/mui-wrappers';
import { CircularProgress } from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';
import { IconButton } from '@pro4all/shared/ui/buttons';
import { Icon } from '@pro4all/shared/ui/icons';
import { Tooltip } from '@pro4all/shared/ui/tooltip';

import { getTabIndex } from '../field-types/helpers';
import { UploaderEditorTypes } from '../types';
import { ProcessingStatusMeta } from '../useUploaderEditorReducer';
import {
  ContentCellWrapper,
  ContentColumnWrapper,
  HeaderWrapper,
  ResizableColumnWrapper,
} from '../wrappers';

export const FileRemoveAndUploadStatusColumn = ({
  deleteFileFromProcessingList,
  initialTabIndex,
  meta,
  noOfInputs,
}: Pick<
  UploaderEditorTypes,
  'deleteFileFromProcessingList' | 'initialTabIndex' | 'meta' | 'noOfInputs'
>) => (
  <ResizableColumnWrapper defaultWidth={40} zIndexResizableColumnDown={1}>
    <HeaderWrapper />
    <ContentColumnWrapper>
      {meta.map(({ id, processingStatus }, index) => {
        const tabIndex = getTabIndex({ index, initialTabIndex, noOfInputs });
        return (
          <FileRemoveAndUploadStatusCell
            deleteFileFromProcessingList={deleteFileFromProcessingList}
            id={id}
            key={id}
            processingStatus={processingStatus}
            tabIndex={tabIndex}
          />
        );
      })}
    </ContentColumnWrapper>
  </ResizableColumnWrapper>
);

type FileRemoveAndUploadStatusCellProps = {
  id: string;
  processingStatus: ProcessingStatusMeta;
} & Pick<UploaderEditorTypes, 'deleteFileFromProcessingList' | 'tabIndex'>;

const FileRemoveAndUploadStatusCellComponent = ({
  deleteFileFromProcessingList,
  id,
  processingStatus,
  tabIndex,
}: FileRemoveAndUploadStatusCellProps) => {
  const onDeleteFileFromUploadList = (id: string) =>
    deleteFileFromProcessingList && deleteFileFromProcessingList(id);

  const circularIcon = <CircularProgress size={20} />;

  let icon = null;
  if (processingStatus) {
    if (processingStatus.isCurrentlyProcessing) {
      icon = circularIcon;
    } else {
      const htmlColor = processingStatus.successfullyProcessed
        ? 'greenMonoHighlight'
        : processingStatus.error
        ? 'errorAlert'
        : 'grey600';

      const iconName = processingStatus.successfullyProcessed
        ? 'checkCircleOutlined'
        : processingStatus.error
        ? 'error'
        : 'hourglassEmpty';

      const iconPlain = (
        <Icon htmlColor={customColors[htmlColor]} iconName={iconName} />
      );
      icon = processingStatus.error ? (
        <Tooltip placement="bottom" title={processingStatus.error}>
          {iconPlain}
        </Tooltip>
      ) : (
        iconPlain
      );
    }
  } else {
    icon = (
      <IconButton
        color="inherit"
        disableBorder
        iconName="close"
        onClick={() => onDeleteFileFromUploadList(id)}
        tabIndex={tabIndex}
      />
    );
  }

  const boxProps = { alignItems: 'center', justifyContent: 'center' };

  return (
    <ContentCellWrapper key={id}>
      <Box
        sx={{
          ...boxProps,
          display: processingStatus?.isCurrentlyProcessing ? 'flex' : 'none',
        }}
      >
        {circularIcon}
      </Box>
      <Box
        sx={{
          ...boxProps,
          display: processingStatus?.isCurrentlyProcessing ? 'none' : 'flex',
        }}
      >
        {icon}
      </Box>
    </ContentCellWrapper>
  );
};

const skipUpdate = (
  prevProps: FileRemoveAndUploadStatusCellProps,
  nextProps: FileRemoveAndUploadStatusCellProps
) => {
  if (prevProps.processingStatus && nextProps.processingStatus) {
    return (
      prevProps.processingStatus.error === nextProps.processingStatus.error &&
      prevProps.processingStatus.isCurrentlyProcessing ===
        nextProps.processingStatus.isCurrentlyProcessing &&
      prevProps.processingStatus.successfullyProcessed ===
        nextProps.processingStatus.successfullyProcessed &&
      prevProps.tabIndex === nextProps.tabIndex
    );
  } else {
    return false;
  }
};

const FileRemoveAndUploadStatusCell = React.memo(
  FileRemoveAndUploadStatusCellComponent,
  skipUpdate
);
