import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Task, TbqModuleType } from '@pro4all/graphql';
import { ActionProps } from '@pro4all/shared/actions';
import { useRouting } from '@pro4all/shared/routing-utils';
import { useTableCheck } from '@pro4all/shared/ui/table';
import { TableActionBar } from '@pro4all/shared/ui/table-action-bar';
import {
  DeleteTBQScanDialog,
  useNavigateToTBQResults,
} from '@pro4all/workflow/ui/task-sidebar';

export const TasksActionBar: React.FC = () => {
  const { t } = useTranslation();
  const { params, goTo } = useRouting();
  const { checkedRows } = useTableCheck<Task>();
  const [showRieMultiDashboardButton, setRieMultiDashboardButton] =
    useState<boolean>(false);
  const [showBrandcheckDashboardButton, setShowBrandcheckDashboardButton] =
    useState<boolean>(false);

  const objectId = params.objectId ?? '';
  const projectId = params.projectId;
  const isOrganizationLevel = Boolean(!projectId);

  const procedureId = checkedRows[0]?.procedureId;
  const allSameProcedure = checkedRows.every(
    (row) => row.procedureId === procedureId
  );

  const navigateToTBQResults = useNavigateToTBQResults({
    isOrganizationLevel,
    objectId,
    projectId,
    taskId: checkedRows[0]?.id ?? '',
  });
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);

  useEffect(() => {
    // Do not update states when deselecting items.
    // Instead use the action bar non selected hide animation
    if (checkedRows.length < 1) return;
    setShowBrandcheckDashboardButton(
      checkedRows.length === 1 &&
        checkedRows[0].tbq?.tbqModuleType === TbqModuleType.Brandcheck
    );

    const allRIEScans = checkedRows.every(
      (row) => row.tbq?.tbqModuleType === TbqModuleType.Rie
    );
    setRieMultiDashboardButton(allRIEScans);
  }, [checkedRows]);

  const altActions: ActionProps[] = [
    {
      ariaLabel: t('Dashboard'),
      disabled: !showBrandcheckDashboardButton,
      key: 'brandcheck-dashboard',
      label: t('Dashboard'),
      onClick: () => {
        navigateToTBQResults();
      },
      startIcon: 'dashboard',
    },
    {
      ariaLabel: t('Dashboard'),
      disabled: !showRieMultiDashboardButton,
      key: 'multi-rie-dashboard',
      label: t('Dashboard'),
      onClick: () => {
        goTo('objectTBQMultiRieResultsUrl', {
          params: { objectId },
          state: {
            taskIds: checkedRows.map((task: Task) => task.id),
          },
        });
      },
      startIcon: 'dashboard',
    },
    {
      ariaLabel: t('Delete'),
      hidden: !allSameProcedure,
      key: 'delete-scan',
      label: t('Delete'),
      onClick: () => {
        setShowDeletePrompt(true);
      },
      startIcon: 'delete',
    },
  ];

  return (
    <>
      <TableActionBar
        altActions={altActions}
        dataTestid="tbq-tasks-action-bar"
        search
      />
      <DeleteTBQScanDialog
        onClose={() => setShowDeletePrompt(false)}
        open={showDeletePrompt}
        procedureId={procedureId || ''}
        taskIds={checkedRows.map((row) => row.id)}
      />
    </>
  );
};
