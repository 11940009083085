import { Route, Routes as RouterDomRoutes } from 'react-router-dom';
import styled from 'styled-components';

import { IntegrationsTableProviderWrapper } from '@pro4all/settings/ui/src/integrations';
import {
  OrganizationDomains,
  OrganizationSettings,
} from '@pro4all/settings/ui/src/settings';
import {
  CancelSubscription,
  ChangeSubscription,
  CompanyDataRegistration,
  LandingPage,
  OrganizationSubscription,
} from '@pro4all/settings/ui/src/subscription';
import { Routes } from '@pro4all/shared/config';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { Box } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Column, Main } from '@pro4all/shared/ui/layout';

import { OrganizationOrganizationTabs } from '../components/tabs/OrganizationOrganizationTabs';
import { OrganizationProvisioning } from '../settings/src/lib/OrganizationProvisioning';
import { DocumentSettingsForm } from '../status-naming/src';

import { OrganizationTabContent } from './OrganizationTabContent';

export const OrganizationOrganization = () => {
  const { searchParams } = useRouting();

  const changeSubscriptionContext = searchParams.is(
    'action',
    'changeSubscription'
  );
  const cancelSubscriptionContext = searchParams.is(
    'action',
    'cancelSubscription'
  );
  const companyDataRegistrationContext = searchParams.is(
    'action',
    'companyDataRegistration'
  );

  const showLanding = searchParams.is('landing', 'true');

  const hasScim = useFeatureFlag('scim');

  return (
    <Main>
      <Column>
        <OrganizationOrganizationTabs />
        <RouterDomRoutes>
          <Route element={<OrganizationTabContent />} path={Routes.general} />

          <Route
            element={
              showLanding ? (
                <LandingPage />
              ) : changeSubscriptionContext ? (
                <ChangeSubscription />
              ) : cancelSubscriptionContext ? (
                <CancelSubscription />
              ) : companyDataRegistrationContext ? (
                <CompanyDataRegistration />
              ) : (
                <OrganizationSubscription />
              )
            }
            path={Routes.license}
          />

          <Route
            element={<IntegrationsTableProviderWrapper />}
            path={Routes.integrations}
          />

          <Route element={<DocumentSettingsForm />} path={Routes.docSettings} />
          <Route element={<OrganizationSettings />} path={Routes.security} />

          {hasScim && (
            <>
              <Route
                element={
                  <StyledContainerBox>
                    <Box maxWidth="850px">
                      <OrganizationDomains />
                    </Box>
                  </StyledContainerBox>
                }
                path={Routes.domains}
              />

              <Route
                element={
                  <StyledContainerBox>
                    <Box maxWidth="850px">
                      <OrganizationProvisioning />
                    </Box>
                  </StyledContainerBox>
                }
                path={Routes.provisioning}
              />
            </>
          )}
        </RouterDomRoutes>
      </Column>
    </Main>
  );
};

export const StyledContainerBox = styled(Box)`
  && {
    overflow: auto;
  }
`;
