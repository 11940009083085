import React from 'react';
import {
  Navigate,
  Route,
  Routes as RouterDomRoutes,
  useMatch,
} from 'react-router-dom';

import { ScopeType, TemplateService, TemplateType } from '@pro4all/graphql';
import { useTemplatesInclude } from '@pro4all/metadata/data-access';
import { Routes } from '@pro4all/shared/config';
import { generateRoute, useRouting } from '@pro4all/shared/routing-utils';

import { RedirectToOrganizationTemplates } from '../components/form-template/RedirectToOrganizationTemplates';
import { TemplateFrame } from '../components/form-template/TemplateFrame';

import { MetaDataContextProvider } from './MetaDataContext';
import { MetaDataMain } from './MetaDataMain';
import { useIncludeOrgItems } from './useIncludeOrgItems';

export const ProjectDocuments = () => {
  const { params, searchParams } = useRouting();

  const { projectId } = params;
  const fieldsPage = generateRoute('projectMetadataDocumentFieldsUrl', {
    params: { projectId },
  });
  const routeItems = useMatch(fieldsPage);
  const includeOrgItems = useIncludeOrgItems();
  const routeTemplates = useMatch(Routes.projectMetadataDocumentTemplatesUrl);

  const templateType = routeTemplates
    ? TemplateType.Document
    : routeItems
    ? TemplateType.Field
    : undefined;

  const templateService = TemplateService.Documents;

  const { selectedTemplate } = useTemplatesInclude({
    includeOrgItems,
    includeScope: true,
    templateService,
    templateType,
  });

  const createContext = searchParams.is('action', 'createTemplate');
  const editContext = searchParams.is('action', 'editSet');
  const scope = selectedTemplate?.scope.type;

  const redirect = `${generateRoute('projectMetadataDocumentTemplatesUrl', {
    params: { projectId },
  })}`;

  return (
    <MetaDataContextProvider
      templateService={templateService}
      templateType={templateType}
    >
      <RouterDomRoutes>
        <Route element={<Navigate to={redirect} />} path="/" />
      </RouterDomRoutes>

      {!createContext && !editContext ? (
        <MetaDataMain
          isProject
          pathFields={Routes.metaDataDocumentsFields}
          pathTemplates={Routes.metaDataDocumentsTemplates}
        />
      ) : (editContext && scope === ScopeType.Project) || createContext ? (
        <TemplateFrame selectedTemplate={selectedTemplate} />
      ) : (
        scope === ScopeType.Organization && (
          <RedirectToOrganizationTemplates
            selectedTemplate={selectedTemplate}
          />
        )
      )}
    </MetaDataContextProvider>
  );
};
