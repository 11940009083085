import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@pro4all/shared/mui-wrappers';
import { Icon } from '@pro4all/shared/ui/icons';
import { Tooltip } from '@pro4all/shared/ui/tooltip';
import { Text } from '@pro4all/shared/ui/typography';

import { InputWrapper } from './CreateSerieSection.styles';

export const SeriesHeader: React.FC = () => {
  const { t } = useTranslation();

  return (
    <InputWrapper>
      <Box display="flex" justifyContent="space-between">
        <Text variant="h4">
          <Icon iconName="clipboard" /> {t('Series settings')}
        </Text>
        <Tooltip
          title={t(
            'Create new series allows you to plan ahead tasks. You can set a max of 14 tasks.'
          )}
        >
          <Icon iconName="info" />
        </Tooltip>
      </Box>
    </InputWrapper>
  );
};
