export const createNavigationUrl = (route: string) => route.split('*').join('');
export const createTabValue = (route: string) => {
  if (route.endsWith('/')) {
    return route.slice(0, -1);
  } else if (route.endsWith('/*')) {
    return route.slice(0, -2);
  }
  return route;
};

const org = '/organization/*';
const proj = '/project/:projectId/*';
const orgBaseUrl = createNavigationUrl(org);

const tasks = 'tasks';
const results = 'results';
const notifications = 'notifications';
const savedAnswers = 'saved-answers';
const drawings = 'drawings';

const baseDms = `dms/*`;
const baseDocs = `docs/*`;

const documentsPath = 'documents/*';

const docs = `${baseDocs}/:path`;
const foldersPath = 'folders/*';
const documentTable = 'doc-table/';
const docTable = `${documentTable}:path/*`;
const orgDocumentTablesPath = `${org}${baseDocs}${foldersPath}`;
const orgDocumentsTablesPathUrl = createNavigationUrl(orgDocumentTablesPath);
const orgDms = createNavigationUrl(`${orgDocumentTablesPath}${docTable}`);
const orgDmsWithoutPath = createNavigationUrl(
  `${org}${baseDocs}${foldersPath}${documentTable}`
);
const orgDocsBaseUrl = createNavigationUrl(`${orgBaseUrl}${baseDocs}`);

const smartFolderTablePathBase = 'smart-folder-table/';
const smartFolderTable = `${smartFolderTablePathBase}:name`;

const projectFolderTablesPath = `${proj}${documentsPath}${foldersPath}`;
const projectDocumentsTablesPathUrl = createNavigationUrl(
  `${projectFolderTablesPath}`
);

const projectSmartFolderTableUrl = createNavigationUrl(
  `${projectFolderTablesPath}${smartFolderTable}`
);
const orgSmartFolderTableUrl = createNavigationUrl(
  `${orgDocumentTablesPath}${smartFolderTable}`
);

const collectionTable = 'collection-table';
const orgCollectionFolderUrl = createNavigationUrl(
  `${orgDocumentTablesPath}${collectionTable}`
);
const projectCollectionFolderUrl = createNavigationUrl(
  `${projectFolderTablesPath}${collectionTable}`
);

const projectDocTableUrl = createNavigationUrl(
  `${projectFolderTablesPath}${docTable}`
);
const projects = 'projects';
const orgProjects = createNavigationUrl(`${org}${projects}`);
const config = 'config/*';
const office = '/office';

const profile = 'profile/*';
const myApiKeyManagement = 'my-api-key-management';
const myApiKeyManagementUrl = createNavigationUrl(
  `${org}${profile}${myApiKeyManagement}`
);
const myProfile = 'my-profile';
const mySignature = 'my-signature';
const mySupport = 'my-support';
const mySupportUrl = createNavigationUrl(`${org}${profile}${mySupport}`);
const profileBaseUrl = createNavigationUrl(`${org}${profile}`);
const myProfileUrl = createNavigationUrl(`${profileBaseUrl}${myProfile}`);
const mySignatureUrl = createNavigationUrl(`${profileBaseUrl}${mySignature}`);
const myTasks = `my-tasks`;
const myOverview = 'my-overview/*';
const myProjects = `my-projects`;

const myTasksOverview = createNavigationUrl(`${org}${myOverview}${myTasks}`);
const myProjectsOverview = createNavigationUrl(
  `${org}${myOverview}${myProjects}`
);

const organizationTemplates = `templates/*`;
const projectTemplates = `templates/*`;
const templates = `templates`;
const projectTemplatesBaseUrl = createNavigationUrl(
  `${proj}${projectTemplates}`
);
const projectTaskTemplatesUrl = createNavigationUrl(
  `${proj}${projectTemplates}${tasks}`
);
const metaDataHierarchicalLists = `hierarchicallists/*`;

const orgMetadata = 'metadata/*';

const taskCategories = `taskcategories`;

const templatesMain = `main/*`;
const hierarchy = `hierarchy`;
const screen = 'screen';
const orgHierarchicalLists = createNavigationUrl(
  `${org}${organizationTemplates}${metaDataHierarchicalLists}${templatesMain}${hierarchy}`
);
const orgTaskCategories = createNavigationUrl(
  `${org}${organizationTemplates}${taskCategories}`
);

const metaDataDocumentScreenTemplates = `templates`;
const metadata = 'metadata/*';

const metaDataDocumentsFields = `fields`;
const metaDataDocumentsTemplates = `templates`;

const orgMetadataTemplates = createNavigationUrl(
  `${org}${organizationTemplates}${orgMetadata}${templatesMain}${metaDataDocumentScreenTemplates}`
);
const orgMetadataBaseUrl = createNavigationUrl(
  `${org}${organizationTemplates}`
);

const metaDataQualityControl = `config/metadata/qualitycontrol/*`;
const formTemplates = 'forms';
const fieldTemplates = 'fields';
const fields = 'fields';
const reportConfig = 'reportconfigurations';
const metaDataQualityControlForms = `forms`;
const snags = `snags`;
const templatesQuality = `quality/*`;
const metaDataQualityControlFields = `fields`;
const metaDataQualityControlReportConfigurations = `reportconfigurations`;
const metaDataQualityControlTemplates = `sets`;
const orgMetadataFieldTemplates = createNavigationUrl(
  `${org}${organizationTemplates}${orgMetadata}${templatesMain}${metaDataDocumentsFields}`
);

const orgQualityTemplates = `${org}${organizationTemplates}${templatesQuality}${templatesMain}`;
const orgQualityFormTemplates = createNavigationUrl(
  `${orgQualityTemplates}${metaDataQualityControlForms}`
);
const orgQualitySnagTemplates = createNavigationUrl(
  `${orgQualityTemplates}${snags}`
);
const orgQualityFieldTemplates = createNavigationUrl(
  `${orgQualityTemplates}${fieldTemplates}`
);
const orgQualityReportConfigurations = createNavigationUrl(
  `${orgQualityTemplates}${metaDataQualityControlReportConfigurations}`
);

const tbq = 'tbq';
const tbqTemplates = `${tbq}/:tbqScopeId?`;
const orgTemplatesTbqflat = createNavigationUrl(
  `${org}${organizationTemplates}${tbq}`
);
const metadataTbqTemplates = createNavigationUrl(
  `${org}${organizationTemplates}${tbqTemplates}`
);

const access = `access/*`;
const orgAccessBaseurl = createNavigationUrl(`${org}${access}`);
const documents = 'documents';
const projectDocumentsBaseUrl = createNavigationUrl(`${proj}${documentsPath}`);

const accessUrl = createNavigationUrl(`${org}${access}${documents}`);

const settingsOrganization = `${config}/organization`;
const configSettings = `settings/*`;
const configSettingsBase = `${org}${configSettings}`;
const configSettingsBaseUrl = createNavigationUrl(configSettingsBase);

const general = 'general';
const license = 'license';
const integrations = 'integrations';
const domains = 'domains';
const docSettings = 'docsettings';
const provisioning = 'provisioning';
const security = 'security';

const orgSettingsGeneral = createNavigationUrl(
  `${configSettingsBase}${general}`
);
const orgSettingsLicense = createNavigationUrl(
  `${configSettingsBase}${license}`
);
const orgSettingsIntegrations = createNavigationUrl(
  `${configSettingsBase}${integrations}`
);
const orgSettingsDomains = createNavigationUrl(
  `${configSettingsBase}${domains}`
);
const orgSettingsDocSettings = createNavigationUrl(
  `${configSettingsBase}${docSettings}`
);
const orgSettingsProvisioning = createNavigationUrl(
  `${configSettingsBase}${provisioning}`
);
const orgSettingsSecurity = createNavigationUrl(
  `${configSettingsBase}${security}`
);

const settings = `settings/*`;
const projectSettings = `project-settings`;
const projectSettingsBaseUrl = createNavigationUrl(`${proj}${settings}`);
const projectSettingsUrl = createNavigationUrl(
  `${proj}${settings}${projectSettings}`
);

const connections = `connections`;
const connectionsSettingsUrl = createNavigationUrl(
  `${proj}${settings}${connections}`
);
const projectDocumentSettingsUrl = createNavigationUrl(
  `${proj}${settings}${documents}`
);

const permissions = 'permissions/*';
const qualitycontrol = 'qualitycontrol';
const qualitycontrolPath = 'qualitycontrol/*';

const projectPermissionsBaseUrl = createNavigationUrl(`${proj}${permissions}`);
const projectDocumentPermissions = createNavigationUrl(
  `${proj}${permissions}${documents}`
);
const projectQualityPermissions = createNavigationUrl(
  `${proj}${permissions}${qualitycontrol}`
);
const projectTaskPermissions = createNavigationUrl(
  `${proj}${permissions}${tasks}`
);

const usersGroups = `usersgroups/*`;
const usersGroupsBase = `${org}${usersGroups}`;
const usersGroupsBaseUrl = createNavigationUrl(usersGroupsBase);
const colleagues = 'colleagues/*';
const members = `members`;
const groups = `groups`;

const projectMembersGroupsUrl = createNavigationUrl(`${proj}${colleagues}`);
const projectMembersUrl = createNavigationUrl(`${proj}${colleagues}${members}`);
const projectGroupsUrl = createNavigationUrl(`${proj}${colleagues}${groups}`);

const orgUsers = createNavigationUrl(`${usersGroupsBase}${members}`);
const orgGroups = createNavigationUrl(`${usersGroupsBase}${groups}`);

const organization = '/organization/*';
const confirmEmail = `${organization}/finalize/:token`;
const confirmEmailUrl = createNavigationUrl(confirmEmail);
const finalizeInvitation = '/user/finalize';
const finalizeInvitationUrl = createNavigationUrl(finalizeInvitation);

const projectTasks = createNavigationUrl(`${proj}${tasks}`);

const projectDmsBase = `${proj}${baseDms}`;
const docTableBase = `${proj}${baseDocs}`;
const document = `${docTableBase}/:folderId`;

const projectMetaDataDocuments = `${proj}config/metadata/documents`;
const projectHierarchicallistsValue = createNavigationUrl(
  `${metaDataHierarchicalLists}${screen}`
);
const projectMetaDataHierarchicalLists = createNavigationUrl(
  `${proj}${projectTemplates}${projectHierarchicallistsValue}`
);
const projectMetadataDocumentFieldsUrl = createNavigationUrl(
  `${projectTemplatesBaseUrl}${documentsPath}${fields}`
);
const projectMetadataDocumentTemplatesValue = createNavigationUrl(
  `${documentsPath}${templates}`
);

const projectMetadataDocumentTemplatesUrl = createNavigationUrl(
  `${projectTemplatesBaseUrl}${projectMetadataDocumentTemplatesValue}`
);
const projectMetaDataQualityControl = `${proj}${metaDataQualityControl}`;

const projectQualityControl = 'quality/*';
const projectQualityControlBaseUrl = createNavigationUrl(
  `${proj}${projectQualityControl}`
);

const projectMetadataQualityBaseUrl = createNavigationUrl(
  `${proj}${projectTemplates}${qualitycontrolPath}`
);

const projectMetadataQualityForms = createNavigationUrl(
  `${projectMetadataQualityBaseUrl}${formTemplates}`
);
const projectMetadataQualitySnagsUrl = createNavigationUrl(
  `${projectMetadataQualityBaseUrl}${snags}`
);

const projectMetadataQualityFieldsUrl = createNavigationUrl(
  `${projectMetadataQualityBaseUrl}${fieldTemplates}`
);
const projectMetaDataQualityControlReportConfigurations = `reportconfigurations`;

const projectMetadataQualityReport = createNavigationUrl(
  `${projectMetadataQualityBaseUrl}${projectMetaDataQualityControlReportConfigurations}`
);

const projectMetaDataQualityControlTemplates = `${projectMetaDataQualityControl}/templates`;

const projectQualityControlMain = `${proj}/qc`;
const projectQualityControlDrawings = `${projectQualityControlMain}/drawings/*`;
const projectQualityControlDrawing = `drawing/:visualContextId`;
const recurringSeries = 'recurringseries';

const projectQualityControlRecurringSeries = `${projectQualityControlBaseUrl}${recurringSeries}`;
const projectQualityControlResultsUrl = createNavigationUrl(
  `${projectQualityControlBaseUrl}${results}`
);

const projectQualityControlDrawingsUrl = createNavigationUrl(
  `${projectQualityControlBaseUrl}${drawings}`
);
const projectQualityControlDrawingUrl = createNavigationUrl(
  `${projectQualityControlBaseUrl}${projectQualityControlDrawing}`
);
const projectQualityControlTasksUrl = createNavigationUrl(
  `${projectQualityControlBaseUrl}${tasks}`
);

const projectQualityControlMySavedAnswersUrl = createNavigationUrl(
  `${projectQualityControlBaseUrl}${savedAnswers}`
);

const projectQualityControlNotificationsUrl = createNavigationUrl(
  `${projectQualityControlBaseUrl}${notifications}`
);

const projectAccess = `${proj}${access}`;

const sharedDocs = `shared`;
const revoke = 'revoke';
const publiclySharedDocsBase = `${sharedDocs}/public`;
const publiclySharedDocs = `/${publiclySharedDocsBase}/:linkId?`;
const revokePublicLink = `${publiclySharedDocs}/${revoke}`;

const docsSharedByInvite = `${sharedDocs}/private/:linkId`;
const orgDocsSharedByInviteUrl = createNavigationUrl(
  `${org}${docsSharedByInvite}`
);
const projectDocsSharedByInvite = createNavigationUrl(
  `${proj}${docsSharedByInvite}`
);

const docsSharedInScope = `${sharedDocs}/scoped/:linkId`;
const orgDocsSharedInScopeUrl = createNavigationUrl(
  `${org}${docsSharedInScope}`
);
const projectDocsSharedInScope = createNavigationUrl(
  `${proj}${docsSharedInScope}`
);

const objectsRoot = 'objects';
const objects = `objects/:objectId?/*`;
const osid = 'osid';

const objectsBaseUrl = createNavigationUrl(`${org}${objects}`);
const objectsUrl = createNavigationUrl(`${org}${objects}`);
const projectObjectsUrl = createNavigationUrl(`${proj}${objects}`);
const orgObjectDrawings = createNavigationUrl(`${org}${objects}${drawings}`);
const projectObjectDrawings = createNavigationUrl(
  `${proj}${objects}${drawings}`
);
const objectsBase = 'objects';
const orgObjects = `${org}${objectsBase}`;

const objectResults = `${objects}/results`;
const projectObjectResults = createNavigationUrl(`${proj}${objectResults}`);
const orgObjectResults = createNavigationUrl(`${org}${objectResults}`);

const objectTasks = createNavigationUrl(`${org}${objects}/tasks`);
const objectOsid = createNavigationUrl(`${org}${objects}${osid}`);
const projectObjectOsid = createNavigationUrl(`${proj}${objects}${osid}`);
const tbqResolveTasks = 'tbq-resolve-tasks';
const objectTBQResults = `tbq-results/:resultSet?`;
const objectTBQResultsUrl = createNavigationUrl(
  `${org}${objects}${objectTBQResults}`
);

const victims = 'Victims';
const victimsPlus = 'VictimsPlus';
const damage = 'Damage';
const damagePlus = 'DamagePlus';
const objectTBQResultsVictimsUrl = createNavigationUrl(
  `${org}${objects}${objectTBQResults}/${victims}/:taskId`
);
const objectTBQResultsDamageUrl = createNavigationUrl(
  `${org}${objects}${objectTBQResults}/${damage}/:taskId`
);
const objectTBQMultiBrandcheckResultsBaseUrl = createNavigationUrl(
  `${org}${objects}/tbq-multi-results`
);
const TBQMultiBrandcheckVictims = createNavigationUrl(
  `${objectTBQMultiBrandcheckResultsBaseUrl}/${victims}`
);
const TBQMultiBrandcheckDamage = createNavigationUrl(
  `${objectTBQMultiBrandcheckResultsBaseUrl}/${damage}`
);
const objectTBQMultiBrandcheckResults = `tbq-multi-results/:resultSet?`;
const orgTBQMultiBrandcheckResultsUrl = createNavigationUrl(
  `${org}${objects}${objectTBQMultiBrandcheckResults}`
);
const objectTBQRieResults = `/tbq-rie/:taskId`;
const objectTBQRieResultsUrl = createNavigationUrl(
  `${org}${objects}${objectTBQRieResults}`
);
const objectDrawing = `drawing/:visualContextId`;
const orgObjectDrawing = createNavigationUrl(
  `${org}${objects}/drawing/:visualContextId`
);
const projectObjectDrawing = createNavigationUrl(
  `${proj}${objects}${objectDrawing}`
);

const objectTBQMultiRieResults = `/tbq-rie`;
const objectTBQMultiRieResultsUrl = createNavigationUrl(
  `${org}${objects}${objectTBQMultiRieResults}`
);

const projectObjectTasks = createNavigationUrl(
  `${proj}${objects}${tbqResolveTasks}`
);
const projectObjectTbq = createNavigationUrl(`${proj}${objects}/tasks`);

const projectObjectTBQResults = `${objectTBQResults}`;
const projectObjectTBQMultiBrandcheckResults = createNavigationUrl(
  `${proj}${objects}${objectTBQMultiBrandcheckResults}`
);
const projectObjMultiBrandCheckBaseUrl = createNavigationUrl(
  `${proj}${objects}/tbq-multi-results`
);
const projectObjectTBQRieResults = createNavigationUrl(
  `${proj}${objects}${objectTBQRieResults}`
);
const projectObjectTBQMultiRieResults = `${proj}${objectTBQMultiRieResults}`;

const messages = '/messages/:messageId?';
const all = `all`;
const inbox = 'inbox';
const sent = 'sent';
const draft = 'draft';
const organizationMessages = 'messages/*';
const projectMessages = 'messages/*';

const projectAllMessages = createNavigationUrl(
  `${proj}${projectMessages}${all}`
);
const projectMessagesInbox = createNavigationUrl(
  `${proj}${projectMessages}${inbox}`
);
const projectMessagesSent = createNavigationUrl(
  `${proj}${projectMessages}${sent}`
);
const projectMessagesDraft = createNavigationUrl(
  `${proj}${projectMessages}${draft}`
);
const publicAttachments = '/messages/shared/public/:linkId?';

const projectMessagesBaseUrl = createNavigationUrl(`${proj}${projectMessages}`);
const organizationMessagesBase = `${org}${organizationMessages}`;
const viewMessage = `messages/view/:messageId?`;

const viewOrgMessage = createNavigationUrl(`${org}${viewMessage}`);
const viewProjMessage = createNavigationUrl(`${proj}${viewMessage}`);

const organizationMessagesUrl = createNavigationUrl(organizationMessagesBase);
const organizationAllMessages = createNavigationUrl(
  `${organizationMessagesBase}${all}`
);

const orgInboxMessages = createNavigationUrl(
  `${organizationMessagesBase}${inbox}`
);
const sentMessages = `${organizationMessages}/sent`;
const orgSentMessages = createNavigationUrl(
  `${organizationMessagesBase}${sent}`
);
const orgDraftMessages = createNavigationUrl(
  `${organizationMessagesBase}${draft}`
);

const moveUser = `/moveuser/:token`;

const projectDocTasks = createNavigationUrl(`${proj}${documentsPath}${tasks}`);
const documentNotifications = 'doc-notifications';
const orgNotificationsUrl = createNavigationUrl(
  `${orgDocsBaseUrl}${documentNotifications}`
);
const projectNotificationsUrl = createNavigationUrl(
  `${proj}${documentsPath}${documentNotifications}`
);

const organizationSupport = `/support`;

export const Routes = {
  TBQMultiBrandcheckDamage,
  TBQMultiBrandcheckVictims,
  access,
  accessUrl,
  all,
  base: '/',
  baseDms,
  baseDocs,
  colleagues,
  collectionTable,
  config,
  configSettings,
  configSettingsBaseUrl,
  confirmEmail,
  confirmEmailUrl,
  connections,
  connectionsSettingsUrl,
  damage,
  damagePlus,
  docSettings,
  docTable,
  docTableBase,
  docs,
  docsSharedByInvite,
  docsSharedInScope,
  document,
  documentNotifications,
  documentTable,
  documents,
  documentsPath,
  domains,
  draft,
  drawings,
  fieldTemplates,
  finalizeInvitation,
  finalizeInvitationUrl,
  foldersPath,
  formTemplates,
  general,
  groups,
  hierarchy,
  inbox,
  integrations,
  license,
  logout: '/logout',
  members,
  messages,
  metaDataDocumentScreenTemplates,
  metaDataDocumentsFields,
  metaDataDocumentsTemplates,
  metaDataHierarchicalLists,
  metaDataQualityControl,
  metaDataQualityControlFields,
  metaDataQualityControlForms,
  metaDataQualityControlReportConfigurations,
  metaDataQualityControlTemplates,
  metadata,
  metadataTbqTemplates,
  moveUser,
  myApiKeyManagement,
  myApiKeyManagementUrl,
  myOverview,
  myProfile,
  myProfileUrl,
  myProjects,
  myProjectsOverview,
  mySignature,
  mySignatureUrl,
  mySupport,
  mySupportUrl,
  myTasks,
  myTasksOverview,
  notifications,
  objectDrawing,
  objectOsid,
  objectTBQMultiBrandcheckResults,
  objectTBQMultiBrandcheckResultsBaseUrl,
  objectTBQMultiRieResults,
  objectTBQMultiRieResultsUrl,
  objectTBQResults,
  objectTBQResultsDamageUrl,
  objectTBQResultsUrl,
  objectTBQResultsVictimsUrl,
  objectTBQRieResults,
  objectTBQRieResultsUrl,
  objectTasks,
  objects,
  objectsBase,
  objectsBaseUrl,
  objectsRoot,
  objectsUrl,
  office,
  org,
  orgAccessBaseurl,
  orgBaseUrl,
  orgCollectionFolderUrl,
  orgDms,
  orgDmsWithoutPath,
  orgDocsBaseUrl,
  orgDocsSharedByInviteUrl,
  orgDocsSharedInScopeUrl,
  orgDocumentsTablesPathUrl,
  orgDraftMessages,
  orgGroups,
  orgHierarchicalLists,
  orgInboxMessages,
  orgMetadata,
  orgMetadataBaseUrl,
  orgMetadataFieldTemplates,
  orgMetadataTemplates,
  orgNotificationsUrl,
  orgObjectDrawing,
  orgObjectDrawings,
  orgObjectResults,
  orgObjects,
  orgProjects,
  orgQualityFieldTemplates,
  orgQualityFormTemplates,
  orgQualityReportConfigurations,
  orgQualitySnagTemplates,
  orgSentMessages,
  orgSettingsDocSettings,
  orgSettingsDomains,
  orgSettingsGeneral,
  orgSettingsIntegrations,
  orgSettingsLicense,
  orgSettingsProvisioning,
  orgSettingsSecurity,
  orgSmartFolderTableUrl,
  orgTBQMultiBrandcheckResultsUrl,
  orgTaskCategories,
  orgTemplatesTbqflat,
  orgUsers,
  organization,
  organizationAllMessages,
  organizationMessages,
  organizationMessagesUrl,
  organizationSupport,
  organizationTemplates,
  osid,
  permissions,
  profile,
  profileBaseUrl,
  proj,
  projectAccess,
  projectAllMessages,
  projectCollectionFolderUrl,
  projectDmsBase,
  projectDocTableUrl,
  projectDocTasks,
  projectDocsSharedByInvite,
  projectDocsSharedInScope,
  projectDocumentPermissions,
  projectDocumentSettingsUrl,
  projectDocumentsBaseUrl,
  projectDocumentsTablesPathUrl,
  projectGroupsUrl,
  projectHierarchicallistsValue,
  projectMembersGroupsUrl,
  projectMembersUrl,
  projectMessages,
  projectMessagesBaseUrl,
  projectMessagesDraft,
  projectMessagesInbox,
  projectMessagesSent,
  projectMetaDataDocuments,
  projectMetaDataHierarchicalLists,
  projectMetaDataQualityControl,
  projectMetaDataQualityControlReportConfigurations,
  projectMetaDataQualityControlTemplates,
  projectMetadataDocumentFieldsUrl,
  projectMetadataDocumentTemplatesUrl,
  projectMetadataDocumentTemplatesValue,
  projectMetadataQualityFieldsUrl,
  projectMetadataQualityForms,
  projectMetadataQualityReport,
  projectMetadataQualitySnagsUrl,
  projectNotificationsUrl,
  projectObjMultiBrandCheckBaseUrl,
  projectObjectDrawing,
  projectObjectDrawings,
  projectObjectOsid,
  projectObjectResults,
  projectObjectTBQMultiBrandcheckResults,
  projectObjectTBQMultiRieResults,
  projectObjectTBQResults,
  projectObjectTBQRieResults,
  projectObjectTasks,
  projectObjectTbq,
  projectObjectsUrl,
  projectPermissionsBaseUrl,
  projectQualityControl,
  projectQualityControlBaseUrl,
  projectQualityControlDrawing,
  projectQualityControlDrawingUrl,
  projectQualityControlDrawings,
  projectQualityControlDrawingsUrl,
  projectQualityControlMain,
  projectQualityControlMySavedAnswersUrl,
  projectQualityControlNotificationsUrl,
  projectQualityControlRecurringSeries,
  projectQualityControlResultsUrl,
  projectQualityControlTasksUrl,
  projectQualityPermissions,
  projectSettings,
  projectSettingsBaseUrl,
  projectSettingsUrl,
  projectSmartFolderTableUrl,
  projectTaskPermissions,
  projectTaskTemplatesUrl,
  projectTasks,
  projectTemplates,
  projectTemplatesBaseUrl,
  projects,
  provisioning,
  publicAttachments,
  publiclySharedDocs,
  publiclySharedDocsBase,
  qualitycontrol,
  qualitycontrolPath,
  recurringSeries,
  registration: '/registration',
  reportConfig,
  results,
  revoke,
  revokePublicLink,
  savedAnswers,
  screen,
  security,
  sent,
  sentMessages,
  settings,
  settingsOrganization,
  smartFolderTable,
  smartFolderTablePathBase,
  snags,
  taskCategories,
  tasks,
  tbq,
  tbqResolveTasks,
  tbqTemplates,
  templatesMain,
  templatesQuality,
  usersGroups,
  usersGroupsBaseUrl,
  victims,
  victimsPlus,
  viewMessage,
  viewOrgMessage,
  viewProjMessage,
  workflow: '/workflow',
};

export interface RouteParams extends Record<string, string | undefined> {
  folderId?: string;
  globalId?: string;
  id?: string;
  linkId?: string;
  messageId?: string;
  name?: string;
  objectId?: string;
  path?: string;
  projectId?: string;
  resultSet?: string;
  taskId?: string;
  tbqScopeId?: string;
  token?: string;
  visualContextId?: string;
}
export const nameof = <T>(name: Extract<keyof T, string>): string => name;
