import React from 'react';

import { Serie } from '@pro4all/graphql';
import { UserTag } from '@pro4all/shared/ui/identity-card';
import { Property } from '@pro4all/shared/ui/property-list';
import { Timestamp } from '@pro4all/shared/ui/timestamp';

export const getSeriesProperties = (
  serie: Serie,
  t: (key: string) => string
): Property[] => [
  {
    label: t('Created by'),
    value: serie.createdByUser ? (
      <UserTag user={serie.createdByUser} />
    ) : (
      t('None')
    ),
  },
  {
    label: t('Created at'),
    value: serie.createdAt ? <Timestamp date={serie.createdAt} /> : t('None'),
  },
  {
    label: t('Completed tasks'),
    value:
      serie.completedTasks?.toString() +
        '/' +
        serie.planAheadTasks?.toString() || t('None'),
  },
  {
    label: t('Start date'),
    value: serie.frequency?.StartAt ? (
      <Timestamp date={serie.frequency.StartAt} />
    ) : (
      t('None')
    ),
  },
  {
    label: t('End date'),
    value: serie.frequency?.StopAt ? (
      <Timestamp date={serie.frequency.StopAt} />
    ) : (
      t('None')
    ),
  },
  {
    label: t('Frequency'),
    value: serie.frequency?.frequencyType
      ? t(
          serie.frequency.frequencyType.charAt(0).toUpperCase() +
            serie.frequency.frequencyType.slice(1)
        )
      : t('None'),
  },
  {
    label: t('Plan ahead tasks'),
    value: serie.planAheadTasks?.toString() || t('None'),
  },
];
