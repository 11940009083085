import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';

export const QuestionMark: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M4 2H20C21.1 2 22 2.9 22 4V16C22 17.1 21.1 18 20 18H6L2 22V4C2 2.9 2.9 2 4 2ZM6 16H19C19.55 16 20 15.55 20 15V5C20 4.45 19.55 4 19 4H5C4.45 4 4 4.45 4 5V18L6 16Z"
        fill={customColors.grey700}
        fillRule="evenodd"
      />
      <path
        d="M11.3926 12.0742C11.3926 11.4681 11.4678 10.985 11.6182 10.625C11.7686 10.265 12.0648 9.87077 12.5068 9.44238C12.9535 9.00944 13.236 8.70182 13.3545 8.51953C13.5368 8.24154 13.6279 7.94076 13.6279 7.61719C13.6279 7.1888 13.5208 6.86296 13.3066 6.63965C13.097 6.41178 12.7871 6.29785 12.377 6.29785C11.985 6.29785 11.6683 6.40951 11.4268 6.63281C11.1898 6.85156 11.0713 7.15007 11.0713 7.52832H9.41016C9.41927 6.72168 9.69271 6.08366 10.2305 5.61426C10.7728 5.14486 11.4883 4.91016 12.377 4.91016C13.293 4.91016 14.0062 5.14258 14.5166 5.60742C15.0316 6.07227 15.2891 6.72168 15.2891 7.55566C15.2891 8.2985 14.9427 9.02995 14.25 9.75L13.4092 10.5771C13.1084 10.9189 12.9535 11.418 12.9443 12.0742H11.3926ZM11.2764 14.2002C11.2764 13.9313 11.3607 13.7148 11.5293 13.5508C11.6979 13.3822 11.9258 13.2979 12.2129 13.2979C12.5046 13.2979 12.7347 13.3844 12.9033 13.5576C13.0719 13.7262 13.1562 13.9404 13.1562 14.2002C13.1562 14.4508 13.0742 14.6605 12.9102 14.8291C12.7461 14.9977 12.5137 15.082 12.2129 15.082C11.9121 15.082 11.6797 14.9977 11.5156 14.8291C11.3561 14.6605 11.2764 14.4508 11.2764 14.2002Z"
        fill={customColors.grey700}
      />
    </svg>
  </SvgIcon>
);
