import React from 'react';
import { useTranslation } from 'react-i18next';

import { Routes } from '@pro4all/shared/config';
import { SubTabs, Tab } from '@pro4all/shared/ui/general';

const base = `${Routes.usersGroupsBaseUrl}`;
export const OrganizationUsersGroupsTabs: React.FC = () => {
  const { t } = useTranslation();

  return (
    <SubTabs base={base}>
      <Tab
        data-testid="organization-users-tab"
        label={t('Users')}
        value={Routes.members}
      />
      <Tab
        data-testid="organization-groups-tab"
        label={t('Groups')}
        value={Routes.groups}
      />
    </SubTabs>
  );
};
