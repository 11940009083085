import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import {
  FieldDefinition,
  useDeleteFieldDefinitionsMutation,
} from '@pro4all/graphql';
import { StorageKeys } from '@pro4all/shared/config';
import { useLocalStorage } from '@pro4all/shared/hooks';
import { useTableCheck } from '@pro4all/shared/ui/table';

import { useMetaDataContext } from '../../views/MetaDataContext';
import { featureConfig } from '../configs/featureConfig';

import { useCacheHelpers } from './useCacheHelpers';

export const useDeleteFieldDefinitions = () => {
  const { t } = useTranslation();
  const { templateType, templateService } = useMetaDataContext();
  const { updateCache, getCachedFieldDefinitions } = useCacheHelpers();
  const { entity } =
    featureConfig[templateService]?.fieldTypes[templateType] ?? {};
  const { enqueueSnackbar } = useSnackbar();
  const [deleteFieldDefinitions] = useDeleteFieldDefinitionsMutation();
  const { checkedRows } = useTableCheck<FieldDefinition>();

  const {
    getLocalStorageItem: getPublishTemplatesAfterFieldUpdateLocalStorage,
  } = useLocalStorage<boolean>({
    key: StorageKeys.PUBLISH_TEMPLATES_AFTER_FIELD_UPDATE,
  });

  const handleDeleteFieldDefinitionsOptimisticResponse = ({
    ids,
  }: {
    ids: string[];
  }) => {
    const cachedFieldDefinitions = getCachedFieldDefinitions();
    const updatedFieldDefinitions =
      cachedFieldDefinitions.fieldDefinitions.filter(
        (field: FieldDefinition) => !ids.includes(field.id)
      );
    updateCache({
      updatedFieldDefinitions,
    });
  };

  return async () => {
    const ids = checkedRows.map((row) => row.id);
    try {
      await deleteFieldDefinitions({
        update: () => {
          handleDeleteFieldDefinitionsOptimisticResponse({
            ids,
          });
        },
        variables: {
          autoPublish:
            getPublishTemplatesAfterFieldUpdateLocalStorage() || false,
          ids,
          templateService,
        },
      });
      enqueueSnackbar(t(`Selected ${entity}s deleted`));
    } catch (e) {
      enqueueSnackbar(
        `${t('Something went wrong')}. ${t('Please try again')}.`
      );
    }
  };
};
