import React from 'react';

import { CommentStatus as CommentStatusType } from '@pro4all/graphql';
import { customColors } from '@pro4all/shared/themes';
import { Icon } from '@pro4all/shared/ui/icons';

type CommentStatusProps = {
  commentStatus: CommentStatusType;
  hasPreviousComments: boolean;
};

export const CommentStatus = ({
  commentStatus,
  hasPreviousComments,
}: CommentStatusProps) => {
  const iconOpacity = hasPreviousComments ? '0.5' : '1';
  return commentStatus === CommentStatusType.AllCommentsRead ? (
    <Icon
      htmlColor={customColors.grey700}
      iconName="chatBubble"
      opacity={iconOpacity}
    />
  ) : null;
};
