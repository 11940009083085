import { TbqRieResult } from '@pro4all/graphql';
import { OptimisticResponseProvider } from '@pro4all/shared/ui/table';

import { TBQMultiRieResults } from './TBQMultiRieResults';

export const TBQMultiRieResultsProviderWrapper = () => (
  <OptimisticResponseProvider<TbqRieResult>>
    <TBQMultiRieResults />
  </OptimisticResponseProvider>
);
