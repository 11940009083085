import React from 'react';
import { useLocation, useNavigate } from 'react-router';

import { Tabs } from './Tabs';

const getActiveTabWithBase = (pathname: string, base = '') => {
  const path = pathname
    .trim()
    .replace(base.trim(), '')
    .split('/')
    .filter(Boolean);
  return path[0];
};
interface Props {
  alternativeValues?: Record<string, string>;
  base?: string;
  small?: boolean;
}

export const RouterTabs: React.FC<Props> = ({
  // base path: this allows you to use relative path values in <Tab />
  base = '',
  ...props
}) => {
  if (base && !base.endsWith('/')) {
    throw new Error('Expected `base` to have a trailing slash');
  }

  const navigate = useNavigate();
  const { hash, pathname, search } = useLocation();

  return (
    <Tabs
      onChange={(event, path: string) => {
        const newPath = base + path;
        if (pathname === newPath) return;
        navigate({ hash, pathname: newPath, search });
      }}
      value={getActiveTabWithBase(pathname, base)}
      {...props}
    />
  );
};
