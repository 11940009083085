import React from 'react';
import { useTranslation } from 'react-i18next';

import { AuthType } from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import { createTabValue, Routes } from '@pro4all/shared/config';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { useSubscriptionRestriction } from '@pro4all/shared/hooks';
import { useAppPermissions } from '@pro4all/shared/identity';
import { useRouting } from '@pro4all/shared/routing-utils';
import { SubTabs, Tab } from '@pro4all/shared/ui/general';

const base = `${Routes.configSettingsBaseUrl}`;

export const OrganizationOrganizationTabs: React.FC = () => {
  const { t } = useTranslation();
  const { hasAppPermission } = useAppPermissions();
  const canUpdateOrganizationSettings = hasAppPermission('OrganizationUpdate');
  const { userAuthType } = useOrganizationContext();
  const { goTo } = useRouting();

  const hasScim = useFeatureFlag('scim');
  const { contextScopedOrganizationSubscriptionLevel } =
    useOrganizationContext();
  const { hasDmsLicense } = useSubscriptionRestriction(
    contextScopedOrganizationSubscriptionLevel
  );

  return canUpdateOrganizationSettings ? (
    <SubTabs base={base}>
      <Tab
        data-testid="organization-settings-organization-tab"
        label={t('Organization')}
        onClick={() => {
          goTo('orgSettingsGeneral');
        }}
        value={createTabValue(Routes.general)}
      />
      <Tab
        data-testid="organization-settings-license-tab"
        label={t('License')}
        onClick={() => {
          goTo('orgSettingsLicense');
        }}
        value={createTabValue(Routes.license)}
      />
      <Tab
        data-testid="organization-settings-integrations-tab"
        label={t('Integrations')}
        onClick={() => {
          goTo('orgSettingsIntegrations');
        }}
        value={createTabValue(Routes.integrations)}
      />
      {hasDmsLicense && (
        <Tab
          data-testid="organization-settings-documents-tab"
          label={t('Documents')}
          onClick={() => {
            goTo('orgSettingsDocSettings');
          }}
          value={createTabValue(Routes.docSettings)}
        />
      )}

      {userAuthType === AuthType.Sso && canUpdateOrganizationSettings && (
        <Tab
          data-testid="organization-settings-settings-tab"
          label={t('Security')}
          onClick={() => {
            goTo('orgSettingsSecurity');
          }}
          value={createTabValue(Routes.security)}
        />
      )}

      {hasScim && (
        <Tab
          data-testid="organization-settings-domains-tab"
          label={t('Domains')}
          onClick={() => {
            goTo('orgSettingsDomains');
          }}
          value={createTabValue(Routes.domains)}
        />
      )}

      {hasScim && (
        <Tab
          data-testid="organization-settings-provisioning-tab"
          label={t('Provisioning')}
          value={createTabValue(Routes.provisioning)}
        />
      )}
    </SubTabs>
  ) : null;
};
