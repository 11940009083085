import styled from 'styled-components';

import { customColors } from '@pro4all/shared/themes';

export const EditorWrap = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
  position: relative;
  gap: 0.5rem;
`;

export const MentionLabel = styled.span`
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const SuggestionWrap = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  min-width: 13rem;
  min-height: 4rem;
  left: -9999px;
  top: -9999px;
  padding: 0.5rem 0;
  background-color: ${customColors.white};
  box-shadow: 0px 3px 12px rgba(51, 51, 51, 0.24);
  border-radius: 0.5rem;
  overflow-y: auto;
  z-index: 1500;
  gap: 0.5rem;
`;

export const Option = styled.div<{ $active: boolean }>`
  display: flex;
  background-color: ${({ $active }) =>
    $active
      ? ({ theme }) => theme.palette.primary.light
      : `${customColors.white}`};
  font-size: 1rem;
  padding: 0.5rem;
  align-items: center;
  gap: 0.5rem;
`;

export const EditorToolbar = styled.div<{ $hide: boolean }>`
  margin-bottom: 5px;
  visibility: ${({ $hide }) => ($hide ? 'hidden' : 'inherit')};
`;

export const ErrorMessage = styled.span`
  color: ${({ theme }) => theme.palette.error.main};
`;
