import React, { useState } from 'react';

import { useGetThumbnail } from '@pro4all/documents/ui/utils';
import { CardMedia } from '@pro4all/shared/mui-wrappers';
import { ImageLoader } from '@pro4all/shared/ui/general';
import { Icon } from '@pro4all/shared/ui/icons';
import { ImagePlaceHolder } from '@pro4all/shared/ui/image-placeholder';

import { SCROLL_DEBOUNCE, TableGridItem, THUMBNAIL_HEIGHT } from './types';

export const TableGridViewThumbnailPicture = ({
  item,
  inScreen,
}: {
  inScreen: boolean;
  item: TableGridItem;
}) => {
  const [imgLoadError, setImgLoadError] = useState(false);

  const { loading, thumbnailUrl } = useGetThumbnail({
    debounceDuration: SCROLL_DEBOUNCE,
    documentId: item.id,
    load: inScreen,
    versionId: item.versionId ?? '',
  });

  return !inScreen && !thumbnailUrl ? (
    <Icon iconName="snooze"></Icon>
  ) : thumbnailUrl && !imgLoadError ? (
    <CardMedia
      component="img"
      image={thumbnailUrl}
      onError={() => {
        setImgLoadError(true);
      }}
      sx={{
        height: 'auto',
        maxHeight: THUMBNAIL_HEIGHT,
        maxWidth: '100%',
        objectFit: 'contain',
      }}
    />
  ) : loading ? (
    <ImageLoader height={THUMBNAIL_HEIGHT} />
  ) : (
    <ImagePlaceHolder randomShape shadow={false} />
  );
};
