import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

import { Button } from '@pro4all/shared/mui-wrappers';
import { FloatingModal } from '@pro4all/shared/ui/floating-modal';

import { FormFields } from '../../types';

import { FooterArea, StyledFloatingModal } from './CreateSeriesModal.styles';
import { CreateSeriesModalForm } from './CreateSeriesModalForm';

interface CreateSerieModalProps {
  onClose: () => void;
  open: boolean;
}

export const CreateSeriesModal: React.FC<CreateSerieModalProps> = ({
  onClose,
  open,
}) => {
  const { t } = useTranslation();
  const { values } = useFormikContext<FormFields>();

  if (!open) return null;

  const isFormComplete = Boolean(
    values.endTime &&
      values.seriesEndDate &&
      values.seriesFrequency &&
      values.seriesPlanAhead
  );

  return (
    <StyledFloatingModal onClose={onClose} open={open}>
      <FloatingModal.Header>{t('Create new series')}</FloatingModal.Header>
      <CreateSeriesModalForm />
      <FooterArea>
        <Button color="inherit" onClick={onClose}>
          {t('Cancel')}
        </Button>
        <Button
          aria-label={t('Save series settings')}
          color="primary"
          disabled={!isFormComplete}
          onClick={onClose}
          variant="contained"
        >
          {t('Create series')}
        </Button>
      </FooterArea>
    </StyledFloatingModal>
  );
};
