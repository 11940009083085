import { client } from '@pro4all/authentication/src/graph-ql';
import { FieldDefinition, FieldDefinitionsDocument } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';

import { useMetaDataContext } from '../../views/MetaDataContext';

export const useCacheHelpers = () => {
  const {
    params: { projectId },
  } = useRouting();
  const { templateService } = useMetaDataContext();

  const getCachedFieldDefinitions = () =>
    client?.readQuery({
      query: FieldDefinitionsDocument,
      variables: {
        includeOrgItems: true,
        projectId,
        templateService,
      },
    });

  const updateCache = ({
    updatedFieldDefinitions,
  }: {
    updatedFieldDefinitions: FieldDefinition[];
  }) => {
    client?.cache.modify({
      fields: {
        fieldDefinitions() {
          return updatedFieldDefinitions;
        },
      },
    });
  };

  return {
    getCachedFieldDefinitions,
    updateCache,
  };
};
