import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { useDeleteTaskMutation } from '@pro4all/graphql';
import { Task } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';
import { useOptimisticResponseContext } from '@pro4all/shared/ui/table';
import { TaskDeleted } from '@pro4all/workflow/ui/snackbars';

export const useSidebarActions = ({ currentTask }: { currentTask: Task }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { searchParams } = useRouting();

  const { deleteItems, restoreItems } = useOptimisticResponseContext<Task>();

  const [deleteTask] = useDeleteTaskMutation({
    onCompleted: () => {
      enqueueSnackbar(
        <TaskDeleted
          procedureId={currentTask.procedureId}
          restoreItems={restoreItems}
          taskId={currentTask.id}
          taskName={currentTask.name}
        />
      );
      onClose();
    },
    onError: () => {
      enqueueSnackbar(t('Something went wrong. Please try again.'));
    },
  });

  const onClose = () => searchParams.clear();

  const onDeleteTask = async () => {
    await deleteTask({
      variables: { id: currentTask.id, procedureId: currentTask.procedureId },
    });
    deleteItems([currentTask.id]);
  };

  const onDuplicateTask = () => {
    searchParams.set({
      action: 'createTask',
      duplicate: true,
      taskType: currentTask.type ?? '',
    });
  };

  const onEditTask = () => {
    searchParams.set({ action: 'editTask', id: currentTask.id });
  };

  const onSuccessfulSubmit = () => {
    onViewTask();
  };

  const onViewTask = () => {
    searchParams.set({ action: 'viewTask', id: currentTask.id });
  };

  const goBackToDocumentSideBar = () => {
    ['action', 'taskType'].forEach((param) => searchParams.delete(param));
  };

  return {
    goBackToDocumentSideBar,
    onClose,
    onDeleteTask,
    onDuplicateTask,
    onEditTask,
    onSuccessfulSubmit,
    onViewTask,
  };
};
