import { ActionProps, useCentralActions } from '@pro4all/shared/actions';

import { DocumentActionProps } from '../../types';

import { useHandleDeleteDocuments } from './useHandleDeleteDocuments';
import { useHideDeleteDocument } from './useHideDeleteDocument';

export const useDeleteAction = ({
  allDocumentsFinalized,
  hasFolderPermission,
  isLocked,
  isSpecialFolder,
  isVersion,
  selection,
  userId,
}: Pick<
  DocumentActionProps,
  | 'allDocumentsFinalized'
  | 'hasFolderPermission'
  | 'isLocked'
  | 'isSpecialFolder'
  | 'isVersion'
  | 'selection'
  | 'userId'
>) => {
  const { ACTION_DELETE } = useCentralActions();

  const { handleDeleteDocuments } = useHandleDeleteDocuments();

  const hideDeleteDocument = useHideDeleteDocument({
    hasFolderPermission,
    selection,
    selectionVersion: [],
    userId,
  });

  const deleteAction: ActionProps = {
    ...ACTION_DELETE,
    disabled:
      hideDeleteDocument() ||
      allDocumentsFinalized ||
      isLocked ||
      isSpecialFolder ||
      isVersion,
    onClick: () =>
      handleDeleteDocuments({ hasFolderPermission, selection, userId }),
  };

  return deleteAction;
};
