import styled from 'styled-components';

import { Box } from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';

export const ScrollContainer = styled.div`
  flex: 1;
  min-height: 0;
  overflow-y: auto;
  padding-right: 1rem;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const MetaContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`;

export const MetaItem = styled.div<{ isExpired?: boolean }>`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: ${({ isExpired }) =>
    isExpired ? customColors.errorAlert : 'inherit'};
  font-weight: ${({ isExpired }) => (isExpired ? '500' : 'inherit')};

  && {
    time {
      color: inherit;
    }
  }
`;

export const LabelRow = styled(Box)`
  && {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
  }
`;

export const FiltersContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-bottom: 1.5rem;
`;

export const LinksContainer = styled.div`
  margin: 1rem 0;
`;

export const TitleContainer = styled.div`
  width: 100%;
`;
