import React from 'react';

import { SvgIconProps } from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';

export const ChangeableSimpleFormIcon: React.FC<
  SvgIconProps & { customColor: string }
> = (props) => {
  const { scale = 1 } = props;

  return (
    <svg
      fill="none"
      height={20 * Number(scale)}
      viewBox="0 0 20 20"
      width={20 * Number(scale)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        fill={props.customColor || customColors.purpleMonoHighlight}
        height="20"
        rx="2"
        width="20"
      />
      <g clipPath="url(#clip0_2612_7466)">
        <rect
          fill={customColors.white}
          height="16"
          transform="translate(2 2)"
          width="16"
        />
      </g>
    </svg>
  );
};
