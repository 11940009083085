import React from 'react';
import { useTranslation } from 'react-i18next';

import { ShareType } from '@pro4all/graphql';
import { ActionProps } from '@pro4all/shared/actions';
import { TrackingEvent } from '@pro4all/shared/config';
import { useRouting } from '@pro4all/shared/routing-utils';
import { IconButton } from '@pro4all/shared/ui/buttons';
import { useAnalytics } from '@pro4all/shared/vendor';

import { useGenerateLinkContext } from '../GenerateLinkContext';

export const ShareLinkButtons: React.FC = () => {
  const { track } = useAnalytics();
  const { t } = useTranslation();
  const { selectedShareMethod, copyLink, message, link } =
    useGenerateLinkContext();
  const { searchParams } = useRouting();

  const showEmailModal = () => searchParams.set({ action: 'share-by-email' });

  const shareToMsTeams = () => {
    window.open(
      `https://teams.microsoft.com/share?href=${encodeURIComponent(
        link
      )}&msgText=${encodeURIComponent(message)}`
    );

    track(TrackingEvent.LinkSharedToMicrosoftTeams);
  };

  const buttonProps: ActionProps[] = [
    {
      ariaLabel: t('Copy link'),
      dataTestId: 'copy-link',
      key: 'copy-link',
      label: t('Copy link'),
      onClick: () => copyLink(),
      startIcon: 'link',
    },
    {
      ariaLabel: t('Share via Microsoft Teams'),
      dataTestId: 'share-to-ms-teams',
      key: 'share-to-ms-teams',
      label: t('Share via Microsoft Teams'),
      onClick: () => shareToMsTeams(),
      startIcon: 'msTeams',
    },
  ];

  if (selectedShareMethod === ShareType.Public) {
    buttonProps.push({
      ariaLabel: 'Email link',
      dataTestId: 'email-link',
      key: 'email-link',
      label: t('Email link'),
      onClick: () => showEmailModal(),
      startIcon: 'mail',
    });
  }

  return (
    <>
      {buttonProps.map(
        ({ ariaLabel, dataTestId, onClick, startIcon }, index) => (
          <IconButton
            ariaLabel={ariaLabel}
            dataTestId={dataTestId}
            iconName={startIcon}
            key={index}
            onClick={onClick}
          />
        )
      )}
    </>
  );
};
