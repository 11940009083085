import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApolloError } from '@apollo/client';

import { TbqRieResult, useMultiRieResultsMutation } from '@pro4all/graphql';
import { Box } from '@pro4all/shared/mui-wrappers';
import { generateRoute, useRouting } from '@pro4all/shared/routing-utils';
import { OptimisticResponseProvider } from '@pro4all/shared/ui/table';

import { Main, StyledButton } from './ResultsUI.styles';
import { RieCardsList } from './RieCardsList';
import { RieTaskResults } from './TBQRieTaskResults';
import { useCountRieResultsByRiskLevel } from './useCountRieResultsByRiskLevel';

export interface ResultCount {
  attentionRequired: number;
  improvementRequired: number;
  measuresRequired: number;
  noPriority: number;
  notAnswered: number;
  workOnHold: number;
}

export const TBQMultiRieResults = () => {
  const { t } = useTranslation();
  const {
    params: { objectId = '' },
    goTo,
    state: { taskIds },
  } = useRouting<{
    taskIds: string[];
  }>();

  const [multiRieResultsMutation] = useMultiRieResultsMutation();
  const countRieResultsByRiskLevel = useCountRieResultsByRiskLevel();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<ApolloError | undefined>();
  const [results, setResults] = useState<TbqRieResult[]>([]);

  generateRoute('objectTBQMultiRieResults', {
    params: { objectId },
  });

  useEffect(() => {
    if (!taskIds || !objectId) {
      return;
    }

    const fetchResults = async () => {
      try {
        const { data } = await multiRieResultsMutation({
          variables: { taskIds: taskIds },
        });

        if (data && data.multiRieResults) {
          const nonNullResults = data.multiRieResults.filter(
            (item) => item !== null
          ) as TbqRieResult[];
          setResults(nonNullResults);
        } else {
          setResults([]);
        }
      } catch (err) {
        setError(err as ApolloError);
      } finally {
        setLoading(false);
      }
    };

    fetchResults();
  }, [taskIds, objectId, multiRieResultsMutation]);

  if (!taskIds || !objectId) {
    return null;
  }

  const back = () => goTo('objectTasks', { params: { objectId } });

  const rieRiskQuestionsCount = countRieResultsByRiskLevel(results);

  const resultCounts = results.length > 0 ? rieRiskQuestionsCount : null;

  return (
    <Main>
      <StyledButton
        data-testid="close-instance"
        onClick={back}
        startIcon="arrowBack"
        variant="outlined"
      >
        {t('Go back')}
      </StyledButton>
      {Boolean(resultCounts) && <RieCardsList resultCount={resultCounts} />}
      <Box flex="1" mt={3} mx={-3}>
        <OptimisticResponseProvider<TbqRieResult>>
          <RieTaskResults
            error={error}
            loading={loading}
            rieResults={results}
          />
        </OptimisticResponseProvider>
      </Box>
    </Main>
  );
};
