import { Controller, useForm } from 'react-hook-form';
import { Resolver } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import styled from 'styled-components';
import * as yup from 'yup';

import {
  useMyProjectsQuery,
  useSetFolderProjectLinkMutation,
} from '@pro4all/graphql';
import { Box } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Option } from '@pro4all/shared/types';
import { FloatingModal } from '@pro4all/shared/ui/floating-modal';
import { FormFooter } from '@pro4all/shared/ui/form';
import { SearchableSelect } from '@pro4all/shared/ui/inputs';
import { Loader } from '@pro4all/shared/ui/loader';
import { Text } from '@pro4all/shared/ui/typography';

import { DMSItem } from './tree/types';
import { useFolderTreeContextOuter } from '.';

interface Props {
  folderId?: string;
  folderName?: string;
  folderPath?: string;
  folderProjectsIds: string[];
}

type FormFields = {
  project: Pick<Option, 'id' | 'label'> | null;
};

const StyledForm = styled.form`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const LinkProject = ({
  folderId,
  folderName,
  folderPath,
  folderProjectsIds = [],
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { projectId: currentProjectId } = useRouting().params;

  const schema: yup.Schema<FormFields> = yup.object({
    project: yup.mixed<Option>().nullable().required(t('Project required.')),
  });

  const resolver: Resolver<FormFields> = async (values) => {
    try {
      const data = await schema.validate(values, { abortEarly: false });
      return { errors: {}, values: data };
    } catch (errors) {
      return {
        errors: errors.inner.reduce(
          (allErrors: Record<string, string>, currentError: any) => ({
            ...allErrors,
            [currentError.path]: currentError.message,
          }),
          {}
        ),
        values: {},
      };
    }
  };

  const { control, handleSubmit, formState } = useForm<FormFields>({
    defaultValues: { project: null },
    mode: 'onBlur',
    resolver,
  });

  const [linkProject] = useSetFolderProjectLinkMutation();
  const { createDMSItem: createProjectLinkInLocalState } =
    useFolderTreeContextOuter();

  const { searchParams } = useRouting();
  const { data, loading: loadingProjects } = useMyProjectsQuery({});

  const showModal = searchParams.is('action', 'linkProjectToFolder');

  const projectOptions: Option[] =
    data?.projects
      ?.filter(
        (project) =>
          !folderProjectsIds.includes(project.id) &&
          currentProjectId !== project.id
      )
      .map((project) => ({
        id: project.id,
        label: project.name,
      })) || [];

  const onSubmit = async ({ project }: FormFields) => {
    if (!project) return;
    const response = await linkProject({
      variables: {
        folderId,
        projectInfo: { id: project.id, name: project.label },
      },
    });

    if (!response.data.setFolderProjectLink.success) {
      enqueueSnackbar(t('Something went wrong'));
      searchParams.clear();
      return;
    }

    enqueueSnackbar(
      t('Link to project "{{projectName}}" created.', {
        projectName: project.label,
      })
    );

    createProjectLinkInLocalState({
      id: response.data.setFolderProjectLink.id,
      name: project.label,
      parentFolderId: folderId,
      path: `${folderPath}/${project.label}`,
      projectId: project.id,
    } as DMSItem);

    searchParams.clear();
  };

  const onClose = () => {
    searchParams.delete('action');
  };

  return (
    <FloatingModal open={showModal}>
      <FloatingModal.Header iconName="objectLinkActive">
        {t('Place and link project')}
      </FloatingModal.Header>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <Box mb={1} mt={2}>
          <Text>
            {t(
              'Select the project that you want to create a link for in folder'
            )}{' '}
            <b>{folderName}</b>
          </Text>
        </Box>
        {loadingProjects ? (
          <Loader />
        ) : (
          <Controller<FormFields>
            control={control}
            name="project"
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <SearchableSelect
                {...field}
                error={Boolean(error)}
                helperText={error?.message}
                options={projectOptions}
                placeholder={t('Select a project')}
              />
            )}
          />
        )}

        <FormFooter
          disableSubmit={!formState.isValid || formState.isSubmitting}
          onClose={onClose}
          pt={2}
          sticky
        />
      </StyledForm>
    </FloatingModal>
  );
};
