import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { documentToAttachment } from '@pro4all/documents/ui/folders';
import { useVersionsIncludeLazyQuery } from '@pro4all/graphql';
import { DocumentVersion } from '@pro4all/graphql';
import { ActionProps, useCentralActions } from '@pro4all/shared/actions';
import { StorageKeys } from '@pro4all/shared/config';
import { useRouting } from '@pro4all/shared/routing-utils';

import { DocumentActionProps } from '../../types';

export const useCreateMessageAction = ({
  allDocumentsExpected,
  selection,
  selectionVersion,
  setLoading,
}: Pick<
  DocumentActionProps,
  'allDocumentsExpected' | 'selection' | 'selectionVersion'
> & { setLoading: Dispatch<SetStateAction<boolean>> }): ActionProps => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { ACTION_SEND_MESSAGE } = useCentralActions();
  const {
    goTo,
    params: { projectId },
    searchParams,
  } = useRouting();
  const docs = selection.length
    ? selection.filter(({ isExpected }) => !isExpected)
    : selectionVersion;

  const storageKey = `${StorageKeys.MESSAGE_ATTACHMENTS}-${
    projectId ?? 'organization'
  }`;
  const clearPrevious = () => {
    sessionStorage.removeItem(storageKey);
  };

  const [getVersions] = useVersionsIncludeLazyQuery();

  const createMessage = async () => {
    setLoading(true);
    clearPrevious();

    const { data: dataVersions } = await getVersions({
      fetchPolicy: 'cache-and-network',
      variables: {
        ids: docs.map(({ versionId }) => versionId),
        includeFileSize: true,
      },
    });

    try {
      const attachments = docs?.map((document) =>
        documentToAttachment(
          document,
          dataVersions?.versions as DocumentVersion[]
        )
      );
      const resolved = await Promise.all(attachments);
      sessionStorage.setItem(storageKey, JSON.stringify(resolved));
      setLoading(false);

      goTo(projectId ? 'projectMessagesBaseUrl' : 'messages', {
        params: { projectId },
        searchParams: { action: 'createMessage', dmsAttachment: true },
      });
    } catch (e) {
      setLoading(false);
      enqueueSnackbar(t('Something went wrong'));
    }
  };

  return {
    ...ACTION_SEND_MESSAGE,
    disabled: allDocumentsExpected,
    hidden: Boolean(searchParams.get('versionid')),
    onClick: () => createMessage(),
  };
};
