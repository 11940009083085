import React from 'react';

import type { Document } from '@pro4all/graphql';
import { StampStatus } from '@pro4all/graphql';
import { customColors } from '@pro4all/shared/themes';
import { Icon } from '@pro4all/shared/ui/icons';

function getIcon(
  qrCodeState?: StampStatus | null,
  hasPreviousQr?: boolean | null
) {
  if (hasPreviousQr && qrCodeState !== StampStatus.Progress) return 'qr';

  switch (qrCodeState) {
    case StampStatus.Done:
      return 'qr';
    case StampStatus.Failed:
      return 'warning';
    case StampStatus.Progress:
      return 'sync';
    default:
      return null;
  }
}

export const QRCodeStatus: React.FC<
  Document & { children?: never } & { isForVersionTable?: boolean }
> = ({ qrCodeState, hasPreviousQr, isForVersionTable }) => {
  const icon = getIcon(qrCodeState, hasPreviousQr);
  return icon &&
    (!isForVersionTable ||
      qrCodeState === StampStatus.Progress ||
      qrCodeState === StampStatus.Done) ? (
    <Icon
      htmlColor={customColors.grey700}
      iconName={icon}
      opacity={
        hasPreviousQr &&
        qrCodeState !== StampStatus.Done &&
        qrCodeState !== StampStatus.Progress
          ? '40%'
          : '100%'
      }
    />
  ) : null;
};
