import { ThemeColor } from '@pro4all/shared/themes';
import { IconName } from '@pro4all/shared/ui/icons';
import {
  ErrorBackground,
  SuccessBackground,
  WarningBackground,
} from '@pro4all/shared/ui/layout';
import { ShapeColor, ShapeType } from '@pro4all/shared/ui/shapes';

import { PageType } from './types';

export const useGetInfoPageUI = (type: PageType) => {
  let bgElement: JSX.Element;
  let color: ThemeColor;
  let iconName: IconName;
  let shapeColor: ShapeColor;
  let shapeType: ShapeType;

  if (type === 'error') {
    bgElement = <ErrorBackground />;
    color = 'errorHover';
    iconName = 'errorOutlined';
    shapeColor = 'orange';
    shapeType = 'trust';
  } else if (type === 'warning') {
    bgElement = <WarningBackground />;
    color = 'warningDark';
    iconName = 'reportProblemOutlined';
    shapeColor = 'orange';
    shapeType = 'build';
  } else {
    bgElement = <SuccessBackground />;
    color = 'successHover';
    iconName = 'checkCircleOutlined';
    shapeColor = 'green';
    shapeType = 'collaborate';
  }

  return {
    bgElement,
    color,
    iconName,
    shapeColor,
    shapeType,
  };
};
