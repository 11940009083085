import { useTranslation } from 'react-i18next';

import { ApprovalStatus } from '@pro4all/documents/ui/approval';
import { CommentStatus } from '@pro4all/documents/ui/comments';
import { FinalizeStatus } from '@pro4all/documents/ui/finalize';
import { useFolderTreeContextInner } from '@pro4all/documents/ui/folders';
import { QRCodeStatus } from '@pro4all/documents/ui/stamper';
import {
  FinalizationState,
  LockType,
  SearchDocument,
  User,
} from '@pro4all/graphql';
import { MatchWrap, ResultMatch } from '@pro4all/search/ui';
import { useActionNamingMapping } from '@pro4all/shared/label-config';
import { useRouting } from '@pro4all/shared/routing-utils';
import {
  DocTablePropertyKeys,
  DocTableSubPropertyKeys,
  RenderOptionType,
} from '@pro4all/shared/types';
import { DocumentIcon } from '@pro4all/shared/ui/document-icon';
import { FilePath } from '@pro4all/shared/ui/file-path';
import { FilterHeaderType } from '@pro4all/shared/ui/filtering';
import { DocumentVersionNumber } from '@pro4all/shared/ui/general';
import { UserTag } from '@pro4all/shared/ui/identity-card';
import { ColumnProps } from '@pro4all/shared/ui/table';
import { FilterHeader } from '@pro4all/shared/ui/table-column-filtering';
import { getFormattedDate, Timestamp } from '@pro4all/shared/ui/timestamp';
import { firstCharToUpperCase, searchToDocument } from '@pro4all/shared/utils';

export const useDefaultColumns: () => ColumnProps<SearchDocument>[] = () => {
  const { searchParams } = useRouting();
  const matchString = searchParams.get('search') || undefined;
  const getActionNamingMapping = useActionNamingMapping();
  const { setExpandedRowKeys } = useFolderTreeContextInner();

  const { t } = useTranslation();

  return [
    {
      filterable: true,
      headerComponent: (
        <FilterHeader<SearchDocument, {}>
          defaultWidth={400}
          filterType={FilterHeaderType.Text}
          label="Name" // i18n
          minWidth={96}
          propertyId={DocTablePropertyKeys.Name}
          showFilterIcon={false}
        />
      ),
      key: DocTablePropertyKeys.Name,
      render: (document) => (
        <MatchWrap>
          <ResultMatch
            inactive={Boolean(document.isExpected)}
            matchString={matchString}
            text={document.name}
          />
        </MatchWrap>
      ),
      width: 400,
    },
    {
      filterable: true,
      getValue: (document: SearchDocument) =>
        document?.extension?.replace('.', '') || '',
      headerComponent: (
        <FilterHeader<SearchDocument, {}>
          defaultWidth={50}
          filterType={FilterHeaderType.Select}
          label="Type"
          minWidth={50}
          propertyId={DocTablePropertyKeys.Extension}
          showFilterIcon={false}
        />
      ),
      key: DocTablePropertyKeys.Extension,
      render: ({ extension, isExpected }: SearchDocument) => (
        <DocumentIcon extension={extension} isExpected={Boolean(isExpected)} />
      ),
      width: 50,
    },
    {
      filterable: true,
      headerComponent: (
        <FilterHeader<SearchDocument, {}>
          defaultWidth={44}
          filterType={FilterHeaderType.Number}
          label="#"
          minWidth={44}
          propertyId={DocTablePropertyKeys.VersionNumber}
          showFilterIcon={false}
        />
      ),
      key: DocTablePropertyKeys.VersionNumber,
      render: ({ isExpected, versionNumber }: SearchDocument) => (
        <DocumentVersionNumber isExpected={isExpected ?? false} variant="body1">
          {versionNumber}
        </DocumentVersionNumber>
      ),
      width: 44,
    },
    {
      headerComponent: (
        <FilterHeader<SearchDocument, User>
          defaultWidth={200}
          filterType={FilterHeaderType.Select}
          label="Updated by" // i18n
          minWidth={120}
          propertyId={DocTablePropertyKeys.UpdatedBy}
          renderOption={RenderOptionType.UserCard}
          showFilterIcon={false}
          subPropertyId={DocTableSubPropertyKeys.UpdatedByName}
        />
      ),
      key: `${DocTablePropertyKeys.UpdatedBy}.${DocTableSubPropertyKeys.UpdatedByName}`,
      render: ({ updatedBy }) => (
        <UserTag user={updatedBy as unknown as User} />
      ),
      width: 200,
    },
    {
      getValue: (document: SearchDocument) =>
        document.updatedAt
          ? getFormattedDate(document.updatedAt, 'lll').label
          : '',
      headerComponent: (
        <FilterHeader<SearchDocument, {}>
          defaultWidth={200}
          filterType={FilterHeaderType.Date}
          label="Updated on" // i18n
          minWidth={120}
          propertyId={DocTablePropertyKeys.UpdatedAt}
          showFilterIcon={false}
        />
      ),
      key: DocTablePropertyKeys.UpdatedAt,
      render: ({ updatedAt }: SearchDocument) => (
        <Timestamp date={updatedAt} format="lll" />
      ),
      width: 200,
    },
    {
      headerComponent: (
        <FilterHeader<SearchDocument, {}>
          defaultWidth={200}
          filterType={FilterHeaderType.Text}
          label="Original location" // i18n
          minWidth={80}
          propertyId={DocTablePropertyKeys.Path}
          showFilterIcon={false}
        />
      ),
      key: DocTablePropertyKeys.Path,
      minWidth: 80,
      render: ({ id, path, pathIds }: SearchDocument) => (
        <FilePath
          id={id}
          path={path || ''}
          pathIds={pathIds}
          setExpandedRowKeys={setExpandedRowKeys}
        />
      ),
      width: 200, // i18n
    },
    {
      getValue: (document: SearchDocument) =>
        document?.qrCodeState === 'done'
          ? 'x'
          : document?.hasPreviousQr
          ? (t('Previous version') as string)
          : '',
      headerComponent: (
        <FilterHeader<SearchDocument, {}>
          defaultWidth={36}
          filterType={FilterHeaderType.Select}
          iconName="qr"
          label="QR code" // i18n
          minWidth={36}
          propertyId={DocTablePropertyKeys.QrCodeState}
          showFilterIcon={false}
        />
      ),
      iconName: 'qr',
      key: DocTablePropertyKeys.QrCodeState,
      render: (document) => <QRCodeStatus {...searchToDocument(document)} />,
      width: 36,
    },
    {
      getValue: (document: SearchDocument) =>
        document.versionState && document.versionState !== 'none'
          ? (t(firstCharToUpperCase(document.versionState)) as string)
          : '',
      headerComponent: (
        <FilterHeader<SearchDocument, {}>
          defaultWidth={36}
          filterType={FilterHeaderType.Select}
          iconName="approveFile"
          label={t(
            '{{Approved}}/{{Rejected}}',
            getActionNamingMapping('Approved/Rejected')
          )} // i18n
          minWidth={36}
          propertyId={DocTablePropertyKeys.ApprovalState}
          showFilterIcon={false}
        />
      ),
      key: DocTablePropertyKeys.ApprovalState,
      render: (document) => <ApprovalStatus {...searchToDocument(document)} />,
      width: 36,
    },
    {
      getValue: (document: SearchDocument) => {
        const { lockedBy, lockType, state } = document;
        if (state === FinalizationState.Finalized)
          return t(firstCharToUpperCase(state)) as string;
        if (lockType === LockType.Prostream)
          return `${lockedBy?.displayName || t('Unknown')} ${t(
            "is editing this document's content."
          )}` as string;
        if (lockType === LockType.OfficeOnline)
          return t('Document being edited in Office.') as string;
        return '';
      },
      headerComponent: (
        <FilterHeader<SearchDocument, {}>
          defaultWidth={36}
          filterType={FilterHeaderType.Select}
          iconName="finalize"
          label={getActionNamingMapping('Finalized') as string} // i18n
          minWidth={36}
          propertyId={DocTablePropertyKeys.FinalizeState}
          showFilterIcon={false}
        />
      ),
      key: DocTablePropertyKeys.FinalizeState,
      render: (document) => <FinalizeStatus {...searchToDocument(document)} />,
      width: 36,
    },
    {
      filterable: true,
      getValue: ({ commentStatus }) => commentStatus,
      headerComponent: (
        <FilterHeader<SearchDocument, {}>
          defaultWidth={40}
          filterType={FilterHeaderType.Select}
          iconName="chatBubble"
          label="Comments" // i18n
          minWidth={40}
          propertyId="commentStatus"
          translateOptions
        />
      ),
      key: DocTablePropertyKeys.CommentStatus,
      render: ({ commentStatus }) =>
        commentStatus && (
          <CommentStatus
            commentStatus={commentStatus}
            hasPreviousComments={false}
          />
        ),
      width: 36,
    },
  ];
};
