import { useRef } from 'react';

import { client } from '@pro4all/authentication/src/graph-ql';
import {
  DocumentDocument,
  DocumentVersion,
  DocumentVersionDocument,
} from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';

export const useCacheHelpers = () => {
  const previousDocumentVersions = useRef<DocumentVersion[]>([]);
  const { searchParams } = useRouting();
  const documentId = searchParams.get('id');

  const getCachedDocument = () =>
    client?.readQuery({
      query: DocumentDocument,
      variables: { id: documentId },
    });

  const getCachedDocumentVersions = () =>
    client?.readQuery({
      query: DocumentVersionDocument,
      variables: { id: documentId },
    });

  const updateCacheDocumentVersions = ({
    updatedDocumentsVersions,
  }: {
    updatedDocumentsVersions: DocumentVersion[];
  }) => {
    const cachedDocumentVersions = getCachedDocumentVersions().documentVersion;
    previousDocumentVersions.current = cachedDocumentVersions;

    // Update the array of document versions in the cache.
    client?.writeQuery({
      data: {
        documentVersion: updatedDocumentsVersions,
      },
      query: DocumentVersionDocument,
      variables: { id: documentId },
    });
  };

  const updateCacheDocument = ({
    selectionVersion,
    updatedDocumentsVersions,
  }: {
    selectionVersion: DocumentVersion[];
    updatedDocumentsVersions: DocumentVersion[];
  }) => {
    const cachedDocument = getCachedDocument().document;

    const mostRecentVersion =
      updatedDocumentsVersions.length > 0
        ? updatedDocumentsVersions.reduce((max, current) => {
            const currentVersion =
              typeof current?.versionNumber === 'number'
                ? current.versionNumber
                : 0;
            const maxVersion =
              typeof max?.versionNumber === 'number' ? max.versionNumber : 0;
            return currentVersion > maxVersion ? current : max;
          })
        : null;

    if (mostRecentVersion) {
      const newVersionNumber =
        mostRecentVersion.versionNumber === selectionVersion[0].versionNumber
          ? Math.max(
              ...[
                0,
                ...(updatedDocumentsVersions?.map(
                  (v) => v.versionNumber ?? 0
                ) ?? []),
              ]
            )
          : mostRecentVersion.versionNumber;

      const newMetaData =
        mostRecentVersion.versionNumber === selectionVersion[0].versionNumber
          ? Math.max(
              ...[
                0,
                ...(updatedDocumentsVersions?.map(
                  (v) => v?.versionNumber ?? 0
                ) ?? []),
              ]
            )
          : mostRecentVersion.metaData;

      const isExpected = newVersionNumber === 0;

      // Update several props of the document in the cache.
      client?.cache.modify({
        fields: {
          isExpected() {
            return isExpected;
          },
          metaData() {
            return newMetaData;
          },
          versionNumber() {
            return newVersionNumber;
          },
          versions() {
            return updatedDocumentsVersions;
          },
        },
        id: client.cache.identify(cachedDocument),
      });
    }
  };

  const restoreVersionsCallback = ({
    selectionVersion,
  }: {
    selectionVersion: DocumentVersion[];
  }) => {
    updateCacheDocument({
      selectionVersion,
      updatedDocumentsVersions: previousDocumentVersions.current,
    });

    updateCacheDocumentVersions({
      updatedDocumentsVersions: previousDocumentVersions.current,
    });
  };

  return {
    getCachedDocument,
    getCachedDocumentVersions,
    restoreVersionsCallback,
    updateCacheDocument,
    updateCacheDocumentVersions,
  };
};
