import React, { useCallback, useMemo, useState } from 'react';
import { ZoomControl } from 'react-leaflet';

import { useRouting } from '@pro4all/shared/routing-utils';

import { MapContents } from './MapContents';
import { StyledMapContainer } from './MapStyles';
import { ContentsProps } from './types';

interface Props extends ContentsProps {
  height?: number;
  width?: number;
}

export const Map: React.FC<Props> = ({
  children,
  disableControls = false,
  height,
  width,
  ...rest
}) => {
  const { searchParams } = useRouting();
  const placeSnag = searchParams.get('placeSnag');
  const [isReady, setIsReady] = useState(false);

  // Use a stable key based on props that would require a new map instance
  const mapKey = useMemo(
    () => `map-${height}-${width}-${rest.visualContextId}-${Date.now()}`,
    [height, width, rest.visualContextId]
  );

  const whenReady = useCallback(() => {
    setIsReady(true);
  }, []);

  return (
    <StyledMapContainer
      $cursor={placeSnag === 'true' ? 'crosshair' : 'grab'}
      attributionControl={false}
      center={[0, 0]}
      doubleClickZoom={!disableControls}
      dragging={!disableControls}
      height={height}
      key={mapKey}
      scrollWheelZoom={!disableControls}
      wheelDebounceTime={10}
      wheelPxPerZoomLevel={350}
      whenReady={whenReady}
      width={width}
      zoom={1}
      zoomControl={false}
      zoomSnap={0.1}
    >
      {!disableControls && <ZoomControl position="bottomleft" />}
      <MapContents
        disableControls={disableControls}
        isReady={isReady}
        {...rest}
      >
        {children}
      </MapContents>
    </StyledMapContainer>
  );
};

Map.displayName = 'Map';
