import { useEffect, useRef } from 'react';
import { useBlocker } from 'react-router-dom';

interface UseListenNavigationParams {
  bodyEdited: boolean;
  handleDraftSave: () => void;
  isDirty: boolean;
  isSubmitted: string | null;
  isSubmitting: boolean;
  readOnly: boolean;
}

export const useListenNavigation = ({
  isDirty,
  bodyEdited,
  isSubmitting,
  isSubmitted,
  readOnly,
  handleDraftSave,
}: UseListenNavigationParams): null => {
  const isSaving = useRef(false);

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) => !readOnly && (isDirty || bodyEdited)
  );

  useEffect(() => {
    const handleBlockedNavigation = async () => {
      if (blocker.state === 'blocked' && !isSaving.current) {
        try {
          isSaving.current = true;
          if (!isSubmitted && !isSubmitting) await handleDraftSave();
          blocker.proceed();
        } finally {
          isSaving.current = false;
        }
      }
    };

    if (blocker.state === 'blocked') {
      handleBlockedNavigation();
    }
  }, [blocker.state, handleDraftSave]);

  // Still handle beforeunload for browser/tab closing
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (!readOnly && (isDirty || bodyEdited)) {
        handleDraftSave();
        event.preventDefault();
        event.returnValue = ''; // Required for Chrome
        return ''; // Required for other browsers
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [bodyEdited, handleDraftSave, isDirty, readOnly]);

  return null;
};
