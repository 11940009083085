import React from 'react';

import { SvgIconProps } from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';

export const ChangeableFormIcon: React.FC<
  SvgIconProps & { customColor: string }
> = (props) => {
  const { scale = 1 } = props;

  return (
    <svg
      fill="none"
      height={44 * Number(scale)}
      viewBox="0 0 27 44"
      width={27 * Number(scale)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M0 2C0 0.895429 0.895431 0 2 0H25C26.1046 0 27 0.895431 27 2V24.4C27 25.5046 26.1046 26.4 25 26.4H14.625V40.3333C14.625 40.3333 13.7812 44 13.5 44C13.2188 44 12.375 40.3333 12.375 40.3333V26.4H2C0.895431 26.4 0 25.5046 0 24.4V2Z"
        fill={props.customColor || customColors.purpleMonoHighlight}
        fillRule="evenodd"
      />
      <rect
        fill={customColors.white}
        height="22"
        transform="translate(2.25 2.2002)"
        width="22.5"
      />
    </svg>
  );
};
