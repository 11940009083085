import {
  CommentFragmentFragmentDoc,
  CommentThread,
  CommentThreadDocument,
  Message,
  useDeleteCommentMutation,
} from '@pro4all/graphql';

import { InnerFooterProps } from './InnerFooter';
import { useCommentOptimisticResponse } from './useCommentOptimisticResponse';

interface Props extends Pick<InnerFooterProps, 'messageId' | 'threadId'> {
  onError: () => void;
  onSuccess: () => void;
  targetId: string;
}

export const useDelete = ({
  messageId,
  onError,
  onSuccess,
  threadId,
  targetId,
}: Props) => {
  const [deleteCommentMutation] = useDeleteCommentMutation();

  const updatedTableCache = useCommentOptimisticResponse();

  const deleteComment = async () => {
    if (messageId) {
      try {
        await deleteCommentMutation({
          optimisticResponse: {
            deleteComment: {
              __typename: 'MutateDeleteCommentResponse',
              errors: [],
              id: messageId,
              success: true,
            },
          },
          update: (cache) => {
            const data: Message | null = cache.readFragment({
              fragment: CommentFragmentFragmentDoc,
              fragmentName: 'CommentFragment',
              id: `Message:${messageId}`,
            });

            const existingThread: { commentThread: CommentThread } | null =
              cache.readQuery({
                query: CommentThreadDocument,
                variables: { targetId },
              });

            if (existingThread) {
              const remainingMessagesIds =
                existingThread?.commentThread?.messages
                  ?.map((message) => message.id)
                  .filter((id) => id !== messageId) || [];

              remainingMessagesIds.length === 0 &&
                updatedTableCache({ status: undefined, targetId });
            }
            if (data) {
              cache.modify({
                broadcast: false,
                fields: {
                  deleted() {
                    return true;
                  },
                },
                id: cache.identify(data),
              });
            }
          },
          variables: {
            id: messageId,
            threadId,
          },
        });
        onSuccess();
      } catch (e) {
        onError();
      }
    }
  };

  return { deleteComment };
};
