import { AuthService } from '@pro4all/authentication/src/services/auth-service';

// Cache for template icon URLs to prevent regenerating URLs for the same template ID
const urlCache = new Map<string, string>();

export const getFileUrlByTemplateId = (templateId?: string) => {
  if (!templateId) return null;

  // Return cached URL if available
  if (urlCache.has(templateId)) {
    return urlCache.get(templateId);
  }

  const accessTokenValue = AuthService.getRawAccessTokenFromLocalStorage();
  const url = `https://graph.prostream.dev/quality/v2/templates/${templateId}/templateIconUrl?access_token=${accessTokenValue}`;

  // Cache the URL
  urlCache.set(templateId, url);

  return url;
};
