import { useState } from 'react';
import ConfettiExplosion from 'react-confetti-explosion';
import styled from 'styled-components';

import { customColors } from '@pro4all/shared/themes';

declare global {
  interface Window {
    confetti: () => void;
  }
}

const StyledConfettiExplosion = styled(ConfettiExplosion)`
  position: absolute;
  left: 50vw;
  overflow: hidden;
`;

export const Confetti = () => {
  const [confettiOpen, setConfettiOpen] = useState<boolean>(false);

  // We bind a confetti function to the window object, so we can access it via
  // Userflow, to award our customers of completing tasks
  window.confetti = () => {
    if (!confettiOpen) {
      setConfettiOpen(true);
    }
  };

  const handleOnComplete = () => {
    setConfettiOpen(false);
  };

  const confettiProps = {
    colors: [
      customColors.purpleMonoHighlight,
      customColors.greenMonoHighlight,
      customColors.orangeAccent,
      customColors.greenLight,
    ],
    duration: 2000,
    force: 0.8,
    onComplete: handleOnComplete,
    particleCount: 300,
    width: window.innerWidth * 1.2,
    zIndex: 9000,
  };

  return confettiOpen ? <StyledConfettiExplosion {...confettiProps} /> : null;
};
