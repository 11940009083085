import { Routes } from '@pro4all/shared/config';

import * as oldRoutes from './oldRoutes';
export const routeRedirects: Record<string, string> = {
  [oldRoutes.access]: Routes.accessUrl,
  [oldRoutes.accessDocuments]: Routes.accessUrl,
  [oldRoutes.allMessages]: Routes.organizationAllMessages,
  [oldRoutes.baseDocs]: Routes.orgDms,
  [oldRoutes.baseSettingsProject]: Routes.projectSettingsUrl,
  [oldRoutes.baseDms]: Routes.orgDms,
  [oldRoutes.config]: Routes.accessUrl,
  [oldRoutes.collectionFolder]: Routes.orgCollectionFolderUrl,
  [oldRoutes.confirmEmail]: Routes.confirmEmailUrl,
  [oldRoutes.docs]: Routes.orgDms,
  //redirect for these routes already exists in shareddocscontext.tsx
  // [oldRoutes.docsSharedByInvite]: Routes.orgDocsSharedByInviteUrl,
  // [oldRoutes.docsSharedInScope]: Routes.orgDocsSharedInScopeUrl,
  [oldRoutes.document]: Routes.orgDms,
  [oldRoutes.draftMessages]: Routes.orgDraftMessages,
  [oldRoutes.finalizeInvitation]: Routes.finalizeInvitationUrl,
  [oldRoutes.groups]: Routes.orgGroups,
  [oldRoutes.inboxMessages]: Routes.orgInboxMessages,
  [oldRoutes.members]: Routes.orgUsers,
  [oldRoutes.messages]: Routes.viewOrgMessage,
  [oldRoutes.metaDataDocuments]: Routes.orgMetadataTemplates,
  [oldRoutes.metaDataDocumentsFields]: Routes.orgMetadataFieldTemplates,
  [oldRoutes.metaDataDocumentsTemplates]: Routes.orgMetadataTemplates,
  [oldRoutes.metaDataHierarchicalLists]: Routes.orgHierarchicalLists,
  [oldRoutes.metaDataQualityControl]: Routes.orgQualityFormTemplates,
  [oldRoutes.metaDataQualityControlFields]: Routes.orgQualityFieldTemplates,
  [oldRoutes.metaDataQualityControlForms]: Routes.orgQualityFormTemplates,
  [oldRoutes.metaDataQualityControlReportConfigurations]:
    Routes.orgQualityReportConfigurations,
  [oldRoutes.metaDataQualityControlSnags]: Routes.orgQualitySnagTemplates,
  [oldRoutes.metaDataQualityControlTBQTemplates]: Routes.orgTemplatesTbqflat,
  [oldRoutes.metaDataQualityControlTBQTemplatesScope]:
    Routes.orgTemplatesTbqflat,
  [oldRoutes.myApiKeyManagement]: Routes.myApiKeyManagementUrl,
  [oldRoutes.myOverview]: Routes.myProjectsOverview,
  [oldRoutes.myProfile]: Routes.myProfileUrl,
  [oldRoutes.myProjects]: Routes.myProjectsOverview,
  [oldRoutes.mySignature]: Routes.mySignatureUrl,
  [oldRoutes.mySupport]: Routes.mySupportUrl,
  [oldRoutes.myTasks]: Routes.myTasksOverview,
  [oldRoutes.objectDrawing]: Routes.orgObjectDrawing,
  [oldRoutes.objectDrawings]: Routes.orgObjectDrawings,
  [oldRoutes.objectResults]: Routes.orgObjectResults,
  [oldRoutes.objectTBQ]: Routes.objectTasks,
  [oldRoutes.objectTBQMultiBrandcheckResults]:
    Routes.orgTBQMultiBrandcheckResultsUrl,
  [oldRoutes.objectTBQMultiRieResults]: Routes.objectTBQRieResultsUrl,
  [oldRoutes.objectTBQOsidLog]: Routes.objectOsid,
  [oldRoutes.objectTBQResults]: Routes.objectTBQResultsUrl,
  [oldRoutes.objectTBQRieResults]: Routes.objectTBQRieResultsUrl,
  [oldRoutes.objectTasks]: Routes.objectTasks,
  [oldRoutes.objects]: Routes.objectsUrl,
  [oldRoutes.objectsBase]: Routes.objectsUrl,
  //these routes haven't changed
  //   office,
  //   openCdeMetadata,
  [oldRoutes.orgTemplatesTasks]: Routes.orgTaskCategories,
  [oldRoutes.organization]: Routes.myOverview,
  [oldRoutes.organizationMessages]: Routes.orgInboxMessages,
  [oldRoutes.organizationNotifications]: Routes.orgNotificationsUrl,
  //hasn't changed
  //   organizationSupport,
  [oldRoutes.projectDocsSharedByInvite]: Routes.projectDocsSharedByInvite,
  [oldRoutes.projectDocsSharedInScope]: Routes.projectDocsSharedInScope,
  [oldRoutes.project]: Routes.projectTasks,
  [oldRoutes.projectAccess]: Routes.projectDocumentPermissions,
  [oldRoutes.projectAccessDocuments]: Routes.projectDocumentPermissions,
  [oldRoutes.projectAccessQualityControl]: Routes.projectQualityPermissions,
  [oldRoutes.projectAccessTasks]: Routes.projectTaskPermissions,
  [oldRoutes.projectAllMessages]: Routes.projectAllMessages,
  [oldRoutes.projectCollectionFolder]: Routes.projectCollectionFolderUrl,
  [oldRoutes.projectDmsBase]: Routes.projectDocTableUrl,
  [oldRoutes.projectDocs]: Routes.projectDocTableUrl,
  [oldRoutes.projectDocsBase]: Routes.projectDocTableUrl,
  [oldRoutes.projectDocsNotifications]: Routes.projectNotificationsUrl,
  [oldRoutes.projectDocsTasks]: Routes.projectDocTasks,
  [oldRoutes.projectDraftMessages]: Routes.projectMessagesDraft,
  [oldRoutes.projectGroups]: Routes.projectGroupsUrl,
  [oldRoutes.projectInboxMessages]: Routes.projectMessagesInbox,
  [oldRoutes.projectMembers]: Routes.projectMembersUrl,
  [oldRoutes.projectMessages]: Routes.viewProjMessage,
  [oldRoutes.projectMetaDataDocuments]:
    Routes.projectMetadataDocumentTemplatesUrl,
  [oldRoutes.projectMetaDataDocumentsFields]:
    Routes.projectMetadataDocumentFieldsUrl,
  [oldRoutes.projectMetaDataDocumentsTemplates]:
    Routes.projectMetadataDocumentTemplatesUrl,
  [oldRoutes.projectMetaDataHierarchicalLists]:
    Routes.projectMetaDataHierarchicalLists,
  [oldRoutes.projectMetaDataQualityControl]: Routes.projectMetadataQualityForms,
  [oldRoutes.projectMetaDataQualityControlFields]:
    Routes.projectMetadataQualityFieldsUrl,
  [oldRoutes.projectMetaDataQualityControlForms]:
    Routes.projectMetadataQualityForms,
  [oldRoutes.projectMetaDataQualityControlReportConfigurations]:
    Routes.projectMetaDataQualityControlReportConfigurations,
  [oldRoutes.projectMetaDataQualityControlSnags]:
    Routes.projectMetadataQualitySnagsUrl,
  [oldRoutes.projectMetaDataQualityControlTemplates]:
    Routes.projectMetaDataQualityControlTemplates,
  //   projectObjectDocuments,
  [oldRoutes.projectObjectDrawing]: Routes.projectObjectDrawing,
  [oldRoutes.projectObjectDrawings]: Routes.projectObjectDrawings,
  [oldRoutes.projectObjectResults]: Routes.projectObjectResults,
  [oldRoutes.projectObjectTBQMultiBrandcheckResults]:
    Routes.projectObjectTBQMultiBrandcheckResults,
  [oldRoutes.projectObjectTBQMultiRieResults]:
    Routes.projectObjectTBQMultiRieResults,
  [oldRoutes.projectObjectTBQOsidLog]: Routes.projectObjectOsid,
  //   projectObjectTBQResults,
  [oldRoutes.projectObjectTBQRieResults]: Routes.projectObjectTBQRieResults,
  [oldRoutes.projectObjectTasks]: Routes.projectObjectTasks,
  [oldRoutes.projectObjectTbq]: Routes.projectObjectTasks,
  [oldRoutes.projectObjects]: Routes.projectObjectTasks,
  [oldRoutes.projectQualityControlDrawing]:
    Routes.projectQualityControlDrawingUrl,
  [oldRoutes.projectQualityControlDrawings]:
    Routes.projectQualityControlDrawingsUrl,
  [oldRoutes.projectQualityControlMain]: Routes.projectQualityControlResultsUrl,
  [oldRoutes.projectQualityControlMySavedAnswers]:
    Routes.projectQualityControlMySavedAnswersUrl,
  [oldRoutes.projectQualityControlNotifications]:
    Routes.projectQualityControlNotificationsUrl,
  [oldRoutes.projectQualityControlRecurringSeries]:
    Routes.projectQualityControlRecurringSeries,
  [oldRoutes.projectQualityControlResults]:
    Routes.projectQualityControlResultsUrl,
  [oldRoutes.projectQualityControlTasks]: Routes.projectQualityControlTasksUrl,
  [oldRoutes.projectSentMessages]: Routes.projectMessagesSent,
  [oldRoutes.projectSettings]: Routes.projectSettingsUrl,
  [oldRoutes.projectSettingsConnections]: Routes.connectionsSettingsUrl,
  [oldRoutes.projectSettingsDocuments]: Routes.projectDocumentSettingsUrl,
  [oldRoutes.projectSettingsProject]: Routes.projectSettingsUrl,
  [`${oldRoutes.project}${oldRoutes.smartFolder}`]:
    Routes.projectSmartFolderTableUrl,
  [`${oldRoutes.project}${oldRoutes.smartFolders}`]:
    Routes.projectSmartFolderTableUrl,
  [oldRoutes.projectTemplatesTasks]: Routes.projectTaskTemplatesUrl,
  [oldRoutes.projectUsersGroups]: Routes.projectMembersUrl,
  [oldRoutes.projectWorkflows]: Routes.projectTasks,
  [oldRoutes.projects]: Routes.orgProjects,
  '/registration': Routes.registration,
  [oldRoutes.sentMessages]: Routes.orgSentMessages,
  [oldRoutes.settings]: Routes.orgSettingsGeneral,
  [oldRoutes.settingsOrganization]: Routes.orgSettingsGeneral,
  [oldRoutes.settingsOrganizationDocuments]: Routes.orgSettingsDocSettings,
  [oldRoutes.settingsOrganizationDomains]: Routes.orgSettingsDomains,
  [oldRoutes.settingsOrganizationIntegrations]: Routes.orgSettingsIntegrations,
  [oldRoutes.settingsOrganizationLicense]: Routes.orgSettingsLicense,
  [oldRoutes.settingsOrganizationOrganization]: Routes.orgSettingsGeneral,
  [oldRoutes.settingsOrganizationProvisioning]: Routes.orgSettingsProvisioning,
  [oldRoutes.settingsOrganizationSettings]: Routes.orgSettingsGeneral,
  [oldRoutes.smartFolder]: Routes.orgSmartFolderTableUrl,
  [oldRoutes.smartFolderPath]: Routes.orgSmartFolderTableUrl,
  [oldRoutes.smartFolders]: Routes.orgSmartFolderTableUrl,
  [oldRoutes.usersGroups]: Routes.orgUsers,
  '/workflow': Routes.myTasksOverview,
};
