import { ReportConfiguration } from '@pro4all/graphql';
import { OptimisticResponseProvider } from '@pro4all/shared/ui/table';

import { ScreenReportConfigurations } from './ScreenReportConfigurations';

export const ScreenReportConfigurationsProviderWrapper = ({
  isProject = false,
}: {
  isProject?: boolean;
}) => (
  <OptimisticResponseProvider<ReportConfiguration>>
    <ScreenReportConfigurations isProject={isProject} />
  </OptimisticResponseProvider>
);
