import { useTranslation } from 'react-i18next';
import { enqueueSnackbar } from 'notistack';

import {
  removeDocumentsFromStore,
  useCollectionFolderContext,
} from '@pro4all/documents/ui/folders';
import {
  SnackbarDocumentDeleted,
  SnackbarDocumentsDeleted,
} from '@pro4all/documents/ui/snackbars';
import { Document, useDeleteDocumentsMutation } from '@pro4all/graphql';
import { TrackingEvent } from '@pro4all/shared/config';
import { useRouting } from '@pro4all/shared/routing-utils';
import { useOptimisticResponseContext } from '@pro4all/shared/ui/table';
import { useAnalytics } from '@pro4all/shared/vendor';

import { DocumentActionProps } from '../../types';
import { useDocumentSelection } from '../../useDocumentSelection';

import { useCacheHelpers } from './useCacheHelpers';

export const useHandleDeleteDocuments = () => {
  const { t } = useTranslation();
  const {
    params: { projectId },
  } = useRouting();
  const { track } = useAnalytics();
  const [deleteDocuments] = useDeleteDocumentsMutation();
  const { getCachedFolder, restoreDocumentsCallback, updateCache } =
    useCacheHelpers();
  const {
    state: { items },
  } = useOptimisticResponseContext<Document>();
  const { deselectDocument } = useDocumentSelection(items);
  const { updateDocumentsCount } = useCollectionFolderContext();

  const handleDeleteDocuments = async ({
    hasFolderPermission,
    selection,
    userId,
  }: Pick<
    DocumentActionProps,
    'hasFolderPermission' | 'selection' | 'userId'
  >) => {
    track(TrackingEvent.DocumentDeleteSelected, {
      fileCount: selection.length,
    });

    const documentIds =
      hasFolderPermission('DeleteOwnContent') &&
      !hasFolderPermission('DeleteContent')
        ? selection
            .filter((row) => row.createdBy?.id === userId)
            .map(({ id }) => id)
        : selection.map(({ id }) => id);
    const skippedDocuments =
      hasFolderPermission('DeleteOwnContent') &&
      !hasFolderPermission('DeleteContent')
        ? selection.filter((row) => row.createdBy?.id !== userId).length
        : 0;

    const {
      data: {
        deleteDocuments: { succeededIds, failedIds },
      },
      errors,
    } = await deleteDocuments({
      variables: { documentIds },
    });

    // Get current folder information in cache
    const cachedFolder = getCachedFolder();

    // Documents in cache are updated to remove deleted documents.
    const updatedDocuments = [
      ...cachedFolder.folder.documents.filter(
        (document: Document) => !documentIds.includes(document.id)
      ),
    ];
    updateCache({ updatedDocuments });

    deselectDocument();

    if (succeededIds?.length) {
      // deleteItems(succeededIds);
      removeDocumentsFromStore(succeededIds, projectId ?? '', userId);
      updateDocumentsCount?.();

      const deletedMessage =
        succeededIds.length === 1 ? (
          <SnackbarDocumentDeleted
            documentId={selection[0].id}
            documentName={selection[0].name}
            restoreItems={restoreDocumentsCallback}
          />
        ) : (
          <SnackbarDocumentsDeleted
            count={succeededIds.length}
            documentIds={succeededIds}
            restoreItems={restoreDocumentsCallback}
          />
        );

      enqueueSnackbar(deletedMessage);
    }

    if (errors) enqueueSnackbar(t('Something went wrong'));
    if (failedIds?.length) {
      enqueueSnackbar(
        t('Failed to delete {{count}} document(s)', {
          count: failedIds.length,
        })
      );
    }

    if (skippedDocuments > 0) {
      enqueueSnackbar(
        t(
          'Deletion of {{count}} documents skipped due to not being own documents',
          {
            count: skippedDocuments,
          }
        )
      );
    }
  };

  return { handleDeleteDocuments };
};
