import styled from 'styled-components';

import { customColors } from '@pro4all/shared/themes';
import { Icon } from '@pro4all/shared/ui/icons';

export const Container = styled.div<{
  borderRadius: number;
  colorBackground: string;
  colorBorder: string;
  colorFont: string;
  onClick?: () => void;
  paddingHorizontal: number;
  paddingVertical: number;
}>`
  display: flex;
  gap: 4px;
  width: max-content;
  min-height: 34px;
  align-items: center;
  border: ${({ colorBorder }) =>
    colorBorder ? `1px solid ${colorBorder}` : 'none'};
  border-radius: 4px;
  border-radius: ${({ borderRadius }) => `${borderRadius}px`};
  color: ${({ colorFont }) =>
    colorFont ? `${colorFont}` : customColors.grey700};
  background-color: ${({ colorBackground }) =>
    colorBackground ? colorBackground : customColors.white};
  padding: ${({ paddingHorizontal, paddingVertical }) =>
    `${paddingVertical}px ${paddingHorizontal}px`};
  &:hover {
    cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  }
`;

export const HoverIcon = styled(Icon)`
  display: flex;
  width: 24px;
  height: 24px;
`;

export const Number = styled.div<{
  numberColorBackground: string;
  numberColorBorder: string;
  numberColorFont: string;
}>`
  display: flex;
  ${Container}:hover &,
  ${Container}:focus & {
    display: none;
  }
  border: ${({ numberColorBorder }) =>
    numberColorBorder ? `1px solid ${numberColorBorder}` : 'none'};
  color: ${({ numberColorFont }) =>
    numberColorFont ? `${numberColorFont}` : customColors.grey700};
  background-color: ${({ numberColorBackground }) =>
    numberColorBackground ? numberColorBackground : customColors.white};
  border-radius: 50%;
  padding: 3px;
  font-size: 10px;
  font-weight: bold;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
`;
