import { Project } from '@pro4all/graphql';
import { ProjectSidebar } from '@pro4all/projects/ui/sidebar';
import {
  ProjectsTable,
  ProjectsTablePostFix,
} from '@pro4all/projects/ui/table';
import {
  DataViewContextProvider,
  DataViewType,
} from '@pro4all/shared/ui/data-view';
import { OptimisticResponseProvider } from '@pro4all/shared/ui/table';

export const MyProjectsProviderWrapper = () => (
  <OptimisticResponseProvider<Project>>
    <>
      <ProjectSidebar />
      <DataViewContextProvider
        initialView={DataViewType.Table}
        viewKey="projects-list"
        views={[DataViewType.Table, DataViewType.Cards]}
      >
        <ProjectsTable postfix={ProjectsTablePostFix.OWN} />
      </DataViewContextProvider>
    </>
  </OptimisticResponseProvider>
);
