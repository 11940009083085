import styled from 'styled-components';

import { customColors } from '@pro4all/shared/themes';
import { IconButton } from '@pro4all/shared/ui/buttons';
import { Menu } from '@pro4all/shared/ui/context-menu';

export const ContentWrap = styled.div`
  min-width: 326px;
  max-width: 420px;
  height: 344px;
  display: flex;
  flex-direction: column;
`;

export const IconWrap = styled.div<{ $active: boolean }>`
  display: inline-flex;
  background-color: ${({ $active }) =>
    $active ? `${customColors.pendingBg}` : 'none'};
  border: ${({ $active }) =>
    $active
      ? `1px solid ${customColors.successHover}`
      : `1px solid ${customColors.grey700}`};
  border-radius: 5px;
  padding: 2.5px 0.5rem;
  vertical-align: middle;
  line-height: 20px;
  max-height: 27px;
  &:hover {
    border: ${({ $active }) =>
      $active
        ? `1px solid ${customColors.successHover}`
        : `1px solid ${customColors.grey900}`};
    background-color: ${({ $active }) =>
      $active ? `${customColors.pendingBg}` : `${customColors.grey300}`};
    cursor: pointer;
  }
`;

export const Footer = styled.footer`
  position: sticky;
  bottom: 0;
  background-color: ${customColors.white};
  display: flex;
  justify-content: right;
  border-top: 1px solid ${customColors.grey200};
  padding: 8px;
  gap: 8px;
`;

// The icon slightly deviates from our general icons so here is an approximation via SCs.
export const ApplyButton = styled(IconButton)`
  && {
    background-color: ${customColors.purpleMonoHighlight};
    svg {
      color: ${customColors.white};
    }
    :hover {
      background-color: ${customColors.purpleMonoHighlight};
      border-color: ${customColors.grey900};
    }
    :disabled {
      svg {
        color: ${customColors.grey900};
      }
    }
  }
`;

export const Counter = styled.div`
  border-radius: 5px;
  padding: 0 4px;
  background-color: ${customColors.successHover};
  color: ${customColors.white};
  font-size: 12px;
  line-height: 20px;
  margin-left: 4px;
`;

export const StyledMenu = styled(Menu)`
  && {
    overflow: hidden;
  }
`;
