import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ScheduledTime, useLoadQueriesQuery } from '@pro4all/graphql';
import { TopTen } from '@pro4all/search/ui';
import { Box } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { OnSearchArgs, SearchEntities } from '@pro4all/shared/search-utils';
import { IconButton } from '@pro4all/shared/ui/buttons';
import { DialogContainer, DialogHeader } from '@pro4all/shared/ui/dialog';
import { Text } from '@pro4all/shared/ui/typography';
import { isDefined } from '@pro4all/shared/utils';

import { SaveQueryForm } from './form/SaveQueryForm';
import { SaveQueryFormProps } from './form/SaveQueryForm.types';
import { calculateScope } from './calculateScope';
import { DialogMode } from './DialogMode';
import { NoContent } from './NoContent';
import { QueryList } from './QueryList';

interface Props {
  mode?: DialogMode;
  onClose: () => void;
  onSearch: (args: OnSearchArgs) => void;
  open: boolean;
  scope: SearchEntities;
  setCurrentQuery: (query: string) => void;
  setMode: (mode: DialogMode) => void;
  topTen: TopTen;
}

export const MyQueriesDialog: React.FC<Props> = ({
  mode,
  onSearch,
  open,
  onClose,
  setCurrentQuery,
  setMode,
  topTen,
  scope,
}) => {
  const { t } = useTranslation();
  const { params, searchParams } = useRouting();

  const { data, loading, refetch } = useLoadQueriesQuery({
    variables: {
      projectId: params.projectId || '',
    },
  });

  useEffect(() => {
    refetch();
  }, [open, refetch]);

  const savedQueries = (data?.loadQueries || [])
    .filter(isDefined)
    .filter((query) => query?.scope === calculateScope(scope));

  const visibleQueries = savedQueries?.filter(
    (searchObject) => !searchObject.deletedAt
  );

  const [savedSearchId, setSavedSearchId] = useState<string | undefined>(
    undefined
  );
  const paramSearchId = searchParams.get('savedSearchId') || null;
  if (paramSearchId && !savedSearchId) {
    setSavedSearchId(paramSearchId);
  }

  const currentQuery = savedQueries.find((s) => s.id === savedSearchId);

  const defaultTime: ScheduledTime = { hour: '07', minute: '00' };

  const editModeFromParams = searchParams.get('action') === 'editSearch';
  const dismiss = () => {
    if (editModeFromParams) {
      searchParams.delete('action');
      onClose();
    } else mode === DialogMode.Edit ? setMode(DialogMode.Default) : onClose();
  };

  const defaultFormValues: SaveQueryFormProps = {
    id: 'new-saved-search',
    mode: DialogMode.Create,
    name: '',
    notificationDays: [],
    notificationTimes: [defaultTime],
    notificationsEnabled: false,
    onClose,
    scope,
  };

  const ContentObject: { [key in DialogMode]: React.ReactNode } = {
    Create: <SaveQueryForm {...defaultFormValues} />,
    Default: (
      <QueryList
        currentId={savedSearchId}
        onClose={onClose}
        onSearch={onSearch}
        savedQueries={savedQueries}
        setCurrentQuery={setCurrentQuery}
        setMode={setMode}
        setQueryId={setSavedSearchId}
        topTen={topTen}
      />
    ),
    Edit: (
      <SaveQueryForm
        {...defaultFormValues}
        mode={DialogMode.Edit}
        onClose={dismiss}
        {...currentQuery}
        id={savedSearchId || ''}
        scope={scope}
      />
    ),
  };

  const defaultMode = mode === DialogMode.Default;
  const createMode = mode === DialogMode.Create;
  const noContent = visibleQueries?.length < 1 && !createMode && !loading;

  if (!mode) return null;

  return (
    <DialogContainer $fitWidth $noHorizontalPadding={defaultMode} open={open}>
      <Box minHeight="160px" minWidth="564px" px={defaultMode ? '10px' : '0'}>
        {noContent ? (
          <NoContent onDismiss={onClose} />
        ) : (
          <>
            <DialogHeader>
              <Box
                alignItems="center"
                display="flex"
                flex="1"
                height="40px"
                justifyContent="space-between"
              >
                <Text variant="h3">{t('Saved searches')}</Text>
                <IconButton
                  color="default"
                  iconName="close"
                  onClick={dismiss}
                />
              </Box>
            </DialogHeader>
            {ContentObject[mode]}
          </>
        )}
      </Box>
    </DialogContainer>
  );
};
