import { Route, Routes as RouterDomRoutes } from 'react-router-dom';

import { NotificationScope } from '@pro4all/graphql';
import { NotificationsPageProviderWrapper } from '@pro4all/notifications';
import {
  Drawing,
  DrawingMainProviderWrapper,
} from '@pro4all/quality-control/ui/floor-plan';
import { MapLinkingContextProvider } from '@pro4all/quality-control/ui/maps';
import { ReportingMain } from '@pro4all/quality-control/ui/report-options';
import { ResultsMain } from '@pro4all/quality-control/ui/results';
import { SavedAnswersTable } from '@pro4all/quality-control/ui/saved-answers';
import { Routes } from '@pro4all/shared/config';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { useRouting } from '@pro4all/shared/routing-utils';
import { SnagFormSearchContextProvider } from '@pro4all/shared/snags-and-forms-search-context';
import {
  DataViewContextProvider,
  DataViewType,
} from '@pro4all/shared/ui/data-view';
import { Column, Main } from '@pro4all/shared/ui/layout';
import { SeriesTable } from '@pro4all/shared/ui/tasks';

import { QualityControlTasksProviderWrapper } from './QualityControlTasksProviderWrapper';
import { Tabs } from './Tabs';

export const QualityControlRoutes = () => {
  const { params, searchParams } = useRouting();
  const hasSeriesFeature = useFeatureFlag('task-series');
  const projectId = params.projectId;

  if (searchParams.get('action') === 'createReport')
    return (
      <MapLinkingContextProvider>
        <ReportingMain />
      </MapLinkingContextProvider>
    );

  return (
    <MapLinkingContextProvider>
      <RouterDomRoutes>
        <Route
          element={
            <DataViewContextProvider
              initialView={DataViewType.Table}
              viewKey="quality-control-results"
              views={[DataViewType.Table, DataViewType.Charts]}
            >
              <SnagFormSearchContextProvider>
                <Tabs />
                <ResultsMain projectId={projectId} />
              </SnagFormSearchContextProvider>
            </DataViewContextProvider>
          }
          path={Routes.results}
        />
        <Route
          element={
            <Main sx={{ overflow: 'hidden' }}>
              <Column>
                <Tabs />
                <DrawingMainProviderWrapper />
              </Column>
            </Main>
          }
          path={Routes.drawings}
        />

        <Route
          element={<QualityControlTasksProviderWrapper />}
          path={Routes.tasks}
        />
        <Route
          element={
            <>
              <Tabs />
              <SavedAnswersTable />
            </>
          }
          path={Routes.savedAnswers}
        />

        {hasSeriesFeature && (
          <Route
            element={
              <>
                <Tabs />
                <SeriesTable />
              </>
            }
            path={Routes.recurringSeries}
          />
        )}

        <Route
          element={
            <Main>
              <Column>
                <Tabs />
                <NotificationsPageProviderWrapper
                  scope={NotificationScope.Qc}
                />
              </Column>
            </Main>
          }
          path={Routes.notifications}
        />

        {[Routes.projectQualityControlDrawing, Routes.objectDrawing].map(
          (path) => (
            <Route element={<Drawing />} key={path} path={path} />
          )
        )}
      </RouterDomRoutes>
    </MapLinkingContextProvider>
  );
};
