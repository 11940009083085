import React, { useEffect } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';

import {
  FolderTreeContextProvider,
  useFolderTreeContextOuter,
} from '@pro4all/documents/ui/folders';
import { GenerateLinkContextProvider } from '@pro4all/documents/ui/share';
import { ShareBuild12ContextProvider } from '@pro4all/documents/ui/share-build12';
import { ShareSnagstreamContextProvider } from '@pro4all/documents/ui/share-sns';
import { Document } from '@pro4all/graphql';
import { Routes } from '@pro4all/shared/config';
import { DocumentSearchContextProvider } from '@pro4all/shared/documents-search-context';
import { useMatchAny } from '@pro4all/shared/hooks';
import {
  generateRoute,
  getLastVisitedOrganizationFolder,
  getLastVisitedProjectFolder,
  useRouting,
} from '@pro4all/shared/routing-utils';
import { OptimisticResponseProvider } from '@pro4all/shared/ui/table';

import { DocumentsBrowserContents } from './DocumentsBrowserContents';

export const DocumentsBrowser: React.FC = () => {
  const navigate = useNavigate();
  const {
    params: { projectId },
    searchParams,
    state: currentRoutingState,
  } = useRouting();

  const {
    state: { path },
  } = useFolderTreeContextOuter();

  const isProjectSmaertFolder = useMatch(Routes.smartFolderTable);
  const isSmartFolder = useMatchAny([
    Routes.projectSmartFolderTableUrl,
    Routes.orgSmartFolderTableUrl,
  ]);

  const routeSmartFolder = isProjectSmaertFolder || isSmartFolder;

  const isCollectionFolder = useMatchAny([
    Routes.orgCollectionFolderUrl,
    Routes.projectCollectionFolderUrl,
  ]);

  const isCollectionTable = useMatch(Routes.collectionTable);

  const routeCollectionFolder = isCollectionFolder || isCollectionTable;

  const isdocTable = useMatch(Routes.docTable);
  const isDocs = useMatch(Routes.docs);
  const routeDocs = isdocTable || isDocs;

  const routeTasks = useMatch(Routes.projectDocTasks);
  const routeNotifications = useMatch(Routes.projectNotificationsUrl);
  const routeProjectDmsBase = useMatch(Routes.projectDmsBase);

  const isDmsRouteOtherThanDocs = Boolean(routeProjectDmsBase && !routeDocs);
  const isSearch = searchParams.has('search');
  const shouldRedirectToFolder =
    !path &&
    !isDmsRouteOtherThanDocs &&
    !routeSmartFolder &&
    !routeCollectionFolder &&
    !routeTasks &&
    !routeNotifications &&
    !isSearch;

  useEffect(() => {
    if (shouldRedirectToFolder) {
      const folderPath =
        (projectId
          ? getLastVisitedProjectFolder(projectId)?.params?.path
          : getLastVisitedOrganizationFolder()?.path) ?? '';
      const pathUrl = projectId
        ? `${generateRoute('projectDocumentsTablesPathUrl', {
            params: { projectId },
          })}/`
        : Routes.orgDocumentsTablesPathUrl;

      let navigateUrl = `${pathUrl}${folderPath}`;
      const searchParamsString = searchParams.toString();
      if (searchParamsString) {
        navigateUrl += `?${searchParamsString}`;
      }

      navigate(navigateUrl, {
        replace: true,
        state: currentRoutingState,
      });
    }
  }, [navigate, path, projectId, shouldRedirectToFolder, currentRoutingState]);

  return (
    <FolderTreeContextProvider projectId={projectId}>
      <OptimisticResponseProvider<Document>>
        <GenerateLinkContextProvider>
          <DocumentSearchContextProvider>
            <ShareBuild12ContextProvider>
              <ShareSnagstreamContextProvider>
                <DocumentsBrowserContents />
              </ShareSnagstreamContextProvider>
            </ShareBuild12ContextProvider>
          </DocumentSearchContextProvider>
        </GenerateLinkContextProvider>
      </OptimisticResponseProvider>
    </FolderTreeContextProvider>
  );
};
