import { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { Marker as LeafletMarker, Tooltip } from 'react-leaflet';
import { DivIcon, LatLng, LatLngBounds, Point } from 'leaflet';
import styled from 'styled-components';

import { QualityControlInstanceType } from '@pro4all/graphql';
import { useQualityControlContext } from '@pro4all/quality-control/context';
import {
  ComposedPin,
  getFileUrlByTemplateId,
  TMuiIcon,
} from '@pro4all/shared/composed-snag-form-pin';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { customColors } from '@pro4all/shared/themes';
import { FormPin, SnagPin } from '@pro4all/shared/ui/icons';
import { MiddleEllipsis } from '@pro4all/shared/ui/middle-ellipsis';

import { useMapLinkingContext } from './MapLinkingContext';
import { MarkerLabelContent } from './types';

const isPointInBounds = (point: LatLng, bounds: LatLngBounds) =>
  bounds.contains(point);

interface BaseMarkerProps {
  draggable?: boolean;
  iconName?: string | null;
  iconType?: number;
  id?: string;
  lat: number;
  lng: number;
  onClick?: (id: string) => void;
  page?: number | null;
  report?: boolean;
  selectedId?: string;
  showLabel?: boolean;
  statusColor?: string;
  templateId?: string;
  type: QualityControlInstanceType;
  visualContextId?: string;
}

interface MarkerPropsWithLabel extends BaseMarkerProps {
  name: string;
  reference: string;
  showLabel?: true;
}

interface MarkerPropsWithoutLabel extends BaseMarkerProps {
  name?: never;
  reference?: never;
  showLabel?: false;
}
export type MarkerProps = MarkerPropsWithLabel | MarkerPropsWithoutLabel;

const MAX_TOOLTIP_PADDING = 8;
const MAX_TOOLTIP_FONT_SIZE = 10;
const MAX_TOOLTIP_WIDTH = 300;
const TOOLTIP_OFFSET_X = 1.5;
const TOOLTIP_OFFSET_Y = -48;

export const Marker = ({
  type,
  id,
  selectedId,
  lat,
  draggable,
  lng,
  name,
  visualContextId,
  onClick,
  statusColor,
  page,
  iconName,
  reference,
  showLabel,
  report = false,
  templateId = '',
  iconType = 1,
}: MarkerProps) => {
  const {
    iconScale,
    showInstanceLabels,
    instanceLabelContent,
    isSliderDragging,
  } = useMapLinkingContext();

  const { drawingBounds, setUpdateMarkerObj, updateMarkerObj } =
    useQualityControlContext();

  const htmlElement = document.createElement('div');
  const selected = id === selectedId;

  const colorTemplateSelection =
    type === QualityControlInstanceType.Form
      ? customColors.purpleShapeShadow
      : customColors.orangeAccent;
  const color =
    selected || !selectedId ? colorTemplateSelection : customColors.grey600;

  const isCustomerIconsEnabled = useFeatureFlag('customericons');

  useEffect(() => {
    const PinFeatureFlagRenderer = isCustomerIconsEnabled ? (
      <ComposedPin
        currentFile={iconType === 2 ? getFileUrlByTemplateId(templateId) : null}
        customColor={statusColor}
        report={report}
        scale={iconScale}
        selectedIconName={iconName as TMuiIcon}
        type={type === QualityControlInstanceType.Form ? 'form' : 'snag'}
      />
    ) : type === QualityControlInstanceType.Form ? (
      <FormPin color={color} iconScale={iconScale} statusColor={statusColor} />
    ) : (
      <SnagPin color={color} iconScale={iconScale} statusColor={statusColor} />
    );
    const root = createRoot(htmlElement);
    root.render(PinFeatureFlagRenderer);
  }, [htmlElement]);

  const icon = new DivIcon({
    html: htmlElement,
    iconAnchor: new Point(12 * iconScale, 48 * iconScale),
  });

  const getMarkerLabelText = () => {
    if (instanceLabelContent === MarkerLabelContent.Ref) return `#${reference}`;
    else if (instanceLabelContent === MarkerLabelContent.NameAndRef)
      return `#${reference} ${name}`;
    return name;
  };

  return (
    <LeafletMarker
      draggable={draggable}
      eventHandlers={{
        click: () => {
          if (onClick && id) onClick(id);
        },
        dragend: (event: L.LeafletEvent) => {
          if (
            drawingBounds &&
            isPointInBounds(
              new LatLng(
                event.target.getLatLng().lat,
                event.target.getLatLng().lng
              ),
              drawingBounds
            )
          ) {
            setUpdateMarkerObj({
              instanceId: id,
              page: page ?? 1,
              visualContextId: visualContextId ? visualContextId : '',
              x: event.target.getLatLng().lng,
              y: event.target.getLatLng().lat,
            });
          } else {
            const marker = event.target;

            const lastPosition = {
              lat: updateMarkerObj.y,
              lng: updateMarkerObj.x,
            };

            marker.setLatLng(
              updateMarkerObj.instanceId === id ? lastPosition : { lat, lng }
            );
          }
        },
      }}
      icon={icon}
      position={{
        lat: updateMarkerObj.instanceId === id ? updateMarkerObj.y : lat,
        lng: updateMarkerObj.instanceId === id ? updateMarkerObj.x : lng,
      }}
      zIndexOffset={selected && 1000}
    >
      {showLabel && name && reference && !isSliderDragging && (
        <MarkerTooltip
          direction="top"
          iconScale={iconScale}
          offset={[TOOLTIP_OFFSET_X * iconScale, TOOLTIP_OFFSET_Y * iconScale]}
          permanent={showInstanceLabels}
        >
          <MiddleEllipsis endLength={11} text={getMarkerLabelText()} />
        </MarkerTooltip>
      )}
    </LeafletMarker>
  );
};
const MarkerTooltip = styled(Tooltip)<{ iconScale: number }>`
  transform: translateY(-100%);
  background-color: ${customColors.grey600};
  color: ${customColors.white};
  font-weight: 500;
  border: ${customColors.grey600};
  border-radius: 5px;
  padding: ${({ iconScale }) =>
    `${Math.min(7 * iconScale, MAX_TOOLTIP_PADDING)}px`};
  font-size: ${({ iconScale }) =>
    `${Math.min(12 * iconScale, MAX_TOOLTIP_FONT_SIZE)}px`};
  max-width: ${({ iconScale }) =>
    `${Math.min(100 * iconScale, MAX_TOOLTIP_WIDTH)}px`};

  .leaflet-tooltip {
    height: auto;
  }

  &.leaflet-tooltip-top::before {
    border-top-color: ${customColors.grey600};
  }

  &.leaflet-tooltip-bottom::before {
    border-bottom-color: ${customColors.grey600};
  }
`;
