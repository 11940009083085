import { useMemo } from 'react';

import { useDocument } from '@pro4all/documents/data-access';
import {
  Document,
  DocumentVersion,
  useCommentThreadQuery,
} from '@pro4all/graphql';
import { Maybe, Message, ReferenceKind, ReferenceType } from '@pro4all/graphql';
import { useCardToggle } from '@pro4all/shared/ui/card-toggle';

import { Comments } from '../shared';

const useCommentCount = (
  messages: Maybe<Message[]> | undefined,
  document: Document | null,
  version: DocumentVersion | null
) =>
  useMemo(() => {
    if (!messages) return 0;

    if (document) {
      return messages.length;
    }

    if (version) {
      return messages.filter((message) =>
        message.references.some(
          (reference) =>
            reference.referenceType === ReferenceType.DocumentVersion &&
            reference.referenceKind === ReferenceKind.To &&
            reference.referenceValue === version.id
        )
      ).length;
    }

    return 0;
  }, [messages, document, version]);

export const SectionComments = () => {
  const { CardToggle, expanded } = useCardToggle({ identifier: 'comments' });
  const { document, version } = useDocument();

  const documentId = document?.id || version?.documentId;
  const { data } = useCommentThreadQuery({
    skip: !documentId,
    variables: { targetId: documentId || '' },
  });

  const commentCount = useCommentCount(
    data?.commentThread?.messages,
    document,
    version
  );

  return (
    <CardToggle count={commentCount} icon="chatOutlined" label="Comments">
      {expanded && <Comments />}
    </CardToggle>
  );
};
