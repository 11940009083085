import { client } from '@pro4all/authentication/src/graph-ql';
import { ObjectDocument, Task } from '@pro4all/graphql';

export const useCacheHelpers = (id: string) => {
  const getCachedObjectDetail = () =>
    client?.readQuery({
      query: ObjectDocument,
      variables: { id },
    });

  const updateTasksInCache = (updatedTaskData: Task[]) => {
    const cachedObject = getCachedObjectDetail();

    client?.cache.modify({
      fields: {
        tasks() {
          return updatedTaskData;
        },
      },
      id: client.cache.identify(cachedObject.object),
    });
  };

  return {
    getCachedObjectDetail,
    updateTasksInCache,
  };
};
