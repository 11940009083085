import { AuthService } from '@pro4all/authentication/src/services/auth-service';
import { useOrganizationContext } from '@pro4all/organization/context';
import { useProjectContext } from '@pro4all/projects/ui/context';
import { useRouting } from '@pro4all/shared/routing-utils';

export const useContextScopedOrganizationId = () => {
  const { params } = useRouting();
  const { projectOrganizationId } = useProjectContext();
  const { userOrganizationId } = useOrganizationContext();
  const organizationIdFallback = AuthService.getProfile()?.tenantId;

  // Use this for scenario's where the underlying component accesses
  // organizationId and is accessed both by Organization and Project specific code
  const getContextScopedOrganizationId = (): string =>
    params.projectId
      ? projectOrganizationId || organizationIdFallback
      : userOrganizationId || organizationIdFallback;

  return getContextScopedOrganizationId;
};
