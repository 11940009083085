import React from 'react';
import { Link } from 'react-router-dom';

import { AuthService } from '@pro4all/authentication/src/services/auth-service';
import { LogoColors } from '@pro4all/shared/types';
import { Logo } from '@pro4all/shared/ui/logos';
import { LogoutLink } from '@pro4all/shared/ui/top-bar';

import * as Styled from './InfoTopBar.styles';

export const InfoTopBar = ({ logoColor }: { logoColor: LogoColors }) => {
  const userId = AuthService.getProfile()?.userId;

  return (
    <Styled.Container>
      <Link to="/">
        <Logo logoColor={logoColor} />
      </Link>
      {userId && <LogoutLink />}
    </Styled.Container>
  );
};
