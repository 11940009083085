import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Task, TaskType, TbqModuleType, User } from '@pro4all/graphql';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { customColors } from '@pro4all/shared/themes';
import { StatusChip } from '@pro4all/shared/ui/chips';
import { Icon } from '@pro4all/shared/ui/icons';
import { UserTag } from '@pro4all/shared/ui/identity-card';
import { Property, PropertyList } from '@pro4all/shared/ui/property-list';
import { Timestamp } from '@pro4all/shared/ui/timestamp';

import { TaskSeriesSection } from '../task-form/series/components/TaskSeriesSection';

interface Props {
  task: Task;
}

export const TaskProperties: React.FC<Props> = ({ task }) => {
  const { t } = useTranslation();
  const hasQualityControlFeatureFlag = useFeatureFlag('qualitycontrol');

  const responsible =
    task.assignment && task.assignment.length ? task.assignment[0] : null;
  const { displayName, email, id, isAdmin, organization } = responsible || {};

  const participant: User | null =
    task.assignment && task.assignment.length
      ? {
          displayName: displayName || '',
          email,
          id: id || '',
          isAdmin,
          organization,
        }
      : null;

  const baseProperties: Property[] = [
    {
      label: t('Description'),
      value: task.description || t('None'),
    },
    {
      label: t('Responsible'),
      value: participant ? <UserTag user={participant} /> : t('None'),
    },
    {
      label: t('Deadline'),
      value: task.endTime ? (
        <TimestampBox>
          <Timestamp date={task.endTime} />
        </TimestampBox>
      ) : (
        t('None')
      ),
    },
    {
      label: t('Status'),
      value: task.status ? <StatusChip status={task.status} /> : t('None'),
    },
    {
      label: t('Created by'),
      value: task.createdByUser?.displayName ? (
        <UserTag user={task.createdByUser} />
      ) : (
        t('None')
      ),
    },
    {
      label: t('Created at'),
      value: task.createdAt ? <Timestamp date={task.createdAt} /> : t('None'),
    },
    {
      label: t('Completed at'),
      value: task.completedAt ? (
        <Timestamp date={task.completedAt} />
      ) : (
        t('None')
      ),
    },
  ];

  const typeProperty = hasQualityControlFeatureFlag
    ? {
        label: t('Type'),
        value:
          (() => {
            const tbq = task?.tbq;
            if (tbq?.tbqScanTypeId?.replace(/0|-/g, '') !== '') {
              switch (tbq?.tbqModuleType) {
                case TbqModuleType.Rie:
                  return (
                    <PropertyContainer>
                      <Icon iconName="tbqBrandcheck" />
                      RI&E
                    </PropertyContainer>
                  );
                case TbqModuleType.Brandcheck:
                  return (
                    <PropertyContainer>
                      <Icon iconName="tbqBrandcheck" />
                      Brandcheck
                    </PropertyContainer>
                  );
              }
            }
            return (
              <PropertyContainer>
                <Icon
                  iconName={
                    task.type === TaskType.Resolve
                      ? 'taskSucceeded'
                      : task.type === TaskType.TbqResolve
                      ? 'tbqResolveTask'
                      : 'qcTask'
                  }
                />
                {t(`Api.TaskType.${task.type}`)}
              </PropertyContainer>
            );
          })() || t('None'),
      }
    : null;

  const categoryProperty = !(
    task.type === TaskType.TbqResolve ||
    task.type === TaskType.Tbq ||
    task.type === TaskType.TbqScan
  )
    ? {
        label: t('Category'),
        value: task.taskCategoryName || t('None'),
      }
    : null;

  const properties: Property[] = [
    ...baseProperties,
    ...(typeProperty ? [typeProperty] : []),
    ...(categoryProperty ? [categoryProperty] : []),
  ];

  return (
    <>
      <PropertyList items={properties} orientation="horizontal" px={3} />
      <TaskSeriesSection task={task} />
    </>
  );
};

const PropertyContainer = styled('div')`
  display: flex;
  gap: 4px;
`;

const TimestampBox = styled(PropertyContainer)`
  padding: 1px 4px;
  color: ${customColors.grey700};
  border: solid 1px ${customColors.grey700};
  border-radius: 5px;
`;
