import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { useRestoreFolderMutation } from '@pro4all/graphql';
import { Button } from '@pro4all/shared/mui-wrappers';
import {
  EntityTypeTranslation,
  ItemChangedMessage,
  MessageAction,
} from '@pro4all/shared/ui/messages';

export const SnackbarFolderDeleted = ({
  folderId,
  folderName,
  restoreFolderToUpdateState,
}: {
  folderId: string;
  folderName: string;
  restoreFolderToUpdateState: () => void;
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [restoreFolder] = useRestoreFolderMutation();

  const entityTypeTranslation = EntityTypeTranslation.Folder;

  const onRestoreFolder = async () => {
    let message = (
      <ItemChangedMessage
        description={MessageAction.Restore}
        entityName={folderName}
        entityTypeTranslation={entityTypeTranslation}
      />
    );
    try {
      await restoreFolder({
        variables: { id: folderId },
      });
      restoreFolderToUpdateState();
    } catch (e) {
      message = t('Something went wrong');
    }
    enqueueSnackbar(message);
  };

  const undoAction = (
    <Button color="inherit" onClick={onRestoreFolder} size="small">
      {t('Undo')}
    </Button>
  );

  return (
    <ItemChangedMessage
      action={undoAction}
      description={MessageAction.Delete}
      entityName={folderName}
      entityTypeTranslation={entityTypeTranslation}
    />
  );
};
