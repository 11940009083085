import React from 'react';
import { Route, Routes as RouterDomRoutes } from 'react-router-dom';

import { useIsAdminInContext } from '@pro4all/shared/identity';
import { Column, Main } from '@pro4all/shared/ui/layout';
import { BigMessageNoAccess } from '@pro4all/shared/ui/messages';

import { ScreenFieldDefinitionsProviderWrapper } from '../components/screens/ScreenFieldDefinitionsProviderWrapper';
import { ScreenReportConfigurationsProviderWrapper } from '../components/screens/ScreenReportConfigurationsProviderWrapper';
import { ScreenTemplatesProviderWrapper } from '../components/screens/ScreenTemplatesProviderWrapper';

import { useIncludeOrgItems } from './useIncludeOrgItems';

export const MetaDataMain = ({
  isProject = false,
  pathFields,
  pathReportConfigurations = [],
  pathTemplates,
}: {
  isProject?: boolean;
  pathFields: string | string[];
  pathReportConfigurations?: string | string[];
  pathTemplates: string | string[];
}) => {
  const includeOrgItems = useIncludeOrgItems();
  const isAdminInContext = useIsAdminInContext();

  const templatesPaths = Array.isArray(pathTemplates)
    ? pathTemplates
    : [pathTemplates];
  const fieldsPaths = Array.isArray(pathFields) ? pathFields : [pathFields];
  const reportConfigurationsPaths = Array.isArray(pathReportConfigurations)
    ? pathReportConfigurations
    : [pathReportConfigurations];

  return (
    <Main>
      <Column>
        <RouterDomRoutes>
          {templatesPaths.map((path) => (
            <Route
              element={
                isAdminInContext ? (
                  <ScreenTemplatesProviderWrapper
                    includeOrgItems={includeOrgItems}
                    isProject={isProject}
                  />
                ) : (
                  <BigMessageNoAccess />
                )
              }
              key={path}
              path={path}
            />
          ))}
          {fieldsPaths.map((path) => (
            <Route
              element={
                isAdminInContext ? (
                  <ScreenFieldDefinitionsProviderWrapper
                    includeOrgItems={includeOrgItems}
                    isProject={isProject}
                  />
                ) : (
                  <BigMessageNoAccess />
                )
              }
              key={path}
              path={path}
            />
          ))}
          {reportConfigurationsPaths.map((path) => (
            <Route
              element={
                isAdminInContext ? (
                  <ScreenReportConfigurationsProviderWrapper
                    isProject={isProject}
                  />
                ) : (
                  <BigMessageNoAccess />
                )
              }
              key={path}
              path={path}
            />
          ))}
        </RouterDomRoutes>
      </Column>
    </Main>
  );
};
