import { useEffect, useMemo, useState } from 'react';

import { AuthService } from '@pro4all/authentication/src/services/auth-service';
import { EntityFolderPermission, Folder } from '@pro4all/graphql';
import { useGenericContext } from '@pro4all/shared/providers';

import { usePermissionsFolderMatrixContext } from './context/PermissionsFolderMatrixProvider';
import { useExclusivePermissionFolderChangeHandler } from './hooks/useExclusivePermissionFolderChangeHandler';
import { useExclusivePermissionSelector } from './hooks/useExclusivePermissionSelector';
import { isDocumentsNone } from './shared/helpers/checkPermissionsWrappers';
import { ExclusivePermissionType } from './types/types';
import { ExclusivePermissionSelector } from './ExclusivePermissionSelector';

export interface ExclusivePermissionFolderSelectorWrapper {
  currentPermissions: EntityFolderPermission;
  exclusivePermissionType: ExclusivePermissionType;
  folder: Folder;
}

/**
 * We use this wrapper to achieve optimal rendering for ExclusivePermissionSelector (the dropdown).
 * If we don't, ALL dropdowns will be re-rendered if a user clicks on whatever checkbox or changes
 * whatever value for whatever folder. Which would have a major bad impact on performance in case of thousands of folders.
 */
export const ExclusivePermissionFolderSelectorWrapper = ({
  currentPermissions,
  exclusivePermissionType,
  folder,
}: ExclusivePermissionFolderSelectorWrapper) => {
  const {
    state: { displayPermissions },
  } = usePermissionsFolderMatrixContext();

  const {
    state: { showFinalPermissions },
  } = useGenericContext();

  const folderPermissions = useMemo(
    () =>
      displayPermissions.find((perm) => perm.folderId === folder.id)
        ?.folderPermissions || [],
    [displayPermissions, folder]
  );

  const { id: userId } = currentPermissions;
  const profile = AuthService.getProfile();
  const { userId: loggedInUserId } = profile;

  const {
    allPermissions,
    getOptionByPermissions,
    inheritedFromGroups,
    optionByPermission,
    optionByPermissionInherited,
    options,
  } = useExclusivePermissionSelector({
    exclusivePermissionType,
    folderPermissions,
    inheritedFrom: currentPermissions.inheritedFrom,
  });

  const permissionsWithInheritance = [
    ...folderPermissions,
    ...currentPermissions.inheritedFrom.map((item) => item.permission),
  ];

  const [selectedOption, setSelectedOption] =
    useState<string>(optionByPermission);

  const handleChange = useExclusivePermissionFolderChangeHandler({
    exclusivePermissionType,
    folderId: folder.id,
    folderPermissions,
    selectedOption,
    setSelectedOption,
  });

  useEffect(() => {
    setSelectedOption(
      getOptionByPermissions(folderPermissions, exclusivePermissionType)
    );
  }, [
    folderPermissions,
    getOptionByPermissions,
    setSelectedOption,
    exclusivePermissionType,
    currentPermissions?.inheritedFrom,
  ]);

  const noDocumentPermissions = isDocumentsNone(permissionsWithInheritance);

  const disableViewPermissions =
    loggedInUserId === userId ||
    (noDocumentPermissions &&
      exclusivePermissionType === ExclusivePermissionType.Versions) ||
    (noDocumentPermissions &&
      exclusivePermissionType === ExclusivePermissionType.Delete) ||
    (noDocumentPermissions &&
      exclusivePermissionType === ExclusivePermissionType.Update);

  return (
    <ExclusivePermissionSelector
      allPermissions={allPermissions}
      disableViewPermissions={disableViewPermissions}
      folder={folder}
      handleChange={handleChange}
      inheritedFromGroups={inheritedFromGroups}
      optionByPermissionInherited={optionByPermissionInherited}
      options={options}
      selectedOption={selectedOption}
      showFinalPermissions={showFinalPermissions}
    />
  );
};
