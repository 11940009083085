import { useCallback, useEffect, useRef } from 'react';

import {
  FilterColumnIdProps,
  FilterColumnProps,
  useFilterContext,
} from '@pro4all/shared/ui/filtering';
import {
  BaseRow,
  useOptimisticResponseContext,
} from '@pro4all/shared/ui/table';

import { useFiltersFromLocalStorage } from './useFiltersFromLocalStorage';

export function useInitializeFromLocalStorage<Row extends BaseRow, SubProp>({
  filterType,
  isMetaData = false,
  isMultiSelect = false,
  metaDataHeaderId = '',
  propertyId,
  pullFromLocalStorage,
  subPropertyId,
  translateOptions,
}: Pick<
  FilterColumnProps,
  | 'filterType'
  | 'isMetaData'
  | 'isMultiSelect'
  | 'metaDataHeaderId'
  | 'pullFromLocalStorage'
  | 'translateOptions'
> &
  Pick<FilterColumnIdProps<Row, SubProp>, 'propertyId' | 'subPropertyId'>) {
  const {
    setRecalculateFilters,
    state: { recalculateFilters },
  } = useOptimisticResponseContext<Row>();

  const { updateTableBasedOnCurrentFilters } = useFilterContext<Row>();

  const itemsFilteredPopulated = useRef(false);

  const initializeFilters = useFiltersFromLocalStorage<Row, SubProp>({
    filterType,
    isMetaData,
    isMultiSelect,
    metaDataHeaderId,
    propertyId,
    recalculateFilters,
    setRecalculateFilters,
    subPropertyId,
    translateOptions,
    updateTableBasedOnCurrentFilters,
  });

  const initializeFiltersCallback = useCallback(initializeFilters, [
    filterType,
    isMetaData,
    isMultiSelect,
    metaDataHeaderId,
    propertyId,
    recalculateFilters,
    setRecalculateFilters,
    subPropertyId,
    translateOptions,
    updateTableBasedOnCurrentFilters,
  ]);

  useEffect(() => {
    if (
      pullFromLocalStorage &&
      (!itemsFilteredPopulated.current || recalculateFilters)
    ) {
      initializeFiltersCallback();
    } else if (pullFromLocalStorage) {
      updateTableBasedOnCurrentFilters([]);
    }
  }, [initializeFiltersCallback, pullFromLocalStorage]);
}
