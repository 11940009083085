import React from 'react';
import { useTranslation } from 'react-i18next';

import { useOrganizationContext } from '@pro4all/organization/context';
import { Routes } from '@pro4all/shared/config';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { useSubscriptionRestriction } from '@pro4all/shared/hooks';
import {
  generateRoute,
  updateLsLastVisitedProjectRoute,
  useRouting,
} from '@pro4all/shared/routing-utils';
import { SubTabs, Tab } from '@pro4all/shared/ui/general';

import * as Styled from './AccessTabs.styles';

export const ProjectAccessTabs: React.FC = () => {
  const { t } = useTranslation();

  const {
    goTo,
    params: { projectId },
  } = useRouting();
  const base = `${generateRoute('projectPermissionsBaseUrl', {
    params: { projectId },
  })}/`;
  const hasQualityControlFeature = useFeatureFlag('qualitycontrol');

  const { contextScopedOrganizationSubscriptionLevel } =
    useOrganizationContext();
  const { hasDmsLicense, hasQualityControlLicense } =
    useSubscriptionRestriction(contextScopedOrganizationSubscriptionLevel);

  return (
    <Styled.TabsWrapper>
      <SubTabs base={base}>
        {hasDmsLicense && (
          <Tab
            data-testid="project-access-documents-tab"
            label={t('Documents')}
            onClick={() => {
              goTo('projectDocumentPermissions', {
                params: { projectId },
              });
              updateLsLastVisitedProjectRoute({
                overwriteCurrentValue: true,
                projectId,
                route: 'projectDocumentPermissions',
              });
            }}
            value={Routes.documents}
          />
        )}
        {hasQualityControlLicense && hasQualityControlFeature && (
          <Tab
            data-testid="project-access-quality-control-tab"
            label={t('Quality control')}
            onClick={() => {
              goTo('projectQualityPermissions', {
                params: { projectId },
              });
              updateLsLastVisitedProjectRoute({
                overwriteCurrentValue: true,
                projectId,
                route: 'projectQualityPermissions',
              });
            }}
            value={Routes.qualitycontrol}
          />
        )}
        (hasDmsLicense || (hasQualityControlLicense &&
        hasQualityControlFeature)) && (
        <Tab
          data-testid="project-access-quality-control-tab"
          label={t('Tasks')}
          onClick={() => {
            goTo('projectTaskPermissions', {
              params: { projectId },
            });
            updateLsLastVisitedProjectRoute({
              overwriteCurrentValue: true,
              projectId,
              route: 'projectTaskPermissions',
            });
          }}
          value={Routes.tasks}
        />
        )
      </SubTabs>
    </Styled.TabsWrapper>
  );
};
