import { useEffect, useRef } from 'react';
import { Descendant, Editor, Transforms } from 'slate';

type TUseMessageSignature = {
  editor: Editor;
  hasMessageBody: boolean;
  messageSignature: string;
  setOriginalSignature: (value: string) => void;
};

export const useMessageSignature = ({
  hasMessageBody,
  messageSignature,
  editor,
  setOriginalSignature,
}: TUseMessageSignature) => {
  const hasAddedSignature = useRef(false);

  useEffect(() => {
    /*
     * when messageBody exists it means that
     * we are showing a message, sent or received but with the slate editor disabled
     * that's why we just show the signature when there is no messageBody
     */
    if (!hasMessageBody && !hasAddedSignature.current) {
      try {
        const signatureNode: Descendant[] = JSON.parse(
          messageSignature || '[]'
        );

        Transforms.insertNodes(editor, [
          {
            children: [{ text: '' }],
            type: 'Line',
          }, // new line before signature
          ...signatureNode,
        ]);

        setOriginalSignature(
          JSON.stringify([
            {
              children: [{ text: '' }],
              type: 'Line',
            },
            {
              children: [{ text: '' }],
              type: 'Line',
            }, // To match the final signature
            ...signatureNode,
          ])
        );

        hasAddedSignature.current = true;
      } catch (error) {
        console.error('Error parsing signature', error);
      }
    }
  }, [messageSignature, hasMessageBody, editor, setOriginalSignature]);
};
