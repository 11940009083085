import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { customColors } from '@pro4all/shared/themes';
import { IconButton } from '@pro4all/shared/ui/buttons';

interface Props {
  disabled?: boolean;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const RemoveTime: React.FC<Props> = ({ disabled, onClick }) => {
  const { t } = useTranslation();

  return (
    <RemoveTimeButton
      ariaLabel={t('Remove time')}
      color="default"
      disableBorder
      disabled={disabled}
      iconName="delete"
      onClick={onClick}
      type="button"
    />
  );
};

export const RemoveTimeButton = styled(IconButton)`
  && {
    background-color: ${customColors.white};
    border-color: ${customColors.grey500};
    color: ${customColors.grey700};
    margin-left: 4px;

    :disabled {
      background-color: ${customColors.grey200};
      border-color: ${customColors.grey200};
      color: ${customColors.grey500};
    }
  }
`;
