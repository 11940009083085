import { Box } from 'libs/shared/mui-wrappers/src/Box';

import { customColors } from '@pro4all/shared/themes';
import { Text } from '@pro4all/shared/ui/typography';

export const FieldDescription = ({
  displayDescription,
}: {
  displayDescription?: string | null;
}) =>
  displayDescription ? (
    <Box color={customColors.grey600} fontStyle="italic">
      <Text>{displayDescription}</Text>
    </Box>
  ) : null;
