import { Template, TemplateType } from '@pro4all/graphql';
import { useAppPermissions } from '@pro4all/shared/identity';
import { useRouting } from '@pro4all/shared/routing-utils';
import { FilterContextProvider } from '@pro4all/shared/ui/filtering';
import { Loader } from '@pro4all/shared/ui/loader';
import {
  Table,
  TableContextProvider,
  useOptimisticResponseContext,
  useSetItemsInLocalState,
} from '@pro4all/shared/ui/table';

import { SetsActionBar } from '../action-bars/SetsActionBar';
import { BigMessage } from '../big-message/BigMessage';
import { useColumnsTemplates } from '../columns/useColumnsTemplates';

import {
  CustomActionBarWrapper,
  CustomDefaultActionBarWrapper,
} from './CustomActionBarWrapper';
import ScopeDropdown from './ScopeDropdown';
import { ScreenTbqTemplatesProps } from './types';

export const ScreenTBQTemplates = ({
  loading,
  selectTemplate,
  templates,
}: ScreenTbqTemplatesProps) => {
  const { hasAppPermission } = useAppPermissions();
  const {
    params: { tbqScopeId },
  } = useRouting();

  const {
    state: { items },
  } = useOptimisticResponseContext<Template>();

  const columnsSets = useColumnsTemplates();

  useSetItemsInLocalState<Template>(templates);

  return (
    <FilterContextProvider<Template>>
      <TableContextProvider
        columns={[...columnsSets]}
        id={`table-templates-${TemplateType.Form}-global`}
        items={items}
      >
        <>
          <CustomActionBarWrapper>
            <ScopeDropdown />
            <CustomDefaultActionBarWrapper>
              <SetsActionBar />
            </CustomDefaultActionBarWrapper>
          </CustomActionBarWrapper>
          <Table<Template>
            emptyRenderer={loading && tbqScopeId ? <Loader /> : <BigMessage />}
            onRowClick={(row) =>
              hasAppPermission('OrganizationUpdate') && selectTemplate(row)
            }
            rowHeight={26}
          />
        </>
      </TableContextProvider>
    </FilterContextProvider>
  );
};
