import React from 'react';
import styled from 'styled-components';

import { useApplication } from '@pro4all/shared/themes';
import { Logo } from '@pro4all/shared/ui/logos';

import { RegistrationForm } from '../components/registration-form/RegistrationForm';
import { RegistrationPage } from '../components/RegistrationPage';

const FormLogo = styled.div`
  align-self: flex-start;
  padding-bottom: var(--spacing);
`;

export const Registration = () => {
  const { isThemePave, isThemePro4all } = useApplication();

  return (
    <RegistrationPage>
      <FormLogo>
        <Logo
          includeLink
          logoColor={
            isThemePave
              ? 'paveBlueYellow'
              : isThemePro4all
              ? 'pro4allMonochromePurple'
              : 'negativeBlack'
          }
        />
      </FormLogo>
      <RegistrationForm />
    </RegistrationPage>
  );
};
