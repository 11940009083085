import React from 'react';

import { SvgIconProps } from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';

import { Build } from './custom/Build';
import { Collaborate } from './custom/Collaborate';
import { Flow1 } from './custom/Flow1';
import { Flow2 } from './custom/Flow2';
import { Follow } from './custom/Follow';
import { Fun } from './custom/Fun';
import { Simplify } from './custom/Simplify';
import { Trust } from './custom/Trust';

export const shapes = {
  build: Build,
  collaborate: Collaborate,
  flow1: Flow1,
  flow2: Flow2,
  follow: Follow,
  fun: Fun,
  simplify: Simplify,
  trust: Trust,
};

export type ShapeColor = 'green' | 'orange' | 'purple' | 'silver';
export type ShapeType = keyof typeof shapes;

export interface ShapeProps extends SvgIconProps {
  colors?: {
    primary: string;
    secondary: string;
  };
}

export const Shape: React.FC<
  Omit<SvgIconProps, 'color'> & {
    color?: ShapeColor;
    mono?: boolean;
    type: ShapeType;
  }
> = ({ type, color, mono, ...rest }) => {
  const {
    greenMonoHighlight,
    greenShapeShadow,
    grey300,
    orangeAccent,
    orangeMonoHighlight,
    orangeShapeShadow,
    purpleAccent,
    purpleMonoHighlight,
    purpleShapeShadow,
    silverMonoHighlight,
    silverShapeShadow,
    successBg,
  } = customColors;

  const shapeColors: {
    [key in ShapeColor]: {
      primary: string;
      secondary: string;
    };
  } = {
    green: {
      primary: mono ? greenMonoHighlight : successBg,
      secondary: greenShapeShadow,
    },
    orange: {
      primary: mono ? orangeMonoHighlight : orangeAccent,
      secondary: orangeShapeShadow,
    },
    purple: {
      primary: mono ? purpleMonoHighlight : purpleAccent,
      secondary: purpleShapeShadow,
    },
    silver: {
      primary: mono ? silverMonoHighlight : grey300,
      secondary: silverShapeShadow,
    },
  };

  return React.createElement(shapes[type], {
    ...rest,
    colors: shapeColors[color ? color : 'purple'],
    viewBox: '0 0 240 140',
  });
};
