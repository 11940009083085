import { ReactNode } from 'react';
import {
  createLayerComponent,
  LayerProps,
  LeafletContextInterface,
} from '@react-leaflet/core';
import L, { DoneCallback } from 'leaflet';

import { TileService } from '@pro4all/quality-control/data-access';
import { WhiteBackground } from '@pro4all/shared/qc-report-assets';

interface AuthTileProps extends LayerProps {
  children?: ReactNode;
  maxZoom?: number;
  minZoom?: number;
  noWrap?: boolean;
  page: number;
  tileSize?: number;
  visualContextId: string;
  zoomOffset?: number;
}

class AuthTile64 extends L.GridLayer {
  visualContextId: string;
  className: string;
  page: number;

  constructor(options: L.GridLayerOptions) {
    super(options);
    this.visualContextId = '';
    this.className = '';
    this.page = 1;
  }

  createTile(coords: L.Coords, done: DoneCallback): HTMLElement {
    const img = document.createElement('img');
    img.setAttribute('role', 'presentation');
    img.setAttribute('alt', 'minimap-tile');

    const onError = () => {
      img.src = WhiteBackground;
      img.setAttribute('alt', '');
      done(new Error('Could not resolve media'), img);
    };

    TileService.getTile64(
      this.visualContextId,
      coords.z,
      coords.x,
      coords.y,
      this.page
    )
      .then((src) => {
        if (src) {
          img.src = src;
          done(undefined, img);
        } else {
          onError();
        }
      })
      .catch(() => {
        onError();
      });

    return img;
  }

  setVisualContextId(id: string) {
    this.visualContextId = id;
    this.redraw();
  }

  setPage(page: number) {
    this.page = page;
    this.redraw();
  }
}

const createAuthTileLayer64 = (
  props: AuthTileProps,
  context: LeafletContextInterface
) => {
  const options: L.GridLayerOptions = {
    maxZoom: props.maxZoom || 18,
    minZoom: props.minZoom || 0,
    noWrap: props.noWrap || false,
    tileSize: props.tileSize || 256,
  };

  const instance = new AuthTile64(options);
  instance.setVisualContextId(props.visualContextId);
  instance.setPage(props.page);

  return { context, instance };
};

export const AuthTileLayer64 = createLayerComponent<AuthTile64, AuthTileProps>(
  createAuthTileLayer64
);
