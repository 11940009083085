import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ResultSet } from '@pro4all/graphql';
import { generateRoute, useRouting } from '@pro4all/shared/routing-utils';
import { SubTabs, Tab } from '@pro4all/shared/ui/general';

export const MultiBrandcheckResultTabs: React.FC = () => {
  const { t } = useTranslation();
  const { goTo } = useRouting();
  const {
    params: { objectId, projectId, resultSet = ResultSet.Victims },
  } = useRouting();

  const base = !projectId
    ? `${generateRoute('objectTBQMultiBrandcheckResultsBaseUrl', {
        params: { objectId },
      })}/`
    : `${generateRoute('projectObjMultiBrandCheckBaseUrl', {
        params: { objectId, projectId },
      })}/`;

  const damageTabValue = resultSet.endsWith('Plus')
    ? ResultSet.DamagePlus
    : ResultSet.Damage;
  const victimsTabValue = resultSet.endsWith('Plus')
    ? ResultSet.VictimsPlus
    : ResultSet.Victims;

  return (
    <Tabs base={base}>
      <Tab
        data-testid="slachtoffers-tab"
        label={t('Slachtoffers')}
        onClick={() =>
          goTo('TBQMultiBrandcheckVictims', {
            params: { objectId },
          })
        }
        value={victimsTabValue}
      />
      <Tab
        data-testid="schade-tab"
        label={t('Schade')}
        onClick={() =>
          goTo('TBQMultiBrandcheckDamage', {
            params: { objectId },
          })
        }
        value={damageTabValue}
      />
    </Tabs>
  );
};

const Tabs = styled(SubTabs)`
  && {
    margin: 0;

    .MuiTabs-indicator {
      background-color: ${({ theme }) => theme.palette.primary.main};
    }

    .MuiTab-root[aria-selected='true'] {
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }
`;
