import React from 'react';

import { SvgIconProps } from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';

export const StaticFormIcon: React.FC<SvgIconProps> = () => (
  <svg
    fill="none"
    height="20"
    viewBox="0 0 14 20"
    width="14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M0.389893 2C0.389893 0.895431 1.28532 0 2.38989 0H11.6099C12.7145 0 13.6099 0.895431 13.6099 2V11.22C13.6099 12.3246 12.7145 13.22 11.6099 13.22H8.46912V15.8333C8.46912 15.8333 7.21913 20 6.80246 20C6.38579 20 5.13579 15.8333 5.13579 15.8333V13.22H2.38989C1.28532 13.22 0.389893 12.3246 0.389893 11.22V2ZM4.45758 5.06769C4.45758 4.51541 4.9053 4.06769 5.45758 4.06769H8.54219C9.09448 4.06769 9.54219 4.51541 9.54219 5.06769V8.15231C9.54219 8.70459 9.09447 9.15231 8.54219 9.15231H5.45758C4.9053 9.15231 4.45758 8.70459 4.45758 8.15231V5.06769Z"
      fill={customColors.black}
      fillOpacity="0.56"
      fillRule="evenodd"
    />
  </svg>
);
