import React, { useCallback } from 'react';
import { NavLink, NavLinkProps, useMatch } from 'react-router-dom';

import { Routes } from '@pro4all/shared/config';
import { NavGroupIds, useSideNavContext } from '@pro4all/shared/contexts';
import {
  Box,
  CountColor,
  ListItemIcon,
  ListItemText,
} from '@pro4all/shared/mui-wrappers';
import {
  updateLsLastVisitedProjectRoute,
  useRouting,
} from '@pro4all/shared/routing-utils';
import { generateRoute } from '@pro4all/shared/routing-utils';
import { Collapsible } from '@pro4all/shared/ui/collapsible';
import { Icon, IconName } from '@pro4all/shared/ui/icons';
import { useToggle } from '@pro4all/shared/ui/toggle';
import { Text } from '@pro4all/shared/ui/typography';

import { Count, StyledTooltip } from './Styled';

interface Props extends NavLinkProps {
  count?: number;
  countColor?: CountColor;
  dataTestId?: string;
  exact?: boolean;
  iconName?: IconName;
  id: NavGroupIds;
  label: string;
  route: keyof typeof Routes;
  setSideNavStateNP: (state: string[]) => void;
  sideNavStateNP: string[];
  to: string;
}

export const NavGroup: React.FC<Props> = ({
  children,
  count = 0,
  dataTestId,
  exact = false,
  iconName,
  id,
  label,
  onClick,
  sideNavStateNP,
  setSideNavStateNP,
  route,
  to,
  countColor = 'default',
}) => {
  const {
    params: { projectId },
  } = useRouting();
  const base = projectId
    ? generateRoute('proj', { params: { projectId } })
    : '';
  const configRouteMatch = useMatch(`${base}${Routes.config}/*`);

  const { toggled } = useToggle();

  const {
    sideNavState: { isSideNavOpen },
  } = useSideNavContext();

  const toggleOpenNavGroup = useCallback(
    (updateRoute: boolean) => {
      if (updateRoute) {
        updateLsLastVisitedProjectRoute({
          projectId: projectId || '',
          route,
        });
      }

      const newState = sideNavStateNP.includes(id)
        ? sideNavStateNP.filter((item) => item !== id)
        : [...sideNavStateNP, id];

      setSideNavStateNP(newState);
    },
    [projectId, route, setSideNavStateNP, sideNavStateNP, id]
  );

  const showHeaderActiveColor =
    configRouteMatch && !sideNavStateNP.includes(id);

  return (
    <StyledTooltip
      className="nav-group-tooltip"
      open={toggled}
      placement="right"
      title={isSideNavOpen ? '' : label}
    >
      <Box
        aria-label={label}
        className={`nav-group ${sideNavStateNP.includes(id) && 'expanded'} ${
          showHeaderActiveColor && 'active'
        }`}
        data-testid={dataTestId}
        display="flex"
        flex={1}
        onClick={() => toggleOpenNavGroup(true)}
        role="button"
      >
        <NavLink
          className="nav-link"
          data-testid={dataTestId}
          end={exact}
          onClick={onClick}
          to={to}
        >
          {iconName && (
            <ListItemIcon>
              <Icon iconName={iconName} />
            </ListItemIcon>
          )}
          {label && isSideNavOpen && (
            <Box pl="6px">
              <Text variant="h6">
                <ListItemText disableTypography primary={label} />
              </Text>
            </Box>
          )}
          {!sideNavStateNP.includes(id) && count > 0 ? (
            <Count countColor={countColor} label={count} size="small" />
          ) : null}
        </NavLink>
        {isSideNavOpen && (
          <Icon
            className="chevron"
            iconName={
              sideNavStateNP.includes(id)
                ? 'keyboardArrowUp'
                : 'keyboardArrowDown'
            }
            onClick={(e) => {
              e.stopPropagation();
              toggleOpenNavGroup(false);
            }}
          />
        )}
      </Box>
      <Box className="group-container" flex="auto">
        <Collapsible open={sideNavStateNP.includes(id)}>{children}</Collapsible>
      </Box>
    </StyledTooltip>
  );
};
