import {
  ReportConfiguration,
  useReportConfigurationsIncludeQuery,
} from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';
import { ReportConfigurationsProps } from '@pro4all/shared/types';

export const useReportConfigurations = ({
  reportConfigurations,
}: ReportConfigurationsProps) => {
  const {
    params: { projectId },
  } = useRouting();

  const { data } = useReportConfigurationsIncludeQuery({
    fetchPolicy: 'cache-and-network',
    skip: Boolean(reportConfigurations),
    variables: {
      includeCreatedAt: true,
      includeCreatedBy: true,
      includeIsDefault: true,
      includeProjectId: true,
      includeReportOptions: true,
      includeScope: true,
      includeUpdatedAt: true,
      includeUpdatedBy: true,
      projectId: projectId || '',
    },
  });

  return {
    loading: !data,
    reportConfigurations: data?.reportConfigurations as ReportConfiguration[],
  };
};
