import styled from 'styled-components';

import { Box, IconButton } from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';
import { UserTag } from '@pro4all/shared/ui/identity-card';

export const Card = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacing(1.33)};
  background-color: ${({ theme }) => theme.palette.background.paper};
  padding: ${({ theme }) => theme.spacing(2.67)};
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 10px;
  box-sizing: border-box;
  width: 100%;
  position: relative;
`;

export const GridContainer = styled(Box)`
  display: grid;
  grid-template-columns:
    minmax(100px, 0.9fr) minmax(120px, 0.8fr) minmax(140px, 1.1fr)
    35px;
  grid-template-rows: 0.8fr 1fr;
  column-gap: ${({ theme }) => theme.spacing(0.8)};
  row-gap: ${({ theme }) => theme.spacing(1.33)};
  align-items: center;
  font-family: ${({ theme }) => theme.typography.fontFamily};
`;

export const TitleContainer = styled(Box)`
  display: flex;
  align-items: center;
`;

export const SeriesTitle = styled.span`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: 500;
  font-size: 16px;
  line-height: 25.6px;
  margin-left: ${({ theme }) => theme.spacing(1.33)};
  color: ${customColors.black};
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
`;

export const Participant = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const CompletedTasks = styled.span`
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  justify-self: flex-end;
  padding-right: ${({ theme }) => theme.spacing(0.5)};
`;

const DatePropertyBase = styled.span`
  margin-top: ${({ theme }) => theme.spacing(1.33)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
`;

export const StartDate = styled(DatePropertyBase)``;

export const EndDate = styled(DatePropertyBase)`
  margin-left: ${({ theme }) => theme.spacing(0.5)};
  max-width: 140px;
  white-space: nowrap;
  overflow: visible;
`;

export const Frequency = styled(Box)`
  display: flex;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing(1.33)};
  white-space: nowrap;
  overflow: hidden;
  margin-left: ${({ theme }) => theme.spacing(2.5)};
`;

export const PropertyName = styled.span`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: 500;
  font-size: 14px;
  line-height: 22.4px;
`;

export const PropertyValue = styled.span`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: 400;
  font-size: 14px;
  line-height: 22.4px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const RemoveButton = styled(IconButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  grid-row: 1 / 3;
  align-self: center;
  padding: ${({ theme }) => theme.spacing(0.5)};
`;

export const StyledUserTag = styled(UserTag)`
  max-width: 150px;
`;

export const SeriesCardWrapper = styled.div<{ isClickable: boolean }>`
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'default')};
  width: 100%;
`;
