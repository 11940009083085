import { css } from 'styled-components';

import { customColors } from '@pro4all/shared/themes';
import { StylingDefaults } from '@pro4all/shared/themes';

export const RbcCalendarStyles = css`
  .rbc-month-view {
    border: 1px solid ${({ theme }) => theme.palette.divider};
    border-radius: 4px;
    background: ${customColors.white};
    overflow: hidden;
  }

  .rbc-event,
  .rbc-day-slot .rbc-background-event {
    background-color: transparent;
    border: 1px solid rgba(0, 0, 0, 0);
    color: ${customColors.black};
    padding: ${({ theme }) => theme.spacing(0.5)}
      ${({ theme }) => theme.spacing(1)};
  }

  .rbc-event:hover {
    background-color: ${({ theme }) => theme.palette.grey[300]};
    border: 1px solid ${({ theme }) => theme.palette.grey[700]};
    cursor: pointer;
  }

  .rbc-header {
    height: 25px;
    line-height: 25px;
    background-color: ${({ theme }) => theme.palette.grey[300]};
  }

  .rbc-row-segment {
    padding: ${({ theme }) => theme.spacing(0.5)}
      ${({ theme }) => theme.spacing(1)};
  }

  .rbc-toolbar button {
    border: 1px solid ${({ theme }) => theme.palette.divider};
    font-size: ${({ theme }) => theme.typography.body1.fontSize};
  }

  .rbc-date-cell > button.rbc-button-link {
    margin-top: ${({ theme }) => theme.spacing(0.5)};
    padding: ${({ theme }) => theme.spacing(0.5)};
  }

  .rbc-now > button.rbc-button-link {
    color: ${({ theme }) => theme.palette.secondary.contrastText};
    background: ${({ theme }) => theme.palette.secondary.main};
    padding: ${({ theme }) => theme.spacing(0.5)};
    border-radius: 50px;
    margin-top: ${({ theme }) => theme.spacing(0.5)};
  }

  .rbc-event.rbc-selected,
  .rbc-day-slot .rbc-selected.rbc-background-event {
    border: 1px solid ${({ theme }) => theme.palette.grey[700]};
  }

  .rbc-toolbar button:hover,
  .rbc-toolbar button.rbc-hover {
    cursor: pointer;
    border: 1px solid ${({ theme }) => theme.palette.divider};
  }

  .rbc-toolbar button:active,
  .rbc-toolbar button.rbc-active {
    box-shadow: unset;
    border: 1px solid ${({ theme }) => theme.palette.divider};
  }

  .rbc-toolbar button:active:hover,
  .rbc-toolbar button:active:focus,
  .rbc-toolbar button.rbc-active:hover,
  .rbc-toolbar button.rbc-active:focus {
    border-color: ${({ theme }) => theme.palette.grey[500]};
  }

  .rbc-toolbar .rbc-toolbar-label {
    font-size: ${({ theme }) => theme.typography.h5.fontSize};
    font-weight: ${({ theme }) => theme.typography.h5.fontWeight};
    text-align: end;
  }

  .rbc-today {
    background-color: inherit;
  }

  .rbc-off-range-bg {
    background-color: ${({ theme }) => theme.palette.grey[200]};
  }

  .rbc-show-more {
    font-size: ${({ theme }) => theme.typography.body2.fontSize};
    font-weight: ${({ theme }) => theme.typography.body2.fontWeight};
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  .rbc-show-more:hover {
    color: ${customColors.black};
  }

  .rbc-toolbar button:first-child:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing(1)};
    border-top-left-radius: ${StylingDefaults.borderRadiusSmall};
    border-top-right-radius: ${StylingDefaults.borderRadiusSmall};
    border-bottom-right-radius: ${StylingDefaults.borderRadiusSmall};
    border-bottom-left-radius: ${StylingDefaults.borderRadiusSmall};
  }

  .rbc-btn-group > button:not(:first-child):not(:last-child):nth-child(2) {
    border-top-left-radius: ${StylingDefaults.borderRadiusSmall};
    border-bottom-left-radius: ${StylingDefaults.borderRadiusSmall};
  }
`;
