import { AuthService } from '@pro4all/authentication/src/services/auth-service';
import { TrackingEvent } from '@pro4all/shared/config';
import { useContextScopedOrganizationId } from '@pro4all/shared/identity';
import { useRouting } from '@pro4all/shared/routing-utils';
import { useAnalytics } from '@pro4all/shared/vendor';

import { useOpenCdeContext } from '../OpenCdeContext';

export const useOpenCdeTracking = () => {
  const { track } = useAnalytics();
  const getContextScopedOrganizationId = useContextScopedOrganizationId();
  const organizationId = getContextScopedOrganizationId();
  const profile = AuthService.getProfile();
  const userId = profile?.userId;
  const {
    params: { projectId },
  } = useRouting();

  const { openCdeRequest } = useOpenCdeContext();

  const trackOpenCdeInit = () =>
    track(TrackingEvent.OpenCdeInit, {
      application: openCdeRequest?.cdeApplication,
      userId,
    });

  const trackOpenCdeShareDocument = (
    documentsId?: string[],
    folderId?: string[]
  ) =>
    track(TrackingEvent.OpenCdeShareDocument, {
      application: openCdeRequest?.cdeApplication,
      documentsId,
      folderId,
      organizationId,
      projectId,
      userId,
    });

  const trackOpenCdeUploadDocument = (
    documentsId?: string[],
    folderId?: string
  ) =>
    track(TrackingEvent.OpenCdeUploadDocument, {
      application: openCdeRequest?.cdeApplication,
      documentsId,
      folderId,
      organizationId,
      projectId,
      userId,
    });

  return {
    trackOpenCdeInit,
    trackOpenCdeShareDocument,
    trackOpenCdeUploadDocument,
  };
};
