import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { CommentStatus } from '@pro4all/documents/ui/comments';
import {
  QualityControlInstance,
  QualityControlInstanceFragmentFragment,
  QualityControlInstanceType,
  State,
  Task,
  User,
  VisualContext,
} from '@pro4all/graphql';
import {
  ComposedPin,
  getFileUrlByTemplateId,
  TMuiIcon,
} from '@pro4all/shared/composed-snag-form-pin';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { Box } from '@pro4all/shared/mui-wrappers';
import { useEmptyFacetColumns, useMDColumns } from '@pro4all/shared/search';
import { useSnagFormSearchContext } from '@pro4all/shared/snags-and-forms-search-context';
import { customColors } from '@pro4all/shared/themes';
import { RenderOptionType } from '@pro4all/shared/types';
import { FilterHeaderType } from '@pro4all/shared/ui/filtering';
import { UserTag } from '@pro4all/shared/ui/identity-card';
import { ColumnProps } from '@pro4all/shared/ui/table';
import { FilterHeader } from '@pro4all/shared/ui/table-column-filtering';
import { getFormattedDate, Timestamp } from '@pro4all/shared/ui/timestamp';
import { hexToRgb } from '@pro4all/shared/utils';

const VisualContextName = styled.div<{ deleted: boolean }>`
  color: ${({ deleted }) => deleted && customColors.grey400};
`;

const Highlight = styled.p<{ backgroundColor: string; color: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
  line-height: 20px;
  padding: 0.5% 3%;
  border-radius: 6px;
  font-size: 13px;
`;

const getTextColor = (backgroundColor: string) => {
  const rgba = hexToRgb(backgroundColor, '1');

  if (rgba !== '(132, 132, 132, 1)') {
    const rgbaValues = rgba.split(',');
    const red = parseInt(rgbaValues[0].split('(')[1]);
    const green = parseInt(rgbaValues[1]);
    const blue = parseInt(rgbaValues[2]);

    return red * 0.299 + green * 0.587 + blue * 0.114 > 186
      ? customColors.grey900
      : customColors.white;
  }

  return customColors.white;
};

export const useColumns = () => {
  const { t } = useTranslation();
  const { mdFacetGroups } = useSnagFormSearchContext();
  const mdColumns =
    useMDColumns<QualityControlInstanceFragmentFragment>(mdFacetGroups);

  const isSelectIconEnabled = useFeatureFlag('customericons');

  const emptyColumns =
    useEmptyFacetColumns<QualityControlInstanceFragmentFragment>(mdFacetGroups);
  const columns: ColumnProps<QualityControlInstanceFragmentFragment>[] = [
    {
      filterable: true,
      headerComponent: (
        <FilterHeader<QualityControlInstance, {}>
          defaultWidth={70}
          filterType={FilterHeaderType.Number}
          label="Ref" // i18n
          minWidth={70}
          propertyId="reference"
          pullFromLocalStorage
        />
      ),
      key: 'reference',
      width: 60,
    },
    {
      defaultSort: true,
      filterable: true,
      headerComponent: (
        <FilterHeader<QualityControlInstance, {}>
          defaultWidth={400}
          filterType={FilterHeaderType.Text}
          label="Name" // i18n
          minWidth={100}
          propertyId="name"
        />
      ),
      key: 'name',
      width: 400,
    },
    {
      filterable: true,
      getValue: ({ type }) => t<string>(`QCResultType.${type}`, type),
      headerComponent: (
        <FilterHeader<QualityControlInstance, {}>
          defaultWidth={120}
          filterType={FilterHeaderType.Select}
          label="Type" // i18n
          minWidth={100}
          propertyId="type"
        />
      ),
      key: 'type',
      width: 120,
    },
    {
      filterable: true,
      getValue: (
        qualityControlInstance: QualityControlInstanceFragmentFragment
      ) =>
        qualityControlInstance.indicativeState
          ? qualityControlInstance.indicativeState.value
          : '',
      headerComponent: (
        <FilterHeader<QualityControlInstance, State>
          defaultWidth={150}
          filterType={FilterHeaderType.Text}
          label="Status" // i18n
          minWidth={120}
          propertyId="indicativeState"
          subPropertyId="value"
        />
      ),
      key: 'indicativeState.value',
      render: (qc) =>
        qc.indicativeState?.color ? (
          <Highlight
            backgroundColor={
              qc.indicativeState.color ? qc.indicativeState.color : ''
            }
            color={getTextColor(qc.indicativeState.color)}
          >
            {qc.indicativeState.value}
          </Highlight>
        ) : (
          <p></p>
        ),
      width: 150,
    },
    {
      filterable: true,
      getValue: (
        qualityControlInstance: QualityControlInstanceFragmentFragment
      ) =>
        qualityControlInstance.createdAt
          ? getFormattedDate(qualityControlInstance.createdAt, 'lll').label
          : '',
      headerComponent: (
        <FilterHeader<QualityControlInstance, {}>
          defaultWidth={200}
          filterType={FilterHeaderType.Date}
          label="Created on" // i18n
          minWidth={120}
          propertyId="createdAt"
        />
      ),
      key: 'createdAt',
      render: ({ createdAt }: QualityControlInstanceFragmentFragment) => (
        <Timestamp date={createdAt} format="lll" />
      ),
      width: 200,
    },
    {
      filterable: true,
      getValue: ({ createdBy }) => createdBy?.displayName,
      headerComponent: (
        <FilterHeader<QualityControlInstance, User>
          defaultWidth={300}
          filterType={FilterHeaderType.Select}
          label="Created by" // i18n
          minWidth={120}
          propertyId="createdBy"
          renderOption={RenderOptionType.UserCard}
          subPropertyId="displayName"
        />
      ),
      key: 'createdBy.displayName',
      render: ({ createdBy }) => <UserTag user={createdBy} />,
      width: 300,
    },
    {
      filterable: true,
      getValue: ({ task }) => task?.name,
      headerComponent: (
        <FilterHeader<QualityControlInstance, Task>
          defaultWidth={300}
          filterType={FilterHeaderType.Text}
          label="Task" // i18n
          minWidth={120}
          propertyId="task"
          subPropertyId="name"
        />
      ),
      key: 'task.name',
      width: 300,
    },
    {
      filterable: true,
      getValue: ({ visualContext }) => visualContext?.name,
      headerComponent: (
        <FilterHeader<QualityControlInstance, VisualContext>
          defaultWidth={300}
          filterType={FilterHeaderType.Text}
          label="Drawing" // i18n
          minWidth={120}
          propertyId="visualContext"
          subPropertyId="name"
        />
      ),
      key: 'visualContext.name',
      render: ({ visualContext }) => {
        const deleted = Boolean(visualContext?.deletedAt);
        return (
          <VisualContextName deleted={deleted}>
            {decodeURI(visualContext?.name || '')}{' '}
            {deleted && `(${t('deleted')})`}
          </VisualContextName>
        );
      },
      width: 300,
    },
    {
      filterable: true,
      getValue: ({ commentStatus }) => commentStatus,
      headerComponent: (
        <FilterHeader<Task, {}>
          defaultWidth={40}
          filterType={FilterHeaderType.Select}
          iconName="chatBubble"
          label="Comments" // i18n
          minWidth={40}
          propertyId="commentStatus"
          translateOptions
        />
      ),
      key: 'commentStatus',
      render: ({ commentStatus }) =>
        commentStatus && (
          <CommentStatus
            commentStatus={commentStatus}
            hasPreviousComments={false}
          />
        ),
      width: 120,
    },
    ...mdColumns,
    ...emptyColumns,
  ];

  if (isSelectIconEnabled) {
    columns.splice(1, 0, {
      disableSort: true,
      filterable: false,
      key: 'marker',
      render: ({ type, indicativeState, templateIconProps }) => (
        <Box sx={{ paddingY: 0.1 }}>
          <ComposedPin
            currentFile={
              templateIconProps?.iconType === 2
                ? getFileUrlByTemplateId(templateIconProps?.templateId || '')
                : undefined
            }
            customColor={indicativeState?.color || undefined}
            selectedIconName={
              templateIconProps?.iconName as TMuiIcon | undefined
            }
            simpleIcon
            top={0.5}
            type={
              type === QualityControlInstanceType.Form
                ? 'form'
                : type === QualityControlInstanceType.Tbq
                ? 'tbq'
                : 'snag'
            }
          />
        </Box>
      ),
      width: 30,
    });
  }
  return columns;
};
