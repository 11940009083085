import styled from 'styled-components';

import { customColors } from '@pro4all/shared/themes';

export const ResizeWrapper = styled.div<{ hasActiveFilter: boolean }>`
  && {
    width: 100%;
    height: 100%;
    user-select: none;
    background-color: ${({ hasActiveFilter }) =>
      hasActiveFilter ? customColors.greenLight : 'inherit'};
    &:hover {
      background-color: ${({ hasActiveFilter }) =>
        hasActiveFilter ? customColors.greenLight : customColors.grey300};
    }
    &:hover .WrapperClass {
      border-right: ${({ hasActiveFilter }) =>
        hasActiveFilter
          ? `4px solid rgb(0, 107, 94, .2)`
          : `4px solid ${customColors.grey400}`};
    }
  }
`;

export const Wrapper = styled.div`
  && {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-right: 2px;
    position: relative;
    border-right: 4px solid transparent;
    &:hover .IconClass {
      opacity: 1;
    }
    &:hover .RemoveFilterClass {
      right: 24px;
    }
    &:hover .OpenFilterClass {
      right: 0;
    }
  }
`;

export const Label = styled.div<{ hasActiveFilter: boolean }>`
  && {
    display: flex;
    align-items: center;
    color: ${({ hasActiveFilter }) =>
      hasActiveFilter ? customColors.greenAccentBackground : 'inherit'};
    width: ${({ hasActiveFilter }) =>
      hasActiveFilter ? 'calc(100% - 32px)' : '100%'};
    padding-left: 8px;
    overflow: hidden;
    &:hover {
      cursor: pointer;
    }
  }
`;

export const Counter = styled.div`
  && {
    width: 22px;
    height: 22px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${customColors.greenAccentBackground};
    color: ${customColors.white};
    margin: 0 6px;
    position: absolute;
    right: 0;
  }
`;

export const Icon = styled.div<{ hasActiveFilter: boolean }>`
  && {
    opacity: 0;
    position: absolute;
    top: 8px;
    height: 100%;
    color: ${({ hasActiveFilter }) =>
      hasActiveFilter
        ? customColors.greenAccentBackground
        : customColors.grey900};
    background-color: ${({ hasActiveFilter }) =>
      hasActiveFilter ? customColors.greenLight : customColors.grey300};
    padding: 0 6px 0 0;
  }
`;
