import { Connection } from '@pro4all/graphql';
import { Main } from '@pro4all/shared/ui/layout';
import { OptimisticResponseProvider } from '@pro4all/shared/ui/table';

import { ConnectionsTable } from './ConnectionsTable';

export const ConnectionsTableProviderWrapper = () => (
  <OptimisticResponseProvider<Connection>>
    <Main>
      <ConnectionsTable />
    </Main>
  </OptimisticResponseProvider>
);
