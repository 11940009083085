import { Template } from '@pro4all/graphql';
import { OptimisticResponseProvider } from '@pro4all/shared/ui/table';

import { ProjectQualityControl } from './ProjectQualityControl';

export const ProjectQualityControlProviderWrapper = () => (
  <OptimisticResponseProvider<Template>>
    <ProjectQualityControl />
  </OptimisticResponseProvider>
);
