import { useTranslation } from 'react-i18next';

import { DocumentVersion, FinalizationState } from '@pro4all/graphql';
import { ActionProps } from '@pro4all/shared/actions';
import { useOptimisticResponseContext } from '@pro4all/shared/ui/table';

import { DocumentActionProps } from '../../types';
import { useHideDeleteDocument } from '../delete/useHideDeleteDocument';

import { useHandleDeleteVersion } from './useHandleDeleteVersion';

export const useDeleteVersionAction = ({
  allDocumentsFinalized,
  hasFolderPermission,
  isLocked,
  selectionVersion,
  userId,
}: Pick<
  DocumentActionProps,
  | 'allDocumentsFinalized'
  | 'hasFolderPermission'
  | 'isLocked'
  | 'selectionVersion'
  | 'userId'
>) => {
  const { t } = useTranslation();

  const { handleDeleteVersion } = useHandleDeleteVersion();

  const hideDeleteDocumentVersion = useHideDeleteDocument({
    hasFolderPermission,
    selection: [],
    selectionVersion,
    userId,
  });

  const {
    state: { items },
  } = useOptimisticResponseContext<DocumentVersion>();

  const isFinalized = items.some(
    (item) => item.state === FinalizationState.Finalized
  );

  const deleteAction: ActionProps = {
    ariaLabel: t('Delete version'),
    dataTestId: 'delete-documents',
    disabled:
      isFinalized ||
      isLocked ||
      hideDeleteDocumentVersion() ||
      allDocumentsFinalized,
    key: 'delete-document-version',
    label: t('Delete version'),
    onClick: () => handleDeleteVersion({ selectionVersion }),
    startIcon: 'delete',
  };

  return deleteAction;
};
