import { ApiConfig } from '@pro4all/shared/config';
import { AvailableThemes, useApplication } from '@pro4all/shared/themes';
import { GetReportImageUrlsProps } from '@pro4all/shared/types';

import CompanyLogoPave from './CompanyLogoPave.png';
import CompanyLogoPro4all from './CompanyLogoPro4all.png';
import CompanyLogoTbq from './CompanyLogoTbq.png';
import NewBackground from './NewBackground.png';

export const useGetReportImageUrls = ({
  reportOptions,
  token,
}: GetReportImageUrlsProps) => {
  const { theme } = useApplication();

  const companyLogoImage =
    theme === AvailableThemes.tbq
      ? CompanyLogoTbq
      : theme === AvailableThemes.pave
      ? CompanyLogoPave
      : CompanyLogoPro4all;

  const companyLogoId = reportOptions.logoId;
  const companyLogo =
    companyLogoId === 'default' || !companyLogoId
      ? companyLogoImage
      : `${getReportImageUrl({
          id: companyLogoId,
        })}?access_token=${token}`;

  const bgId = reportOptions.bgId;
  const background =
    bgId === 'default' || !bgId
      ? NewBackground
      : `${getReportImageUrl({
          id: bgId,
        })}?access_token=${token}`;

  const bgIdFrontPage = reportOptions.bgIdFrontPage;
  const backgroundFrontPage =
    bgIdFrontPage === 'default' || !bgIdFrontPage
      ? NewBackground
      : `${getReportImageUrl({
          id: bgIdFrontPage,
        })}?access_token=${token}`;

  return { background, backgroundFrontPage, companyLogo };
};

const getReportImageUrl = ({ id }: { id: string }) =>
  ApiConfig.reportApi + `/v2/image/get/${id}`;
