import React from 'react';
import styled from 'styled-components';

import { Box } from '@pro4all/shared/mui-wrappers';
import { useApplication } from '@pro4all/shared/themes';
import { CenteredContents } from '@pro4all/shared/ui/layout';
import { Shape, ShapeType } from '@pro4all/shared/ui/shapes';
import { Text } from '@pro4all/shared/ui/typography';

const BigMessageBox = styled(Box)`
  && {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 75%;
    min-width: 240px;
    max-width: 480px;
    text-align: center;

    > .MuiSvgIcon-root {
      display: block;
      width: 240px;
      height: auto;
      margin-bottom: ${({ theme }) => theme.spacing(3)};
    }
  }
`;

interface Props {
  shapeName: ShapeType;
  text?: React.ReactNode;
  title: string;
}

export const BigMessage: React.FC<Props> = ({
  children,
  shapeName,
  text,
  title,
}) => {
  const { isThemePro4all } = useApplication();

  return (
    <CenteredContents $fullHeight>
      <BigMessageBox>
        <Shape
          aria-hidden={false}
          color={isThemePro4all ? 'purple' : 'green'}
          fontSize="inherit"
          mono
          role="presentation"
          style={{ maxHeight: '140px', maxWidth: '240px' }}
          type={shapeName}
        />
        <Text variant="h3">{title}</Text>
        <Box my={1}>{text && <Text>{text}</Text>}</Box>
        <Box my={1}>{children}</Box>
      </BigMessageBox>
    </CenteredContents>
  );
};
