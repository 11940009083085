export enum ActionType {
  ADD_ITEMS = 'ADD_ITEMS',
  DELETE_ITEMS = 'DELETE_ITEMS',
  EDIT_ITEMS = 'EDIT_ITEMS',
  FILTER_ITEMS = 'FILTER_ITEMS',
  REPLACE_ALL_ITEMS = 'REPLACE_ALL_ITEMS',
  RESET_INITIAL_ITEMS = 'RESET_INITIAL_ITEMS',
  RESTORE_ITEMS = 'RESTORE_ITEMS',
  SET_FILTERED_ITEMS = 'SET_FILTERED_ITEMS',
  SET_ITEM = 'SET_ITEM',
  SET_RECALCULATE_FILTERS = 'SET_RECALCULATE_FILTERS',
  TRIGGER_COLUMN_SORT = 'TRIGGER_COLUMN_SORT',
}

export interface BaseEntityType {
  id: string;
}

export type State<EntityType extends BaseEntityType> = {
  deletedItems: EntityType[];
  item: EntityType | null;
  items: EntityType[];
  itemsInitial: EntityType[];
  recalculateFilters: boolean;
};

type ActionAddPayload<EntityType extends BaseEntityType> = {
  payload: EntityType[];
  type: ActionType.ADD_ITEMS;
};
type ActionIdsPayload = {
  payload: string[];
  type: ActionType.DELETE_ITEMS | ActionType.RESTORE_ITEMS;
};
type ActionItemsPayload<EntityType extends BaseEntityType> = {
  payload: EntityType[];
  type:
    | ActionType.EDIT_ITEMS
    | ActionType.FILTER_ITEMS
    | ActionType.REPLACE_ALL_ITEMS
    | ActionType.SET_FILTERED_ITEMS
    | ActionType.TRIGGER_COLUMN_SORT;
};
type ActionItemPayload<EntityType extends BaseEntityType> = {
  payload: EntityType;
  type: ActionType.SET_ITEM;
};
type ActionNoPayload = {
  type: ActionType.RESET_INITIAL_ITEMS;
};
type ActionSetRecalculateFiltersPayload = {
  payload: boolean;
  type: ActionType.SET_RECALCULATE_FILTERS;
};
type Action<EntityType extends BaseEntityType> =
  | ActionAddPayload<EntityType>
  | ActionIdsPayload
  | ActionItemPayload<EntityType>
  | ActionItemsPayload<EntityType>
  | ActionNoPayload
  | ActionSetRecalculateFiltersPayload;

export function optimisticResponseReducer<EntityType extends BaseEntityType>(
  state: State<EntityType>,
  action: Action<EntityType>
) {
  const hasInitialItems = Boolean(state.itemsInitial.length);

  switch (action.type) {
    case ActionType.ADD_ITEMS: {
      return {
        ...state,
        items: hasInitialItems
          ? state.items
          : [...state.items, ...action.payload],
        itemsInitial: hasInitialItems
          ? [...state.itemsInitial, ...action.payload]
          : [],
        recalculateFilters: true,
      };
    }
    case ActionType.DELETE_ITEMS: {
      return {
        ...state,
        deletedItems: [
          ...state.deletedItems.filter((item) =>
            action.payload.every((id) => id !== item.id)
          ),
          ...state.items.filter((item) =>
            action.payload.some((id) => id === item.id)
          ),
        ],
        items: state.items.filter((item) =>
          action.payload.every((id) => id !== item.id)
        ),
      };
    }
    case ActionType.EDIT_ITEMS: {
      return {
        ...state,
        items: hasInitialItems
          ? state.items
          : state.items?.map(
              (item) =>
                action.payload.find(
                  (editedItem) => editedItem.id === item.id
                ) ?? item
            ),

        itemsInitial: hasInitialItems
          ? state.itemsInitial?.map(
              (item) =>
                action.payload.find(
                  (editedItem) => editedItem.id === item.id
                ) ?? item
            )
          : [],
        recalculateFilters: true,
      };
    }
    case ActionType.FILTER_ITEMS: {
      const itemsInitial = hasInitialItems ? state.itemsInitial : state.items;

      return {
        ...state,
        items: action.payload,
        itemsInitial,
      };
    }
    case ActionType.REPLACE_ALL_ITEMS: {
      return {
        ...state,
        // Update items that match by id when hasInitialItems is true
        items: hasInitialItems
          ? state.items.map(
              (item) =>
                action.payload.find((newItem) => newItem.id === item.id) ?? item
            )
          : action.payload,
        itemsInitial: hasInitialItems ? action.payload : [],
      };
    }
    case ActionType.RESET_INITIAL_ITEMS: {
      return {
        ...state,
        itemsInitial: [],
      };
    }
    case ActionType.RESTORE_ITEMS: {
      return {
        ...state,
        items: [
          ...state.items,
          ...state.deletedItems.filter((item) =>
            action.payload.some((id) => id === item.id)
          ),
        ],
      };
    }
    case ActionType.SET_FILTERED_ITEMS: {
      return {
        ...state,
        items: action.payload,
      };
    }
    case ActionType.SET_ITEM: {
      return {
        ...state,
        item: action.payload,
      };
    }
    case ActionType.SET_RECALCULATE_FILTERS: {
      return {
        ...state,
        recalculateFilters: action.payload,
      };
    }
    case ActionType.TRIGGER_COLUMN_SORT: {
      return {
        ...state,
        items: action.payload,
      };
    }

    default:
      return state;
  }
}
