import React from 'react';
import { useWatch } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Maybe } from 'graphql/jsutils/Maybe';

import { WeekDay } from '@pro4all/graphql';
import { Button } from '@pro4all/shared/mui-wrappers';
import { Icon } from '@pro4all/shared/ui/icons';
import { isDefined } from '@pro4all/shared/utils';

import { ContentWrap } from '../selectTime/SelectTime.styles';

import { ErrorMessage, ListOfDays } from './SelectDays.styles';

type TSelectDaysFields = {
  notificationDays: Maybe<WeekDay>[];
};

type Props<T extends TSelectDaysFields> = {
  enabled?: boolean;
};

export const SelectDays = <T extends TSelectDaysFields>({
  enabled,
}: Props<T>) => {
  const { t } = useTranslation();

  const {
    control,
    setValue,
    formState: { errors },
    trigger,
  } = useFormContext<TSelectDaysFields>();
  const selectedDays = useWatch({ control, name: 'notificationDays' })?.filter(
    isDefined
  );

  const onSelect = (value: WeekDay) => {
    const included = selectedDays?.includes(value);

    const nextValue = included
      ? selectedDays
        ? [...selectedDays.filter((day) => day !== value)]
        : undefined
      : selectedDays
      ? [...selectedDays, value]
      : [value];

    setValue('notificationDays', nextValue || []);
    trigger('notificationDays');
  };

  const errorMessage = errors.notificationDays?.message || '';

  return (
    <ContentWrap $enabled={enabled} $error={Boolean(errorMessage)}>
      <ListOfDays>
        {Object.values(WeekDay).map((value, index) => {
          const selected = value && selectedDays?.includes(value);

          return (
            <li key={index}>
              <Button
                className="BypassOverride"
                color={selected ? 'primary' : 'inherit'}
                disableElevation
                disabled={!enabled}
                onClick={() => (enabled ? onSelect(value) : false)}
                startIcon={
                  selected ? <Icon iconName="check"></Icon> : undefined
                }
                variant="contained"
              >
                {t(value)}
              </Button>
            </li>
          );
        })}
      </ListOfDays>
      <ErrorMessage>{t(errorMessage)}</ErrorMessage>
    </ContentWrap>
  );
};
