import React from 'react';

import { LogoImageProps } from './types';

export const LogoTbq = ({ compactMode, logoColor }: LogoImageProps) => {
  let colorText = '';
  switch (logoColor) {
    case 'negativeBlack':
      colorText = '#212121';
      break;
    case 'negativeWhite':
    default:
      colorText = '#FFFFFF';
      break;
  }

  const compactLogo = (
    <svg fill="none" height="34" viewBox="0 0 30 34" width="30">
      <path
        d="M6.17523 11.3699L14.9477 6.35752L23.3188 11.1405L28.572 8.118L14.9477 0.333496L0.922087 8.34736L6.17523 11.3699Z"
        fill={colorText}
      />
      <path
        d="M24.2869 22.5972V12.2354L29.5039 9.20229V25.6093L15.5478 33.6668L15.5296 27.6533L24.2869 22.5972Z"
        fill={colorText}
      />
      <path
        d="M14.1163 27.5655L5.61735 22.6586V12.7571L0.400391 9.72402V25.6707L14.1346 33.6001L14.1163 27.5655Z"
        fill={colorText}
      />
    </svg>
  );

  const regularLogo = (
    <svg fill="none" height="80" viewBox="0 0 142 80" width="142">
      <path
        d="M26.196 13.2263L13.3503 20.617L1.89067 14.0039L26.196 0L51.7453 14.7545L40.3127 21.3743L26.223 13.2601L26.196 13.2263Z"
        fill={colorText}
      />
      <path
        d="M26.1958 45.7578L12.1197 37.6435V22.2196L0.666748 15.6065V44.2431L26.1958 58.984V45.7578Z"
        fill={colorText}
      />
      <path
        d="M116.075 66.9428L130.002 58.9029V42.7825L141.333 36.237V65.4146L116.075 80V66.9428Z"
        fill={colorText}
      />
      <path
        d="M41.2996 31.3414V58.8285H32.5104V31.3414H22.1934V24.2346H51.7113V31.3414H41.2996Z"
        fill={colorText}
      />
      <path
        d="M78.5587 40.524C80.2649 40.1203 81.7829 39.1478 82.863 37.7664C83.9431 36.3851 84.521 34.6772 84.5015 32.9237C84.5015 27.6426 79.5661 24.2887 71.8181 24.2887H55.4636V58.8285H72.3658C80.4788 58.8285 85.6441 55.123 85.6441 49.2469C85.7225 47.1806 85.047 45.1563 83.7433 43.5514C82.4397 41.9464 80.597 40.8705 78.5587 40.524ZM64.2189 30.9965H71.3246C73.8937 30.9965 75.5231 32.2813 75.5231 34.506C75.5231 36.7306 73.8937 38.11 71.3246 38.11H64.2189V30.9965ZM71.3043 52.0666H64.2189V44.4257H71.3246C74.5833 44.4257 76.6048 45.8592 76.6048 48.1785C76.5845 50.6466 74.563 52.0666 71.3043 52.0666Z"
        fill={colorText}
      />
      <path
        d="M106.055 51.8367C104.024 51.846 102.036 51.2529 100.342 50.1324C98.6485 49.0118 97.3249 47.4142 96.5388 45.5413C95.7527 43.6685 95.5394 41.6046 95.9259 39.6105C96.3125 37.6165 97.2814 35.7818 98.7104 34.3385C100.139 32.8952 101.964 31.908 103.954 31.5017C105.944 31.0955 108.009 31.2884 109.89 32.0561C111.77 32.8238 113.38 34.1318 114.517 35.8148C115.654 37.4978 116.267 39.4801 116.278 41.5113V41.5654C116.278 42.9651 116.379 43.6345 115.858 44.9058L121.673 51.1808C123.802 48.2461 124.593 45.3115 124.593 41.5654C124.593 31.5037 116.622 23.9168 106.055 23.9168C95.4879 23.9168 87.5168 31.5037 87.5168 41.5654C87.5168 51.6271 95.4879 59.2139 106.055 59.2139C109.692 59.2139 112.235 58.7 115.162 57.0163L109.753 51.1808C108.408 51.8367 107.657 51.8367 106.055 51.8367Z"
        fill={colorText}
      />
      <path
        d="M115.473 45.6022L113.445 43.4722L107.968 48.0636L110.578 50.8359L115.987 56.6714L118.015 58.8758L124.391 55.1703L121.301 51.884L115.473 45.6022Z"
        fill={colorText}
      />
      <path
        d="M64.1986 77.6671H30.3132V76.0713H64.1986V77.6671Z"
        fill={colorText}
      />
      <path
        d="M32.3891 70.5333H33.0314V73.7047H32.3891V70.5333ZM33.1194 69.6475C33.1194 69.8436 32.9638 69.9991 32.7069 69.9991C32.6582 70.0028 32.6092 69.9965 32.563 69.9806C32.5168 69.9647 32.4743 69.9395 32.4382 69.9065C32.4021 69.8736 32.3732 69.8336 32.3531 69.7891C32.333 69.7446 32.3222 69.6964 32.3214 69.6475C32.3231 69.5975 32.3349 69.5483 32.3561 69.5029C32.3772 69.4575 32.4074 69.4168 32.4446 69.3834C32.4819 69.3499 32.5255 69.3243 32.5729 69.3081C32.6203 69.2919 32.6705 69.2855 32.7204 69.2891C32.7705 69.2845 32.8211 69.2902 32.8689 69.3061C32.9168 69.3219 32.9608 69.3474 32.9983 69.3811C33.0357 69.4147 33.0658 69.4558 33.0867 69.5016C33.1075 69.5475 33.1186 69.5972 33.1194 69.6475Z"
        fill={colorText}
      />
      <path
        d="M33.9302 71.4056C33.9302 71.0878 33.9302 70.8173 33.8964 70.5604H34.4237V71.0811C34.5338 70.8957 34.6917 70.7433 34.8808 70.6398C35.0699 70.5363 35.2833 70.4855 35.4987 70.4928C35.9449 70.4928 36.6413 70.7497 36.6413 71.8451V73.7047H36.0531V71.906C36.0531 71.3989 35.857 70.9796 35.3027 70.9796C35.1262 70.9801 34.9544 71.0366 34.8122 71.1412C34.67 71.2457 34.5647 71.3928 34.5116 71.5611C34.4834 71.6461 34.4697 71.7353 34.4711 71.8249V73.7047H33.9302V71.4056Z"
        fill={colorText}
      />
      <path
        d="M37.5676 70.5333H38.2438V73.7047H37.5676V70.5333ZM38.2843 69.6543C38.2843 69.7601 38.2423 69.8616 38.1675 69.9364C38.0927 70.0112 37.9912 70.0532 37.8854 70.0532C37.7796 70.0532 37.6781 70.0112 37.6033 69.9364C37.5285 69.8616 37.4864 69.7601 37.4864 69.6543C37.4881 69.6036 37.5 69.5538 37.5216 69.5079C37.5432 69.4621 37.5739 69.4211 37.6119 69.3875C37.6499 69.354 37.6944 69.3285 37.7426 69.3128C37.7908 69.297 37.8417 69.2913 37.8921 69.2959C37.9414 69.2913 37.9911 69.2968 38.0381 69.3123C38.0851 69.3278 38.1284 69.3528 38.1652 69.3859C38.2021 69.4189 38.2317 69.4592 38.2522 69.5042C38.2727 69.5493 38.2836 69.5981 38.2843 69.6475V69.6543Z"
        fill={colorText}
      />
      <path
        d="M39.7988 69.6949V70.4522H40.6779V70.8917H39.7988V72.589C39.7988 72.9811 39.9205 73.2043 40.2586 73.2043C40.3795 73.2087 40.5003 73.1951 40.617 73.1637V73.5965C40.4435 73.6584 40.2602 73.6882 40.0761 73.6844C39.9522 73.6916 39.8282 73.6731 39.7118 73.63C39.5954 73.5869 39.4892 73.5203 39.3999 73.4342C39.3079 73.3191 39.2397 73.187 39.1991 73.0454C39.1584 72.9038 39.1462 72.7556 39.1632 72.6092V70.8917H38.6425V70.4522H39.2038V69.8707L39.7988 69.6949Z"
        fill={colorText}
      />
      <path
        d="M41.1576 70.5333H41.8338V73.7047H41.1576V70.5333ZM41.9082 69.6475C41.9082 69.8436 41.7526 69.9991 41.4957 69.9991C41.4472 70.0019 41.3986 69.9949 41.3528 69.9786C41.307 69.9623 41.265 69.9371 41.2291 69.9043C41.1932 69.8716 41.1642 69.832 41.1438 69.7879C41.1234 69.7438 41.112 69.6961 41.1103 69.6475C41.1119 69.5975 41.1237 69.5483 41.1449 69.5029C41.166 69.4575 41.1962 69.4168 41.2334 69.3834C41.2707 69.3499 41.3143 69.3243 41.3617 69.3081C41.4091 69.2919 41.4593 69.2855 41.5092 69.2891C41.5594 69.2845 41.6099 69.2902 41.6577 69.3061C41.7056 69.3219 41.7496 69.3474 41.7871 69.3811C41.8245 69.4147 41.8546 69.4558 41.8755 69.5016C41.8963 69.5475 41.9074 69.5972 41.9082 69.6475Z"
        fill={colorText}
      />
      <path
        d="M44.085 72.0548C43.4901 72.0548 42.814 72.1494 42.814 72.7309C42.808 72.8005 42.8171 72.8706 42.8407 72.9364C42.8642 73.0022 42.9016 73.0621 42.9504 73.1121C42.9991 73.1622 43.058 73.2011 43.1232 73.2263C43.1883 73.2516 43.2581 73.2625 43.3278 73.2584C43.4885 73.2622 43.6462 73.2143 43.7776 73.1218C43.9091 73.0293 44.0074 72.897 44.058 72.7445C44.065 72.6861 44.065 72.6271 44.058 72.5687L44.085 72.0548ZM44.6124 72.8797C44.6079 73.1243 44.626 73.3687 44.6665 73.61H44.1662L44.1256 73.2246C44.0212 73.3702 43.883 73.4881 43.7227 73.5683C43.5625 73.6484 43.3852 73.6882 43.2061 73.6844C43.0874 73.6909 42.9686 73.6729 42.8572 73.6314C42.7457 73.59 42.644 73.5261 42.5584 73.4436C42.4728 73.3611 42.405 73.2619 42.3594 73.1521C42.3138 73.0422 42.2914 72.9242 42.2934 72.8053C42.2934 72.0683 42.9695 71.6626 44.0918 71.6693V71.6085C44.1056 71.515 44.0979 71.4196 44.0693 71.3295C44.0408 71.2394 43.9921 71.157 43.9269 71.0886C43.8617 71.0201 43.7818 70.9674 43.6932 70.9345C43.6047 70.9016 43.5097 70.8893 43.4157 70.8985C43.1376 70.8981 42.8653 70.978 42.6314 71.1284L42.5097 70.7633C42.8067 70.5818 43.1489 70.4881 43.4968 70.4928C44.4096 70.4928 44.6327 71.1284 44.6327 71.7369L44.6124 72.8797Z"
        fill={colorText}
      />
      <path
        d="M46.1811 69.6949V70.4522H47.0534V70.8917H46.1811V72.589C46.1811 72.9811 46.296 73.2043 46.6409 73.2043C46.7595 73.2088 46.8781 73.1951 46.9925 73.1637V73.5965C46.8192 73.659 46.6358 73.6888 46.4516 73.6844C46.3277 73.6916 46.2037 73.6731 46.0873 73.63C45.9709 73.5869 45.8647 73.5203 45.7754 73.4342C45.5953 73.1991 45.513 72.9036 45.5455 72.6092V70.8917H45.018V70.4522H45.5455V69.8707L46.1811 69.6949Z"
        fill={colorText}
      />
      <path
        d="M49.6898 71.7911C49.7043 71.6781 49.6944 71.5633 49.6609 71.4545C49.6273 71.3456 49.5708 71.2453 49.4953 71.1601C49.4197 71.0749 49.3267 71.0068 49.2227 70.9605C49.1186 70.9143 49.0059 70.8908 48.892 70.8917C48.6562 70.9001 48.4323 70.9976 48.2655 71.1644C48.0987 71.3313 48.0012 71.5552 47.9928 71.7911H49.6898ZM47.9996 72.19C47.9901 72.3339 48.012 72.4782 48.0638 72.6128C48.1156 72.7474 48.1961 72.8691 48.2997 72.9694C48.4032 73.0698 48.5274 73.1464 48.6636 73.194C48.7997 73.2416 48.9446 73.2589 49.0881 73.2449C49.3865 73.2545 49.6836 73.2015 49.9602 73.0893L50.0616 73.4883C49.7273 73.6256 49.3683 73.6924 49.007 73.6844C48.7992 73.7005 48.5905 73.6716 48.3949 73.5996C48.1994 73.5275 48.0217 73.4142 47.8741 73.2671C47.7264 73.1201 47.6123 72.9429 47.5394 72.7477C47.4666 72.5524 47.4367 72.3437 47.452 72.1359C47.4322 71.9283 47.456 71.7189 47.5217 71.521C47.5873 71.323 47.6935 71.141 47.8335 70.9864C47.9734 70.8318 48.144 70.7081 48.3344 70.6231C48.5248 70.5381 48.7308 70.4937 48.9393 70.4928C49.1221 70.4919 49.3029 70.5294 49.4702 70.6029C49.6376 70.6763 49.7876 70.7841 49.9106 70.9193C50.0336 71.0544 50.1268 71.2139 50.1843 71.3873C50.2418 71.5608 50.2622 71.7444 50.2442 71.9263C50.2497 72.0141 50.2497 72.1022 50.2442 72.19H47.9996Z"
        fill={colorText}
      />
      <path
        d="M52.8742 71.8654C52.8837 71.7914 52.8837 71.7164 52.8742 71.6423C52.837 71.4539 52.7331 71.2852 52.5816 71.1672C52.4301 71.0491 52.2411 70.9898 52.0493 70.9999C51.495 70.9999 51.1637 71.4868 51.1637 72.1427C51.1637 72.7986 51.4611 73.2314 52.0426 73.2314C52.232 73.229 52.4145 73.1599 52.5579 73.0362C52.7014 72.9125 52.7966 72.7422 52.8268 72.5552C52.8376 72.4789 52.8376 72.4015 52.8268 72.3252L52.8742 71.8654ZM53.4083 69.2959V72.8527C53.4083 73.1164 53.4083 73.4139 53.4083 73.6168H52.9215V73.1029C52.8258 73.2852 52.6803 73.4366 52.502 73.5395C52.3237 73.6424 52.1198 73.6927 51.9141 73.6844C51.7308 73.6736 51.5516 73.6256 51.3875 73.5433C51.2233 73.461 51.0777 73.3461 50.9594 73.2056C50.8411 73.0652 50.7526 72.9021 50.6994 72.7263C50.6462 72.5505 50.6293 72.3658 50.6498 72.1833C50.6253 71.9888 50.6416 71.7914 50.6977 71.6036C50.7539 71.4159 50.8487 71.2419 50.976 71.093C51.1033 70.944 51.2603 70.8233 51.437 70.7385C51.6137 70.6538 51.8062 70.6069 52.002 70.601C52.1775 70.5908 52.3526 70.6273 52.5094 70.7069C52.6662 70.7865 52.7991 70.9063 52.8945 71.054V69.2959H53.4083Z"
        fill={colorText}
      />
      <path
        d="M55.9774 72.4334C55.9737 72.4987 55.9737 72.5642 55.9774 72.6295C56.0222 72.8078 56.1248 72.9662 56.2691 73.0799C56.4135 73.1937 56.5914 73.2564 56.7752 73.2584C57.3363 73.2584 57.6676 72.7986 57.6676 72.1224C57.6676 71.4462 57.3837 70.9999 56.8158 70.9999C56.6239 71.0037 56.439 71.0727 56.2915 71.1956C56.144 71.3185 56.0428 71.488 56.0045 71.6761C55.999 71.7481 55.999 71.8204 56.0045 71.8925L55.9774 72.4334ZM55.4298 69.2959H55.9571V71.1419C56.0567 70.9688 56.2023 70.8266 56.3777 70.7311C56.5531 70.6356 56.7515 70.5906 56.951 70.601C57.6744 70.601 58.1882 71.2095 58.1815 72.1088C58.2089 72.2986 58.197 72.4919 58.1467 72.6768C58.0963 72.8618 58.0085 73.0344 57.8887 73.184C57.7689 73.3336 57.6197 73.4571 57.4502 73.5466C57.2808 73.6362 57.0947 73.69 56.9037 73.7047C56.7054 73.7147 56.5084 73.6678 56.3359 73.5695C56.1635 73.4713 56.0226 73.3258 55.9301 73.1502V73.637H55.4298C55.4298 73.4342 55.4298 73.1367 55.4298 72.8729V69.2959Z"
        fill={colorText}
      />
      <path
        d="M59.2023 70.4928L59.8784 72.2712C59.946 72.4672 60.0204 72.7039 60.0745 72.8865C60.1353 72.7039 60.1962 72.474 60.2705 72.2576L60.8587 70.4928H61.4267L60.6153 72.6363C60.4293 73.3358 60.082 73.9819 59.6012 74.5229C59.4129 74.7007 59.1788 74.8224 58.9251 74.8745L58.7899 74.4147C58.9633 74.3583 59.124 74.2687 59.2632 74.151C59.4567 73.9835 59.6112 73.7758 59.7162 73.5424C59.7354 73.4992 59.749 73.4538 59.7567 73.4072C59.7617 73.36 59.7617 73.3124 59.7567 73.2652L58.6547 70.4928H59.2023Z"
        fill={colorText}
      />
      <path
        d="M92.1007 68.498L87.6183 76.0713H85.7253L90.2483 68.498H92.1007Z"
        fill={colorText}
      />
      <path
        d="M82.2704 68.498C81.3665 68.4755 80.4848 68.7797 79.7869 69.3547C79.0891 69.9298 78.6218 70.7371 78.4708 71.6287V69.4311C78.4708 68.8699 78.2274 68.6265 77.6528 68.6265H75.7124V71.48L74.056 69.2688C73.9132 69.044 73.7119 68.8623 73.4737 68.7431C73.2355 68.624 72.9693 68.572 72.7038 68.5927H64.2189V70.5333C64.2189 71.0946 64.4623 71.3312 65.0235 71.3312H66.308V75.1044C66.308 75.6724 66.5514 75.9158 67.1261 75.9158H69.222V71.3312H70.9798V75.1044C70.9798 75.6724 71.2299 75.9158 71.8046 75.9158H73.7382V73.0555L75.3879 75.2667C75.5257 75.4867 75.7202 75.6656 75.951 75.7846C76.1818 75.9035 76.4403 75.9582 76.6995 75.9428H78.4844V72.9608C78.6263 73.8545 79.0919 74.6647 79.7926 75.2371C80.4933 75.8094 81.3801 76.104 82.284 76.0646C82.7881 76.0782 83.2897 75.9901 83.759 75.8055C84.2283 75.6209 84.6555 75.3436 85.0152 74.9901C85.3749 74.6367 85.6597 74.2143 85.8526 73.7483C86.0454 73.2823 86.1424 72.7822 86.1377 72.2779C86.1512 71.7712 86.0602 71.2671 85.8704 70.7971C85.6806 70.3271 85.3961 69.9012 85.0346 69.546C84.673 69.1907 84.2422 68.9137 83.769 68.7323C83.2958 68.5508 82.7903 68.4688 82.284 68.4912L82.2704 68.498ZM82.284 73.4071C82.0616 73.4031 81.8453 73.3335 81.6623 73.207C81.4793 73.0806 81.3378 72.9028 81.2554 72.6962C81.1731 72.4895 81.1536 72.2631 81.1994 72.0454C81.2452 71.8277 81.3543 71.6284 81.513 71.4725C81.6717 71.3166 81.8729 71.211 82.0913 71.1691C82.3098 71.1271 82.5358 71.1507 82.7409 71.2367C82.946 71.3227 83.1212 71.4674 83.2444 71.6527C83.3675 71.8379 83.4333 72.0554 83.4333 72.2779C83.4297 72.5798 83.3069 72.868 83.0915 73.0796C82.8762 73.2912 82.5858 73.4089 82.284 73.4071Z"
        fill={colorText}
      />
      <path
        clipRule="evenodd"
        d="M92.3671 75.6048V69.5934H93.7004V75.6048H92.3671Z"
        fill={colorText}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M96.4655 69.2784H99.3281V70.6117H96.5447C96.3838 70.6367 96.2366 70.7182 96.1299 70.8422C96.0167 70.974 95.9576 71.1438 95.9647 71.3175L95.9662 71.3531L95.9638 71.3886C95.9522 71.5652 96.0092 71.7393 96.1229 71.8748C96.2301 72.0025 96.38 72.0865 96.5442 72.1116H97.9246L97.9342 72.1119L97.9151 72.7783C97.9342 72.1119 97.9339 72.1119 97.9342 72.1119L97.9353 72.1119L97.9366 72.112L97.9395 72.1121L97.947 72.1124L97.9682 72.1134C97.985 72.1143 98.007 72.1158 98.0337 72.1181C98.0867 72.1228 98.159 72.1308 98.2444 72.1449C98.4119 72.1726 98.6461 72.2261 98.889 72.3324C99.1309 72.4384 99.4089 72.6089 99.6289 72.8851C99.8572 73.172 99.9947 73.5384 99.9947 73.9752C99.9947 74.4777 99.8854 74.9012 99.6626 75.2366C99.4366 75.5766 99.1315 75.7695 98.8428 75.8711C98.563 75.9695 98.2975 75.9835 98.1141 75.9775C98.0198 75.9744 97.9402 75.9658 97.8817 75.9574C97.8728 75.9562 97.8643 75.9549 97.8563 75.9536H95.1364V74.6203H97.9891L98.0547 74.635C98.0574 74.6355 98.0625 74.6364 98.0698 74.6374C98.0886 74.6401 98.1194 74.6436 98.1576 74.6448C98.2392 74.6475 98.3269 74.6391 98.4004 74.6133C98.4649 74.5906 98.513 74.5574 98.552 74.4987C98.5941 74.4353 98.6614 74.2874 98.6614 73.9752C98.6614 73.8337 98.6223 73.7615 98.5857 73.7155C98.5407 73.659 98.4655 73.6026 98.3541 73.5538C98.2438 73.5055 98.1247 73.4765 98.0272 73.4605C97.9802 73.4527 97.9421 73.4485 97.9179 73.4464C97.9097 73.4457 97.9032 73.4453 97.8986 73.445H96.466L96.4301 73.4411C95.9125 73.3849 95.4365 73.1309 95.1017 72.732C94.7745 72.3423 94.6069 71.8438 94.6314 71.3365C94.6197 70.8378 94.7931 70.3519 95.1189 69.973C95.4524 69.5851 95.9207 69.3382 96.4293 69.2824L96.4655 69.2784Z"
        fill={colorText}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M102.071 69.2514H104.953V70.5847H102.192C102.032 70.6222 101.888 70.7103 101.782 70.8365C101.665 70.9749 101.601 71.1501 101.601 71.3312C101.601 71.5124 101.665 71.6876 101.782 71.826C101.888 71.9522 102.032 72.0403 102.192 72.0778H103.551L103.562 72.0782L103.54 72.7445C103.562 72.0782 103.562 72.0782 103.562 72.0782L103.564 72.0782L103.565 72.0783L103.568 72.0784L103.575 72.0787L103.596 72.0799C103.613 72.0809 103.635 72.0824 103.662 72.0849C103.715 72.0897 103.787 72.0981 103.872 72.1125C104.039 72.1408 104.273 72.195 104.515 72.3017C105.011 72.5201 105.62 73.0087 105.62 73.9413C105.62 74.4446 105.51 74.8678 105.291 75.2043C105.069 75.5446 104.769 75.7431 104.484 75.8517C104.206 75.9572 103.942 75.979 103.758 75.9782C103.664 75.9779 103.584 75.9715 103.525 75.9648C103.512 75.9634 103.5 75.9619 103.489 75.9604H100.728V74.6271H103.605L103.662 74.6382C103.664 74.6386 103.669 74.6393 103.676 74.6401C103.695 74.6422 103.725 74.6448 103.764 74.6449C103.845 74.6452 103.934 74.6345 104.01 74.6055C104.077 74.5798 104.131 74.542 104.174 74.476C104.219 74.4062 104.286 74.2529 104.286 73.9413C104.286 73.7244 104.188 73.6146 103.978 73.522C103.867 73.4731 103.747 73.4437 103.65 73.4272C103.603 73.4192 103.564 73.4149 103.54 73.4127C103.532 73.412 103.526 73.4115 103.522 73.4112H102.071L102.015 73.4017C101.526 73.3186 101.083 73.065 100.763 72.6861C100.443 72.3071 100.267 71.8272 100.267 71.3312C100.267 70.8353 100.443 70.3554 100.763 69.9764C101.083 69.5975 101.526 69.3439 102.015 69.2608L102.071 69.2514ZM103.513 73.4106C103.513 73.4106 103.513 73.4106 103.513 73.4106V73.4106Z"
        fill={colorText}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M107.471 75.4761C107.996 75.8166 108.622 76.0145 109.294 76.0145C111.144 76.0145 112.644 74.514 112.644 72.6633C112.644 71.9913 112.447 71.3654 112.106 70.8408L111.127 71.82C111.245 72.0766 111.311 72.3623 111.311 72.6633C111.311 73.7778 110.408 74.6811 109.294 74.6811C108.993 74.6811 108.707 74.6152 108.45 74.4969L107.471 75.4761ZM110.201 70.8605C109.928 70.723 109.62 70.6455 109.294 70.6455C108.18 70.6455 107.276 71.5488 107.276 72.6633C107.276 72.9896 107.354 73.2978 107.491 73.5705L106.519 74.5428C106.155 74.0068 105.943 73.3599 105.943 72.6633C105.943 70.8126 107.443 69.3122 109.294 69.3122C109.99 69.3122 110.637 69.5247 111.173 69.8884L110.201 70.8605Z"
        fill={colorText}
        fillRule="evenodd"
      />
    </svg>
  );

  return compactMode ? compactLogo : regularLogo;
};
