import React from 'react';
import { Link } from 'react-router-dom';

import type { StamperProps } from '@pro4all/documents/ui/stamper';
import { Stamper } from '@pro4all/documents/ui/stamper';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Main } from '@pro4all/shared/ui/layout';
import { BigMessageNoDocumentsSelected } from '@pro4all/shared/ui/messages';

export const DocumentsStamper = () => {
  const { makeUrl, state } = useRouting<StamperProps>();
  if (!state?.queue.length) {
    return (
      <BigMessageNoDocumentsSelected
        anchor={<Link to={makeUrl({ searchParams: { action: undefined } })} />}
      />
    );
  }

  return (
    <Main flexDirection="column">
      <Stamper queue={state?.queue} />
    </Main>
  );
};
