import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ErrorMessage,
  FieldArray,
  FieldArrayRenderProps,
  useFormikContext,
} from 'formik';
import * as Yup from 'yup';

import { usePaste } from '@pro4all/metadata/ui/utils';
import { customColors } from '@pro4all/shared/themes';
import { usePreventSubmitOnEnter } from '@pro4all/shared/ui/form';

import { StyledErrorMessage } from './CustomSelection';
import { CustomStatusOption } from './CustomStatusOption';
import { StyledAddOption } from './Styles';
import { CustomType, FormFields } from './Types';

export type Status = {
  color: string;
  name: string;
};

export const CustomStatusForm = ({
  values: { statusOptions = [] },
}: CustomType) => {
  usePreventSubmitOnEnter();
  const { t } = useTranslation();
  const { setFieldTouched } = useFormikContext<FormFields>();
  const [newStatusName, setNewStatusName] = useState('');
  const newStatusRef = useRef<HTMLInputElement>();
  const { onPasteHandler, onPasteOverWriteHandler } = usePaste();

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewStatusName(event.currentTarget.value);
  };

  // Use Formik's arrayHelper to dynamically add a status field and color input
  const onEnter = (
    event: React.KeyboardEvent<HTMLInputElement>,
    arrayHelpers: FieldArrayRenderProps
  ) => {
    if (event.key === 'Enter') {
      event.preventDefault();

      if (newStatusName) {
        arrayHelpers.push({
          color: customColors.purpleMonoHighlight,
          name: newStatusName,
        });
      }
      // Touch the fields to force validation (we want error messages to show immediately)

      setFieldTouched(`newOptionError${statusOptions.length}`);

      // Clear the 'new status' input
      setNewStatusName('');

      // setTimeout because of some weird async behaviour in Formik.
      setTimeout(
        () => newStatusRef.current.scrollIntoView({ behavior: 'smooth' }),
        100
      );
    }
  };

  return (
    <FieldArray
      name="statusOptions"
      render={(arrayHelpers) => (
        <>
          {statusOptions?.map((status, index) => (
            <React.Fragment key={index}>
              <CustomStatusOption
                arrayHelpers={arrayHelpers}
                index={index}
                onPasteOverWriteHandler={(
                  event: React.ClipboardEvent<HTMLInputElement>
                ) =>
                  onPasteOverWriteHandler({
                    arrayHelpers,
                    event,
                    index,
                    setFieldTouched,
                  })
                }
              />
              <StyledErrorMessage
                aria-label={`newOptionError${index}`}
                role="alert"
              >
                <ErrorMessage name={`newOptionError${index}`} />
              </StyledErrorMessage>
            </React.Fragment>
          ))}
          <StyledAddOption
            autoFocus
            name="newStatusName"
            onChange={onInputChange}
            onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) =>
              onEnter(e, arrayHelpers)
            }
            onPaste={(event: React.ClipboardEvent<HTMLInputElement>) =>
              onPasteHandler({
                arrayHelpers,
                event,
                selectionOptions: statusOptions,
                setFieldTouched,
              })
            }
            placeholder={t('Add status')}
            ref={newStatusRef}
            value={newStatusName}
          />
        </>
      )}
    />
  );
};

export const useCustomStatusValidationSchema = () => {
  const { t } = useTranslation();
  const characterLimit = 9999;

  return {
    statusOptions: () =>
      Yup.array()
        .of(
          Yup.object()
            .shape({
              color: Yup.string().nullable(),
              name: Yup.string().required().max(characterLimit),
            })
            .test('cannotBeEmpty', '', function (status) {
              const { options } = this;
              const currentIndex = (options as { index: number }).index;
              if (status.name) {
                return true;
              }

              return this.createError({
                message: t("'{{name}}' is required", { name: t('Name') }),
                path: `newOptionError${currentIndex}`,
              });
            })
            .test('characterLimit', '', function (status) {
              const { options } = this;
              const currentIndex = (options as { index: number }).index;

              const exceedsCharLimit = status.name?.length > characterLimit;

              if (!exceedsCharLimit) {
                return true;
              }

              return this.createError({
                message: t('Cannot exceed {{number}} characters', {
                  number: `${characterLimit}`,
                }),
                path: `newOptionError${currentIndex}`,
              });
            })
            .test('uniqueName', '', function (status) {
              const { parent, options } = this;
              const currentIndex = (options as { index: number }).index;

              const duplicateNames = parent.filter(
                (_status: Status) =>
                  _status.name === status.name && Boolean(_status.name)
              );
              const isUnique = duplicateNames.length <= 1;

              if (isUnique) {
                return true;
              }

              return this.createError({
                message: t("'{{name}}' is already used", {
                  name: t('Name'),
                }),
                path: `newOptionError${currentIndex}`,
              });
            })
        )
        .when(['type'], {
          is: (type) => type?.id === 'Status',
          path: 'newOptionError',
          then: Yup.array().required(),
        })
        .test('noEmptyNames', '', function (statuses) {
          const { options } = this;
          const currentIndex = (options as { index: number }).index;

          const hasEmptyNames = statuses?.some(
            (status: Status) => !status.name
          );

          if (!hasEmptyNames || !statuses.length) {
            return true;
          }

          return this.createError({
            message: t("'{{name}}' is required", { name: t('Name') }),
            path: `newOptionError${currentIndex}`,
          });
        })
        .test('uniqueNames', '', function (statuses) {
          const { options } = this;
          const currentIndex = (options as { index: number }).index;

          const mapper = (status: Status) => status.name;
          const set = [...new Set(statuses?.map(mapper))];

          const hasUniqueNames = statuses?.length === set.length || !statuses;
          if (hasUniqueNames) {
            return true;
          }

          return this.createError({
            message: t("'{{name}}' is already used", { name: t('Name') }),
            path: `newOptionError${currentIndex}`,
          });
        }),
  };
};
