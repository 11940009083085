import { IntegrationType } from '@pro4all/graphql';
import { IconName } from '@pro4all/shared/ui/icons';

import { CustomBuild12 } from '../form/CustomBuild12';
import { CustomSnagstream } from '../form/CustomSnagstream';

type ConfigIntegrationTypeType = {
  [Type in IntegrationType]: {
    CustomForm?: React.ElementType;
    icon: IconName;
    label: string;
  };
};

export const useIntegrationConfig = (): ConfigIntegrationTypeType => ({
  Integration12Build: {
    CustomForm: CustomBuild12,
    icon: 'build12',
    label: '12Build',
  },
  IntegrationSnagstream: {
    CustomForm: CustomSnagstream,
    icon: 'snagstream',
    label: 'Snagstream',
  },
  // TODO: Temporarily add this to prevent issues because BE already added `IntegrationSta`, so FE will have to add it to the graphql schema
  IntegrationSta: {
    CustomForm: CustomSnagstream,
    icon: 'snagstream',
    label: 'Snagstream',
  },
});
