import React, { useEffect, useRef, useState } from 'react';

import { Box, Divider, Grid } from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';
import { ProfilePictureEntity } from '@pro4all/shared/types';
import { useFilterContext } from '@pro4all/shared/ui/filtering';
import { useOptimisticResponseContext } from '@pro4all/shared/ui/table';
import { BaseRow } from '@pro4all/shared/ui/table';
import { useFiltersFromLocalStorage } from '@pro4all/shared/ui/table-column-filtering';

import { TableGridViewCard } from './TableGridViewCard';
import { TableGridItem } from './types';

const SPACING = 4;

export type TableGridViewProps<T> = {
  items: T[];
  itemsToShow: TableGridItem[];
  onClick: (item: T) => void;
  profilePictureEntity?: ProfilePictureEntity;
};

export const TableGridView = <T,>({
  items,
  onClick,
  profilePictureEntity,
  itemsToShow,
}: TableGridViewProps<T>) => {
  const BoxRef = useRef<HTMLDivElement>(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [containerHeight, setContainerHeight] = useState(0);

  const onScroll = (event: React.UIEvent<HTMLElement>) => {
    const target = event.target as HTMLElement;
    setScrollPosition(target.scrollTop);
  };

  const {
    setRecalculateFilters,
    state: { recalculateFilters },
  } = useOptimisticResponseContext<BaseRow>();
  const { updateTableBasedOnCurrentFilters } = useFilterContext<BaseRow>();

  const initializeFilters = useFiltersFromLocalStorage<BaseRow, null>({
    recalculateFilters,
    setRecalculateFilters,
    updateTableBasedOnCurrentFilters,
  });

  // Set initial container height and update on resize
  useEffect(() => {
    const updateHeight = () => {
      // Use viewport height instead of container's clientHeight
      setContainerHeight(window.innerHeight);
    };

    updateHeight();
    window.addEventListener('resize', updateHeight);
    initializeFilters();
    return () => window.removeEventListener('resize', updateHeight);
  }, []);

  return (
    <Box
      onScroll={onScroll}
      ref={BoxRef}
      sx={{
        background: customColors.grey100,
        height: '100%',
        marginBottom: '80px',
        overflow: 'auto',
        position: 'relative',
        width: '100%',
      }}
    >
      <Divider />
      <Grid
        container
        key="document-grid-container"
        spacing={SPACING}
        sx={{ padding: SPACING }}
      >
        {items &&
          items.map((item, index) => (
            <Grid
              item
              key={itemsToShow[index].id}
              lg={3}
              md={6}
              sm={12}
              xl={2}
              xs={12}
            >
              <TableGridViewCard
                containerHeight={containerHeight}
                itemToShow={itemsToShow[index]}
                onClick={() => {
                  if (item) onClick(item);
                }}
                parentScrollPosition={scrollPosition}
                profilePictureEntity={profilePictureEntity}
              />
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};
