import React, { useEffect, useState } from 'react';
import { useMapEvents } from 'react-leaflet';
import { LatLng, LatLngBounds } from 'leaflet';

import { TemplateType } from '@pro4all/graphql';
import { useQualityControlContext } from '@pro4all/quality-control/context';
import { useRouting } from '@pro4all/shared/routing-utils';

import { AuthTileLayer } from './AuthTileLayer';
import { MapContextMenu } from './MapContextMenu';
import { useMapLinkingContext } from './MapLinkingContext';
import { SelectSnagModal } from './SelectSnagModal';
import { ContentsProps } from './types';

const isPointInBounds = (point: LatLng, bounds: LatLngBounds) =>
  bounds.contains(point);

export const MapContents: React.FC<ContentsProps & { isReady: boolean }> = ({
  isReady,
  lat,
  lng,
  mapHeight,
  mapWidth,
  visualContextId,
  zoomLevels = 5,
}) => {
  const {
    isContextMenuOpen,
    isSelectTemplateDialogOpen,
    centering,
    setCentering,
    setCoordinates,
    setIsContextMenuOpen,
    setIsSelectTemplateDialogOpen,
    setSelectedTemplateType,
  } = useMapLinkingContext();

  const { searchParams } = useRouting();
  const page = Number(searchParams.get('page'));
  const placeSnag = searchParams.get('placeSnag');

  const [localZoom, setLocalZoom] = useState<number>(1);
  const { setDrawingBounds, drawingBounds } = useQualityControlContext();

  const map = useMapEvents({
    click: ({ latlng: { lat: y, lng: x } }) => {
      if (drawingBounds && isPointInBounds(new LatLng(y, x), drawingBounds)) {
        if (placeSnag === 'false' || !placeSnag) return;
        setCoordinates({ x, y });
        setSelectedTemplateType(TemplateType.Snag);
        setIsSelectTemplateDialogOpen(true);
      }
    },
    contextmenu: ({ latlng: { lat: y, lng: x } }) => {
      if (drawingBounds && isPointInBounds(new LatLng(y, x), drawingBounds)) {
        if (placeSnag === 'true') return;
        setIsContextMenuOpen(true);
        setCoordinates({ x, y });
      }
    },
  });

  // Handle centering
  useEffect(() => {
    if (!isReady || !map || !lat || !lng) return;

    const timer = setTimeout(() => {
      if (!isContextMenuOpen || centering) {
        const zoomLevel = map.getZoom();
        if (zoomLevel !== localZoom) {
          map.setView([lat, lng]);
          setLocalZoom(zoomLevel);
        } else {
          map.setView([lat, lng]);
        }
        setCentering(false);
      }
    }, 100);

    return () => clearTimeout(timer);
  }, [
    isReady,
    map,
    lat,
    lng,
    localZoom,
    isContextMenuOpen,
    centering,
    setCentering,
  ]);

  useEffect(() => {
    if (!map || !mapHeight || !mapWidth) return;

    const timer = setTimeout(() => {
      try {
        // Check if map container exists and is mounted
        const container = map.getContainer();
        if (!container || !container.isConnected) {
          return;
        }

        // Force a map invalidation before setting bounds
        map.invalidateSize();

        const southWest = map.unproject([0, mapHeight], zoomLevels);
        const northEast = map.unproject([mapWidth, 0], zoomLevels);
        const bounds = new LatLngBounds(southWest, northEast);

        if (container.isConnected) {
          // Double check before operations
          map.fitBounds(bounds);
          setDrawingBounds(bounds);
        }
      } catch (error) {
        console.error('Error initializing map bounds:', error);
      }
    }, 100);

    return () => clearTimeout(timer);
  }, [map, mapHeight, mapWidth, zoomLevels, setDrawingBounds]);

  // Cleanup
  useEffect(() => {
    let isCurrentInstance = true;

    return () => {
      if (map && isCurrentInstance) {
        try {
          // First remove all event listeners
          map.off();
          // Only try to remove if the container still exists and belongs to this instance
          const container = map.getContainer();
          // if (container && container.parentNode) {
          //   map.remove();
          // }
        } catch (error) {
          console.error('Error cleaning up map:', error);
        }
        isCurrentInstance = false;
      }
    };
  }, [map]);

  if (!visualContextId) return null;

  return (
    <>
      <AuthTileLayer
        eventHandlers={{
          load: () => console.log('Tiles loaded successfully'),
          loading: () => console.log('Tiles loading...'),
        }}
        maxZoom={zoomLevels}
        minZoom={1}
        noWrap
        page={page}
        tileSize={256}
        visualContextId={visualContextId}
        zoomOffset={0}
      />
      {!placeSnag && isContextMenuOpen && <MapContextMenu />}
      {isSelectTemplateDialogOpen && (
        <SelectSnagModal visualContextId={visualContextId} />
      )}
    </>
  );
};
