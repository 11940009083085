import { Task } from '@pro4all/graphql';
import { OptimisticResponseProvider } from '@pro4all/shared/ui/table';

import { Workflow } from './Workflow';

export const WorkflowProviderWrapper = () => (
  <OptimisticResponseProvider<Task>>
    <Workflow />
  </OptimisticResponseProvider>
);
