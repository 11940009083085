import { Template, TemplateService } from '@pro4all/graphql';
import { useTemplatesInclude } from '@pro4all/metadata/data-access';
import { useRouting } from '@pro4all/shared/routing-utils';
import { OptimisticResponseProvider } from '@pro4all/shared/ui/table';

import { useMetaDataContext } from '../../views/MetaDataContext';
import { TemplateFrame } from '../form-template/TemplateFrame';

import { ScreenTemplates } from './ScreenTemplates';

export const ScreenTemplatesProviderWrapper = ({
  includeOrgItems = true,
  isProject = false,
}: {
  includeOrgItems?: boolean;
  isProject?: boolean;
}) => {
  const { searchParams } = useRouting();
  const createContext = searchParams.is('action', 'createTemplate');
  const editContext = searchParams.is('action', 'editSet');
  const showTemplate = createContext || editContext;

  const { templateType, templateService } = useMetaDataContext();

  const { loading, templates, selectedTemplate, selectTemplate } =
    useTemplatesInclude({
      includeCreatedAt: true,
      includeCreatedBy: true,
      includeIcon: Boolean(templateService === TemplateService.QualityControl),
      includeInstanceCount: true,
      includeOrgItems,
      includeScope: true,
      includeState: true,
      includeType: true, // We don't need this for the table, but we do need it for the export from the template editor.
      templateService,
      templateType,
    });

  return (
    <OptimisticResponseProvider<Template>>
      {showTemplate ? (
        <TemplateFrame selectedTemplate={selectedTemplate} />
      ) : (
        <ScreenTemplates
          isProject={isProject}
          loading={loading}
          selectTemplate={selectTemplate}
          templates={templates}
        />
      )}
    </OptimisticResponseProvider>
  );
};
