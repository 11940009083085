import styled from 'styled-components';

import { customColors } from '@pro4all/shared/themes';
import { Button as MUIButton } from '@pro4all/shared/ui/buttons';

export const ContentWrap = styled.div<{ $enabled?: boolean; $error?: boolean }>`
  color: ${({ $enabled }) =>
    $enabled ? customColors.grey700 : customColors.grey400};
`;

export const AddTimeButton = styled(MUIButton)`
  && {
    font-weight: 500;
    border: 0;
    outline: 0;
    display: inline-flex;
    gap: 4px;
    cursor: var(--cursor);
    align-items: center;
    margin-left: 21px;
  }
`;

export const ListOfTimes = styled.ol`
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  margin: 0 0 8px 0;
`;

export const TimeInputWrapper = styled.div`
  display: inline-flex;
  align-items: center;
`;
