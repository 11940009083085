import styled from 'styled-components';

import { customColors } from '@pro4all/shared/themes';

export const ContentWrap = styled.div<{ $enabled?: boolean; $error?: boolean }>`
  color: ${({ $enabled }) =>
    $enabled ? customColors.grey700 : customColors.grey400};
`;

export const Form = styled.form`
  display: flex;
  margin-top: 22px;
  flex-direction: column;
  min-width: 720px;
  min-height: 160px;
  gap: 28px;
`;
