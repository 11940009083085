import React, { Suspense } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';

import { client } from '@pro4all/authentication/src/graph-ql';
import { OpenCdeContextProvider } from '@pro4all/opencde/context';
import { OrganizationContextProvider } from '@pro4all/organization/context';
import { QualityControlContextProvider } from '@pro4all/quality-control/context';
import { SubscriptionContextProvider } from '@pro4all/settings/ui/src/subscription';
import {
  ClientRedirectContextProvider,
  ClipboardContextProvider,
  ObjectDetailProvider,
  SideNavContextProvider,
  SidePanelContextProvider,
} from '@pro4all/shared/contexts';
import { GenericProvider } from '@pro4all/shared/providers';
import { SavedAnswersContextProvider } from '@pro4all/shared/providers/saved-answers';
import { useApplication } from '@pro4all/shared/themes';
import { ThemeProvider } from '@pro4all/shared/themes';
import { Confetti } from '@pro4all/shared/ui/confetti';
import { FileUploadProvider } from '@pro4all/shared/ui/file-upload';
import { GlobalStyles, SnackbarProvider } from '@pro4all/shared/ui/general';
import {
  DiscardModalProvider,
  MessageProvider,
} from '@pro4all/shared/ui/messages';
import { Snow } from '@pro4all/shared/ui/snowfall';
import {
  SortColumnContextProvider,
  TableContextMenuContextProvider,
} from '@pro4all/shared/ui/table';
import { ToggleProvider } from '@pro4all/shared/ui/toggle';

import useBrowserTabStyle from '../useBrowserTabStyle';

export const Providers: React.FC = ({ children }) => {
  const { theme } = useApplication();

  useBrowserTabStyle({
    theme,
  });

  const router = createBrowserRouter([
    {
      element: (
        <Suspense fallback={<div>Loading ...</div>}>
          <Confetti />
          <Snow />
          <GenericProvider>
            <DndProvider backend={HTML5Backend}>
              <ApolloProvider client={client}>
                <ThemeProvider themeName={theme}>
                  <GlobalStyles />
                  <SideNavContextProvider>
                    <DiscardModalProvider>
                      <SidePanelContextProvider>
                        <OpenCdeContextProvider>
                          <FileUploadProvider>
                            <SnackbarProvider>
                              <SubscriptionContextProvider>
                                <ClipboardContextProvider>
                                  <ClientRedirectContextProvider>
                                    <SavedAnswersContextProvider>
                                      <SortColumnContextProvider>
                                        <ToggleProvider
                                          cycle
                                          limit={1}
                                          scope="Tooltip"
                                        >
                                          <TableContextMenuContextProvider>
                                            <ObjectDetailProvider>
                                              <MessageProvider>
                                                <OrganizationContextProvider>
                                                  <QualityControlContextProvider>
                                                    {children}
                                                  </QualityControlContextProvider>
                                                </OrganizationContextProvider>
                                              </MessageProvider>
                                            </ObjectDetailProvider>
                                          </TableContextMenuContextProvider>
                                        </ToggleProvider>
                                      </SortColumnContextProvider>
                                    </SavedAnswersContextProvider>
                                  </ClientRedirectContextProvider>
                                </ClipboardContextProvider>
                              </SubscriptionContextProvider>
                            </SnackbarProvider>
                          </FileUploadProvider>
                        </OpenCdeContextProvider>
                      </SidePanelContextProvider>
                    </DiscardModalProvider>
                  </SideNavContextProvider>
                </ThemeProvider>
              </ApolloProvider>
            </DndProvider>
          </GenericProvider>
        </Suspense>
      ),
      path: '/*',
    },
  ]);
  return <RouterProvider router={router} />;
};
