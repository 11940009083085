import { useTranslation } from 'react-i18next';

import { ActionProps } from '@pro4all/shared/actions';
import { useRouting } from '@pro4all/shared/routing-utils';

import { useConfigureDownloadNameContext } from './ConfigureDownloadNameProvider';
import { useOnSubmit } from './useOnSubmit';

export const useActions = ({ templateId }: { templateId: string }) => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();

  const { resetSegments } = useConfigureDownloadNameContext();

  const onClose = () => {
    resetSegments();
    searchParams.delete('configureDownload');
  };

  const onSubmit = useOnSubmit(templateId);

  const actions: ActionProps[] = [
    {
      ariaLabel: t('Cancel'),
      color: 'inherit',
      dataTestId: 'cancel-config-download-name',
      key: 'cancel-config-download-name',
      label: t('Cancel'),
      onClick: onClose,
      startIcon: 'cancel',
    },
    {
      ariaLabel: t('Save and close'),
      color: 'primary',
      dataTestId: 'save-config-download-name',
      key: 'save-config-download-name',
      label: t('Save and close'),
      onClick: onSubmit,
      startIcon: 'save',
    },
  ];

  return { actions, onClose };
};
