import { Routes } from '@pro4all/shared/config';
import { useMatchAny } from '@pro4all/shared/hooks';
import { useMediaQuery, useTheme } from '@pro4all/shared/mui-wrappers';
import { breakpoints } from '@pro4all/shared/themes';
import {
  BigMessageRotateScreen,
  BigMessageScreenTooSmall,
} from '@pro4all/shared/ui/messages';

export function useScreenTooSmallForPage() {
  const theme = useTheme();
  const smallerThanTabletBreakpoint = useMediaQuery(
    theme.breakpoints.down('md')
  );
  const screenTooNarrowForPage = useMatchAny([
    Routes.orgMetadataTemplates,
    Routes.metaDataHierarchicalLists,
    Routes.metaDataQualityControl,
    Routes.metaDataQualityControlFields,
    Routes.metaDataQualityControlForms,
    Routes.metaDataQualityControlReportConfigurations,
    Routes.snags,
    Routes.metaDataQualityControlTemplates,
    Routes.documentNotifications,
    Routes.projectNotificationsUrl,
    Routes.projectMetadataDocumentFieldsUrl,
    Routes.projectMetadataDocumentTemplatesUrl,
    Routes.projectMetaDataHierarchicalLists,
    Routes.projectMetadataQualityFieldsUrl,
  ]);
  const windowWideEnoughToViewPage = window.innerHeight > breakpoints.values.md;

  if (smallerThanTabletBreakpoint && screenTooNarrowForPage)
    return windowWideEnoughToViewPage ? (
      <BigMessageRotateScreen />
    ) : (
      <BigMessageScreenTooSmall />
    );

  return null;
}
