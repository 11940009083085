import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { AuthService } from '@pro4all/authentication/src/services/auth-service';
import { Document, Folder } from '@pro4all/graphql';
import { TrackingEvent } from '@pro4all/shared/config';
import { useContextScopedOrganizationId } from '@pro4all/shared/identity';
import { useRouting } from '@pro4all/shared/routing-utils';
import {
  convertForbiddenFilenameCharacters,
  truncateDownloadNameForEditPublish,
} from '@pro4all/shared/utils';
import { useAnalytics } from '@pro4all/shared/vendor';

export function useDropFilesPublish({
  folder,
  mustBeOneFile = true, // For now only on a single file base.
}: {
  folder?: Folder;
  mustBeOneFile?: boolean;
}) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { goTo, params } = useRouting();
  const { track } = useAnalytics();
  const profile = AuthService.getProfile();
  const { userId } = profile;

  const getContextScopedOrganizationId = useContextScopedOrganizationId();
  const organizationId = getContextScopedOrganizationId();

  const { projectId } = params;

  const dropFiles = (files: File[]) => {
    if (mustBeOneFile && files.length !== 1) {
      enqueueSnackbar(t('Please upload only one document'));
      return;
    }
    track(TrackingEvent.PublishFileIntent, {
      fileCount: files.length,
      folderId: folder?.id,
      organizationId,
      projectId: folder?.projectId || projectId,
      userId,
    });

    const documentCurrent: Document = JSON.parse(
      window.sessionStorage.getItem('p4a:ps:currentDocument')
    );

    const fileNameUpload = files[0].name;
    const downloadName = truncateDownloadNameForEditPublish(documentCurrent);

    const splittedName = downloadName.split('.');
    const extension = documentCurrent.extension.replace('.', '');
    const name =
      extension === splittedName[splittedName.length - 1]
        ? downloadName.split('.').slice(0, -1).join('.')
        : downloadName;

    const fileNameMatch = `${name}-${documentCurrent.versionId}${
      extension ? `.${extension}` : ''
    }`;

    const fileNameMatchValidCharacters =
      convertForbiddenFilenameCharacters(fileNameMatch);

    if (fileNameMatchValidCharacters !== fileNameUpload) {
      enqueueSnackbar(
        t(
          "The file name does not match the document name. Select a file with following name '{{fileNameMatchValidCharacters}}'",
          {
            fileNameMatchValidCharacters,
          }
        )
      );
    } else {
      goTo({
        searchParams: {
          action: 'uploadDocuments',
          fullscreen: true,
        },
        state: {
          documentCurrent,
          filesToUpload: files,
          publishDocument: true,
        },
      });
    }
  };

  return dropFiles;
}
