import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';

export const Indicative = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M23 10C23 11.1 22.1 12 21 12C20.82 12 20.65 11.98 20.49 11.93L16.93 15.48C16.98 15.64 17 15.82 17 16C17 17.1 16.1 18 15 18C13.9 18 13 17.1 13 16C13 15.82 13.02 15.64 13.07 15.48L10.52 12.93C10.36 12.98 10.18 13 10 13C9.82 13 9.64 12.98 9.48 12.93L4.93 17.49C4.98 17.65 5 17.82 5 18C5 19.1 4.1 20 3 20C1.9 20 1 19.1 1 18C1 16.9 1.9 16 3 16C3.18 16 3.35 16.02 3.51 16.07L8.07 11.52C8.02 11.36 8 11.18 8 11C8 9.9 8.9 9 10 9C11.1 9 12 9.9 12 11C12 11.18 11.98 11.36 11.93 11.52L14.48 14.07C14.64 14.02 14.82 14 15 14C15.18 14 15.36 14.02 15.52 14.07L19.07 10.51C19.02 10.35 19 10.18 19 10C19 8.9 19.9 8 21 8C22.1 8 23 8.9 23 10Z"
      fill={customColors.grey700}
    />
    <path
      d="M4 5L4.75 7.25L7 8L4.75 8.75L4 11L3.25 8.75L1 8L3.25 7.25L4 5Z"
      fill={customColors.grey700}
    />
    <path
      d="M15 2L16 5L19 6L16 7L15 10L14 7L11 6L14 5L15 2Z"
      fill={customColors.grey700}
    />
  </SvgIcon>
);
