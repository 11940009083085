export const baseDms = `/dms`;
export const baseDocs = `${baseDms}/docs`;
export const docs = `${baseDocs}/:path*`;
export const smartFolderPath = 'smart-folders';
export const smartFolders = `${baseDocs}/${smartFolderPath}`;
export const smartFolder = `${smartFolders}/:name`;
export const collectionFolder = `${baseDocs}/collection-folder`;
export const projects = '/projects';
export const config = '/config';
export const office = '/office';

export const myApiKeyManagement = '/my-api-key-management';
export const myProfile = '/my-profile';
export const mySignature = '/my-signature';
export const mySupport = '/my-support';
export const myOverview = '/my-overview';
export const myProjects = `${myOverview}/my-projects`;
export const myTasks = `${myOverview}/my-tasks`;
export const openCdeMetadata = '/open-cde-metadata';

export const templates = `${config}/templates`;
export const metaDataDocuments = `${config}/metadata/documents`;
export const metaDataHierarchicalLists = `${config}/metadata/hierarchicallists`;
export const metaDataDocumentsFields = `${metaDataDocuments}/fields`;
export const metaDataDocumentsTemplates = `${metaDataDocuments}/templates`;

export const metaDataQualityControl = `${config}/metadata/qualitycontrol`;
export const metaDataQualityControlForms = `${metaDataQualityControl}/forms`;
export const metaDataQualityControlSnags = `${metaDataQualityControl}/snags`;
export const metaDataQualityControlFields = `${metaDataQualityControl}/fields`;
export const metaDataQualityControlReportConfigurations = `${metaDataQualityControl}/reportconfigurations`;
export const metaDataQualityControlTemplates = `${metaDataQualityControl}/sets`;

export const metaDataQualityControlTBQ = `${config}/metadata/qualitycontrol`;
export const metaDataQualityControlTBQTemplates = `${metaDataQualityControlTBQ}/tbq`;
export const metaDataQualityControlTBQTemplatesScope = `${metaDataQualityControlTBQTemplates}/:tbqScopeId`;

export const orgTemplatesTasks = `${templates}/tasks`;

export const access = `${config}/access`;
export const baseAccessDocuments = `${access}/documents`;
export const accessDocuments = `${baseAccessDocuments}`;

export const settingsOrganization = `${config}/organization`;

export const settingsOrganizationIntegrations = `${settingsOrganization}/integrations`;
export const settingsOrganizationLicense = `${settingsOrganization}/license`;
export const settingsOrganizationOrganization = `${settingsOrganization}/organization`;
export const settingsOrganizationSettings = `${settingsOrganization}/settings`;
export const settingsOrganizationDocuments = `${settingsOrganization}/documents`;
export const settingsOrganizationProvisioning = `${settingsOrganization}/provisioning`;
export const settingsOrganizationDomains = `${settingsOrganization}/domains`;

export const settings = `${config}/settings`;
export const baseSettingsProject = `${settings}/project`;

export const usersGroups = `${config}/usersgroups`;
export const members = `${usersGroups}/members`;
export const groups = `${usersGroups}/groups`;

export const organization = '/organization';
export const confirmEmail = `${organization}/finalize/:token`;
export const finalizeInvitation = '/user/finalize';

export const project = `${projects}/:projectId`;

export const projectWorkflows = `${project}/workflow`;

export const projectDmsBase = `${project}${baseDms}`;
export const projectDocsBase = `${project}${baseDocs}`;
export const document = `${projectDocsBase}/:folderId`;

export const projectDocs = `${project}${docs}`;
export const projectDocsTasks = `${project}${baseDms}/document-tasks`;
export const projectDocsNotifications = `${project}${baseDms}/notifications`;

export const projectMetaDataDocuments = `${project}${metaDataDocuments}`;
export const projectMetaDataHierarchicalLists = `${project}${metaDataHierarchicalLists}`;
export const projectMetaDataDocumentsFields = `${projectMetaDataDocuments}/fields`;
export const projectMetaDataDocumentsTemplates = `${projectMetaDataDocuments}/templates`;

export const projectMetaDataQualityControl = `${project}${metaDataQualityControl}`;
export const projectMetaDataQualityControlForms = `${projectMetaDataQualityControl}/forms`;
export const projectMetaDataQualityControlSnags = `${projectMetaDataQualityControl}/snags`;
export const projectMetaDataQualityControlFields = `${projectMetaDataQualityControl}/fields`;
export const projectMetaDataQualityControlReportConfigurations = `${projectMetaDataQualityControl}/reportconfigurations`;
export const projectMetaDataQualityControlTemplates = `${projectMetaDataQualityControl}/templates`;

export const projectTemplatesTasks = `${project}${templates}/tasks`;

export const projectQualityControlMain = `${project}/qc`;
export const projectQualityControlResults = `${projectQualityControlMain}/results`;
export const projectQualityControlDrawings = `${projectQualityControlMain}/drawings`;
export const projectQualityControlDrawing = `${projectQualityControlDrawings}/:visualContextId`;
export const projectQualityControlMySavedAnswers = `${projectQualityControlMain}/mysavedanswers`;
export const projectQualityControlRecurringSeries = `${projectQualityControlMain}/recurringseries`;
export const projectQualityControlTasks = `${projectQualityControlMain}/tasks`;
export const projectQualityControlNotifications = `${projectQualityControlMain}/notifications`;

export const projectAccess = `${project}${access}`;
export const projectAccessDocuments = `${projectAccess}/documents`;
export const projectAccessQualityControl = `${projectAccess}/qualitycontrol`;
export const projectAccessTasks = `${projectAccess}/tasks`;

export const projectSettings = `${project}${settings}`;
export const projectSettingsProject = `${projectSettings}/project`;
export const projectSettingsConnections = `${projectSettings}/connections`;
export const projectSettingsDocuments = `${projectSettings}/documents`;

export const projectUsersGroups = `${project}${usersGroups}`;
export const projectMembers = `${projectUsersGroups}/members`;
export const projectGroups = `${projectUsersGroups}/groups`;
export const projectCollectionFolder = `${project}${collectionFolder}`;

export const sharedDocs = `/shared`;

export const publiclySharedDocs = `${sharedDocs}/public/:linkId?`;
export const revokePublicLink = `${publiclySharedDocs}/revoke`;

export const docsSharedByInvite = `${sharedDocs}/private/:linkId`;
export const projectDocsSharedByInvite = `${project}${docsSharedByInvite}`;

export const docsSharedInScope = `${sharedDocs}/scoped/:linkId`;
export const projectDocsSharedInScope = `${project}${docsSharedInScope}`;

export const objects = `/objects/:objectId?`;
export const objectsBase = '/objects';
export const objectDocuments = `${objects}/documents`;
export const objectDrawings = `${objects}/floor-plans`;
export const objectResults = `${objects}/results`;
export const objectTasks = `${objects}/tasks`;
export const objectTBQ = `${objects}/tbq`;
export const objectTBQOsidLog = `${objects}/osid-log`;
export const objectTBQResults = `${objects}/tbq-results/:resultSet?`;
export const objectTBQMultiBrandcheckResults = `${objects}/tbq-multi-results/:resultSet?`;
export const objectTBQRieResults = `${objects}/tbq-rie/:taskId`;
export const objectDrawing = `${objects}/floor-plans/:visualContextId`;
export const objectTBQMultiRieResults = `${objects}/tbq-rie`;

export const projectObjects = `${project}${objects}`;
export const projectObjectDocuments = `${project}${objectDocuments}`;
export const projectObjectDrawings = `${project}${objectDrawings}`;
export const projectObjectResults = `${project}${objectResults}`;
export const projectObjectTasks = `${project}${objectTasks}`;
export const projectObjectTBQOsidLog = `${project}${objectTBQOsidLog}`;
export const projectObjectTbq = `${project}${objectTBQ}`;
export const projectObjectTBQResults = `${project}${objectTBQResults}`;
export const projectObjectTBQMultiBrandcheckResults = `${project}${objectTBQMultiBrandcheckResults}`;
export const projectObjectTBQRieResults = `${project}${objectTBQRieResults}`;
export const projectObjectDrawing = `${project}${objectDrawing}`;
export const projectObjectTBQMultiRieResults = `${project}${objectTBQMultiRieResults}`;

export const messages = '/messages/:messageId?';
export const projectMessages = `${project}${messages}`;
export const projectAllMessages = `${project}/messages/all`;
export const projectInboxMessages = `${project}/messages/inbox`;
export const projectSentMessages = `${project}${messages}/sent`;
export const projectDraftMessages = `${project}${messages}/draft`;
export const publicAttachments = '/messages/shared/public/:linkId?';

export const organizationMessages = '/messages';
export const allMessages = `${organizationMessages}/all`;
export const inboxMessages = `${organizationMessages}/inbox`;
export const sentMessages = `${organizationMessages}/sent`;
export const draftMessages = `${organizationMessages}/draft`;

export const moveUser = `/moveuser/:token`;

export const organizationNotifications = `${baseDms}/notifications`;

export const organizationSupport = `/support`;
