import { HierarchicalList } from '@pro4all/graphql';
import { OptimisticResponseProvider } from '@pro4all/shared/ui/table';

import { HierarchicalLists } from './HierarchicalLists';
import { HierarchicalListsProps } from './types';

export const HierarchicalListsProviderWrapper = ({
  isProject = false,
  pathHierarchicalLists,
}: HierarchicalListsProps) => (
  <OptimisticResponseProvider<HierarchicalList>>
    <HierarchicalLists
      isProject={isProject}
      pathHierarchicalLists={pathHierarchicalLists}
    />
  </OptimisticResponseProvider>
);
