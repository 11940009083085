import { NotificationsUser } from '@pro4all/graphql';
import { OptimisticResponseProvider } from '@pro4all/shared/ui/table';

import { NotificationsPage } from './NotificationsPage';
import { NotificationPageProps } from './types';

export const NotificationsPageProviderWrapper = ({
  scope,
}: NotificationPageProps) => (
  <OptimisticResponseProvider<NotificationsUser>>
    <NotificationsPage scope={scope} />
  </OptimisticResponseProvider>
);
