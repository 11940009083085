import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { useRestoreDocumentsMutation } from '@pro4all/graphql';
import { Alert, Button } from '@pro4all/shared/mui-wrappers';

export const SnackbarDocumentsDeleted = ({
  count,
  documentIds,
  restoreItems,
}: {
  count: number;
  documentIds: string[];
  restoreItems: () => void;
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [restoreDocuments] = useRestoreDocumentsMutation();
  const [hideUndoAction, setHideUndoAction] = useState(false);

  const onRestoreDocuments = async () => {
    try {
      const { data } = await restoreDocuments({
        variables: { documentIds },
      });

      restoreItems();
      setHideUndoAction(true);

      const succeeded = data?.restoreDocuments?.successful?.length || 0;
      const failed = data?.restoreDocuments?.unsuccessful?.length || 0;

      if (succeeded > 0) {
        enqueueSnackbar(
          t('Restored {{count}} document(s)', { count: succeeded })
        );
      }
      if (failed > 0) {
        enqueueSnackbar(
          t('Restoring {{count}} document(s) failed', { count: failed })
        );
      }
    } catch (e) {
      enqueueSnackbar(t('Something went wrong'));
    }
  };

  const undo = hideUndoAction ? null : (
    <Button color="inherit" onClick={onRestoreDocuments} size="small">
      {t('Undo')}
    </Button>
  );

  return (
    <Alert action={undo} severity="success">
      {t('Deleted {{count}} document(s)', { count })}
    </Alert>
  );
};
