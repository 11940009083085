import { TbqRieResult } from '@pro4all/graphql';
import { OptimisticResponseProvider } from '@pro4all/shared/ui/table';

import { TBQRieResults } from './TBQRieResults';

export const TBQRieResultsProviderWrapper = () => (
  <OptimisticResponseProvider<TbqRieResult>>
    <TBQRieResults />
  </OptimisticResponseProvider>
);
