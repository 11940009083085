import { customColors } from '@pro4all/shared/themes';

const colorPattern = [
  customColors.purpleMonoHighlight,
  customColors.blueAccent,
  customColors.success,
  customColors.successBg,
  customColors.greenShapeShadow,
  customColors.pendingBg,
  customColors.purpleAccent,
  customColors.errorAlert,
  customColors.orangeAccent,
  customColors.errorBg,
  customColors.orangeMonoHighlight,
  customColors.ai300,
  customColors.grey600,
  customColors.grey500,
  customColors.grey100,
  customColors.white,
  customColors.black,
];

const ChartColorsHex = [
  ...colorPattern,
  ...colorPattern,
  ...colorPattern,
  ...colorPattern,
];

export const ChartColors = ChartColorsHex.map((color) => hexToRGBA(color, 1));

export function hexToRGBA(hex: string, opacity: number): string {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}
