import { Navigate } from 'react-router';

import { AuthType } from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import { Routes } from '@pro4all/shared/config';
import { useAppPermissions } from '@pro4all/shared/identity';
import { Box } from '@pro4all/shared/mui-wrappers';
import { ResponseWrapper } from '@pro4all/shared/ui/response-wrapper';

import { OrganizationSettingsForm } from './OrganizationSettingsForm';

export const OrganizationSettings = () => {
  // In case we have to add a second setting:
  // 1. Remove the condition of a user with sso to redirect.
  // 2. Display the `Settings` page also if the user is not logged in via SSO see in (OrganizationOrganizationTabs.tsx).
  // 3. Hide the enforce sso setting when the users is not logged with SSO (see in OrganizationSettingsForm).

  const { hasAppPermission } = useAppPermissions();
  const { userAuthType } = useOrganizationContext();
  const shouldRedirect =
    !hasAppPermission('OrganizationUpdate') || userAuthType !== AuthType.Sso;

  if (shouldRedirect) {
    return <Navigate to={Routes.orgSettingsGeneral} />;
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      overflow="scroll"
      pt={4}
    >
      <ResponseWrapper>
        <OrganizationSettingsForm showCancel={false} />
      </ResponseWrapper>
    </Box>
  );
};
