import React from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { CommentStatus } from '@pro4all/documents/ui/comments';
import { Project, Task, TaskType, User } from '@pro4all/graphql';
import { NO_VALUE } from '@pro4all/shared/constants';
import { Chip } from '@pro4all/shared/mui-wrappers';
import { RenderOptionType } from '@pro4all/shared/types';
import { StatusChip } from '@pro4all/shared/ui/chips';
import { FilterHeaderType } from '@pro4all/shared/ui/filtering';
import { UserTag } from '@pro4all/shared/ui/identity-card';
import { MiddleEllipsis } from '@pro4all/shared/ui/middle-ellipsis';
import { ColumnProps } from '@pro4all/shared/ui/table';
import { FilterHeader } from '@pro4all/shared/ui/table-column-filtering';
import { getFormattedDate, Timestamp } from '@pro4all/shared/ui/timestamp';
import { Tooltip } from '@pro4all/shared/ui/tooltip';
import { titleCaseToSentenceCase } from '@pro4all/shared/utils';
import { useTaskStatuses } from '@pro4all/workflow/ui/utils';

import { IconWrapper, StyledIcon, TitleContainer } from './tableColumns.styled';
import { isOverdue, isUpcoming } from './utils';

type TaskIconName =
  | 'serieRounded'
  | 'taskSucceeded'
  | 'tbqResolveTask'
  | 'tbqBrandcheck'
  | 'qcTask';

const getAction = (documentAction: string) =>
  titleCaseToSentenceCase(documentAction ?? '') ?? '';

const getTaskIcon = (task: Task): TaskIconName => {
  if (task.serieId) return 'serieRounded';

  switch (task.type) {
    case TaskType.Resolve:
      return 'taskSucceeded';
    case TaskType.TbqResolve:
      return 'tbqResolveTask';
    case TaskType.TbqScan:
      return 'tbqBrandcheck';
    default:
      return 'qcTask';
  }
};

const getTaskName = (task: Task): string =>
  task.serieIndex != null
    ? `${task.name || ''} [${task.serieIndex + 1}]`
    : task.name || '';

export const useColumns = (
  typeFilter: TaskType[],
  hideProject?: boolean,
  hideUser?: boolean
) => {
  const { t } = useTranslation();
  const statuses = useTaskStatuses();

  const columns: ColumnProps<Task>[] = [
    {
      filterable: true,
      getValue: (task: Task) => {
        const { status } = task;
        return status ? statuses[status].label : '';
      },
      headerComponent: (
        <FilterHeader<Task, {}>
          defaultWidth={120}
          filterType={FilterHeaderType.Select}
          label="Status" // i18n
          minWidth={120}
          propertyId="status"
          translateOptions
        />
      ),
      key: 'status',
      render: ({ status }) => (status ? <StatusChip status={status} /> : null),
      width: 120,
    },
    {
      defaultSort: true,
      filterable: true,
      headerComponent: (
        <FilterHeader<Task, {}>
          defaultWidth={200}
          filterType={FilterHeaderType.Text}
          label="Title" // i18n
          minWidth={100}
          propertyId="name"
          pullFromLocalStorage
        />
      ),
      key: 'name',
      minWidth: 100,
      render: (task: Task) => (
        <TitleContainer>
          {task.serieId ? (
            <Tooltip title={t('This task is part of a series')}>
              <IconWrapper>
                <StyledIcon color="inherit" iconName={getTaskIcon(task)} />
              </IconWrapper>
            </Tooltip>
          ) : (
            <IconWrapper>
              <StyledIcon color="inherit" iconName={getTaskIcon(task)} />
            </IconWrapper>
          )}
          {getTaskName(task)}
        </TitleContainer>
      ),
      width: 200,
    },
    {
      filterable: true,
      getValue: (task: Task) => titleCaseToSentenceCase(t(task.type) ?? ''),
      headerComponent: (
        <FilterHeader<Task, {}>
          defaultWidth={200}
          filterType={FilterHeaderType.Select}
          label="Type" // i18n
          minWidth={120}
          propertyId="type"
        />
      ),
      key: 'type',
      width: 120,
    },
    {
      filterable: true,
      headerComponent: (
        <FilterHeader<Task, {}>
          defaultWidth={200}
          filterType={FilterHeaderType.Text}
          label="Category" // i18n
          minWidth={100}
          propertyId="taskCategoryName"
        />
      ),
      key: 'taskCategoryName',
      minWidth: 100,
      width: 200,
    },
    {
      filterable: true,
      getValue: (task: Task) => {
        const { assignment } = task;
        return assignment?.[0]?.displayName ? assignment[0].displayName : '';
      },
      headerComponent: (
        <FilterHeader<Task, User>
          customCallbackId="assignment.displayName"
          defaultWidth={200}
          filterType={FilterHeaderType.Select}
          getCustomValueCallback={(assignment: User[]) =>
            assignment?.[0]?.displayName ?? NO_VALUE
          }
          label="Responsible" // i18n
          minWidth={120}
          propertyId="assignment"
          renderOption={RenderOptionType.UserCard}
          translateOptions
        />
      ),
      key: 'assignment.displayName',
      minWidth: 120,
      render: ({ assignment }) =>
        assignment?.[0] ? <UserTag user={assignment[0]} /> : null,
      width: 200,
    },
    {
      filterable: true,
      getValue: (task: Task) => (task.project ? task.project.name : ''),
      headerComponent: (
        <FilterHeader<Task, Project>
          defaultWidth={150}
          filterType={FilterHeaderType.Select}
          label="Project" // i18n
          minWidth={120}
          propertyId="project"
          subPropertyId="name"
        />
      ),
      key: 'project.name',
      render: ({ project }) =>
        project ? <MiddleEllipsis text={project.name} /> : '-',
      width: 200,
    },
    {
      filterable: true,
      getValue: (task: Task) =>
        task.endTime ? getFormattedDate(task.endTime).label : '-',
      headerComponent: (
        <FilterHeader<Task, {}>
          defaultWidth={150}
          filterType={FilterHeaderType.Date}
          label="Deadline" // i18n
          minWidth={120}
          propertyId="endTime"
        />
      ),
      key: 'endTime',
      render: (task) =>
        isOverdue(task) ? (
          <Chip
            color="error"
            label={dayjs(task.endTime).format('ll')}
            size="small"
            variant="filled"
          />
        ) : isUpcoming(task) ? (
          <Chip
            color="warning"
            label={dayjs(task.endTime).format('ll')}
            size="small"
            variant="filled"
          />
        ) : (
          <Timestamp date={task.endTime} />
        ),
      width: 200,
    },
    {
      filterable: true,
      getValue: (task: Task) =>
        t(getAction(task.documentAction || '')) as string,
      headerComponent: (
        <FilterHeader<Task, {}>
          defaultWidth={120}
          filterType={FilterHeaderType.Select}
          getCustomValueCallback={(documentAction) =>
            t(getAction(documentAction))
          }
          label="Action" // i18n
          minWidth={60}
          propertyId="documentAction"
          translateOptions
        />
      ),
      key: 'documentAction',
      render: ({ documentAction }) => t(getAction(documentAction || '')),
      width: 120,
    },
    {
      filterable: true,
      getValue: (task: Task) => task.commentStatus,
      headerComponent: (
        <FilterHeader<Task, {}>
          defaultWidth={40}
          filterType={FilterHeaderType.Select}
          iconName="chatBubble"
          label="Comments" // i18n
          minWidth={40}
          propertyId="commentStatus"
          translateOptions
        />
      ),
      key: 'commentStatus',
      render: (task: Task) =>
        task.commentStatus
          ? task.commentStatus && (
              <CommentStatus
                commentStatus={task.commentStatus}
                hasPreviousComments={false}
              />
            )
          : null,
      width: 40,
    },
    {
      filterable: true,
      getValue: (task: Task) => task.createdByUser?.displayName,
      headerComponent: (
        <FilterHeader<Task, User>
          defaultWidth={150}
          filterType={FilterHeaderType.Select}
          label="Created by" // i18n
          minWidth={120}
          propertyId="createdByUser"
          renderOption={RenderOptionType.UserCard}
          subPropertyId="displayName"
        />
      ),
      key: 'createdByUser.displayName',
      render: ({ createdByUser }) =>
        createdByUser ? <UserTag user={createdByUser} /> : null,
      width: 120,
    },
    {
      filterable: true,
      getValue: (task: Task) =>
        task.createdAt ? getFormattedDate(task.createdAt).label : '',
      headerComponent: (
        <FilterHeader<Task, {}>
          defaultWidth={150}
          filterType={FilterHeaderType.Date}
          label="Created at" // i18n
          minWidth={120}
          propertyId="createdAt"
        />
      ),
      key: 'createdAt',
      render: ({ createdAt }) => <Timestamp date={createdAt} />,
      width: 120,
    },
    {
      filterable: true,
      getValue: (task: Task) =>
        task.completedAt ? getFormattedDate(task.completedAt).label : '',
      headerComponent: (
        <FilterHeader<Task, {}>
          defaultWidth={200}
          filterType={FilterHeaderType.Date}
          label="Completed at" // i18n
          minWidth={120}
          propertyId="completedAt"
        />
      ),
      key: 'completedAt',
      render: ({ completedAt }) => <Timestamp date={completedAt} />,
      width: 120,
    },
  ];

  return columns.filter((column) => {
    if (column.key === 'taskCategoryName') return true;
    if (column.key === 'project.name') return !hideProject;
    if (column.key === 'assignment.displayName') return !hideUser;
    return (
      column.key !== 'documentAction' || typeFilter.includes(TaskType.Document)
    );
  });
};
