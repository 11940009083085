import { Option } from '@pro4all/shared/types';

export function generateSeriesTasks(
  startDate: string,
  endDate: string,
  frequency: Option
): string[] {
  if (!frequency || !frequency.label) {
    return [];
  }

  const tasks: string[] = [];
  const currentDate = new Date(startDate);
  const end = new Date(endDate);

  // Set the time to avoid problems with daylight saving time or time shifts
  currentDate.setHours(12, 0, 0, 0);
  end.setHours(12, 0, 0, 0);

  let count = 0;

  while (currentDate <= end && count < 14) {
    const dayOfWeek = currentDate.getDay();

    switch (frequency.label) {
      case 'Daily':
        if (dayOfWeek !== 0 && dayOfWeek !== 6) {
          tasks.push(currentDate.toISOString().split('T')[0]);
          count++;
        }
        currentDate.setDate(currentDate.getDate() + 1);
        break;

      case 'Weekly':
        tasks.push(currentDate.toISOString().split('T')[0]);
        count++;
        currentDate.setDate(currentDate.getDate() + 7);
        break;

      case 'Every 2 weeks':
        tasks.push(currentDate.toISOString().split('T')[0]);
        count++;
        currentDate.setDate(currentDate.getDate() + 14);
        break;

      case 'Monthly': {
        tasks.push(currentDate.toISOString().split('T')[0]);
        count++;
        const day = currentDate.getDate();
        currentDate.setMonth(currentDate.getMonth() + 1);
        if (currentDate.getDate() !== day) currentDate.setDate(0);
        break;
      }

      case 'Yearly':
        tasks.push(currentDate.toISOString().split('T')[0]);
        count++;
        currentDate.setFullYear(currentDate.getFullYear() + 1);
        break;

      default:
        return tasks;
    }
  }
  return tasks;
}
