import { Resolver } from 'react-hook-form';

import {
  AttachmentInput as GqlAttachment,
  Message,
  ReferenceKind,
  ReferenceType,
} from '@pro4all/graphql';
import { isDefined } from '@pro4all/shared/utils';
import { isValidEmail } from '@pro4all/shared/utils/email';

import { toUserInfo } from '../../utils';
import { MessageFormFields, RecipientField } from '../types';

export const resolver: Resolver<MessageFormFields> = async (values) => {
  const validateEmails = (recipients: RecipientField[]) =>
    recipients.every((recipient) => {
      try {
        return (
          recipient.type !== ReferenceType.Email ||
          Boolean(isValidEmail(recipient.email))
        );
      } catch (e) {
        return false; // If there is an issue with regex, better safe than sorry that is not a valid email
      }
    });

  const errors: Record<string, { message?: string; type?: string }> = {};
  const valuesTo = values.to || [];

  if (!valuesTo.length) {
    errors['to'] = { type: 'required' };
  }

  ['to', 'cc', 'bcc'].forEach((field) => {
    const fieldValues = values[field as keyof typeof values] || [];
    if (
      fieldValues.length &&
      !validateEmails(fieldValues as RecipientField[])
    ) {
      errors[field] = { message: 'Not a valid email', type: 'pattern' };
    }
  });

  return Object.keys(errors).length
    ? { errors, values: {} }
    : { errors: {}, values };
};

export const createDefaultValues = ({
  message,
  dmsAttachments,
}: {
  dmsAttachments: GqlAttachment[];
  message: Message;
}): MessageFormFields => ({
  attachments: [...dmsAttachments, ...(message?.attachments || [])],
  bcc:
    message.references
      .filter(({ referenceKind }) => referenceKind === ReferenceKind.Bcc)
      .map(toUserInfo)
      .filter(isDefined) || [],
  body: message.body || null,
  cc:
    message.references
      .filter(({ referenceKind }) => referenceKind === ReferenceKind.Cc)
      .map(toUserInfo)
      .filter(isDefined) || [],
  mentions: message.references
    .filter(
      ({ referenceKind, referenceType }) =>
        referenceKind === ReferenceKind.Body &&
        referenceType === ReferenceType.Mention
    )
    .map(toUserInfo)
    .filter(isDefined),
  messageId: message.id,
  newFiles: [],
  subject: message.subject || '',
  to:
    message.references
      .filter(({ referenceKind }) => referenceKind === ReferenceKind.To)
      .map(toUserInfo)
      .filter(isDefined) || [],
});
