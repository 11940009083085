import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { useUserFolderPermissions } from '@pro4all/documents/data-access';
import { SnackbarFolderDeleted } from '@pro4all/documents/ui/snackbars';
import { Folder, useDeleteFolderMutation } from '@pro4all/graphql';
import { ActionProps, useCentralActions } from '@pro4all/shared/actions';
import { useRouting } from '@pro4all/shared/routing-utils';

import { useFolderTreeContextOuter } from '../tree/FolderTreeProviderOuter';
import { encodePath } from '../utils/encodePath';
import { useFolderPath } from '../utils/useFolderPath';

export const useDeleteFolderAction = (folder: Folder) => {
  const { goTo, searchParams } = useRouting();
  const { t } = useTranslation();
  const { ACTION_DELETE } = useCentralActions();
  const { deleteDMSItem, restoreFolder } = useFolderTreeContextOuter();

  const { path, projectId, docsRoute } = useFolderPath();

  const { enqueueSnackbar } = useSnackbar();

  const { hasFolderPermission } = useUserFolderPermissions({
    folderId: folder?.id,
  });

  const [deleteFolderMutation] = useDeleteFolderMutation({
    onCompleted: () => {
      enqueueSnackbar(
        <SnackbarFolderDeleted
          folderId={folder.id}
          folderName={folder.name}
          restoreFolderToUpdateState={restoreFolder}
        />
      );

      if (path[path.length - 1] === encodeURIComponent(folder.name)) {
        goTo(docsRoute, {
          params: {
            path: encodePath(path.slice(0, path.length - 1)),
            projectId,
          },
        });
      }
    },
    onError: () => {
      enqueueSnackbar(t('Something went wrong'));
    },
  });

  const handleDeleteFolder = async () => {
    await deleteFolderMutation({
      variables: { id: folder.id },
    });
    deleteDMSItem(folder.id);
    searchParams.clear();
  };

  const deleteFolderMenuItem: ActionProps = {
    ...ACTION_DELETE,
    dataTestId: 'delete-folder',
    disabled: !hasFolderPermission('DeleteFolder'),
    key: 'delete-folder',
    onClick: handleDeleteFolder,
  };

  return { deleteFolderMenuItem };
};
