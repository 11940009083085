import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { StandardDateRange } from '@pro4all/graphql';
import { delimiters, FilterBaseProps } from '@pro4all/shared/search-utils';
import { useTakePrimaryThemeColor } from '@pro4all/shared/themes';
import { Option } from '@pro4all/shared/types';

import { ResultMatch } from '../../results/';
import { ContentWrap } from '../Filter.styles';
import { FilterBreadcrumb } from '../FilterBreadcrumb';
import { FilterOptions } from '../FilterOptions';
import { standardRangeNames } from '../records';
import { useFilters } from '../utils/';

import { IconRight, StyledLi } from './Styles';

export const DateFilter: React.FC<FilterBaseProps> = ({
  metaDataKey,
  value,
  type,
}) => {
  const { t } = useTranslation();
  const { takePrimaryThemeColor } = useTakePrimaryThemeColor();
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);

  const { setFilterValue } = useFilters();

  const options: Option[] = Object.values(StandardDateRange).map((range) => ({
    iconName:
      selectedOption && selectedOption?.id === range
        ? 'checkCircleOutlined'
        : undefined,
    id: range,
    label: standardRangeNames[range],
  }));
  const customRangeOption = {
    id: `${type}-custom-option`,
    label: t('Custom'),
  };

  const ids = value.split(delimiters.multiSelectOptions);
  const initialOption =
    options.find((option) => ids.includes(option.id)) || customRangeOption;

  const onSelect = (option: Option) => {
    if (selectedOption?.id === option.id) setSelectedOption(null);
    setSelectedOption(option);
    setFilterValue({ metaDataKey, type, value: option.id });
  };

  useEffect(() => {
    if (!value) {
      setSelectedOption(null);
    } else if (!selectedOption) {
      setSelectedOption(initialOption);
    }
  }, [initialOption, selectedOption, value]);

  const renderOptions = () => {
    const standardRangeOptions = options.map((option) => (
      <StyledLi
        $active={Boolean(selectedOption?.id === option.id)}
        $disabled={Boolean(selectedOption)}
        key={option.id}
        onClick={() => onSelect(option)}
      >
        <ResultMatch text={t(option.label)} />
        {selectedOption?.id === option.id && (
          <IconRight
            color={takePrimaryThemeColor ? 'primary' : 'secondary'}
            iconName="check"
          />
        )}
      </StyledLi>
    ));
    return [...standardRangeOptions];
  };

  return (
    <ContentWrap>
      <FilterBreadcrumb metaDataKey={metaDataKey} />
      <FilterOptions loading={false} renderOptions={renderOptions} />
    </ContentWrap>
  );
};
