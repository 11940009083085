import { useMemo } from 'react';

import { Task } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';
import { OptimisticResponseProvider } from '@pro4all/shared/ui/table';
import { TaskSidebar } from '@pro4all/workflow/ui/task-sidebar';

export const useTaskDetails = () => {
  const { searchParams } = useRouting();

  const createTask = searchParams.is('action', 'createTask');
  const editTask = searchParams.is('action', 'editTask');
  const viewTask = searchParams.is('action', 'viewTask');

  const TaskDetails = useMemo(
    () => () =>
      createTask || viewTask || editTask ? (
        <OptimisticResponseProvider<Task>>
          <TaskSidebar />
        </OptimisticResponseProvider>
      ) : null,
    [createTask, viewTask, editTask]
  );

  return { TaskDetails, createTask, editTask, viewTask };
};
