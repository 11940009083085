import React, { useContext, useMemo, useState } from 'react';

type ColumnSizes = Record<React.Key, number>;

interface DocumentsContextValues {
  docTableColumnSizes: ColumnSizes;
  openedSmartFolderName: string | null;
  setDocTableColumnSizes?: (value: ColumnSizes) => void;
  setOpenedSmartFolderName?: (value: string | null) => void;
  setTableScrollPositionLeft?: (value: number) => void;
  setVersionPaneExpanded?: (value: boolean) => void;
  tableScrollPositionLeft: number;
  versionPaneExpanded: boolean;
}

const DocumentsContext = React.createContext<DocumentsContextValues>({
  docTableColumnSizes: null,
  openedSmartFolderName: null,
  tableScrollPositionLeft: null,
  versionPaneExpanded: false,
});

export function useDocumentsContext() {
  return useContext<DocumentsContextValues>(DocumentsContext);
}

export const DocumentsContextProvider: React.FC = ({ children }) => {
  const [versionPaneExpanded, setVersionPaneExpanded] = useState(false);
  const [docTableColumnSizes, setDocTableColumnSizes] = useState(null);
  const [tableScrollPositionLeft, setTableScrollPositionLeft] = useState(null);
  const [openedSmartFolderName, setOpenedSmartFolderName] = useState<
    string | null
  >(null);

  const values = useMemo(
    () => ({
      docTableColumnSizes,
      openedSmartFolderName,
      setDocTableColumnSizes,
      setOpenedSmartFolderName,
      setTableScrollPositionLeft,
      setVersionPaneExpanded,
      tableScrollPositionLeft,
      versionPaneExpanded,
    }),
    [
      openedSmartFolderName,
      setOpenedSmartFolderName,
      docTableColumnSizes,
      setDocTableColumnSizes,
      setTableScrollPositionLeft,
      setVersionPaneExpanded,
      tableScrollPositionLeft,
      versionPaneExpanded,
    ]
  );

  return (
    <DocumentsContext.Provider value={values}>
      {children}
    </DocumentsContext.Provider>
  );
};
