import { customColors } from '@pro4all/shared/themes';
import { Icon, IconName } from '@pro4all/shared/ui/icons';
import { OFFICE_ICON_BY_EXTENSION } from '@pro4all/shared/utils';

import * as Styled from './DocumentIcon.styles';

export const DocumentIcon = ({
  extension,
  isExpected,
}: {
  extension?: string | null;
  isExpected: boolean | null;
}) => {
  let backgroundColor: string;

  const filteredExtension =
    extension?.replace('.', '')?.slice(0, Math.min(4, extension?.length)) ?? '';

  const fontColor = WHITE_EXTENSIONS.includes(filteredExtension)
    ? customColors.white
    : customColors.black;

  switch (filteredExtension) {
    case 'doc':
    case 'docx':
      backgroundColor = customColors.blueAccent;
      break;
    case 'ppt':
    case 'pptx':
      backgroundColor = customColors.orangeAccent;
      break;
    case 'png':
    case 'jpg':
    case 'jpeg':
      backgroundColor = customColors.purpleShapeShadow;
      break;
    case 'csv':
    case 'xls':
    case 'xlsx':
      backgroundColor = customColors.greenShapeShadow;
      break;
    case 'dwg':
    case 'ifc':
    case 'rvt':
      backgroundColor = customColors.warning;
      break;
    case 'zip':
      backgroundColor = customColors.grey700;
      break;
    case 'pdf':
      backgroundColor = customColors.errorAlert;
      break;
    case 'txt':
      backgroundColor = customColors.grey400;
      break;
    default:
      backgroundColor = customColors.grey300;
  }

  if (OFFICE_ICON_BY_EXTENSION[filteredExtension]) backgroundColor = 'none';

  return (
    <Styled.Container color={backgroundColor} opacity={isExpected ? '40%' : ''}>
      {OFFICE_ICON_BY_EXTENSION[filteredExtension] ? (
        <Icon
          iconName={OFFICE_ICON_BY_EXTENSION[filteredExtension] as IconName}
        />
      ) : (
        <Styled.Text color={fontColor}>
          {filteredExtension.toUpperCase()}
        </Styled.Text>
      )}
    </Styled.Container>
  );
};

const WHITE_EXTENSIONS = [
  'csv',
  'doc',
  'docx',
  'jpeg',
  'jpg',
  'pdf',
  'png',
  'ppt',
  'pptx',
  'xls',
  'xlsx',
  'zip',
];
