import styled from 'styled-components';

import { Box } from '@pro4all/shared/mui-wrappers';
import { Icon } from '@pro4all/shared/ui/icons';
import { Text } from '@pro4all/shared/ui/typography';

export const LevelCard = styled(Box)<{
  $isChecked: boolean;
  $takePrimaryThemeColor: boolean;
}>`
  && {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 518px;
    width: 350px;
    padding: ${({ theme }) => theme.spacing(4)};

    border: ${({ $isChecked, $takePrimaryThemeColor, theme }) =>
      $isChecked
        ? `1px solid ${
            $takePrimaryThemeColor
              ? theme.palette.primary.main
              : theme.palette.secondary.main
          }`
        : `1px solid ${theme.palette.divider}`};

    border-radius: 10px;
    margin-bottom: ${({ theme }) => theme.spacing(5)};
    &:not(:last-child) {
      margin-right: ${({ theme }) => theme.spacing(5)};
    }
    &:hover {
      cursor: pointer;
    }
  }
`;

export const Title = styled(Text)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing(1)};
  }
`;

export const Subtitle = styled(Text)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing(2)};
  }
`;

export const Description = styled(Text)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing(2)};
  }
`;

export const Price = styled(Text)`
  && {
    margin-right: ${({ theme }) => theme.spacing(1)};
  }
`;

export const BulletIcon = styled(Icon)`
  margin-right: ${({ theme }) => theme.spacing(1)};
`;

export const Chip = styled(Box)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing(1)};
    &:not(:last-child) {
      margin-right: ${({ theme }) => theme.spacing(1)};
    }
    width: fit-content;
  }
`;

export const ZoneWrapper = styled(Box)`
  && {
    display: flex;
    flex-direction: column;
  }
`;
