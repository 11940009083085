import { useRouting } from '@pro4all/shared/routing-utils';

interface NavigateToTBQResultsParams {
  isOrganizationLevel: boolean;
  objectId: string;
  projectId?: string;
  taskId: string;
}

interface NavigateToTBQBrandcheckMultiResultsParams {
  isOrganizationLevel: boolean;
  objectId: string;
  projectId?: string;
}

export const useNavigateToTBQResults = ({
  isOrganizationLevel,
  objectId,
  projectId,
  taskId,
}: NavigateToTBQResultsParams) => {
  const { goTo } = useRouting();

  return () => {
    const routeName = isOrganizationLevel
      ? 'objectTBQResultsUrl'
      : 'projectObjectTBQResults';
    const goToParams = isOrganizationLevel
      ? { params: { objectId, resultSet: 'Victims' } }
      : { params: { objectId, projectId, resultSet: 'Victims' } };
    goTo(routeName, {
      ...goToParams,
      searchParams: { taskId },
    });
  };
};

export const useNavigateToTBQMultiBrandcheckResults = ({
  isOrganizationLevel,
  objectId,
  projectId,
}: NavigateToTBQBrandcheckMultiResultsParams) => {
  const { goTo } = useRouting();

  return () => {
    const routeName = isOrganizationLevel
      ? 'orgTBQMultiBrandcheckResultsUrl'
      : 'projectObjectTBQMultiBrandcheckResults';
    const goToParams = isOrganizationLevel
      ? { params: { objectId, resultSet: 'Victims' } }
      : { params: { objectId, projectId, resultSet: 'Victims' } };
    goTo(routeName, {
      ...goToParams,
    });
  };
};
