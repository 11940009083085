import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { useChangeSubscriptionMutation } from '@pro4all/graphql';
import { Routes } from '@pro4all/shared/config';

import { useSubscriptionAnalytics } from '../hooks/useSubscriptionAnalytics';
import { useSubscriptionHelpers } from '../hooks/useSubscriptionHelpers';

export const useSubmitChangeSubscription = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [changeSubscription] = useChangeSubscriptionMutation();
  const { trackLicenseSelected } = useSubscriptionAnalytics();

  // In case user continues cancelled license there's possibly no selectedSubscriptionLevel.
  // In that case we take the ID from the current subscription.
  const { getSubscriptionLevel } = useSubscriptionHelpers();
  const currentSubscriptionId: string = getSubscriptionLevel()?.id || '';

  return async (selectedSubscriptionLevel: string) => {
    trackLicenseSelected({
      licenseId: selectedSubscriptionLevel || currentSubscriptionId,
    });
    const response = await changeSubscription({
      variables: {
        id: selectedSubscriptionLevel || currentSubscriptionId,
        locale: window.localStorage.getItem('i18nextLng') || 'nl',
        redirectUrl: `${window.location.host}${Routes.orgSettingsLicense}?landing=true`,
      },
    });
    if (response.data?.changeSubscription?.mollieRedirect) {
      window.location.href = response.data.changeSubscription.mollieRedirect;
    } else {
      enqueueSnackbar(t('Something went wrong'));
    }
  };
};
