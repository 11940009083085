import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Message, MessageStatus } from '@pro4all/graphql';
import { ActionProps, useCentralActions } from '@pro4all/shared/actions';
import { Routes } from '@pro4all/shared/config';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { useMatchAny } from '@pro4all/shared/hooks';
import { useRouting } from '@pro4all/shared/routing-utils';
import {
  useTableCheck,
  useTableContextMenuRowContext,
} from '@pro4all/shared/ui/table';

import { useOptimisticDeleteDraftBatch } from '../mutation-utils/apollo-cache/useOptimisticDeleteDraftBatch';
import { useOptimisticMarkMessage } from '../mutation-utils/apollo-cache/useOptimisticMarkMessage';
export function useMessageActions() {
  const {
    ACTION_CREATE,
    ACTION_MARK_READ,
    ACTION_MARK_UNREAD,
    ACTION_DISCARD_DRAFT,
  } = useCentralActions();
  const { contextMenuRow: ctxMessage } =
    useTableContextMenuRowContext<Message>();
  const { t } = useTranslation();
  const { goTo, params } = useRouting();
  const { checkedRows: selectedMessages, uncheckAllRows } =
    useTableCheck<Message>();

  const [markMessage] = useOptimisticMarkMessage();
  const [deleteDraftMessageBatch] = useOptimisticDeleteDraftBatch();

  const hasDraftFeature = useFeatureFlag('draft-messages');

  const allRead = !selectedMessages.some((message) => !message.read);
  const allUnread = !selectedMessages.some((message) => message.read);
  const [markMessageIsLoading, setMarkMessageIsLoading] = useState(false);
  const anySent = selectedMessages.some(
    (message) => message.status !== MessageStatus.Draft
  );

  const toMarkInput = (message: Message) => ({
    id: message.id,
    threadId: message.threadId,
  });

  const isDraftMessage = useMatchAny([
    Routes.orgDraftMessages,
    Routes.projectMessagesDraft,
  ]);

  const markAs = async (read: boolean, messages: Message[]) => {
    if (!messages.length) return;

    const isRead = (message: Message) => message.read;
    const isUnread = (message: Message) => !message.read;

    const filteredInput = read
      ? messages.filter(isUnread).map(toMarkInput)
      : messages.filter(isRead).map(toMarkInput);
    // TODO: Change to check message type when that part is merged

    try {
      setMarkMessageIsLoading(true);
      await markMessage({
        variables: {
          messages: filteredInput,
          read,
        },
      });
    } catch (error) {
      console.log(error);
    } finally {
      setMarkMessageIsLoading(false);
    }
    uncheckAllRows();
  };

  const discardDraft = async (messages: Message[]) => {
    if (!messages.length) return;
    try {
      setMarkMessageIsLoading(true);
      deleteDraftMessageBatch({
        variables: {
          messages: messages.map(toMarkInput),
        },
      });
    } catch (error) {
      console.log(error);
    } finally {
      setMarkMessageIsLoading(false);
    }
  };

  const onCreateMessage = () => {
    goTo(
      params.projectId ? 'projectMessagesBaseUrl' : 'organizationMessagesUrl',
      {
        params,
        searchParams: { action: 'createMessage' },
      }
    );
  };

  const contextMenuActions: ActionProps[] = [
    {
      ...ACTION_MARK_READ,
      hidden: Boolean(ctxMessage?.read),
      onClick: () => ctxMessage && markAs(true, [ctxMessage]),
    },
    {
      ...ACTION_MARK_UNREAD,
      hidden: Boolean(!ctxMessage?.read),
      onClick: () => ctxMessage && markAs(false, [ctxMessage]),
    },
  ];

  const defaultTableActions: ActionProps[] = [
    {
      ...ACTION_CREATE,
      ariaLabel: t('Create message'),
      dataTestId: 'new-message-button',
      key: 'new-message-button',
      label: t('Create message'),
      onClick: onCreateMessage,
      startIcon: 'prostreamChat',
    },
  ];

  const altTableActions: ActionProps[] = [
    {
      ...ACTION_MARK_READ,
      disabled: allRead || markMessageIsLoading,
      onClick: () => markAs(true, selectedMessages),
    },
    {
      ...ACTION_MARK_UNREAD,
      disabled: allUnread || markMessageIsLoading,
      onClick: () => markAs(false, selectedMessages),
    },
  ];

  // TODO: Once draft is released, add the discard draft action to the altTableActions array
  if (hasDraftFeature && isDraftMessage) {
    altTableActions.push({
      ...ACTION_DISCARD_DRAFT,
      disabled: anySent || markMessageIsLoading,
      onClick: () => discardDraft(selectedMessages),
    });
  }

  const tableActions = selectedMessages.length
    ? altTableActions
    : defaultTableActions;

  return { contextMenuActions, tableActions };
}
