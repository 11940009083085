import { useTranslation } from 'react-i18next';

import { AuthService } from '@pro4all/authentication/src/services/auth-service';
import { ActionProps } from '@pro4all/shared/actions';
import { useRouting } from '@pro4all/shared/routing-utils';
import { IconName } from '@pro4all/shared/ui/icons';

export const useActionLogin = ({
  icon = 'prostream',
  isInviteExistingUser = false,
  label = 'Login to Prostream',
  isSsoEnforced = false,
}: {
  icon?: IconName;
  isInviteExistingUser?: boolean;
  isSsoEnforced?: boolean;
  label?: string;
}) => {
  const { t } = useTranslation();
  const { goTo } = useRouting();

  const actionLogin: ActionProps = {
    ariaLabel: label,
    color: 'inherit',
    dataTestId: 'login-to-prostream',
    key: 'login-to-prostream',
    label: t(label),
    onClick: () =>
      isInviteExistingUser || isSsoEnforced
        ? AuthService.logout()
        : goTo('myProjectsOverview'),
    startIcon: icon,
  };

  return actionLogin;
};
