import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { createTabValue, Routes } from '@pro4all/shared/config';
import { Box } from '@pro4all/shared/mui-wrappers';
import {
  generateRoute,
  updateLsLastVisitedProjectRoute,
  useRouting,
} from '@pro4all/shared/routing-utils';
import { useIsMobileScreen } from '@pro4all/shared/themes';
import { SubTabs, Tab } from '@pro4all/shared/ui/general';

const Container = styled(Box)`
  && {
    @media print {
      display: none;
    }
  }
`;

export const Tabs = () => {
  const { t } = useTranslation();
  const { goTo, params } = useRouting();
  const isMobileScreen = useIsMobileScreen();
  const { projectId } = params;

  const hasTaskTab = Boolean(projectId);

  const base = projectId
    ? `${generateRoute('projectDocumentsBaseUrl', {
        params: { projectId },
      })}/`
    : `${Routes.orgDocsBaseUrl}`;

  return (
    <Container>
      <SubTabs base={base}>
        <Tab
          data-testid="dms-docs-tab"
          label={t('Folders')}
          onClick={() => {
            const goToRoute = projectId ? 'projectDocTableUrl' : 'orgDms';

            updateLsLastVisitedProjectRoute({
              overwriteCurrentValue: true,
              projectId,
              route: goToRoute,
            });
            goTo(goToRoute, {
              params,
            });
          }}
          value={createTabValue(Routes.foldersPath)}
        />
        {hasTaskTab && (
          <Tab
            data-testid="dms-tasks-tab"
            label={t('Tasks')}
            onClick={() => {
              goTo('projectDocTasks', {
                params: { projectId },
              });
              updateLsLastVisitedProjectRoute({
                overwriteCurrentValue: true,
                projectId,
                route: 'projectDocTasks',
              });
            }}
            value={Routes.tasks}
          />
        )}
        {!isMobileScreen && (
          <Tab
            data-testid="dms-tasks-tab"
            label={t('Notifications')}
            onClick={() => {
              const goToRoute = projectId
                ? 'projectNotificationsUrl'
                : 'orgNotificationsUrl';

              goTo(
                goToRoute,
                projectId
                  ? {
                      params,
                    }
                  : undefined
              );
              updateLsLastVisitedProjectRoute({
                overwriteCurrentValue: true,
                projectId,
                route: goToRoute,
              });
            }}
            value={createTabValue(Routes.documentNotifications)}
          />
        )}
      </SubTabs>
    </Container>
  );
};
