import React from 'react';

import { customColors } from '@pro4all/shared/themes';

export const AiIcon: React.FC = () => (
  <svg aria-hidden="true" focusable="false" viewBox="0 0 24 24">
    <path
      clipRule="evenodd"
      d="M19.5333 2.83333C19.5333 2.3731 19.1602 2 18.7 2C18.2398 2 17.8667 2.3731 17.8667 2.83333C17.8667 3.29357 17.4936 3.66667 17.0333 3.66667C16.5731 3.66667 16.2 4.03976 16.2 4.5C16.2 4.96024 16.5731 5.33333 17.0333 5.33333C17.4936 5.33333 17.8667 5.70643 17.8667 6.16667C17.8667 6.6269 18.2398 7 18.7 7C19.1602 7 19.5333 6.6269 19.5333 6.16667C19.5333 5.70643 19.9064 5.33333 20.3667 5.33333C20.8269 5.33333 21.2 4.96024 21.2 4.5C21.2 4.03976 20.8269 3.66667 20.3667 3.66667C19.9064 3.66667 19.5333 3.29357 19.5333 2.83333ZM14.6873 9.84203C14.4596 9.76113 14.2807 9.58167 14.2005 9.35375L11.9467 2.94929C11.9231 2.88205 11.8375 2.8 11.7 2.8C11.5625 2.8 11.4769 2.88205 11.4533 2.94929L9.19951 9.35376C9.11931 9.58167 8.94039 9.76113 8.71271 9.84203L3.15273 11.8175C3.03883 11.858 3 11.9435 3 12C3 12.0565 3.03883 12.142 3.15273 12.1825L8.71272 14.158C8.94039 14.2389 9.11931 14.4183 9.19951 14.6462L11.4533 21.0507C11.4769 21.118 11.5625 21.2 11.7 21.2C11.8375 21.2 11.9231 21.118 11.9467 21.0507L14.2005 14.6462C14.2807 14.4183 14.4596 14.2389 14.6873 14.158L20.2473 12.1825C20.3612 12.142 20.4 12.0565 20.4 12C20.4 11.9435 20.3612 11.858 20.2473 11.8175L14.6873 9.84203Z"
      fill={customColors.warning}
    ></path>
  </svg>
);
