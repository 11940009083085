import { useMemo } from 'react';

import { AvailableThemes } from '../theme-provider/ThemeProvider';

let cachedThemeState: null | ReturnType<typeof calculateThemeState> = null;

const calculateThemeState = () => {
  // Parse URL search params to get theme query parameter
  const searchParams = new URLSearchParams(window.location.search);
  const themeParam = searchParams.get('theme')?.toLowerCase();

  const isThemeTbq =
    window.location.origin.includes('tbq') || themeParam === 'tbq';
  const isThemePave =
    window.location.origin.includes('pave') || themeParam === 'pave';

  const theme = isThemeTbq
    ? AvailableThemes.tbq
    : isThemePave
    ? AvailableThemes.pave
    : AvailableThemes.pro4all;

  const isThemePro4all = theme === AvailableThemes.pro4all;

  return { isThemePave, isThemePro4all, isThemeTbq, theme };
};

export const useApplication = () =>
  useMemo(() => {
    if (!cachedThemeState) {
      cachedThemeState = calculateThemeState();
    }
    return cachedThemeState;
  }, []);
