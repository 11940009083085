import React from 'react';
import { useTranslation } from 'react-i18next';

import { Role, User, UserState } from '@pro4all/graphql';
import { useIsAdminInContext } from '@pro4all/shared/identity';
import { FilterHeaderType } from '@pro4all/shared/ui/filtering';
import { UserTag } from '@pro4all/shared/ui/identity-card';
import { Link } from '@pro4all/shared/ui/link';
import { MiddleEllipsis } from '@pro4all/shared/ui/middle-ellipsis';
import {
  ColumnProps,
  LabelHeader,
  StyledCellContent,
} from '@pro4all/shared/ui/table';
import { FilterHeader } from '@pro4all/shared/ui/table-column-filtering';
import { getFormattedDate, Timestamp } from '@pro4all/shared/ui/timestamp';

import { joinRoles, Roles } from '../components/Roles';

import { StatusChip } from './StatusChip';
import { useUserStatuses } from './useUserStatuses';

const isActive = (state: UserState) => state !== UserState.Deactivated;

export const useOrganizationColumns = (): ColumnProps<User>[] => {
  const { t } = useTranslation();
  const statuses = useUserStatuses();
  const isAdminInContext = useIsAdminInContext();

  const getRole = (roles: Role[]) =>
    joinRoles(roles?.map((role) => ({ ...role, name: t(role?.name) })));

  const columns = [
    {
      defaultSort: true,
      filterable: true,
      headerComponent: (
        <FilterHeader<User, {}>
          defaultWidth={300}
          filterType={FilterHeaderType.Text}
          label="Name" // i18n
          minWidth={100}
          propertyId="displayName"
          pullFromLocalStorage
        />
      ),
      key: 'displayName',
      render: (user: User) => <UserTag user={user} />,
      width: 300,
    },
    {
      filterable: true,
      headerComponent: (
        <FilterHeader<User, {}>
          defaultWidth={400}
          filterType={FilterHeaderType.Text}
          label="Email" // i18n
          minWidth={70}
          propertyId="email"
        />
      ),
      key: 'email',
      render: ({ email, state }: User) => (
        <StyledCellContent active={isActive(state)}>
          <MiddleEllipsis endLength={9} text={email} />
        </StyledCellContent>
      ),
      width: 400,
    },
    {
      filterable: true,
      getValue: ({ roles }: User) => getRole(roles),
      headerComponent: (
        <FilterHeader<User, Role>
          customCallbackId="roles.name"
          defaultWidth={300}
          filterType={FilterHeaderType.Select}
          getCustomValueCallback={(roles: Role[]) => getRole(roles)}
          label="Role"
          minWidth={75}
          propertyId="roles"
          translateOptions
        />
      ),
      headerRenderer: () => (
        <LabelHeader label="Role" /> // i18n
      ),
      key: 'roles.name',
      minWidth: 75,
      render: ({ roles, state }: User) => (
        <StyledCellContent active={isActive(state)}>
          <Roles roles={roles} />
        </StyledCellContent>
      ),
      width: 300,
    },
    {
      filterable: true,
      getValue: ({ state }: User) => statuses[state]?.label,
      headerComponent: (
        <FilterHeader<User, {}>
          defaultWidth={220}
          filterType={FilterHeaderType.Select}
          label="Invite status" // i18n
          minWidth={100}
          propertyId="state"
          translateOptions
        />
      ),
      key: 'state',
      render: ({ state }: User) => <StatusChip state={state} />,
      width: 220,
    },
    {
      filterable: true,
      getValue: (user: User) =>
        user.lastOnlineAt
          ? getFormattedDate(user.lastOnlineAt, 'lll')?.label
          : '-',
      headerComponent: (
        <FilterHeader<User, {}>
          defaultWidth={200}
          filterType={FilterHeaderType.Date}
          label="Last online" // i18n
          minWidth={120}
          propertyId="lastOnlineAt"
        />
      ),
      key: 'lastOnlineAt',
      render: ({ lastOnlineAt }: User) =>
        lastOnlineAt ? (
          <Timestamp date={lastOnlineAt} format="lll" />
        ) : (
          <p>-</p>
        ),
      width: 200,
    },
  ];

  if (isAdminInContext) {
    columns.push(
      {
        filterable: true,
        headerComponent: (
          <FilterHeader<User, {}>
            defaultWidth={200}
            filterType={FilterHeaderType.Select}
            label="Job title" // i18n
            minWidth={70}
            propertyId="jobTitle"
          />
        ),
        key: 'jobTitle',
        render: (member: User) => (
          <MiddleEllipsis endLength={9} text={member.jobTitle ?? ''} />
        ),
        width: 200,
      },
      {
        filterable: true,
        headerComponent: (
          <FilterHeader<User, {}>
            defaultWidth={200}
            filterType={FilterHeaderType.Select}
            label="Phone number" // i18n
            minWidth={70}
            propertyId="phoneNumber"
          />
        ),
        key: 'phoneNumber',
        render: (member: User) => (
          <StyledCellContent active={isAdminInContext}>
            <Link href={`tel:${member.phoneNumber}`}>{member.phoneNumber}</Link>
          </StyledCellContent>
        ),
        width: 200,
      }
    );
  }

  return columns;
};
