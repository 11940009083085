import { AuthService } from '@pro4all/authentication/src/services/auth-service';
import { EntityFolderPermission } from '@pro4all/graphql';
import { useGenericContext } from '@pro4all/shared/providers';
import { IconButton } from '@pro4all/shared/ui/buttons';

import { usePermissionsFolderMatrixContext } from './context/PermissionsFolderMatrixProvider';

interface Props {
  currentPermissions: EntityFolderPermission;
  folderId: string;
  projectId: string;
}

export const SelectAllPermissionsForFolderButton = ({
  folderId,
  currentPermissions,
}: Props) => {
  const { id: userId } = currentPermissions;
  const { userId: loggedInUserId } = AuthService.getProfile();
  const { toggleAllPermissions } = usePermissionsFolderMatrixContext();

  const {
    state: { showFinalPermissions },
  } = useGenericContext();

  return showFinalPermissions ? null : (
    <IconButton
      color="primary"
      dataTestId="selectPermissionsRow"
      disableBorder
      disabled={loggedInUserId === userId}
      iconName="arrowLeftRounded"
      onClick={async () => {
        toggleAllPermissions(folderId);
      }}
    />
  );
};
