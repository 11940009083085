import React from 'react';
import { useMatch } from 'react-router-dom';
import styled from 'styled-components';

import { Routes } from '@pro4all/shared/config';
import { useTheme } from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';
import { Icon } from '@pro4all/shared/ui/icons';
import { RowIconWrapRight } from '@pro4all/shared/ui/table';

import { useDragAndDropFolder } from './useDragAndDropFolder';

const FolderIcon = styled(Icon)`
  padding-right: ${({ theme }) => theme.spacing(1)};
`;

type FolderNameProps = {
  allowDragAndDrop?: boolean;
  hasDocuments: boolean;
  hasMoreOptions?: boolean;
  id: string;
  isParentOfSelected: boolean;
  name: string;
  parentFolderId: string;
  rowPath: string;
};

export const FolderName = ({
  id,
  name,
  parentFolderId,
  hasDocuments = false,
  hasMoreOptions = false,
  allowDragAndDrop = false,
  rowPath,
}: FolderNameProps) => {
  const theme = useTheme();
  const { drag, drop, hoversOverCurrent, isDragging } = useDragAndDropFolder({
    id,
    name,
    parentFolderId,
  });

  const matchOrgDms = useMatch(`${Routes.orgDms}*`);
  const matchProjDms = useMatch(`${Routes.projectDocTableUrl}*`);
  const match = matchOrgDms || matchProjDms;
  const isNestedFolder = match?.params?.['*']?.length;
  const path = isNestedFolder
    ? match?.params?.path + '/' + match?.params?.['*']
    : match?.params?.path;

  const hovering = hoversOverCurrent && !isDragging;

  const isSelected = path === rowPath;

  const Row = (
    <>
      <FolderIcon
        htmlColor={
          isSelected
            ? theme.palette.primary.main
            : hovering
            ? theme.palette.warning.dark
            : theme.palette.warning.main
        }
        iconName={
          isSelected && !hovering
            ? hasDocuments
              ? 'folderSelectedWithDocuments'
              : 'folderOpen'
            : hasDocuments
            ? 'folderWithDocuments'
            : 'folder'
        }
        label={name}
      />
      {hasMoreOptions && !isDragging && (
        <RowIconWrapRight>
          <Icon
            htmlColor={customColors.grey600}
            iconName="moreHoriz"
            onClick={(e) => {
              e.stopPropagation();
              const rect = e.currentTarget.getBoundingClientRect();
              const evt = new MouseEvent('contextmenu', {
                bubbles: true,
                clientX: rect.right,
                clientY: rect.top,
              });
              e.currentTarget.dispatchEvent(evt);
            }}
          />
        </RowIconWrapRight>
      )}
    </>
  );

  return allowDragAndDrop
    ? drag(drop(<div style={{ display: 'flex', width: '100%' }}>{Row}</div>))
    : Row;
};
