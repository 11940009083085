import React from 'react';
import {
  Navigate,
  Route,
  Routes as RouterDomRoutes,
  useMatch,
} from 'react-router-dom';

import { ScopeType, TemplateService, TemplateType } from '@pro4all/graphql';
import { useTemplatesInclude } from '@pro4all/metadata/data-access';
import { Routes } from '@pro4all/shared/config';
import { generateRoute, useRouting } from '@pro4all/shared/routing-utils';

import { RedirectToOrganizationTemplates } from '../components/form-template/RedirectToOrganizationTemplates';
import { TemplateFrame } from '../components/form-template/TemplateFrame';

import { MetaDataContextProvider } from './MetaDataContext';
import { MetaDataMain } from './MetaDataMain';
import { useIncludeOrgItems } from './useIncludeOrgItems';

export const ProjectQualityControl = () => {
  const routeForms = useMatch(Routes.projectMetadataQualityForms);
  const routeSnags = useMatch(Routes.projectMetadataQualitySnagsUrl);

  const routeTemplates = useMatch(
    Routes.projectMetaDataQualityControlTemplates
  );
  const routeFields = useMatch(Routes.projectMetadataQualityFieldsUrl);

  const includeOrgItems = useIncludeOrgItems();

  const templateService = TemplateService.QualityControl;
  const templateType = routeForms
    ? TemplateType.Form
    : routeSnags
    ? TemplateType.Snag
    : routeTemplates
    ? TemplateType.Section
    : routeFields
    ? TemplateType.Field
    : undefined;

  const qualityControlRoutesTemplates = [
    Routes.formTemplates,
    Routes.projectMetaDataQualityControlTemplates,
    Routes.snags,
  ];

  const qualityControlRoutesFields = [Routes.fieldTemplates];

  const qualityControlRoutesReportConfigurations = [
    Routes.projectMetaDataQualityControlReportConfigurations,
  ];

  const { selectedTemplate } = useTemplatesInclude({
    includeOrgItems,
    includeScope: true,
    templateService,
    templateType,
  });
  const { params, searchParams } = useRouting();
  const createContext = searchParams.is('action', 'createTemplate');
  const editContext = searchParams.is('action', 'editSet');
  const scope = selectedTemplate?.scope?.type;

  const { projectId } = params;
  const redirect = `${generateRoute('projectMetadataQualityForms', {
    params: { projectId },
  })}`;

  return (
    <MetaDataContextProvider
      templateService={templateService}
      templateType={templateType}
    >
      {!createContext && !editContext ? (
        <MetaDataMain
          isProject
          pathFields={qualityControlRoutesFields}
          pathReportConfigurations={qualityControlRoutesReportConfigurations}
          pathTemplates={qualityControlRoutesTemplates}
        />
      ) : (editContext && scope === ScopeType.Project) || createContext ? (
        <TemplateFrame selectedTemplate={selectedTemplate} />
      ) : (
        scope === ScopeType.Organization && (
          <RedirectToOrganizationTemplates
            selectedTemplate={selectedTemplate}
          />
        )
      )}
      <RouterDomRoutes>
        <Route element={<Navigate to={redirect} />} path="/" />
      </RouterDomRoutes>
    </MetaDataContextProvider>
  );
};
