import { deepmerge } from '@mui/utils';

import { ThemeOptions } from '@pro4all/shared/mui-wrappers';

import { customColors } from './customColors';
import { muiStyleOverridesGeneric } from './muiStyleOverridesGeneric';
import { muiStyleOverridesPro4all } from './muiStyleOverridesPro4all';
import { themeGeneric } from './themeGeneric';

export type ThemeColor = keyof typeof customColors;

export const themePro4all: ThemeOptions = deepmerge(themeGeneric, {
  components: deepmerge(muiStyleOverridesGeneric, muiStyleOverridesPro4all),
  palette: {
    action: {
      hover: 'rgba(100, 0, 0, 0.10)',
    },
    primary: {
      contrastText: customColors.white,
      dark: customColors.pro4allPrimaryDark,
      light: customColors.pro4allPrimaryLight,
      main: customColors.pro4allPrimaryMain,
    },
    secondary: {
      contrastText: customColors.grey900,
      dark: customColors.pro4allSecondaryDark,
      light: customColors.pro4allSecondaryLight,
      main: customColors.pro4allSecondaryMain,
    },
    text: {
      primary: customColors.grey900,
      secondary: customColors.grey700,
    },
  },
});
