import React from 'react';
import { useTranslation } from 'react-i18next';

import { customColors } from '@pro4all/shared/themes';
import { InstancePageHeaderProps } from '@pro4all/shared/types';

import { applyColor } from './applyColor';
import { Instance } from './elements';

export const InstancePageHeader = ({
  instanceId,
  items = [],
  reportOptions,
}: InstancePageHeaderProps) => {
  const { t } = useTranslation();

  if (items.length === 0) {
    return null;
  }

  return (
    <Instance.ItemList
      compactLists={reportOptions.compactLists}
      inlineLists={reportOptions.inlineLists}
    >
      {items.map((item) => {
        const { id, indicative, value, type, valueType } = item;
        const key = `${instanceId}_${id}`;

        if (value && type === 'Status' && indicative) {
          const color =
            valueType?.options?.find((option) => option.name === value)
              ?.color || customColors.purpleMonoHighlight;

          return (
            <Instance.Item key={key}>
              <Instance.Label>{t('Status1')}</Instance.Label>
              <Instance.Value>
                <Instance.Status background={color} color={applyColor(color)}>
                  {value}
                </Instance.Status>
              </Instance.Value>
            </Instance.Item>
          );
        }

        return <div key={key}></div>;
      })}
    </Instance.ItemList>
  );
};
