import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ActionProps } from '@pro4all/shared/actions';
import {
  Card as MuiCard,
  CardActionArea,
  CardHeader as MuiCardHeader,
  CardProps as MuiCardProps,
} from '@pro4all/shared/mui-wrappers';
import { ContextMenuInstance } from '@pro4all/shared/ui/context-menu-instance';
import { Icon, IconName } from '@pro4all/shared/ui/icons';

export interface ListCardProps extends MuiCardProps {
  disabledButtonBorder?: boolean;
  iconName?: IconName;
  menuItems?: ActionProps[];
  meta?: React.ReactNode;
  onClick?: () => void;
  onContextMenu?: () => void;
  title?: string;
  titleWithElement?: React.ReactNode;
}

const StyledCard = styled(MuiCard)<ListCardProps>`
  && {
    width: 100%;
    border-color: transparent;
    border-radius: '0px';
    padding: '0px';

    &:hover {
      background-color: transparent;
    }

    & + & {
      margin-top: ${({ theme }) => theme.spacing(1)};
    }
  }
`;

const CardHeader = styled(MuiCardHeader)`
  && {
    .MuiCardHeader-title {
      display: flex;
      align-items: center;
      margin-bottom: ${({ subheader }) =>
        subheader ? ({ theme }) => theme.spacing(1) : 0};
      word-break: break-all;

      > .MuiSvgIcon-root {
        margin-right: ${({ theme }) => theme.spacing(1)};
      }
    }

    .MuiCardHeader-action {
      align-self: center;
      margin: 0;
    }

    ${({ onClick }) => onClick && 'cursor: pointer;'}

    padding: ${({ theme }) => theme.spacing(1.5)};
  }
`;

const CardMetaList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
`;

const CardMetaItem = styled.li`
  &:not(:last-of-type) {
    margin-right: ${({ theme }) => theme.spacing(1)};
  }
`;

export const ListCard: React.FC<ListCardProps> = ({
  iconName = 'file',
  menuItems = [],
  disabledButtonBorder,
  meta,
  onClick,
  onContextMenu,
  title,
  titleWithElement,
}) => {
  const { t } = useTranslation();

  const cardMeta = meta && (
    <CardMetaList>
      {React.Children.map(meta, (child) => (
        <CardMetaItem>{child}</CardMetaItem>
      ))}
    </CardMetaList>
  );

  return (
    <StyledCard variant="outlined">
      <CardActionArea component="article" onClick={onClick}>
        <CardHeader
          action={
            <ContextMenuInstance
              disableBorder={disabledButtonBorder}
              menuItems={menuItems}
              name={title}
              onContextMenu={onContextMenu}
            />
          }
          avatar={<Icon iconName={iconName} />}
          subheader={cardMeta}
          subheaderTypographyProps={{
            variant: 'caption',
          }}
          title={title || titleWithElement || t('Untitled')}
          titleTypographyProps={{ variant: 'h6' }}
        />
      </CardActionArea>
    </StyledCard>
  );
};
