import React, { useEffect, useState } from 'react';

import { IdentityService } from '@pro4all/shared/api-services';
import { CardMedia } from '@pro4all/shared/mui-wrappers';
import { ProfilePictureEntity } from '@pro4all/shared/types';
import { Icon } from '@pro4all/shared/ui/icons';
import { ImagePlaceHolder } from '@pro4all/shared/ui/image-placeholder';

import { TableGridItem, THUMBNAIL_HEIGHT } from './types';

export const TableGridViewProfilePicture = ({
  item,
  profilePictureEntity,
  inScreen,
}: {
  inScreen: boolean;
  item: TableGridItem;
  profilePictureEntity: ProfilePictureEntity;
}) => {
  const [imageError, setImageError] = useState(false);
  const [url, setUrl] = useState<string | null>(null);

  useEffect(() => {
    if (inScreen && !url) {
      const profileUrl = IdentityService.getProfileUrl({
        id: item.id,
        profilePictureEntity: profilePictureEntity,
      });
      setUrl(profileUrl);
    }
  }, [inScreen]);

  return !inScreen && !url ? (
    <Icon iconName="snooze" />
  ) : url && !imageError ? (
    <CardMedia
      component="img"
      height={THUMBNAIL_HEIGHT}
      image={url}
      onError={() => setImageError(true)}
    />
  ) : (
    <ImagePlaceHolder randomShape shadow={false} />
  );
};
