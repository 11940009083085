import React from 'react';

import { useTakePrimaryThemeColor } from '@pro4all/shared/themes';

import { StyledProgress } from '../form/FormStyles';

type UploadProgressProps = {
  progress: number;
  showProgress: boolean;
};

export const UploadProgress: React.FC<UploadProgressProps> = ({
  progress,
  showProgress,
}) => {
  const { takePrimaryThemeColor } = useTakePrimaryThemeColor();

  return (
    <StyledProgress
      $visible={showProgress}
      color={takePrimaryThemeColor ? 'primary' : 'secondary'}
      data-testid="upload-progress"
      value={progress * 100}
      variant="determinate"
    />
  );
};
