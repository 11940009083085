import { Task } from '@pro4all/graphql';
import { OptimisticResponseProvider } from '@pro4all/shared/ui/table';

import { MyTasks } from '../components/tasks/MyTasks';

export const MyTasksProviderWrapper = () => (
  <OptimisticResponseProvider<Task>>
    <MyTasks />
  </OptimisticResponseProvider>
);
