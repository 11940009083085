import { VisualContext } from '@pro4all/graphql';
import { OptimisticResponseProvider } from '@pro4all/shared/ui/table';

import { DrawingsMain } from './DrawingMain';
import { DrawingsMainProps } from './types';

export const DrawingMainProviderWrapper = ({
  projectId,
}: DrawingsMainProps) => (
  <OptimisticResponseProvider<VisualContext>>
    <DrawingsMain projectId={projectId} />
  </OptimisticResponseProvider>
);
