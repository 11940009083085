import React, { useEffect, useRef, useState } from 'react';
import { Controller, FormProvider, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Descendant } from 'slate';
import { ReactEditor } from 'slate-react';

import {
  AttachmentInput,
  AttachmentList,
  AttachmentsUploadPreview,
  defaultEditorValues,
  ImageUploading,
  MentionButton,
  SlateEditor,
  stringToPlainText,
} from '@pro4all/communication/ui/general';
import {
  AttachmentContext,
  AttachmentInput as GqlAttachment,
  EmailActions,
  Message,
} from '@pro4all/graphql';
import { Routes, StorageKeys } from '@pro4all/shared/config';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Text } from '@pro4all/shared/ui/typography';
import { tryParseString } from '@pro4all/shared/utils';

import { useSubmitMessage } from '../mutation-utils/useSubmitMessage';

import { Header } from './header/Header';
import { useAttachmentHandlers } from './hooks/useAttachmentHandlers';
import { useAttachments } from './hooks/useAttachments';
import { useDraftHandlers } from './hooks/useDraftHandlers';
import { useEditor } from './hooks/useEditor';
import { useListenNavigation } from './hooks/useListenNavigation';
import { useMentionHandlers } from './hooks/useMentionHandlers';
import { useMessageForm } from './hooks/useMessageForm';
import { useMessageSignature } from './hooks/useMessageSignature';
import { createDefaultValues } from './message-utils/formHelpers';
import { MessageEditorButtons } from './MessageEditorButtons';
import { EditorWrap, SubjectWrap } from './MessageForm.styles';
import { MessageFormFields } from './types';

interface Props {
  focus?: boolean;
  message: Message;
  messageSignature?: string;
  readOnly?: boolean;
  type: 'main' | 'original' | 'related' | 'reply';
  withPlaceholder?: boolean;
}

// TODO: Fix this, start from useMessageForm and drill down

export const MessageForm: React.FC<Props> = ({
  focus = false,
  message,
  messageSignature,
  readOnly = false,
  type,
  withPlaceholder,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { t } = useTranslation();
  const {
    params: { projectId },
    searchParams,
  } = useRouting();

  const { enqueueSnackbar } = useSnackbar();

  const [bodyEdited, setBodyEdited] = React.useState<boolean>(false);

  const isSubmitted = searchParams.get('submitting');
  const viewMessage = useMatch(Routes.viewMessage);

  const submitDraft = useSubmitMessage({
    messageId: message.id,
    threadId: message.threadId,
  });

  const editDraft = useSubmitMessage({
    action: EmailActions.DraftEdit,
    messageId: message.id,
    parentId: message.parentId || '',
    threadId: message.threadId,
  });

  /* Initialize editor */
  const editor = useEditor();

  const focusSlate = () => {
    ReactEditor.focus(editor);
  };
  const blurSlate = () => {
    ReactEditor.blur(editor);
  };

  const originalSignature = useRef<string>(messageSignature || '');

  const attachmentInputId = `message-input-${message.id}`;
  const imageInputId = `image-input-upload-${message.id}`;

  const openFileInput = (currentInputId: string) => {
    const fileInput = document.getElementById(currentInputId);
    fileInput && fileInput.click();
  };

  const onUploadSuccess = () => form.trigger();

  const handleClickAddCollection = async () => {
    // TODO: Fix this, start from useMessageForm and drill down
    await addColectionFiles(
      (name, value, config) => form.setValue(name as any, value, config),
      form.getValues
    );
    form.trigger();
  };

  const handleDraftSuccess = async () => {
    enqueueSnackbar(t('Your message has been saved as a draft'));
  };
  const handleDraftError = () => {
    console.error('Failed to save draft');
  };

  const fromDms = searchParams.get('dmsAttachment') === 'true';

  const storageKey = `${StorageKeys.MESSAGE_ATTACHMENTS}-${
    projectId ?? 'organization'
  }`;
  const storageEntry = sessionStorage.getItem(storageKey);
  const dmsAttachments: GqlAttachment[] =
    fromDms && storageEntry ? JSON.parse(storageEntry) : [];
  if (!fromDms && storageEntry) localStorage.removeItem(storageKey);

  const defaultValues: MessageFormFields = createDefaultValues({
    dmsAttachments,
    message,
  });

  // There is an issue with typescript and react hook that gives
  // an error when compiling (you will see that the editor shows no error
  // but when compiling it will refuse to) with DeepMap.
  // Check this link: https://github.com/microsoft/TypeScript/issues/41953#issuecomment-1426266497
  // We can avoid this by avoiding the type and  using this example: https://react-hook-form.com/ts#Resolver
  // as the basis for our resolver

  const form = useMessageForm(
    defaultValues
  ) as UseFormReturn<MessageFormFields>;

  const {
    handleImageUpload,
    handleUpload,
    addColectionFiles,
    isImageUploading = false,
    isUploading = false,
    uploadProgress,
    isAddingCollection,
  } = useAttachments({
    editor,
    onUploadSuccess,
  });

  const isDraftEdit = message.status === 'Draft' && message.id !== 'new';
  const hasMessageBody = Boolean(message?.body?.length);

  const draftBody = form.getValues().body || '';
  const parsedDraftBody = tryParseString(draftBody) || defaultEditorValues;

  const messageBody: Descendant[] = hasMessageBody
    ? tryParseString(isDraftEdit ? draftBody : message.body || '') ||
      defaultEditorValues
    : parsedDraftBody;

  const value: Descendant[] = messageBody;

  const values = form.getValues();
  const { isDirty } = form.formState;

  const CustomButtons: React.ReactNode[] = [
    <MessageEditorButtons
      attachmentInputId={`message-input-${message.id}`}
      handleClickAddCollection={handleClickAddCollection}
      imageInputId={`image-input-upload-${message.id}`}
      isAddingCollection={isAddingCollection}
      isImageUploading={isImageUploading}
      isSubmitting={form.formState.isSubmitting}
      isUploading={isUploading}
      key={`message-editor-buttons-${message.id}`}
      openFileInput={openFileInput}
      t={t}
    />,
    <MentionButton key="mentionButton" />,
  ];

  const { handleMention } = useMentionHandlers(form);
  const { handleDeleteAttachment } = useAttachmentHandlers(form);

  const { handleDraftSaveManual, handleDebounceDraftSave, debouncedEditDraft } =
    useDraftHandlers({
      bodyEdited,
      editDraft,
      form,
      handleDraftError,
      handleDraftSuccess,
      isDraftEdit,
      isSubmitted: Boolean(isSubmitted),
      readOnly,
      submitDraft,
    });

  useEffect(
    () => () => {
      debouncedEditDraft.cancel();
    },
    [debouncedEditDraft]
  );

  useListenNavigation({
    bodyEdited,
    handleDraftSave: handleDraftSaveManual,
    isDirty,
    isSubmitted,
    isSubmitting: isSubmitting,
    readOnly,
  });

  useMessageSignature({
    editor,
    hasMessageBody,
    messageSignature: messageSignature || '',
    setOriginalSignature: (value: string) => {
      originalSignature.current = value; // Update the ref directly
    },
  });

  const bodyText = message?.body ? stringToPlainText(message.body) : null;
  const subject = message?.subject?.length
    ? message.subject
    : `(${t('No subject').toLowerCase()})`;

  return (
    //circumvent typescript error until we replace react-hook-form with formik
    //@ts-ignore
    <FormProvider<any> {...form}>
      <form>
        <EditorWrap
          $bgColor="white"
          $hasFocus={focus}
          $hasPadding={type !== 'main'}
        >
          <Header
            handleDebounceDraftSave={handleDebounceDraftSave}
            isSubmitting={isSubmitting}
            isUploading={isUploading}
            message={message}
            setIsSubmitting={setIsSubmitting}
          />
          {readOnly && (
            <SubjectWrap>
              <Text variant="h5">{subject}</Text>
            </SubjectWrap>
          )}
          {(Boolean(bodyText) || !readOnly) && (
            <Controller
              control={form.control}
              name="body"
              render={() => (
                <SlateEditor
                  CustomButtons={CustomButtons}
                  autofocus={focus}
                  displayGroups
                  editor={editor}
                  onBlur={blurSlate}
                  onChange={(value) => {
                    form.setValue('body', value);
                    form.trigger('body').then();
                    originalSignature.current !== form.getValues('body')
                      ? setBodyEdited(true)
                      : setBodyEdited(false);
                    handleDebounceDraftSave();
                  }}
                  onFocus={focusSlate}
                  onMention={handleMention}
                  placeholder="Start typing to compose your message. Type @ to mention someone"
                  readOnly={readOnly || isImageUploading}
                  showToolbar={!readOnly}
                  value={value}
                  withPlaceholder={withPlaceholder}
                />
              )}
            />
          )}
          <AttachmentInput
            id={attachmentInputId}
            multiple
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleUpload(event, form.setValue, form.getValues)
            }
            type="file"
          />
          <AttachmentInput
            id={imageInputId}
            multiple
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleImageUpload(
                event,
                (name, value, config) =>
                  form.setValue(name as any, value, config),
                form.getValues as any
              )
            }
            type="file"
          />
          <ImageUploading isLoading={isImageUploading} />
          {readOnly ? (
            <AttachmentList
              attachments={message.attachments}
              context={AttachmentContext.Msg}
              messageId={message.id}
            />
          ) : (
            <AttachmentsUploadPreview
              attachments={values.attachments}
              context={AttachmentContext.Msg}
              newFiles={values.newFiles}
              onDelete={handleDeleteAttachment}
              uploadProgress={uploadProgress.current || undefined}
            />
          )}
        </EditorWrap>
      </form>
    </FormProvider>
  );
};
