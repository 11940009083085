import { Route, Routes as RouterDomRoutes } from 'react-router-dom';

import { Drawing } from '@pro4all/quality-control/ui/floor-plan';
import { MapLinkingContextProvider } from '@pro4all/quality-control/ui/maps';
import { ReportingMain } from '@pro4all/quality-control/ui/report-options';
import {
  AnswersPercentageContextProvider,
  ResultSidebar,
} from '@pro4all/quality-control/ui/results';
import { Routes } from '@pro4all/shared/config';
import { PhotoProvider } from '@pro4all/shared/contexts';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { HierarchyEditorProvider } from '@pro4all/shared/hierarchy-editor';
import { Box } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Main } from '@pro4all/shared/ui/layout';
import { Loader } from '@pro4all/shared/ui/loader';

import { TBQMultiBrandcheckResults } from './tbq/results/TBQMultiBrandcheckResults';
import { TBQMultiRieResultsProviderWrapper } from './tbq/results/TBQMultiRieResultsProviderWrapper';
import { TBQResultsProviderWrapper } from './tbq/results/TBQResultsProviderWrapper';
import { TBQRieResultsProviderWrapper } from './tbq/results/TBQRieResultsProviderWrapper';
import * as Messages from './Messages';
import { ObjectsProvider } from './ObjectsContext';
import { ObjectsEdit } from './ObjectsEdit';
import { ObjectsView } from './ObjectsView';
import { useObjects } from './useObjects';

const ObjectsUI = () => {
  const objectsFeature = useFeatureFlag('objects');
  const { searchParams } = useRouting();
  const { error, loading, objects, ...context } = useObjects();

  if (searchParams.get('action') === 'createReport') {
    return (
      <MapLinkingContextProvider>
        <ReportingMain />
      </MapLinkingContextProvider>
    );
  }

  if (!objectsFeature) return <Box p={3}>Nothing to see here.</Box>;
  if (error) return <Messages.Error error={error} />;
  if (!objects.length && loading) return <Loader />;

  return (
    <Main>
      <ObjectsProvider
        error={error}
        loading={loading}
        objects={objects}
        {...context}
      >
        {searchParams.is('action', 'editObjects') ? (
          <ObjectsEdit />
        ) : (
          <ObjectsView />
        )}
      </ObjectsProvider>
    </Main>
  );
};

export const Objects = () => (
  <MapLinkingContextProvider>
    <RouterDomRoutes>
      {[Routes.objectTBQResults, Routes.projectObjectTBQResults].map((path) => (
        <Route element={<TBQResultsProviderWrapper />} key={path} path={path} />
      ))}

      {[
        Routes.objectTBQMultiBrandcheckResults,
        Routes.projectObjectTBQMultiBrandcheckResults,
      ].map((path) => (
        <Route
          element={<TBQMultiBrandcheckResults />}
          key={path}
          path={path}
        ></Route>
      ))}

      {[Routes.projectObjectTBQRieResults, Routes.objectTBQRieResults].map(
        (path) => (
          <Route
            element={<TBQRieResultsProviderWrapper />}
            key={path}
            path={path}
          />
        )
      )}
      {[
        Routes.projectObjectTBQMultiRieResults,
        Routes.objectTBQMultiRieResults,
      ].map((path) => (
        <Route
          element={<TBQMultiRieResultsProviderWrapper />}
          key={path}
          path={path}
        />
      ))}

      {[Routes.objectDrawing].map((path) => (
        <Route element={<Drawing />} key={path} path={path} />
      ))}

      {['/*'].map((path) => (
        <Route
          element={
            <HierarchyEditorProvider>
              <ObjectsUI />
            </HierarchyEditorProvider>
          }
          key={path}
          path={path}
        />
      ))}
    </RouterDomRoutes>
    <PhotoProvider>
      <AnswersPercentageContextProvider>
        <ResultSidebar />
      </AnswersPercentageContextProvider>
    </PhotoProvider>
  </MapLinkingContextProvider>
);
