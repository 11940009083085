import { Route, Routes as RouterDomRoutes } from 'react-router-dom';

import { SentOrInbox } from '@pro4all/graphql';
import { Routes } from '@pro4all/shared/config';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Column, Main } from '@pro4all/shared/ui/layout';

import { MessagesTable } from '../table/MessageTable';
import { MessageTabs } from '../tabs/MessageTabs';

export const MessageOverview = () => {
  const { params } = useRouting();
  const { projectId = '' } = params;

  return (
    <Main sx={{ overflow: 'hidden' }}>
      <Column>
        <MessageTabs />
        <RouterDomRoutes>
          {[Routes.all].map((path) => (
            <Route element={<MessagesTable />} key={path} path={path} />
          ))}
          {[Routes.inbox].map((path) => (
            <Route
              element={<MessagesTable sentOrInbox={SentOrInbox.Inbox} />}
              key={path}
              path={path}
            />
          ))}
          {[Routes.sent].map((path) => (
            <Route
              element={<MessagesTable sentOrInbox={SentOrInbox.Sent} />}
              key={path}
              path={path}
            />
          ))}
          {[Routes.draft].map((path) => (
            <Route
              element={<MessagesTable sentOrInbox={SentOrInbox.Draft} />}
              key={path}
              path={path}
            />
          ))}
        </RouterDomRoutes>
      </Column>
    </Main>
  );
};
