import React from 'react';

import type { Document } from '@pro4all/graphql';
import { ConversionStatus } from '@pro4all/graphql';
import { customColors } from '@pro4all/shared/themes';
import { Icon } from '@pro4all/shared/ui/icons';

export const ThreeDConversionStatus: React.FC<Document> = ({
  threeDConversionState,
  hasPreviousThreeDConversion,
}) => {
  const currentStatus =
    threeDConversionState?.status || hasPreviousThreeDConversion?.status;
  if (!currentStatus) return null;
  if (!Object.values(ConversionStatus).includes(currentStatus)) return null;

  const iconName =
    currentStatus === ConversionStatus.Failed
      ? 'error'
      : currentStatus === ConversionStatus.Success
      ? 'cube'
      : 'sync';
  const htmlColor =
    currentStatus === ConversionStatus.Failed
      ? customColors.errorAlert
      : hasPreviousThreeDConversion?.status
      ? customColors.grey500
      : customColors.grey700;
  return (
    <Icon
      htmlColor={htmlColor}
      iconName={iconName}
      opacity={
        hasPreviousThreeDConversion?.status &&
        threeDConversionState?.status !== ConversionStatus.Success
          ? '0.5'
          : '1'
      }
    />
  );
};
