import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';

export const Reply: React.FC<SvgIconProps> = (props) => (
  <SvgIcon
    {...props}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.82499 12L9.72499 14.9C9.92499 15.1 10.0208 15.3333 10.0125 15.6C10.0042 15.8667 9.89999 16.1 9.69999 16.3C9.49999 16.4833 9.26666 16.5792 8.99999 16.5875C8.73333 16.5958 8.49999 16.5 8.29999 16.3L3.69999 11.7C3.49999 11.5 3.39999 11.2667 3.39999 11C3.39999 10.7333 3.49999 10.5 3.69999 10.3L8.29999 5.7C8.48333 5.51667 8.71249 5.425 8.98749 5.425C9.26249 5.425 9.49999 5.51667 9.69999 5.7C9.89999 5.9 9.99999 6.1375 9.99999 6.4125C9.99999 6.6875 9.89999 6.925 9.69999 7.125L6.82499 10H16C17.3833 10 18.5625 10.4875 19.5375 11.4625C20.5125 12.4375 21 13.6167 21 15V18C21 18.2833 20.9042 18.5208 20.7125 18.7125C20.5208 18.9042 20.2833 19 20 19C19.7167 19 19.4792 18.9042 19.2875 18.7125C19.0958 18.5208 19 18.2833 19 18V15C19 14.1667 18.7083 13.4583 18.125 12.875C17.5417 12.2917 16.8333 12 16 12H6.82499Z"
      fill={customColors.black}
      fillOpacity="0.56"
    />
  </SvgIcon>
);
