import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useFolderTreeContextOuter } from '@pro4all/documents/ui/folders';
import { Folder } from '@pro4all/graphql';
import { useTheme } from '@pro4all/shared/mui-wrappers';
import { Icon } from '@pro4all/shared/ui/icons';
import { MiddleEllipsis } from '@pro4all/shared/ui/middle-ellipsis';

export function TreeBreadcrumbs() {
  const theme = useTheme();

  const [activeFolder, setActiveFolder] = useState<Folder | null>(null);
  const { state: treeState } = useFolderTreeContextOuter();
  const path = treeState?.path;

  useEffect(() => {
    const activeFolder = treeState.folders.find((item) => item.path === path);
    setActiveFolder(activeFolder || null);
  }, [treeState, path]);

  return (
    <>
      <Icon htmlColor={theme.palette.warning.main} iconName="folder"></Icon>
      <TreeBreadcrumbsMiddleEllipsis
        endLength={40}
        text={activeFolder?.path ? activeFolder?.path : ''}
      ></TreeBreadcrumbsMiddleEllipsis>
    </>
  );
}

const TreeBreadcrumbsMiddleEllipsis = styled(MiddleEllipsis)`
  align-items: center;
  margin-left: ${({ theme }) => theme.spacing(1)};
`;

export default TreeBreadcrumbs;
