import { Template } from '@pro4all/graphql';
import { OptimisticResponseProvider } from '@pro4all/shared/ui/table';

import { ProjectDocuments } from './ProjectDocuments';

export const ProjectDocumentsProviderWrapper = () => (
  <OptimisticResponseProvider<Template>>
    <ProjectDocuments />
  </OptimisticResponseProvider>
);
