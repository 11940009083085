import { ApiKey } from '@pro4all/graphql';
import { OptimisticResponseProvider } from '@pro4all/shared/ui/table';

import { ApiKeysTable } from './ApiKeysTable';

export const ApiKeysTableProviderWrapper = () => (
  <OptimisticResponseProvider<ApiKey>>
    <ApiKeysTable />
  </OptimisticResponseProvider>
);
