import { useTranslation } from 'react-i18next';

import { Serie } from '@pro4all/graphql';
import { Box } from '@pro4all/shared/mui-wrappers';
import { Icon } from '@pro4all/shared/ui/icons';
import { PropertyList } from '@pro4all/shared/ui/property-list';
import { Tooltip } from '@pro4all/shared/ui/tooltip';
import { Text } from '@pro4all/shared/ui/typography';

import { LabelRow, LinksContainer } from '../styles/series.styles';
import { getSeriesProperties } from '../utils/series-properties.utils';

import { PlannedTasks } from './PlannedTasks';

interface Props {
  serie: Serie;
}

export const SeriesProperties: React.FC<Props> = ({ serie }) => {
  const { t } = useTranslation();

  const properties = getSeriesProperties(serie, t);

  return (
    <>
      <Box px={3}>
        <PropertyList items={properties} orientation="horizontal" />
      </Box>
      <LinksContainer>
        <Box mb={5} mt={5} mx={3}>
          <LabelRow>
            <Text variant="h4">{t('Planned tasks')}</Text>
            <Tooltip
              title={t(
                'Create new series allows you to plan ahead tasks. You can set a max of 14 tasks.'
              )}
            >
              <Icon iconName="info" />
            </Tooltip>
          </LabelRow>
          {serie.id && <PlannedTasks serieId={serie.id} />}
        </Box>
      </LinksContainer>
    </>
  );
};
