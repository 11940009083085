import { Project } from '@pro4all/graphql';
import { TrackingEvent } from '@pro4all/shared/config';
import {
  getLastVisitedProjectRoute,
  updateLsLastVisitedProjectRoute,
  useRouting,
} from '@pro4all/shared/routing-utils';
import { useAnalytics } from '@pro4all/shared/vendor';

import { isExpired } from './utils';

export const useOnRowClick = () => {
  const { track } = useAnalytics();
  const { goTo } = useRouting();

  const onRowClick = (row: Project) => {
    if (!isExpired(row.organization?.expireDate)) {
      const projectId = row.id;
      track(TrackingEvent.ProjectSelected, { projectId });
      updateLsLastVisitedProjectRoute({
        overwriteCurrentValue: false,
        projectId,
        route: 'projectTasks',
      });
      const { params, route } = getLastVisitedProjectRoute(projectId) || {};
      goTo(route || 'projectTasks', {
        params: { ...params, projectId },
      });
    }
  };

  return { onRowClick };
};
