import { useTranslation } from 'react-i18next';

import { TbqBrandcheckMultiResult } from '@pro4all/graphql';
import { Box } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Button, IconButton } from '@pro4all/shared/ui/buttons';

import {
  BrandcheckProgressBarType,
  calculateAveragesByObject,
} from './brandcheckMultiAverages';
import {
  BarPercentageContainer,
  ClickableGoToObjectProgressBar,
  GotoObjectText,
  GoToObjectWrapper,
  Percentage,
  ProgressBarsPerObjectWrapper,
  SubTitle,
} from './Progressbar.styles';

interface BrandcheckMultiDashboardDetailedProgressProps {
  brandcheckMultiResult: TbqBrandcheckMultiResult[];
  id: string | null;
  progressBarType: BrandcheckProgressBarType | null;
}

export const BrandcheckMultiDashboardDetailedProgress: React.FC<
  BrandcheckMultiDashboardDetailedProgressProps
> = ({ id, brandcheckMultiResult, progressBarType }) => {
  const { t } = useTranslation();
  const {
    searchParams,
    goTo,
    params: { projectId },
  } = useRouting();

  if (!id || !brandcheckMultiResult) return null;

  const goalOrCategoryName = searchParams.get('goalOrCategoryName');

  if (!progressBarType) return null;

  const filteredResults = brandcheckMultiResult.filter((scan) =>
    progressBarType === BrandcheckProgressBarType.Category
      ? scan.categories.some((category) => category.id === id)
      : scan.operationGoals.some((goal) => goal.id === id)
  );

  const averagesByObject = calculateAveragesByObject(
    filteredResults,
    id,
    progressBarType
  );

  const handleGoTo = (objectId: string) => {
    const routeName = projectId ? 'projectObjectTbq' : 'objectTasks';
    const projectIdParam = projectId ? projectId : '';

    goTo(routeName, {
      params: {
        objectId: objectId,
        projectId: projectIdParam,
      },
    });
  };

  return (
    <ProgressBarsPerObjectWrapper>
      <Box alignItems="center" display="flex">
        <SubTitle>
          {progressBarType === BrandcheckProgressBarType.Category
            ? 'Categorie'
            : 'Operationele doelen'}{' '}
          <strong>{goalOrCategoryName}</strong>
        </SubTitle>
        <IconButton
          ariaLabel={t(
            'The dashboard shows the average score of all scans for an object in the selected category/operational goals.'
          )}
          color="default"
          disableBorder
          iconName="helpOutline"
        />
      </Box>
      {averagesByObject.map((result) => (
        <>
          <GotoObjectText>
            {`${t('Object')}: `}
            <GoToObjectWrapper>
              <Button
                aria-label={result.objectName}
                color="inherit"
                data-testid="close-instance"
                onClick={() => handleGoTo(result.objectId)}
                variant="text"
              >
                {result.objectName}
              </Button>
            </GoToObjectWrapper>
          </GotoObjectText>
          <BarPercentageContainer>
            <ClickableGoToObjectProgressBar
              max={100}
              onClick={() => handleGoTo(result.objectId)}
              value={Math.min(
                Math.max(2, Math.ceil(result.averagePercentage * 100), 1)
              )}
            />
            <Percentage>{`${Math.ceil(
              result.averagePercentage * 100
            )}%`}</Percentage>
          </BarPercentageContainer>
        </>
      ))}
    </ProgressBarsPerObjectWrapper>
  );
};
