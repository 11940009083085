import { Route, Routes as RouterDomRoutes } from 'react-router-dom';

import { PrivateRoute } from '@pro4all/authentication/src/components';
import { useOrganizationContext } from '@pro4all/organization/context';
import { useSubscriptionHelpers } from '@pro4all/settings/ui/src/subscription';
import { Routes } from '@pro4all/shared/config';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { useSubscriptionRestriction } from '@pro4all/shared/hooks';
import { BigMessageNoSubscriptionAccess } from '@pro4all/shared/ui/messages';

import { MetadataTabs } from '../components/tabs/MetadataTabs';

import { TemplatesTasksProviderWrapper } from './projectTemplatesTasks/TemplatesTasksProviderWrapper';
import { OrganizationDocuments } from './OrganizationDocuments';
import { OrganizationHierarchicalLists } from './OrganizationHierarchicalLists';
import { OrganizationQualityControl } from './OrganizationQualityControl';
import { OrganizationTBQ } from './OrganizationTBQ';

export const OrganizationTemplates = () => {
  const hasQualityControlFeature = useFeatureFlag('qualitycontrol');
  const { isExpired } = useSubscriptionHelpers();
  const { contextScopedOrganizationSubscriptionLevel } =
    useOrganizationContext();
  const { hasDmsLicense, hasGeneralProjectLicense, hasQualityControlLicense } =
    useSubscriptionRestriction(contextScopedOrganizationSubscriptionLevel);

  return (
    <>
      <MetadataTabs base={Routes.orgMetadataBaseUrl} />
      <RouterDomRoutes>
        <Route
          element={
            <PrivateRoute
              appPermission="OrganizationUpdate"
              element={
                !isExpired && hasDmsLicense ? (
                  <OrganizationDocuments />
                ) : (
                  <BigMessageNoSubscriptionAccess />
                )
              }
            />
          }
          path={Routes.orgMetadata}
        />

        <Route
          element={
            <PrivateRoute
              appPermission="OrganizationUpdate"
              element={
                !isExpired &&
                hasQualityControlLicense &&
                hasQualityControlFeature ? (
                  <OrganizationQualityControl />
                ) : (
                  <BigMessageNoSubscriptionAccess />
                )
              }
            />
          }
          path={Routes.templatesQuality}
        />
        {[Routes.tbqTemplates].map((path) => (
          <Route
            element={
              <PrivateRoute
                appPermission="OrganizationUpdate"
                element={
                  !isExpired &&
                  hasQualityControlLicense &&
                  hasQualityControlFeature ? (
                    <OrganizationTBQ />
                  ) : (
                    <BigMessageNoSubscriptionAccess />
                  )
                }
                key={path}
              />
            }
            key={path}
            path={path}
          />
        ))}
        <Route
          element={
            <PrivateRoute
              appPermission="OrganizationUpdate"
              element={
                !isExpired &&
                hasQualityControlLicense &&
                hasQualityControlFeature ? (
                  <OrganizationHierarchicalLists />
                ) : (
                  <BigMessageNoSubscriptionAccess />
                )
              }
            />
          }
          path={Routes.metaDataHierarchicalLists}
        />

        <Route
          element={
            <PrivateRoute
              appPermission="OrganizationUpdate"
              element={
                !isExpired &&
                ((hasQualityControlLicense && hasQualityControlFeature) ||
                  hasDmsLicense) ? (
                  <TemplatesTasksProviderWrapper />
                ) : (
                  <BigMessageNoSubscriptionAccess />
                )
              }
            />
          }
          path={Routes.taskCategories}
        />
      </RouterDomRoutes>
    </>
  );
};
