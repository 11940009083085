import { ReactNode } from 'react';
import {
  createLayerComponent,
  LayerProps,
  LeafletContextInterface,
} from '@react-leaflet/core';
import L, { DoneCallback } from 'leaflet';

import { TileService } from '@pro4all/quality-control/data-access';

interface AuthTileProps extends LayerProps {
  children?: ReactNode;
  maxZoom?: number;
  minZoom?: number;
  noWrap?: boolean;
  page: number;
  tileSize?: number;
  visualContextId: string;
  zoomOffset?: number;
}

class AuthTile extends L.GridLayer {
  visualContextId: string;
  page: number;

  constructor(options: L.GridLayerOptions) {
    super(options);
    this.visualContextId = '';
    this.page = 1;
  }

  createTile(coords: L.Coords, done: DoneCallback): HTMLElement {
    const img = document.createElement('img');
    img.setAttribute('role', 'presentation');

    img.addEventListener('load', () => {
      img.style.opacity = '1';
      done(undefined, img);
    });

    img.addEventListener('error', (e) => {
      img.style.opacity = '0';
      done(e.error);
    });

    TileService.getTileUrl(
      this.visualContextId,
      coords.z,
      coords.x,
      coords.y,
      this.page
    )
      .then((src) => {
        if (src) {
          img.src = src;
        } else {
          img.style.opacity = '0';
          done(undefined, img);
        }
      })
      .catch((error) => {
        img.style.opacity = '0';
        done(error, img);
      });

    return img;
  }

  setVisualContextId(id: string) {
    this.visualContextId = id;
    this.redraw();
  }

  setPage(page: number) {
    this.page = page;
    this.redraw();
  }
}

const createAuthTileLayer = (
  props: AuthTileProps,
  context: LeafletContextInterface
) => {
  const options: L.GridLayerOptions = {
    className: 'leaflet-tile-container',
    keepBuffer: 2,
    maxZoom: props.maxZoom || 18,
    minZoom: props.minZoom || 0,
    noWrap: props.noWrap || false,
    tileSize: props.tileSize || 256,
    updateWhenIdle: true,
    updateWhenZooming: false,
  };

  const instance = new AuthTile(options);
  instance.setVisualContextId(props.visualContextId);
  instance.setPage(props.page);

  return { context, instance };
};

export const AuthTileLayer = createLayerComponent<AuthTile, AuthTileProps>(
  createAuthTileLayer
);
