import { useCallback, useEffect, useState } from 'react';

export const useInScreen = (
  boxRef: React.RefObject<HTMLDivElement>,
  containerHeight: number,
  parentScrollPosition: number
) => {
  const [inScreen, setInScreen] = useState(false);
  const [timeoutRef, setTimeoutRef] = useState<number | null>(null);

  const clearTimeoutRef = useCallback(() => {
    if (timeoutRef) {
      window.clearTimeout(timeoutRef);
      setTimeoutRef(null);
    }
  }, [timeoutRef]);

  useEffect(() => {
    clearTimeoutRef();

    if (boxRef.current) {
      const { offsetTop: top, clientHeight } = boxRef.current;
      const bottom = top + clientHeight;

      const isInView =
        (top >= parentScrollPosition &&
          top <= parentScrollPosition + containerHeight) ||
        (bottom >= parentScrollPosition &&
          bottom <= parentScrollPosition + containerHeight &&
          top <= parentScrollPosition);

      if (isInView) {
        setTimeoutRef(
          window.setTimeout(() => {
            setInScreen(true);
          }, 500)
        );
      } else {
        setInScreen(false);
      }
    }

    return clearTimeoutRef;
  }, [parentScrollPosition, containerHeight, boxRef]);

  return inScreen;
};
