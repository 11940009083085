import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Status, Task } from '@pro4all/graphql';
import { Box, FormControl } from '@pro4all/shared/mui-wrappers';
import { Option } from '@pro4all/shared/types';
import { SearchableSelect } from '@pro4all/shared/ui/inputs';
import { Text } from '@pro4all/shared/ui/typography';

import { FiltersContainer } from '../styles/series.styles';
import {
  filterTasks,
  getSortOptions,
  getStatusOptions,
} from '../utils/series.utils';

import { SeriesTasksList } from './SeriesTasksList';

interface SeriesTasksTabProps {
  tasks?: Task[];
  title: string;
}

type SortOrder = 'asc' | 'desc';

export const SeriesTasksTab: React.FC<SeriesTasksTabProps> = ({
  tasks = [],
}) => {
  const { t } = useTranslation();
  const [selectedStatus, setSelectedStatus] = useState<Status | 'all'>('all');
  const [sortOrder, setSortOrder] = useState<SortOrder>('asc');

  const statusOptions = getStatusOptions(t);
  const sortOptions = getSortOptions(t);

  const handleStatusChange = (option: Option) => {
    setSelectedStatus(option.id as Status | 'all');
  };

  const handleSortChange = (option: Option) => {
    setSortOrder(option.id as SortOrder);
  };

  const filteredTasks = filterTasks(tasks, selectedStatus, sortOrder);

  return (
    <Box mb={5} mt={5} mx={3}>
      <FiltersContainer>
        <FormControl sx={{ width: '250px' }}>
          <SearchableSelect
            label={t('Status')}
            name="status"
            onChange={handleStatusChange}
            options={statusOptions}
            value={statusOptions.find((option) => option.id === selectedStatus)}
          />
        </FormControl>
        <FormControl sx={{ width: '250px' }}>
          <SearchableSelect
            label={t('Sort by')}
            name="sort"
            onChange={handleSortChange}
            options={sortOptions}
            value={sortOptions.find((option) => option.id === sortOrder)}
          />
        </FormControl>
      </FiltersContainer>
      {!filteredTasks || filteredTasks.length === 0 ? (
        <Text variant="body2">{t('No tasks available yet')}</Text>
      ) : (
        <SeriesTasksList tasks={filteredTasks} />
      )}
    </Box>
  );
};
