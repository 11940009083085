import styled, { css } from 'styled-components';

import { customColors } from '@pro4all/shared/themes';

const handlePlacement = {
  bottomLeft: css`
    bottom: 0;
    cursor: nesw-resize;
    left: 0;
    transform: translate(-50%, 50%);
  `,
  bottomRight: css`
    bottom: 0;
    cursor: nwse-resize;
    right: 0;
    transform: translate(50%, 50%);
  `,
  topLeft: css`
    left: 0;
    cursor: nwse-resize;
    top: 0;
    transform: translate(-50%, -50%);
  `,
  topRight: css`
    right: 0;
    cursor: nesw-resize;
    top: 0;
    transform: translate(50%, -50%);
  `,
};

export const Handle = styled.div<{ $position: keyof typeof handlePlacement }>`
  position: absolute;
  ${({ $position }) => handlePlacement[$position]}

  background-color: ${customColors.white};
  border: 2px solid ${customColors.greenMonoHighlight};
  border-radius: 50%;
  height: 0.75rem;
  width: 0.75rem;

  /* enlarge the actionable area */
  &::after {
    content: '';
    display: block;
    height: 1rem;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 1rem;
  }
`;

export const Guide = styled.div`
  border: dashed 1px ${customColors.grey500};
  cursor: grab;
  position: absolute;

  &[aria-grabbed='true'] {
    cursor: grabbing;
  }
`;
