import { deepmerge } from '@mui/utils';

import { ThemeOptions } from '@pro4all/shared/mui-wrappers';

import { customColors } from './customColors';
import { muiStyleOverridesGeneric } from './muiStyleOverridesGeneric';
import { muiStyleOverridesTbq } from './muiStyleOverridesTbq';
import { themeGeneric } from './themeGeneric';

export const themeTbq: ThemeOptions = deepmerge(themeGeneric, {
  components: deepmerge(muiStyleOverridesGeneric, muiStyleOverridesTbq),
  palette: {
    action: {
      hover: 'rgba(0, 0, 0, 0.10)',
    },
    primary: {
      contrastText: customColors.white,
      dark: customColors.tbqPrimaryDark,
      light: customColors.tbqPrimaryLight,
      main: customColors.tbqPrimaryMain,
    },
    secondary: {
      contrastText: customColors.white,
      dark: customColors.tbqSecondaryDark,
      light: customColors.tbqSecondaryLight,
      main: customColors.tbqSecondaryMain,
    },
    text: {
      primary: customColors.grey700,
      secondary: customColors.grey900,
    },
  },
});
