import React from 'react';
import { useTranslation } from 'react-i18next';

import { CommentForm } from '@pro4all/communication/ui/comments';
import {
  ThreadContextProvider,
  useThreadContext,
} from '@pro4all/communication/ui/comments';
import {
  Document,
  DocumentVersion,
  Message,
  Reference,
  ReferenceInput,
  ReferenceKind,
  ReferenceType,
} from '@pro4all/graphql';
import { Box } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Loader } from '@pro4all/shared/ui/loader';
import { BigMessageNoCommentsInThread } from '@pro4all/shared/ui/messages';

import { useCommentTracking } from './useCommentTracking';

type VersionIdWithNumber = {
  versionId: string;
  versionNumber: string;
};

type DocumentCommentValues = {
  documentCommentVariant: 'document' | 'version';
  documentId: string;
  documentVersionIdsWithVersionNumbers: VersionIdWithNumber[];
  folderId: string;
  versionId: string;
  versionNumber: string;
};

const getDocumentCommentValuesVersion = (
  version: DocumentVersion
): DocumentCommentValues => ({
  documentCommentVariant: 'version',
  documentId: version.documentId,
  documentVersionIdsWithVersionNumbers: [],
  folderId: version.folderId ?? '',
  versionId: version.id,
  versionNumber: `${version.versionNumber}`,
});

const getDocumentCommentValuesDocument = (
  document: Document
): DocumentCommentValues => ({
  documentCommentVariant: 'document',
  documentId: document.id,
  documentVersionIdsWithVersionNumbers:
    document.versions?.map((version) => ({
      versionId: `${version?.id}`,
      versionNumber: `${version?.versionNumber}`,
    })) ?? [],
  folderId: document.folderId ?? '',
  versionId: document.versionId ?? '',
  versionNumber: '',
});

export const DocumentComments: React.FC<{
  documentOrVersion: Document | DocumentVersion;
}> = ({ documentOrVersion }) => {
  /* The document acquired from useDocument is flimsy. For the query we'll use the id from params instead.
  Consider refactoring this across sidebar subcomponents */
  const {
    searchParams,
    params: { projectId },
  } = useRouting();
  const documentId = searchParams.get('id');
  const versionId = searchParams.get('versionid');

  const documentCommentValues =
    documentOrVersion.__typename === 'DocumentVersion'
      ? getDocumentCommentValuesVersion(documentOrVersion)
      : getDocumentCommentValuesDocument(documentOrVersion as Document);
  const editState = searchParams.get('editComment');

  // Reference to the current document version
  const references: ReferenceInput[] = [
    {
      referenceId: null,
      referenceKind: ReferenceKind.To,
      referenceType: ReferenceType.DocumentVersion,
      referenceValue: documentCommentValues.versionId,
    },
    ...(projectId
      ? [
          {
            referenceId: null,
            referenceKind: ReferenceKind.LinkInfo,
            referenceType: ReferenceType.Project,
            referenceValue: projectId,
          },
        ]
      : []),
    ...(documentOrVersion.folderId
      ? [
          {
            referenceId: null,
            referenceKind: ReferenceKind.LinkInfo,
            referenceType: ReferenceType.Folder,
            referenceValue: documentOrVersion.folderId,
          },
        ]
      : []),
  ];

  // Reference to the current document
  const target: ReferenceInput = {
    referenceId: null,
    referenceKind: ReferenceKind.Target,
    referenceType: ReferenceType.Document,
    referenceValue: documentCommentValues.documentId,
  };

  const {
    trackAttachmentAdded,
    trackAttachmentButtonClicked,
    trackAttachmentDownload,
    trackAttachmentRemoved,
    trackInputClick,
    trackSubmit,
  } = useCommentTracking(
    documentCommentValues.documentId,
    documentCommentValues.folderId
  );

  const filterFn = (message: Message) =>
    !versionId ||
    (Boolean(versionId) &&
      Boolean(
        message?.references?.find(
          (reference) =>
            reference.referenceKind === ReferenceKind.To &&
            reference.referenceValue === versionId
        )
      ));

  return (
    <ThreadContextProvider
      callbackOptions={{
        onAttachmentAdded: trackAttachmentAdded,
        onAttachmentButtonClicked: trackAttachmentButtonClicked,
        onAttachmentDownload: trackAttachmentDownload,
        onAttachmentRemoved: trackAttachmentRemoved,
        onInputClicked: trackInputClick,
        onSubmit: trackSubmit,
      }}
      filterFn={filterFn}
      newComment
      pollInterval={editState ? 0 : 5000}
      references={references}
      target={target}
      targetId={documentId || ''}
    >
      <CommentsList
        documentCommentValues={documentCommentValues}
        documentId={documentId}
        documentOrVersion={documentOrVersion}
        versionId={versionId}
      />
    </ThreadContextProvider>
  );
};

// Custom comment list for Documents, due to the nature of documents
// we need a custom solution for displaying its comment list

const CommentsList: React.FC<{
  documentCommentValues: DocumentCommentValues;
  documentId: string | null;
  documentOrVersion: Document | DocumentVersion;
  versionId: string | null;
}> = ({ documentCommentValues, documentOrVersion, documentId, versionId }) => {
  const { t } = useTranslation();
  const context = useThreadContext();
  if (!context) throw Error('Comments context not initialized.');
  const { comments, loading } = context;

  // We resolve version labels here because we want to keep comments-ui lib agnostic
  const getVersionLabel = (references: Reference[]) => {
    const referenceValue = references?.find(
      (reference) =>
        reference?.referenceType === ReferenceType.DocumentVersion &&
        reference?.referenceKind === ReferenceKind.To
    )?.referenceValue;
    const versionNumber =
      documentCommentValues.documentCommentVariant === 'version'
        ? documentCommentValues.versionNumber
        : documentCommentValues.documentVersionIdsWithVersionNumbers.find(
            (version) => version.versionId === referenceValue
          )?.versionNumber;

    return `${t('Version')} ${versionNumber || ''}`;
  };

  const showSpinner =
    loading ||
    (!documentId && documentOrVersion.__typename === 'Document') ||
    (!versionId && documentOrVersion.__typename === 'DocumentVersion') ||
    (!versionId && !documentId) ||
    (comments &&
      !comments.length &&
      documentOrVersion.__typename === 'Document');

  const noMessages =
    !comments ||
    (!comments.length && documentOrVersion.__typename === 'DocumentVersion');
  return (
    <>
      {showSpinner && (
        <Box display="flex" justifyContent="center">
          <Loader />
        </Box>
      )}
      {noMessages && <BigMessageNoCommentsInThread />}
      {comments?.map(({ id: messageId, body, ...comment }, key) => (
        <CommentForm
          body={body || undefined}
          key={key}
          messageId={messageId}
          {...comment}
          referenceText={getVersionLabel(comment.references)}
        />
      ))}
    </>
  );
};
