import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

import { DocumentService } from '@pro4all/documents/data-access';

import type { Preview } from '../data';
import { Status as PreviewStatus } from '../data';
import { Shape } from '../shape';
import type {
  Canvas as CanvasState,
  Shape as ShapeState,
  StamperDocument,
} from '../store';
import { Dimensions, Position } from '../types';

import * as Styled from './Canvas.styles';
import { ImageNotFound } from './ImageNotFound';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.mjs`;

const options = {
  cMapUrl: '/cmaps/',
  standardFontDataUrl: '/standard_fonts/',
};

export interface Props extends Partial<CanvasState> {
  background: Preview;
  children?: never;
  current: StamperDocument;
  dimensions: Dimensions;
  onDimensionsChange: (dimensions: Dimensions) => void;
  onInit: (canvas: CanvasState) => void;
  onPositionChange: (position: Position) => void;
  position: Position;
  shapes?: ShapeState[];
}

export const Canvas: React.FC<Props> = ({
  background,
  height = 0,
  onInit,
  shapes = [],
  width = 0,
  position,
  dimensions,
  onDimensionsChange,
  onPositionChange,
  current,
}) => {
  const figureRef = useRef<HTMLElement>(null);
  const canvasRef = useRef<HTMLDivElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);

  const [canvas, setCanvas] = useState({ height, width });

  const [url, setUrl] = useState<string>();

  const calculateCanvas = useCallback(() => {
    if (!figureRef.current || !canvasRef.current) return;

    let naturalWidth: number;
    let naturalHeight: number;

    // Handle PDF case
    const pdfCanvas = figureRef.current.querySelector('canvas');
    if (pdfCanvas) {
      naturalWidth = pdfCanvas.width;
      naturalHeight = pdfCanvas.height;
    }
    // Handle image case
    else if (imgRef.current) {
      naturalWidth = imgRef.current.naturalWidth;
      naturalHeight = imgRef.current.naturalHeight;
    } else {
      return;
    }

    const scaleProp = naturalHeight > naturalWidth ? 'Height' : 'Width';

    const { offsetHeight: maxHeight, offsetWidth: maxWidth } =
      figureRef.current;

    const scale =
      scaleProp === 'Height'
        ? figureRef.current.clientHeight / naturalHeight
        : figureRef.current.clientWidth / naturalWidth;

    let height = naturalHeight * scale;
    let width = naturalWidth * scale;

    if (height > maxHeight) {
      width = width * (maxHeight / height);
      height = maxHeight;
    } else if (width > maxWidth) {
      height = height * (maxWidth / width);
      width = maxWidth;
    }

    const canvas = { height, width };

    onInit(canvas);
    setCanvas(canvas);
  }, [onInit]);

  useEffect(() => {
    window.addEventListener('resize', calculateCanvas);
    return () => window.removeEventListener('resize', calculateCanvas);
  }, [calculateCanvas]);

  useEffect(() => {
    if (current) {
      getDownloadUrl();
    }
  }, [current]);

  if (background.status === PreviewStatus.NotFound && !url)
    return <ImageNotFound retry={background.retry} />;

  const getDownloadUrl = async () => {
    if (!current?.documentId) return;
    const downloadUrlResult = await DocumentService.getDownloadUrl({
      documentId: current.documentId,
      id: current.id || '',
    });
    if (downloadUrlResult.data) setUrl(downloadUrlResult.data);
  };

  return (
    <Styled.Figure ref={figureRef}>
      {background.src ? (
        <Styled.Img
          alt=""
          onLoad={calculateCanvas}
          ref={imgRef}
          src={background.src}
        />
      ) : (
        url && (
          <Styled.Container>
            <Document file={url} options={options}>
              <Page
                height={canvas.height}
                key="0"
                onLoadSuccess={() => calculateCanvas()}
                pageNumber={1}
                renderAnnotationLayer={false}
                renderTextLayer={false}
                scale={1}
                width={canvas.width}
              />
            </Document>
          </Styled.Container>
        )
      )}

      <Styled.Canvas ref={canvasRef} style={canvas}>
        {shapes.map((shape, index) => (
          <Shape
            {...shape}
            canvas={canvas}
            container={canvasRef?.current}
            dimensions={dimensions}
            key={shape.id ?? index}
            onDimensionsChange={onDimensionsChange}
            onPositionChange={onPositionChange}
            position={position}
          />
        ))}
      </Styled.Canvas>
    </Styled.Figure>
  );
};
