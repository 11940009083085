import React, { useEffect, useRef } from 'react';
import {
  Route,
  Routes as RouterDomRoutes,
  useMatch,
  useParams,
} from 'react-router-dom';

import { DrawingMainProviderWrapper } from '@pro4all/quality-control/ui/floor-plan';
import {
  AnswersPercentageContextProvider,
  ResultSidebar,
} from '@pro4all/quality-control/ui/results';
import { Routes } from '@pro4all/shared/config';
import {
  PhotoProvider,
  useObjectDetailContext,
} from '@pro4all/shared/contexts';
import { useHierarchyEditorContext } from '@pro4all/shared/hierarchy-editor';
import { generateRoute, useRouting } from '@pro4all/shared/routing-utils';
import { Loader } from '@pro4all/shared/ui/loader';

import * as Messages from '../../Messages';
import { ProjectStatus } from '../../projects';
import { OsidLogsProviderWrapper } from '../../tbq/osid/OsidLogsProviderWrapper';
import { TBQResolveTasksProviderWrapper } from '../../tbq/tasks/list/TBQResolveTasksProviderWrapper';

import { ResultsProviderWrapper } from './ResultsProviderWrapper';
import { Tabs } from './Tabs';
import { TbqTasksProviderWrapper } from './TbqTasksProviderWrapper';

export const ObjectDetail: React.FC = () => {
  const { setObjectId, object, loading, id } = useObjectDetailContext();
  const { objectId } = useParams();

  useEffect(() => {
    setObjectId(objectId);
  }, [objectId]);

  const {
    editItem,
    state: { items },
  } = useHierarchyEditorContext();
  const hasBeenSetInitially = useRef(false);

  useEffect(() => {
    if (items.length > 0) {
      const currentItem = items.find((item) => item.id === id);
      if (currentItem && hasBeenSetInitially.current === false) {
        // User reloads application with a route that contains an objectId, this object then should be selected automatically.
        editItem({ ...currentItem, selected: true });
        hasBeenSetInitially.current = true;
      } else if (!currentItem) {
        // User clicked on navigation item `Objects`.
        // There may be one item that has the selected flag set to true, this one must revert to false.
        const selectedItem = items.find((item) => item.selected);
        if (selectedItem) {
          editItem({ ...selectedItem, selected: false });
        }
      }
    }
  }, [editItem, id, items]);

  const routeProjectId = useRouting().params.projectId;
  const osidUrl = generateRoute('objectOsid', { params: { objectId } });
  const osidPage = useMatch(osidUrl);
  if (!objectId) return <Messages.NoObject />;
  if (!object && loading) return <Loader />;
  if (!object) return <Messages.NotFound />;

  const routeOrObjectProjectId = routeProjectId || object.projectId || '';
  const procedureId = object.project?.mainProcedure?.id || '';

  return (
    <>
      {routeOrObjectProjectId && <Tabs />}
      {!osidPage && <ProjectStatus />}
      <RouterDomRoutes>
        {[Routes.tasks, Routes.projectObjectTbq].map((route) => (
          <Route
            element={
              <TbqTasksProviderWrapper
                procedureId={procedureId}
                routeOrObjectProjectId={routeOrObjectProjectId}
              />
            }
            key={route}
            path={route}
          />
        ))}
        {[Routes.drawings].map((route) => (
          <Route
            element={
              <DrawingMainProviderWrapper projectId={routeOrObjectProjectId} />
            }
            key={route}
            path={route}
          />
        ))}
        {[Routes.results].map((route) => (
          <Route
            element={
              <ResultsProviderWrapper projectId={routeOrObjectProjectId} />
            }
            key={route}
            path={route}
          />
        ))}
        {[Routes.tbqResolveTasks].map((route) => (
          <Route
            element={
              <TBQResolveTasksProviderWrapper
                procedureId={procedureId}
                projectId={routeOrObjectProjectId}
              />
            }
            key={route}
            path={route}
          />
        ))}
        {[Routes.osid].map((route) => (
          <Route
            element={<OsidLogsProviderWrapper />}
            key={route}
            path={route}
          />
        ))}
      </RouterDomRoutes>
      <PhotoProvider>
        <AnswersPercentageContextProvider>
          <ResultSidebar />
        </AnswersPercentageContextProvider>
      </PhotoProvider>
    </>
  );
};
