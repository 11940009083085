import React from 'react';
import { Navigate, Route, Routes as RouterDomRoutes } from 'react-router-dom';

import { Routes } from '@pro4all/shared/config';

import { MyProjectsProviderWrapper } from './MyProjectsProviderWrapper';
import { MyTasksProviderWrapper } from './MyTasksProviderWrapper';

export const MyOverview: React.FC = () => (
  <RouterDomRoutes>
    <Route
      element={<MyProjectsProviderWrapper />}
      path={`${Routes.myProjects}`}
    />
    <Route element={<MyTasksProviderWrapper />} path={Routes.myTasks} />
    <Route
      element={<Navigate to={Routes.myProjectsOverview} />}
      path={Routes.myOverview}
    />
  </RouterDomRoutes>
);
