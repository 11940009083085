import React from 'react';
import { deepmerge } from '@mui/utils';
import { ThemeProvider as SCThemeProvider } from 'styled-components';

import {
  createTheme,
  CssBaseline,
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
} from '@pro4all/shared/mui-wrappers';

import { themeGeneric } from '../themes/themeGeneric';
import { themePave } from '../themes/themePave';
import { themePro4all } from '../themes/themePro4all';
import { themeTbq } from '../themes/themeTbq';

export enum AvailableThemes {
  pave = 'pave',
  pro4all = 'pro4all',
  tbq = 'tbq',
}

const themes = {
  pave: themePave,
  pro4all: themePro4all,
  tbq: themeTbq,
};

export const ThemeProvider: React.FC<{
  themeName?: keyof typeof AvailableThemes;
}> = ({ children, themeName = AvailableThemes.pro4all }) => {
  const muiTheme = createTheme(deepmerge(themeGeneric, themes[themeName]));
  return (
    <StylesProvider injectFirst>
      <CssBaseline>
        <MuiThemeProvider theme={muiTheme}>
          <SCThemeProvider theme={muiTheme}>{children}</SCThemeProvider>
        </MuiThemeProvider>
      </CssBaseline>
    </StylesProvider>
  );
};
