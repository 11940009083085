import React from 'react';

import { TableActionBar } from '@pro4all/shared/ui/table-action-bar';

import { useProjectActions } from './actions/useProjectActions';

export const ProjectsActionBar: React.FC = () => {
  const { mainActions } = useProjectActions();

  return <TableActionBar dataViewToggle mainActions={mainActions} search />;
};
