import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import {
  Document,
  DocumentFragmentFragmentDoc,
  DocumentMetaDataFragmentFragmentDoc,
  useMapDocumentVersionMetaDataInstanceMutation,
  useSaveInstancesMutation,
  useUpdateDocumentsMutation,
} from '@pro4all/graphql';
import { useStoreErrorInDataDog } from '@pro4all/shared/datadog-logging';
import { useRouting } from '@pro4all/shared/routing-utils';

import { UploadEditorRoutingState } from '../UploaderEditor';
import { useUploaderEditorContext } from '../UploaderEditorProvider';
export const useSaveDocuments = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { setProcessingStatus, startProcessing, state, stopProcessing } =
    useUploaderEditorContext();
  const {
    params: { projectId },
    searchParams,
    state: { versionIdsForEdit },
  } = useRouting<UploadEditorRoutingState>();

  const [saveInstances] = useSaveInstancesMutation();
  const [mapDocumentVersionsToMetadataInstances] =
    useMapDocumentVersionMetaDataInstanceMutation();
  const [updateDocuments] = useUpdateDocumentsMutation();
  const { storeErrorInDataDog } = useStoreErrorInDataDog();

  let allIdsSuccessfullyUpdated = true;

  const saveDocuments = async ({ closeEditor }: { closeEditor: boolean }) => {
    const { meta, templateId } = state;

    const errorTitle = 'File save error';
    const reactComponent = `useSaveDocuments`;

    // 1. Start the upload process. So now the system knows it's in an upload state and user cannot change things like metadata and name etc..
    startProcessing(true);

    //*** 2. Update changed document names */
    const documentsWithNameChange = meta.filter(
      (document) => document.name !== document.nameInitial
    );

    if (documentsWithNameChange.length) {
      const updateDocumentsPayload = documentsWithNameChange.map(
        (document) => ({
          id: document.id,
          name: document.name,
        })
      );

      try {
        const response = await updateDocuments({
          update: (cache, data) => {
            if (data.data && data.data.updateDocuments) {
              data.data.updateDocuments.forEach((documentId) => {
                const document: Document | null = cache.readFragment({
                  fragment: DocumentFragmentFragmentDoc,
                  fragmentName: 'DocumentFragment',
                  id: `Document:${documentId}`,
                });

                const editedDocument =
                  documentsWithNameChange.find(
                    (document) => document.id === documentId
                  ) ?? null;

                if (document && editedDocument) {
                  cache.writeFragment({
                    data: { ...document, name: editedDocument.name },
                    fragment: DocumentFragmentFragmentDoc,
                    fragmentName: 'DocumentFragment',
                    id: `Document:${documentId}`,
                  });
                }
              });
            }
          },
          variables: {
            documents: updateDocumentsPayload,
          },
        });

        const documentIdsWithNameChange = documentsWithNameChange.map(
          (doc) => doc.id
        );
        const documentIdsSuccesfullyMutated = response.data?.updateDocuments;
        // If the response does not contain all the document ids that were supposed to be updated, we have to inform the user.
        // Check out which ids were not updated.
        const documentIdsNotUpdated = documentIdsWithNameChange.filter(
          (id) => !documentIdsSuccesfullyMutated?.includes(id)
        );
        if (documentIdsNotUpdated.length) {
          allIdsSuccessfullyUpdated = false;
          setProcessingStatus({
            documentIds: documentIdsNotUpdated,
            processingStatus: {
              error: t('There was an error updating the document name'),
              isCurrentlyProcessing: false,
              successfullyProcessed: false,
            },
          });
          storeErrorInDataDog({
            additionalProps: {
              documentIds: documentIdsNotUpdated,
              errorInfo: 'There was an error updating the document name',
            },
            errorTitle,
            reactComponent,
          });
        }
      } catch (error) {
        allIdsSuccessfullyUpdated = false;
        enqueueSnackbar(t('Could not update documents'));
      }
    }

    //*** 3. Update changed meta data */
    const documentsWithMetaDataChanges = meta.filter((document) => {
      let include = false;
      document.metaData?.forEach((metaData) => {
        if (metaData.value !== metaData.valueInitial) {
          include = true;
        }
      });
      return include;
    });

    const answers = documentsWithMetaDataChanges.map((document) => {
      const { id, metaData, metadataInstanceId } = document;
      const metaDataChanged = metaData?.filter(
        (metaDataCell) => metaDataCell.value !== metaDataCell.valueInitial
      );
      const fields = metaDataChanged?.map(({ fieldDefinitionId, value }) => ({
        fieldDefinitionId,
        value,
      }));

      return {
        createNewInstanceForVersion: Boolean(!metadataInstanceId),
        documentId: id,
        fields,
        metadataInstanceId,
        templateId,
      };
    });

    const variables = {
      answers,
      previousInstanceId: '',
      projectId,
      templateId,
    };

    try {
      const responseSaveInstances = await saveInstances({
        update: (cache, data) => {
          if (data.data && data.data.saveInstancesForDocuments) {
            for (const documentMetaDataResponse of data.data
              .saveInstancesForDocuments) {
              const documentMetadata: Document | null = cache.readFragment({
                fragment: DocumentMetaDataFragmentFragmentDoc,
                id: `Document:${documentMetaDataResponse?.id}`,
              });

              if (documentMetadata) {
                const upsertedAnswers = answers.find(
                  (upsertedAnswer) =>
                    upsertedAnswer.documentId === documentMetaDataResponse?.id
                );

                if (upsertedAnswers && documentMetadata.metaData?.answers) {
                  const updatedAnswers = documentMetadata.metaData.answers.map(
                    (answer) => {
                      const updatedAnswer = upsertedAnswers.fields?.find(
                        (upsertedAnswer) =>
                          upsertedAnswer.fieldDefinitionId ===
                          answer?.fieldDefinitionId
                      );
                      if (updatedAnswer) {
                        return { ...answer, value: updatedAnswer.value };
                      }
                      return answer;
                    }
                  );

                  cache.writeFragment({
                    data: {
                      ...document,
                      metaData: {
                        ...documentMetadata.metaData,
                        answers: updatedAnswers,
                      },
                    },
                    fragment: DocumentMetaDataFragmentFragmentDoc,
                    id: `Document:${documentMetaDataResponse?.id}`,
                  });
                }
              }
            }
          }
        },
        variables,
      });

      const documentIdsWithMetaDataChange = documentsWithMetaDataChanges.map(
        (doc) => doc.id
      );
      const documentIdsSuccesfullyMutated =
        responseSaveInstances.data?.saveInstancesForDocuments?.map(
          (doc) => doc?.id
        );

      // If the response does not contain all the document ids that were supposed to be updated, we have to inform the user.
      // Check out which ids were not updated.
      const documentIdsNotUpdated = documentIdsWithMetaDataChange.filter(
        (id) => !documentIdsSuccesfullyMutated?.includes(id)
      );
      if (documentIdsNotUpdated.length) {
        allIdsSuccessfullyUpdated = false;
        setProcessingStatus({
          documentIds: documentIdsNotUpdated,
          processingStatus: {
            error: t('There was an error updating the meta data'),
            isCurrentlyProcessing: false,
            successfullyProcessed: false,
          },
        });
        storeErrorInDataDog({
          additionalProps: {
            documentIds: documentIdsNotUpdated,
            errorInfo: 'There was an error updating the meta data',
          },
          errorTitle,
          reactComponent,
        });
      }

      // If the document was uploaded in a period no template was assigned to the folder, we have to perform one extra step
      // to connect the metadataInstanceId taken from the `saveInstances` mutation to the version.
      const documentsWithoutMetadataInstanceId =
        documentsWithMetaDataChanges.filter(
          (document) => !document.metadataInstanceId
        );
      if (documentsWithoutMetadataInstanceId.length) {
        const payload = documentsWithoutMetadataInstanceId.map((document) => {
          const responseDocument =
            responseSaveInstances.data?.saveInstancesForDocuments?.find(
              (doc) => doc?.id === document.id
            );
          return {
            documentId: document.id,
            metadataInstanceId: responseDocument?.instanceId || '',
            versionId: versionIdsForEdit.length
              ? versionIdsForEdit[0] // When editing a version we have to take that versionId.
              : document.versionId, // Otherwise we take the versionId of the document which is always the most recent one.
          };
        });

        try {
          await mapDocumentVersionsToMetadataInstances({
            variables: {
              mappings: payload,
            },
          });
        } catch (error) {
          allIdsSuccessfullyUpdated = false;
          enqueueSnackbar(t('Could not update all metadata'));
        }
      }
    } catch (error) {
      allIdsSuccessfullyUpdated = false;
      enqueueSnackbar(t('Could not update metadata'));
    }

    if (closeEditor && allIdsSuccessfullyUpdated) {
      searchParams.clear();
    } else {
      if (allIdsSuccessfullyUpdated) {
        stopProcessing(true); // 'true' means that the processing icon will be replaced with a checkmark icon, indicating everything was processed successfully.
      } else {
        stopProcessing(false);
      }
    }
  };

  return saveDocuments;
};
