import React from 'react';
import { createRoot } from 'react-dom/client';

import { initI18n } from '@pro4all/i18n';

import App from './app/app';

initI18n(localStorage.getItem('i18nextLng'));
const container = document.getElementById('root');
if (!container) {
  throw new Error('Root element not found');
}
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
