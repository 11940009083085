import React from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import type { TbqFieldOption } from '@pro4all/graphql';
import { RadioGroup } from '@pro4all/shared/mui-wrappers';
import { Radio } from '@pro4all/shared/ui/inputs';
import { sortBy } from '@pro4all/shared/utils';

import type { Fields } from '../useTBQSettings';

import { TBQEntityInput } from './TBQEntityInput';
import * as Styled from './TBQFieldOptions.styles';

export const TBQFieldOptions = ({ id, name, options }: TbqFieldOption) => {
  // TODO: FIX this type assertion
  const { control } = useFormContext<Fields>() as { control: any };
  const { t } = useTranslation();
  const fieldName: `tbqConfig.${string}` = `tbqConfig.${id}`;

  // HACK due to the fact that our Selects are wonky in supporting either
  // Option | string we need to fetch a defaultValue from our form config.
  const defaultValue: string = useWatch({ name: fieldName });

  if (!options?.length) return null;

  const sorted = [...options].sort((a, b) => {
    if (a.name === 'true') return -1;
    else if (a.name === 'false') return 1;
    else return sortBy({ key: 'name' })(a, b);
  });

  if (sorted.length > 5) {
    const selectOptions = sorted
      .map(({ id, name: label }) => ({ id, label }))
      .sort(sortBy({ key: 'label' }));

    return (
      <TBQEntityInput
        defaultValue={defaultValue ?? selectOptions[0].id}
        id={id}
        label={t(`TBQ.${name}`)}
        // TODO: FIX this assertion?
        name={fieldName as keyof Fields}
        options={selectOptions}
      />
    );
  }

  return (
    <Styled.FieldOptions>
      <strong>{t(`TBQ.${name}`)}</strong>
      <Controller
        control={control}
        defaultValue={defaultValue}
        name={fieldName}
        render={({ field }) => (
          <RadioGroup {...field}>
            {sorted.map(({ id, name: label }) => (
              <Radio
                id={id}
                key={id}
                label={t(`TBQ.${label}`)}
                name={field.name}
                onChange={() => field.onChange(id)} // Ensure correct selection behavior
                value={id} // Handle change properly
              />
            ))}
          </RadioGroup>
        )}
      />
    </Styled.FieldOptions>
  );
};
