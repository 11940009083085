import styled, { css } from 'styled-components';

import { customColors } from '@pro4all/shared/themes';
import { Section } from '@pro4all/shared/ui/section';

// 21cm https://www.freshconsulting.com/insights/blog/uiux-principle-46-text-box-width-should-help-users-read/
const Column = css`
  display: flex;
  flex-direction: column;
  max-width: 21cm;
  ${({ theme }) => theme.breakpoints.down('md')} {
    max-width: 100%;
  }
`;

export const BranchWrap = styled.div<{
  $borderColor?: string;
}>`
  ${Column}
  gap: 2rem;
  padding: 1rem 0 3rem 0;
  justify-content: center;
  flex-grow: 1;

  ${({ theme }) => theme.breakpoints.down('md')} {
    flex-wrap: no-wrap;
  }
`;

export const Previous = styled.div`
  ${Column}
  gap: 1rem;
`;

export const MessageWrapper = styled.div<{
  $borderColor?: string;
  $paddingBottom?: number;
}>`
  ${Column}
  flex: 1;
  padding: 4rem 4rem 6rem;
  ${({ $paddingBottom }) =>
    $paddingBottom && `padding-bottom: ${$paddingBottom}px`};
  ${({ $borderColor }) => $borderColor && `border: 1px solid ${$borderColor}`};
  gap: 24px;
  background-color: ${customColors.white};
  border-radius: 20px;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 2rem;
  }
`;

export const Toggle = styled(Section.AltToggle)`
  margin-left: auto;
  color: ${customColors.grey600};
  text-align: right;
  font-weight: 500;
`;
