import styled from 'styled-components';

import { customColors } from '@pro4all/shared/themes';

export const SelectorInputWrap = styled.div`
  padding: 0.5rem 0.7rem;
  > div {
    font-weight: 500;
  }
`;

export const SelectorButtonWrap = styled.div`
  margin-left: 0.5rem;
`;

export const SelectorWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 278px;
  background-color: ${customColors.white};

  ul {
    max-height: 320px;
    overflow-y: auto;
    padding: 0;
    li {
      height: 40px;
      padding: 0 0.5rem;
      line-height: 40px;
      list-style: none;
      cursor: pointer;
      &:hover {
        background-color: ${customColors.grey300};
      }
    }
  }
`;

export const SelectorHeader = styled.div`
  height: 33px;
  display: flex;
  align-items: center;
  font-weight: 500;
  color: ${customColors.grey900};
`;
