import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router-dom';

import { FieldDefinition, ScopeType } from '@pro4all/graphql';
import { Routes } from '@pro4all/shared/config';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Sidebar } from '@pro4all/shared/ui/sidebar';

import { useMetaDataContext } from '../../views/MetaDataContext';
import { featureConfig } from '../configs/featureConfig';
import { FieldDefinitionForm } from '../form-field-definition/FieldDefinitionForm';

import { RedirectToOrganizationFields } from './RedirectToOrganizationFields';

export const ItemSidebar: React.FC<{ selectedField?: FieldDefinition }> = ({
  selectedField,
}) => {
  const { t } = useTranslation();
  const { templateType, templateService } = useMetaDataContext();
  const { entity } =
    featureConfig[templateService]?.fieldTypes[templateType] ?? {};
  const { searchParams } = useRouting();
  const [isOpen, setIsOpen] = useState(false);

  const editMode = searchParams.is('action', 'editItem');
  const validParams = searchParams.is('action', 'createField') || editMode;
  const scope = selectedField?.scope.type;
  const routeOrgFieldDefinitions = useMatch(
    Routes.projectMetadataDocumentFieldsUrl
  );
  const routeOrgQuestionFields = useMatch(
    Routes.projectMetadataQualityFieldsUrl
  );
  const redirect =
    (routeOrgFieldDefinitions || routeOrgQuestionFields) &&
    scope === ScopeType.Organization;

  useEffect(() => {
    setIsOpen(validParams);
  }, [validParams]);

  const handleClose = () => searchParams.clear();

  return (
    <Sidebar onClose={handleClose} open={isOpen}>
      <Sidebar.Header
        icon="addFieldDefinition"
        title={
          redirect
            ? selectedField?.name
            : editMode
            ? t(`Edit ${entity}`)
            : t(`New ${entity}`)
        }
      />

      {redirect ? (
        <RedirectToOrganizationFields selectedFieldDefinition={selectedField} />
      ) : (
        isOpen && (
          <FieldDefinitionForm
            isReusable
            onClose={handleClose}
            selectedField={selectedField}
          />
        )
      )}
    </Sidebar>
  );
};
