import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { DocumentHistoryEvent, QcHistoryEvent } from '@pro4all/graphql';
import { ActionProps } from '@pro4all/shared/actions';
import { Box } from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';
import { UserCard } from '@pro4all/shared/ui/identity-card';
import { Timestamp } from '@pro4all/shared/ui/timestamp';

import { EventPropertyList } from './EventPropertyList';

type EventLogExpandCollapseProps = {
  event: DocumentHistoryEvent | QcHistoryEvent;
  title: string;
};

export const EventLogExpandCollapse = ({
  event,
  title,
}: EventLogExpandCollapseProps) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);

  const displayExpandCollapseIcon = Boolean(
    (
      event?.properties?.map(
        (property) =>
          Boolean(property?.addedResolvedValues?.length) ||
          Boolean(property?.deletedResolvedValues?.length)
      ) || [false]
    ).filter(Boolean).length
  );

  const cardIcons: ActionProps[] = [
    {
      ariaLabel: isExpanded ? t('Collapse') : t('Expand'),
      dataTestId: isExpanded ? 'collapse' : 'expand',
      disabled: !displayExpandCollapseIcon,
      key: isExpanded ? 'collapse' : 'expand',
      label: isExpanded ? t('Collapse') : t('Expand'),
      onClick: () => (isExpanded ? setIsExpanded(false) : setIsExpanded(true)),
      startIcon: isExpanded ? 'expandLess' : 'expandMore',
    },
  ];

  return (
    <Box
      sx={{
        borderBottom: `1px solid ${customColors.grey400}`,
        mb: 2,
        pb: 2,
        pl: 2,
        pr: 2,
      }}
    >
      <Box>
        <UserCard
          cardIcons={cardIcons}
          nameSuffix={title}
          sublinePrefix={
            event?.changeDate ? (
              <Timestamp
                color="inherit"
                date={dayjs(event?.changeDate).format('YYYY-MM-DD HH:mm')}
                format="lll"
              />
            ) : (
              ''
            )
          }
          sublineSuffix={
            isDocumentHistoryEvent(event) && event.versionNumber
              ? `${t('Version')} ${event.versionNumber}`
              : null
          }
          userId={event && event.user ? event.user.id : ''}
        />
      </Box>

      {isExpanded && (
        <Box sx={{ ml: 8, mr: 2, mt: 2 }}>
          <EventPropertyList properties={event?.properties || undefined} />
        </Box>
      )}
    </Box>
  );
};

const isDocumentHistoryEvent = (
  event: DocumentHistoryEvent | QcHistoryEvent
): event is DocumentHistoryEvent => 'versionNumber' in event;
