import { useActivateSubscriptionMutation } from '@pro4all/graphql';
import { Routes } from '@pro4all/shared/config';

import { CompanyDataRegistrationFields } from '../CompanyDataRegistration';
import { useSubscriptionAnalytics } from '../hooks/useSubscriptionAnalytics';

export const useSubmitCompanyDataRegistration = () => {
  const [activateSubscription] = useActivateSubscriptionMutation();
  const { trackLicenseSelected } = useSubscriptionAnalytics();

  return async (
    values: CompanyDataRegistrationFields,
    selectedSubscriptionLevel: string
  ) => {
    trackLicenseSelected({
      licenseId: selectedSubscriptionLevel,
    });

    const {
      addressLine1,
      addressLine2,
      country,
      city,
      postalcode,
      vatIdentificationNumber,
    } = values;
    const response = await activateSubscription({
      variables: {
        addressLine1,
        addressLine2,
        city,
        country: country.id,
        id: selectedSubscriptionLevel,
        locale: window.localStorage.getItem('i18nextLng') || 'nl',
        postalcode,
        redirectUrl: `${window.location.host}${Routes.orgSettingsLicense}?landing=true`,
        vatIdentificationNumber,
      },
    });

    if (response.data?.activateSubscription?.mollieRedirect) {
      window.location.href = response.data.activateSubscription.mollieRedirect;
    }
  };
};
