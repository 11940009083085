import { useMemo } from 'react';
import { useMatch } from 'react-router-dom';

import { Task, TaskType } from '@pro4all/graphql';
import { useFetchTasks } from '@pro4all/quality-control/data-access';
import { TasksActionBar } from '@pro4all/quality-control/ui/tasks';
import { Routes } from '@pro4all/shared/config';
import {
  DataViewContextProvider,
  DataViewType,
} from '@pro4all/shared/ui/data-view';
import { OptimisticResponseProvider } from '@pro4all/shared/ui/table';
import { TasksMain, TaskTablePostFix } from '@pro4all/shared/ui/tasks';

import { Tabs } from './Tabs';

export const QualityControlTasksProviderWrapper = () => {
  const onQcTasksPage = useMatch(Routes.projectQualityControlTasksUrl);
  const typeFilter = [TaskType.Resolve, TaskType.QualityControl];
  const { loading, data } = useFetchTasks({
    skip: !onQcTasksPage,
    typeFilter,
  });

  const tasks = useMemo(
    () => data?.project?.mainProcedure?.tasks || [],
    [data?.project?.mainProcedure?.tasks]
  ) as Task[];

  return (
    <OptimisticResponseProvider<Task>>
      <DataViewContextProvider
        initialView={DataViewType.Table}
        viewKey="quality-control-tasks"
        views={[DataViewType.Table, DataViewType.Charts, DataViewType.Calendar]}
      >
        <Tabs />
        <TasksMain
          TasksActionBar={<TasksActionBar />}
          hideProject
          hideUser={false}
          loading={loading}
          mainProcedureId={data?.project?.mainProcedure?.id}
          noTasksText="Have they been completed? On a roll! Need new ones? Create them so you can guarantee overall quality."
          noTasksTitle="You don't have any quality control tasks yet"
          postfix={TaskTablePostFix.QC}
          tasks={tasks}
          typeFilter={typeFilter}
        />
      </DataViewContextProvider>
    </OptimisticResponseProvider>
  );
};
