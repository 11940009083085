import React from 'react';

import { Document, DocumentVersionState } from '@pro4all/graphql';
import { useTheme } from '@pro4all/shared/mui-wrappers';
import { Icon } from '@pro4all/shared/ui/icons';

export const ApprovalStatus: React.FC<Document> = ({
  versionState,
  hasPreviousApproveRejectStatus,
}) => {
  const theme = useTheme();
  const iconOpacity =
    hasPreviousApproveRejectStatus &&
    versionState !== DocumentVersionState.Approved &&
    versionState !== DocumentVersionState.Rejected
      ? '0.5'
      : '1';

  return versionState === DocumentVersionState.Approved ||
    (hasPreviousApproveRejectStatus === DocumentVersionState.Approved &&
      versionState === DocumentVersionState.None) ? (
    <Icon
      htmlColor={
        !hasPreviousApproveRejectStatus ||
        versionState === DocumentVersionState.Approved
          ? theme.palette.success.main
          : theme.palette.grey[700]
      }
      iconName="approveFile"
      opacity={iconOpacity}
    />
  ) : versionState === DocumentVersionState.Rejected ||
    (hasPreviousApproveRejectStatus === DocumentVersionState.Rejected &&
      versionState === DocumentVersionState.None) ? (
    <Icon
      htmlColor={
        !hasPreviousApproveRejectStatus ||
        versionState === DocumentVersionState.Rejected
          ? theme.palette.error.main
          : theme.palette.grey[700]
      }
      iconName="notInterested"
      opacity={iconOpacity}
    />
  ) : null;
};
