/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ApolloHookReturnType,
  QcPermissionCategory,
  useVisualContextsQuery,
  VisualContext,
  VisualContextStatus,
} from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import { useQualityControlContext } from '@pro4all/quality-control/context';
import {
  DrawingRouterState,
  useQCPermissions,
} from '@pro4all/quality-control/data-access';
import { DroppedDrawing } from '@pro4all/quality-control/data-access';
import { useMapLinkingContext } from '@pro4all/quality-control/ui/maps';
import { getDrawingRoute } from '@pro4all/quality-control/utils';
import { Action } from '@pro4all/shared/config';
import { useRouting } from '@pro4all/shared/routing-utils';
import { useIsMobileScreen } from '@pro4all/shared/themes';
import {
  FileUpload,
  FileUploadOverlay,
  useFileRejections,
  useFileUploadContext,
} from '@pro4all/shared/ui/file-upload';
import { FilterContextProvider } from '@pro4all/shared/ui/filtering';
import { BigMessageNoQualityControlDrawings } from '@pro4all/shared/ui/messages';
import { ResponseWrapper } from '@pro4all/shared/ui/response-wrapper';
import {
  Table,
  TableContextProvider,
  useOptimisticResponseContext,
  useSetItemsInLocalState,
} from '@pro4all/shared/ui/table';
import { useAnalytics } from '@pro4all/shared/vendor';

import { DrawingsActionBar } from './DrawingActionBar';
import VisualContextListMobile from './DrawingMobileList';
import { DrawingUploadModal } from './DrawingUploadModal';
import { DrawingsMainProps } from './types';
import { useColumns } from './useColumns';
import { useFetchDrawings } from './useFetchDrawings';

type VCResult = ApolloHookReturnType<
  typeof useVisualContextsQuery,
  'visualContexts'
>;

const MAX_SIZE = 50000000;

export const DrawingsMain = ({ projectId }: DrawingsMainProps) => {
  const { t } = useTranslation();
  const { goTo, params, url } = useRouting();
  const [showModal, setShowModal] = useState(false);

  const isMobileScreen = useIsMobileScreen();

  const {
    canUpdate,
    setCanUpdate,
    cantUpdate,
    setCantUpdate,
    beCheck,
    setBeCheck,
    cantUpload,
    setCantUpload,
    canUpload,
    setCanUpload,
  } = useQualityControlContext();

  projectId ??= params.projectId;

  useMapLinkingContext().setPreviousRoute('projectQualityControlDrawingsUrl');

  const columns = useColumns();

  const { data } = useFetchDrawings(projectId);

  const visualContexts = useMemo(
    () => data?.visualContexts || [],
    [data?.visualContexts]
  );

  useSetItemsInLocalState<VisualContext>(visualContexts);

  const { reset } = useFileUploadContext();
  const acceptedFileTypes = ['.jpeg', '.jpg', '.pdf', '.png'];

  const {
    state: { items, itemsInitial },
  } = useOptimisticResponseContext<VisualContext>();

  const onDropRejected = useFileRejections({
    acceptedFileTypes,
    maxSize: MAX_SIZE,
  });

  const decodeDrawingNames = (drawings: VisualContext[]) =>
    drawings.map((drawing) => ({
      ...drawing,
      name: decodeURIComponent(drawing.name),
    }));

  const { track } = useAnalytics();

  const { userDisplayName } = useOrganizationContext();

  const {
    floorCreateNone,
    floorCreateAll,
    floorReadOwnAssigned,
    floorReadAll,
    floorUpdateOwn,
    floorUpdateAll,
  } = useQCPermissions({
    category: QcPermissionCategory.Qcs,
  });

  const analyzeDrawingsToUpload = (droppedDrawings: DroppedDrawing[]) => {
    const owned: DroppedDrawing[] = [];
    const notOwned: DroppedDrawing[] = [];
    const newFiles: DroppedDrawing[] = [];

    droppedDrawings.forEach((droppedDrawing) => {
      const file = items.find((file) => file.name === droppedDrawing.name);

      if (file) {
        droppedDrawing.visualContextId = file.id;
        if (file.createdBy.displayName === userDisplayName) {
          owned.push(droppedDrawing);
        } else {
          notOwned.push(droppedDrawing);
        }
      } else {
        droppedDrawing.visualContextId = '';
        newFiles.push(droppedDrawing);
      }
    });

    return [owned, notOwned, newFiles];
  };

  const onDrop = (droppedDrawings: []) => {
    reset();

    const [owned, notOwned, newFiles] =
      analyzeDrawingsToUpload(droppedDrawings);

    setCanUpdate(
      (owned.length > 0 ? owned : []).concat(
        notOwned.length > 0 && floorUpdateAll ? notOwned : []
      )
    );

    setCantUpdate(notOwned.length > 0 && floorUpdateOwn ? notOwned : []);
    setBeCheck(
      newFiles.length > 0 && floorCreateAll && floorReadOwnAssigned
        ? newFiles
        : []
    );
    setCanUpload(
      newFiles.length > 0 && floorCreateAll && floorReadAll ? newFiles : []
    );
    setCantUpload(newFiles.length > 0 && floorCreateNone ? newFiles : []);

    setShowModal(true);
  };

  const onClick = (visualContext: VisualContext) => {
    const { id, name, status } = visualContext;

    track(Action.OpenDrawing, {
      id,
      name,
      projectId,
    });

    status === VisualContextStatus.Processed &&
      goTo(getDrawingRoute(params), {
        params: {
          objectId: params.objectId,
          projectId,
          visualContextId: id,
        },
        state: {
          previousPageName: 'drawings',
          previousPageUrl: url,
        } as DrawingRouterState,
      });
  };

  useEffect(() => {
    if (
      showModal &&
      canUpdate.length === 0 &&
      cantUpdate.length === 0 &&
      beCheck.length === 0 &&
      canUpload.length === 0 &&
      cantUpload.length === 0
    ) {
      setCanUpdate([]);
      setCanUpload([]);
      setBeCheck([]);
      setCantUpload([]);
      setCantUpdate([]);
      setShowModal(false);
      reset();
    }
  }, [
    canUpdate,
    cantUpdate,
    canUpload,
    cantUpload,
    beCheck,
    showModal,
    setCanUpdate,
    setCanUpload,
    setBeCheck,
    setCantUpload,
    setCantUpdate,
    reset,
  ]);
  return (
    <>
      <ResponseWrapper isLoading={!data}>
        <FileUpload
          accept={acceptedFileTypes}
          maxSize={MAX_SIZE}
          onDrop={onDrop}
          onDropRejected={onDropRejected}
        >
          {({ isDragActive, openFileInput }) => (
            <TableContextProvider
              columns={columns}
              id="table-qc-drawings"
              items={decodeDrawingNames(items)}
            >
              <FilterContextProvider<VisualContext>>
                <DrawingsActionBar
                  openFileInput={openFileInput}
                  visualContexts={data?.visualContexts}
                />
                <FileUploadOverlay isActive={isDragActive}>
                  {items.length || itemsInitial.length ? (
                    isMobileScreen ? (
                      <VisualContextListMobile
                        onRowClick={onClick}
                        visualContexts={items}
                      ></VisualContextListMobile>
                    ) : (
                      <Table<VCResult> onRowClick={onClick} />
                    )
                  ) : (
                    <BigMessageNoQualityControlDrawings />
                  )}
                </FileUploadOverlay>
              </FilterContextProvider>
            </TableContextProvider>
          )}
        </FileUpload>
      </ResponseWrapper>

      <DrawingUploadModal
        name="resolveTaskWarning"
        onClose={() => {
          setCanUpdate([]);
          setCanUpload([]);
          setBeCheck([]);
          setCantUpload([]);
          setCantUpdate([]);
          setShowModal(false);
          reset();
        }}
        onConfirm={null}
        open={showModal}
        title={t('Drawings upload confirmation')}
        visualContexts={data?.visualContexts}
      />
    </>
  );
};
