import { useRef } from 'react';

import { client } from '@pro4all/authentication/src/graph-ql';
import { useFolderTreeContextOuter } from '@pro4all/documents/ui/folders';
import { Document, FolderByPathDocument } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';

export const useCacheHelpers = () => {
  const { folderEmptyAfterDocumentDelete } = useFolderTreeContextOuter();
  const previousDocuments = useRef<Document[]>([]);
  const {
    params: { path, projectId },
  } = useRouting();

  const getCachedFolder = () =>
    client?.readQuery({
      query: FolderByPathDocument,
      variables: { path: path ?? '/', projectId },
    });

  const updateCache = ({
    updatedDocuments,
  }: {
    updatedDocuments: Document[];
  }) => {
    const hasDocuments = updatedDocuments.length > 0;
    const cachedFolder = getCachedFolder();
    previousDocuments.current = cachedFolder.folder.documents;

    // Update the props `documents` and `hasDocuments` in the folder cache.
    client?.cache.modify({
      fields: {
        documents() {
          return updatedDocuments;
        },
        hasDocuments() {
          return hasDocuments;
        },
      },
      id: client.cache.identify(cachedFolder.folder),
    });

    // We need to update the folder to show the correct folder icon, for the case that all documents in this folder have been deleted.
    if (!hasDocuments) folderEmptyAfterDocumentDelete(cachedFolder.folder.id);
  };

  const restoreDocumentsCallback = () =>
    updateCache({ updatedDocuments: previousDocuments.current });

  return { getCachedFolder, restoreDocumentsCallback, updateCache };
};
