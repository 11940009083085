import React, { useEffect, useState } from 'react';

import { AuthService } from '@pro4all/authentication/src/services/auth-service';
import { useRouting } from '@pro4all/shared/routing-utils';
import { SsoFailPage } from '@pro4all/shared/ui/sso-fail-page';

export const Sso = () => {
  const { searchParams } = useRouting();
  const ssoError = searchParams.get('error');
  const passwordChanged = searchParams.get('passwordChanged');
  const [hasError, setHasError] = useState(false);

  const logOnConsole = Boolean(hasError || ssoError);

  useEffect(() => {
    if (logOnConsole) console.log('sso.tsc: useEffect()', passwordChanged);
    const signIn = async () => {
      if (logOnConsole) console.log('sso-signin: init.');
      if (passwordChanged) {
        if (logOnConsole) console.log('sso-signin: passwordChanged.');
        await AuthService.signoutRedirectCallbackAction();
      } else {
        try {
          if (logOnConsole) console.log('sso-signin: non password changed.');
          await AuthService.signinRedirectCallbackAction();
        } catch (error: any) {
          console.log('error on signinRedirectCallbackAction', error);
          setHasError(true);
        }
      }
    };
    signIn();
  }, [logOnConsole, passwordChanged]);

  if (logOnConsole) {
    console.log('window', window.location);
    console.log('searchParams', searchParams);
  }

  return hasError || ssoError ? <SsoFailPage /> : <span>Signing in...</span>;
};
