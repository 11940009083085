import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useGetTbqGlobalScopeLazyQuery } from '@pro4all/graphql';
import { Box } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Option } from '@pro4all/shared/types';
import { Select } from '@pro4all/shared/ui/inputs';

const ScopeDropdown = () => {
  const {
    searchParams,
    params: { tbqScopeId },
    goTo,
  } = useRouting();
  const navigate = useNavigate();
  const [getScopes] = useGetTbqGlobalScopeLazyQuery();
  const [scopeOptions, setScopeOptions] = useState<Option[]>([]);

  useEffect(() => {
    if (!tbqScopeId && scopeOptions.length > 0) {
      goTo('metadataTbqTemplates', {
        params: {
          tbqScopeId: scopeOptions[0].id,
        },
      });
    }
  }, [scopeOptions]);

  const handleChange = useCallback(
    (e: Option) => {
      const actionParam = searchParams.get('action');
      goTo('metadataTbqTemplates', {
        params: {
          tbqScopeId: e.id,
        },
        state: { previousRouteParam: actionParam },
      });
    },
    [navigate, searchParams]
  );

  useEffect(() => {
    const asyncScopes = async () => {
      const response = await getScopes();
      const scopeOptions: Option[] = response?.data?.getTBQGlobalScope?.map(
        (gs) => ({
          id: gs.scopeId,
          label: gs.name,
        })
      );
      setScopeOptions(scopeOptions);
    };
    asyncScopes();
  }, []);

  return (
    <Box>
      {scopeOptions.length > 0 && (
        <StyledSelect
          defaultValue={scopeOptions.length > 0 ? scopeOptions[0].id : null}
          name="tbqScope"
          onChange={(e) => handleChange(e)}
          options={scopeOptions}
          value={scopeOptions.find((so) => so.id === tbqScopeId)}
        />
      )}
    </Box>
  );
};

const StyledSelect = styled(Select)`
  && {
    width: 170px;
  }
`;

export default ScopeDropdown;
