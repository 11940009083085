import { useRef } from 'react';

import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Divider,
  Typography,
} from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';
import { ProfilePictureEntity } from '@pro4all/shared/types';
import { MiddleEllipsis } from '@pro4all/shared/ui/middle-ellipsis';
import { useInScreen } from '@pro4all/shared/ui/table';

import { TableGridViewProfilePicture } from './TableGridViewProfilePicture';
import { TableGridViewThumbnailPicture } from './TableGridViewThumbnailPicture';
import { TableGridItem, THUMBNAIL_HEIGHT } from './types';

export const TableGridViewCard = ({
  containerHeight,
  onClick,
  parentScrollPosition,
  profilePictureEntity,
  itemToShow,
}: {
  containerHeight: number;
  itemToShow: TableGridItem;
  onClick: () => void;
  parentScrollPosition: number;
  profilePictureEntity?: ProfilePictureEntity;
}) => {
  const cardRef = useRef<HTMLDivElement>(null);
  const inScreen = useInScreen(cardRef, containerHeight, parentScrollPosition);

  return (
    <Card ref={cardRef} variant="outlined">
      <CardActionArea onClick={onClick}>
        <CardContent>
          <MiddleEllipsis text={itemToShow.title}></MiddleEllipsis>
          <Typography color="text.secondary" variant="body2">
            {itemToShow.description ?? ''}
          </Typography>
        </CardContent>
        <Divider />
        <Box
          sx={{
            alignItems: 'center',
            backgroundColor: customColors.grey400,
            display: 'flex',
            height: `${THUMBNAIL_HEIGHT}px`,
            justifyContent: 'center',
            width: '100%',
          }}
        >
          {itemToShow.imageSection ? (
            itemToShow.imageSection
          ) : itemToShow.versionId ? (
            <TableGridViewThumbnailPicture
              inScreen={inScreen}
              item={itemToShow}
            />
          ) : (
            profilePictureEntity && (
              <TableGridViewProfilePicture
                inScreen={inScreen}
                item={itemToShow}
                profilePictureEntity={profilePictureEntity}
              />
            )
          )}
        </Box>
        <CardActions>{itemToShow.bottomSection}</CardActions>
      </CardActionArea>
    </Card>
  );
};
