import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import {
  useRestoreDocumentMutation,
  useRestoreDocumentVersionMutation,
} from '@pro4all/graphql';
import { Button } from '@pro4all/shared/mui-wrappers';
import {
  EntityTypeTranslation,
  ItemChangedMessage,
  MessageAction,
} from '@pro4all/shared/ui/messages';

export const SnackbarDocumentDeleted = ({
  documentId,
  documentName,
  restoreItems,
  versionId,
}: {
  documentId: string;
  documentName: string;
  onComplete?: () => void;
  restoreItems: () => void;
  versionId?: string;
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [restoreDocument] = useRestoreDocumentMutation();
  const [restoreDocumentVersion] = useRestoreDocumentVersionMutation();
  const [hideUndoAction, setHideUndoAction] = useState(false);

  const entityTypeTranslation = versionId
    ? EntityTypeTranslation.DocumentVersion
    : EntityTypeTranslation.Document;

  const onRestoreDocument = async () => {
    let message = (
      <ItemChangedMessage
        description={MessageAction.Restore}
        entityName={documentName}
        entityTypeTranslation={entityTypeTranslation}
      />
    );
    try {
      if (versionId) {
        await restoreDocumentVersion({
          variables: { documentId, versionId },
        });
        restoreItems();
      } else {
        await restoreDocument({
          variables: { id: documentId },
        });
        restoreItems();
      }
    } catch (e) {
      message = t('Something went wrong');
    }
    enqueueSnackbar(message);
  };

  const undoAction = hideUndoAction ? null : (
    <Button
      color="inherit"
      onClick={() => {
        onRestoreDocument();
        setHideUndoAction(true);
      }}
      size="small"
    >
      {t('Undo')}
    </Button>
  );

  return (
    <ItemChangedMessage
      action={undoAction}
      description={MessageAction.Delete}
      entityName={documentName}
      entityTypeTranslation={entityTypeTranslation}
    />
  );
};
