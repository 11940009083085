import React from 'react';
import { useTranslation } from 'react-i18next';

import { useGetDeleteTbqScanItemsLazyQuery } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';
import { customColors } from '@pro4all/shared/themes';
import { Dialog } from '@pro4all/shared/ui/dialog';
import { Text } from '@pro4all/shared/ui/typography';
import { useDeleteTbqScan } from '@pro4all/workflow/data-access';

interface DeleteTBQScanDialogProps {
  onClose: () => void;
  open: boolean;
  procedureId: string;
  taskIds: string[];
}

export const DeleteTBQScanDialog: React.FC<DeleteTBQScanDialogProps> = ({
  open,
  onClose,
  taskIds,
  procedureId,
}) => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();
  const [
    fetchDeleteTbqScanItems,
    { loading: loadingScanItems, data: dataScanItems },
  ] = useGetDeleteTbqScanItemsLazyQuery({
    fetchPolicy: 'cache-and-network',
  });

  const { handleDelete } = useDeleteTbqScan({
    linkedSnagIds: dataScanItems?.getDeleteTBQScanItems?.deletingSnags ?? [],
    procedureId,
    taskIds: dataScanItems?.getDeleteTBQScanItems?.deletingTasks ?? [],
    tbqFormIds: dataScanItems?.getDeleteTBQScanItems?.deletingForms ?? [],
  });

  // Fetch delete items data when dialog opens
  React.useEffect(() => {
    if (open && taskIds.length > 0) {
      fetchDeleteTbqScanItems({ variables: { taskIds } });
    }
  }, [fetchDeleteTbqScanItems, open, taskIds]);

  if (!open) {
    return null;
  }

  return (
    <Dialog
      buttonColor={customColors['errorAlert']}
      confirmLabel={t('Delete')}
      iconName="warning"
      name="forgeDialog"
      onClose={onClose}
      onConfirm={async () => {
        if (!loadingScanItems) {
          await handleDelete();
          if (searchParams.get('id')) {
            searchParams.delete('id');
          }
          if (searchParams.get('action')) {
            searchParams.delete('action');
          }
          onClose();
        }
      }}
      open={open}
      submitDisabled={loadingScanItems}
      title={t('Are you sure?')}
    >
      {loadingScanItems ? (
        <Text>{t('Loading scan details...')}</Text>
      ) : (
        <Text display="inline">
          {t(
            "You are about to delete {{scans}} scans, and {{snags}} linked snags. This action can't be undone.",
            {
              scans:
                dataScanItems?.getDeleteTBQScanItems?.deletingTasks?.length ||
                0,
              snags:
                dataScanItems?.getDeleteTBQScanItems?.deletingSnags?.length ||
                0,
            }
          )}
        </Text>
      )}
    </Dialog>
  );
};
