import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  OrganizationSubscriptionLevel,
  useUnreadCountQuery,
} from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import { useFetchProject } from '@pro4all/projects/data-access';
import {
  ProjectAccessType,
  useProjectContext,
} from '@pro4all/projects/ui/context';
import { ApiConfig } from '@pro4all/shared/config';
import { useSideNavContext } from '@pro4all/shared/contexts';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { useSubscriptionRestriction } from '@pro4all/shared/hooks';
import { useMediaQuery, useTheme } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import {
  MenuItem,
  NavigationItems,
  OrganizationTitle,
  SideNavigation,
} from '@pro4all/shared/ui/side-navigation';

import { ExitProjectButton } from './ExitProjectButton';

export const ProjectNavigation = ({
  projectPermission,
}: {
  projectPermission: ProjectAccessType;
}) => {
  const { t } = useTranslation();
  const hasQualityControlFeatureFlag = useFeatureFlag('qualitycontrol');
  const theme = useTheme();
  const tooSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    params: { projectId },
  } = useRouting();
  const hasObjectsFeature = useFeatureFlag('objects');
  const hasHierarchyFeature = useFeatureFlag('hierarchy-field');
  const hasQCNotificationsFeature = useFeatureFlag('quality-digest-settings');
  const hasDraftFeature = useFeatureFlag('draft-messages');
  const hasSeriesFeature = useFeatureFlag('task-series');

  const {
    sideNavState: { isSideNavOpen },
  } = useSideNavContext();

  const { projectData } = useFetchProject();
  const {
    id,
    name,
    organizationSubscriptionLevel: subscriptionLevel,
  } = projectData?.project.organization || {};
  const organizationName = name || '';
  const organizationSubscriptionLevel =
    subscriptionLevel || OrganizationSubscriptionLevel.Inactive;

  const { userIsOrganizationAdmin } = useOrganizationContext();

  const { isExternalUserInProject, projectAccessLoadStatus } =
    useProjectContext();

  const isOrgAdminWithoutProjectAccess =
    userIsOrganizationAdmin &&
    projectAccessLoadStatus?.status === ProjectAccessType.NoAccess;

  const projectUpdate = Boolean(
    projectPermission === ProjectAccessType.ProjectUpdate ||
      isOrgAdminWithoutProjectAccess
  );

  const { hasDmsLicense, hasQualityControlLicense } =
    useSubscriptionRestriction(organizationSubscriptionLevel);
  const { data } = useUnreadCountQuery({
    fetchPolicy: 'cache-first',
    pollInterval: 10000 * ApiConfig.pollEnabled,
    variables: { isOrganization: false },
  });

  const unreadAllMessages =
    data?.unreadCount?.find((item) => item?.id === projectId)?.all || 0;
  const unreadInboxMessages =
    data?.unreadCount?.find((item) => item?.id === projectId)?.inbox || 0;
  const unreadSentMessages =
    data?.unreadCount?.find((item) => item?.id === projectId)?.sent || 0;
  const unreadDraftMessages =
    data?.unreadCount?.find((item) => item?.id === projectId)?.draft || 0;

  const menuItems: MenuItem[] = [
    {
      condition: !isOrgAdminWithoutProjectAccess,
      dataTestId: 'project-messages',
      displayName: t('Messages'),
      icon: unreadAllMessages > 0 ? 'prostreamChatUnread' : 'prostreamChat',
      id: 'messages',
      messageCount: unreadAllMessages,
      route: 'projectAllMessages',
      subItems: [
        {
          dataTestId: 'project-messages-all',
          displayName: t('All'),
          icon: undefined,
          messageCount: unreadAllMessages,
          route: 'projectAllMessages',
        },
        {
          dataTestId: 'project-messages-inbox',
          displayName: t('Inbox'),
          icon: undefined,
          messageCount: unreadInboxMessages,
          route: 'projectMessagesInbox',
        },
        {
          countColor: 'secondary',
          dataTestId: 'project-messages-sent',
          displayName: t('Sent'),
          messageCount: unreadSentMessages,
          route: 'projectMessagesSent',
        },
        {
          condition: hasDraftFeature,
          countColor: 'secondary',
          dataTestId: 'project-messages-draft',
          displayName: t('Draft'),
          messageCount: unreadDraftMessages,
          route: 'projectMessagesDraft',
        },
      ],
      trackPayload: 'Messages',
    },
    {
      dataTestId: 'project-myTasks',
      displayName: t('Tasks'),
      icon: 'qcTask',
      id: 'workflows',
      route: 'projectTasks',
      trackPayload: 'Project workflow',
    },
    {
      condition: hasDmsLicense,
      dataTestId: 'project-documents',
      displayName: t('Documents'),
      icon: 'folderOpen',
      id: 'Documents',
      route: 'projectDocumentsTablesPathUrl',
      subItems: [
        {
          dataTestId: 'project-documents-folders',
          displayName: t('Folders'),
          route: 'projectDocumentsTablesPathUrl',
        },
        {
          dataTestId: 'project-documents-tasks',
          displayName: t('Tasks'),
          route: 'projectDocTasks',
        },
        {
          condition: !tooSmallScreen,
          dataTestId: 'project-documents-notifications',
          displayName: t('Notifications'),
          route: 'projectNotificationsUrl',
        },
      ],
    },
    {
      condition: hasQualityControlFeatureFlag && hasQualityControlLicense,
      dataTestId: 'project-qc',
      displayName: t('Quality control'),
      icon: 'controlResults',
      id: 'qualityControl',
      route: 'projectQualityControlResultsUrl',
      subItems: [
        {
          dataTestId: 'project-qc-results',
          displayName: t('Results'),
          route: 'projectQualityControlResultsUrl',
        },
        {
          dataTestId: 'project-qc-drawings',
          displayName: t('Drawings'),
          route: 'projectQualityControlDrawingsUrl',
        },
        {
          dataTestId: 'projects-qc-tasks',
          displayName: t('Tasks'),
          route: 'projectQualityControlTasksUrl',
        },
        {
          dataTestId: 'projects-qc-my-saved-answers',
          displayName: t('My saved answers'),
          route: 'projectQualityControlMySavedAnswersUrl',
        },
        {
          condition: hasSeriesFeature,
          dataTestId: 'projects-qc-recurring-series',
          displayName: t('Series'),
          route: 'projectQualityControlRecurringSeries',
        },
        {
          condition: hasQCNotificationsFeature,
          dataTestId: 'projects-qc-notifications',
          displayName: t('Notifications'),
          route: 'projectQualityControlNotificationsUrl',
        },
      ],
    },
    {
      condition: !tooSmallScreen && hasObjectsFeature,
      dataTestId: 'project-objects',
      displayName: t('Objects'),
      icon: 'deviceHub',
      id: 'projectObjects',
      route: 'projectObjectsUrl',
    },
    {
      condition: projectUpdate && !tooSmallScreen,
      dataTestId: 'project-templates',
      displayName: t('Templates'),
      icon: 'qualityControl',
      id: 'projectTemplates',
      route: 'projectTaskTemplatesUrl',
      subItems: [
        {
          condition: hasDmsLicense,
          dataTestId: 'project-templates-documents',
          displayName: t('Metadata'),
          route: 'projectMetadataDocumentFieldsUrl',
        },
        {
          condition: hasQualityControlFeatureFlag && hasQualityControlLicense,
          dataTestId: 'project-templates-qc',
          displayName: t('Quality control'),
          route: 'projectMetadataQualityForms',
        },
        {
          condition:
            hasQualityControlFeatureFlag &&
            hasQualityControlLicense &&
            hasHierarchyFeature,
          dataTestId: 'project-hierarchical-lists',
          displayName: t('Hierarchical lists'),
          route: 'projectMetaDataHierarchicalLists',
        },
        {
          dataTestId: 'project-templates-tasks',
          displayName: t('Tasks'),
          route: 'projectTaskTemplatesUrl',
        },
      ],
    },
    {
      condition: !tooSmallScreen,
      dataTestId: 'project-permissions',
      displayName: t('Permissions'),
      icon: 'security',
      id: 'permissions',
      route: `projectDocumentPermissions`,
      subItems: [
        {
          condition: hasDmsLicense,
          dataTestId: 'project-permissions-documents',
          displayName: t('Documents'),
          route: 'projectDocumentPermissions',
        },
        {
          condition: hasQualityControlLicense && hasQualityControlFeatureFlag,
          dataTestId: 'project-permissions-qc',
          displayName: t('Quality control'),
          route: 'projectQualityPermissions',
        },
        {
          condition:
            (hasQualityControlLicense && hasQualityControlFeatureFlag) ||
            hasDmsLicense,
          dataTestId: 'project-permissions-tasks',
          displayName: t('Tasks'),
          route: 'projectTaskPermissions',
        },
      ],
      trackPayload: {
        targetLocation: 'Access',
      },
    },
    {
      condition: projectUpdate,
      dataTestId: 'project-settings',
      displayName: t('Settings'),
      icon: 'settingsApplications',
      id: 'projectSettings',
      route: `projectSettingsUrl`,
      subItems: [
        {
          dataTestId: 'project-settings-project',
          displayName: t('Project'),
          route: `projectSettingsUrl`,
        },
        {
          condition: hasDmsLicense && !isExternalUserInProject,
          dataTestId: 'project-settings-connections',
          displayName: t('Connections'),
          route: `connectionsSettingsUrl`,
        },
        {
          condition: hasDmsLicense && !isExternalUserInProject,
          dataTestId: 'project-settings-documents',
          displayName: t('Documents'),
          route: `projectDocumentSettingsUrl`,
        },
      ],
      trackPayload: {
        targetLocation: 'Settings',
      },
    },
    {
      condition: projectUpdate,
      dataTestId: 'project-projectMembers',
      displayName: t('Project members'),
      icon: 'group',
      id: 'projectMembers',
      route: `projectMembersUrl`,
      subItems: [
        {
          dataTestId: 'project-projectMembers-members',
          displayName: t('Members'),
          route: `projectMembersUrl`,
        },
        {
          dataTestId: 'project-projectMembers-groups',
          displayName: t('Groups'),
          route: 'projectGroupsUrl',
        },
      ],
      trackPayload: 'Users and groups',
    },
  ];
  return (
    <SideNavigation color="light">
      {isSideNavOpen && (
        <OrganizationTitle
          organizationId={id || ''}
          organizationName={organizationName}
          projectId={projectId}
          projectName={projectData?.project?.name || ''}
        />
      )}
      <NavigationItems menuItems={menuItems} />
      <ExitProjectButton />
    </SideNavigation>
  );
};
