import { useCallback, useRef } from 'react';

import { useLocalStorage } from '@pro4all/shared/hooks';
import {
  FilterHeaderType,
  FilterType,
  FilterTypeLS,
} from '@pro4all/shared/ui/filtering';
import { BaseRow, useTableContext } from '@pro4all/shared/ui/table';

import { useOnSet } from './useOnSet';

export function useFiltersFromLocalStorage<Row extends BaseRow, SubProp>({
  updateTableBasedOnCurrentFilters,
  setRecalculateFilters,
  filterType,
  isMetaData,
  isMultiSelect,
  metaDataHeaderId,
  propertyId,
  subPropertyId,
  translateOptions,
}: {
  filterType?: FilterHeaderType;
  isMetaData?: boolean;
  isMultiSelect?: boolean;
  metaDataHeaderId?: string;
  propertyId?: keyof Row;
  recalculateFilters: boolean;
  setRecalculateFilters: (value: boolean) => void;
  subPropertyId?: keyof SubProp | undefined;
  translateOptions?: boolean | undefined;
  updateTableBasedOnCurrentFilters: (filters: FilterType<Row>[]) => void;
}) {
  const { id, idFilteringSorting } = useTableContext<Row>();
  const tableId = idFilteringSorting || id;

  const { getFilteredItems } = useOnSet<Row, SubProp>({
    filterType: filterType ?? FilterHeaderType.Text,
    isMetaData,
    isMultiSelect,
    metaDataHeaderId,
    propertyId: propertyId ?? 'id',
    subPropertyId,
    translateOptions,
  });

  const { localStorageItem } = useLocalStorage({
    key: `prostream-column-filters-${tableId}`,
  }) as { localStorageItem: FilterTypeLS[] | {} };

  const itemsFilteredPopulated = useRef(false);

  return useCallback(() => {
    const filtersFromLocalStorage: FilterType<Row>[] = Array.isArray(
      localStorageItem
    )
      ? localStorageItem.map(
          ({
            filterType,
            filterValues,
            isMultiSelect,
            propertyId,
            subPropertyId,
            translateOptions,
          }) => {
            const propId = propertyId.split('.')[0] as keyof Row;
            const metaDataHeaderId = propertyId.split('.')[1];
            const subPropId = subPropertyId as keyof SubProp;
            return {
              filterType,
              filterValues,
              isMultiSelect,
              itemsFiltered: getFilteredItems({
                filterType,
                filterValues,
                isMetaDataLocalStorage: Boolean(metaDataHeaderId),
                isMultiSelectLocalStorage: isMultiSelect,
                metaDataHeaderIdLocalStorage: metaDataHeaderId,
                propertyId: propId,
                subPropertyId: subPropId,
                translateOptionsLocalStorage: translateOptions,
              }),
              propertyId,
              subPropertyId,
              translateOptions,
            };
          }
        )
      : [];
    itemsFilteredPopulated.current = true;
    updateTableBasedOnCurrentFilters(filtersFromLocalStorage);
    setRecalculateFilters(false);
  }, [
    localStorageItem,
    getFilteredItems,
    updateTableBasedOnCurrentFilters,
    setRecalculateFilters,
  ]);
}
