import { deepmerge } from '@mui/utils';

import { ThemeOptions } from '@pro4all/shared/mui-wrappers';

import { customColors } from './customColors';
import { muiStyleOverridesGeneric } from './muiStyleOverridesGeneric';
import { muiStyleOverridesPave } from './muiStyleOverridesPave';
import { themeGeneric } from './themeGeneric';

export type ThemeColor = keyof typeof customColors;

export const themePave: ThemeOptions = deepmerge(themeGeneric, {
  components: deepmerge(muiStyleOverridesGeneric, muiStyleOverridesPave),
  palette: {
    action: {
      hover: 'rgba(100, 0, 0, 0.10)',
    },
    primary: {
      contrastText: customColors.grey900,
      dark: customColors.pavePrimaryDark,
      light: customColors.pavePrimaryLight,
      main: customColors.pavePrimaryMain,
    },
    secondary: {
      contrastText: customColors.grey900,
      dark: customColors.paveSecondaryDark,
      light: customColors.paveSecondaryLight,
      main: customColors.paveSecondaryMain,
    },
    text: {
      primary: customColors.grey900,
      secondary: customColors.grey700,
    },
  },
});
