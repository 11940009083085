import { customColors } from '@pro4all/shared/themes';
import { hexToRgb } from '@pro4all/shared/utils';

export const applyColor = (color: string) => {
  const rgba = hexToRgb(color, '1');
  if (rgba !== '(132, 132, 132, 1)') {
    const rgbaValues = rgba.split(',');
    const red = parseInt(rgbaValues[0].split('(')[1]);
    const green = parseInt(rgbaValues[1]);
    const blue = parseInt(rgbaValues[2]);
    return (color =
      red * 0.299 + green * 0.587 + blue * 0.114 > 186
        ? customColors.grey900
        : customColors.white);
  } else {
    return (color = customColors.black);
  }
};
