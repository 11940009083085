import styled from 'styled-components';

import { customColors } from '@pro4all/shared/themes';
import { Loader as UILoader } from '@pro4all/shared/ui/loader';

import { navBarHeight } from '../Stamper.styles';

const spaceFromTopToCanvas = Number(navBarHeight.replace('px', '')) * 4;

export const Canvas = styled.div`
  box-shadow: 0 0 0 2px ${customColors.grey400};
  height: 100%;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
`;

export const Figure = styled.figure`
  flex: 1;
  margin: 0;
  min-height: 0;
  position: relative;
`;

export const Img = styled.img`
  display: block;
  height: 100%;
  max-height: calc(100vh - ${spaceFromTopToCanvas}px);
  object-fit: contain;
  user-select: none;
  justify-self: center;
  max-width: 100vh;
  width: 100%;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  max-height: calc(100vh - ${spaceFromTopToCanvas}px);
  user-select: none;
  width: 100%;

  .react-pdf__Document {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .react-pdf__Page {
    display: flex;
    justify-content: center;
  }

  canvas {
    max-width: 100%;
  }
`;

export const Loader = styled(UILoader)`
  background-color: ${customColors.grey300};
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`;
