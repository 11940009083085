import React, { PropsWithChildren, useEffect, useState } from 'react';
import { NestedMenuItem } from 'mui-nested-menu';

import { ActionProps, useGroupedActions } from '@pro4all/shared/actions';
import { Box, Divider, MenuProps } from '@pro4all/shared/mui-wrappers';
import { Icon } from '@pro4all/shared/ui/icons';

import * as Styled from './ContextMenu.styled';
import { Position } from './types';

export interface ContextMenuProps {
  initialPosition?: Position;
  menuItems?: ActionProps[];
  onClose?: () => void;
  open: boolean;
  variant?: MenuProps['variant'];
}

export const OFFSET_X = -2;
export const OFFSET_Y = -4;

export const ContextMenu = ({
  children,
  open,
  onClose,
  menuItems,
  initialPosition = { left: 0, top: 0 },
  variant,
}: PropsWithChildren<ContextMenuProps>) => {
  const [position, setPosition] = useState<Position>(initialPosition);
  const [hasSetPosition, setHasSetPosition] = useState(false);

  const { getGroupedActions } = useGroupedActions();

  const preventAndClose = (event: React.MouseEvent) => {
    event.preventDefault();
    onClose && onClose();
  };

  const setMousePosition = (e: MouseEvent) => {
    setPosition({
      left: e.clientX + OFFSET_X,
      top: e.clientY + OFFSET_Y,
    });
    setHasSetPosition(true);
  };

  useEffect(() => {
    setPosition({ left: initialPosition.left, top: initialPosition.top });
    setHasSetPosition(true);
  }, [initialPosition.left, initialPosition.top]);

  useEffect(() => {
    window.addEventListener('contextmenu', setMousePosition);
    return () => {
      window.removeEventListener('contextmenu', setMousePosition);
    };
  });

  const stopPropagation = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  const showMenu =
    (Boolean(menuItems?.length) || Boolean(React.Children.count(children))) &&
    hasSetPosition;

  const processMenuItems = (menuItems: ActionProps[]) => {
    const menuItemsFiltered = menuItems.filter(
      (menuItem) => !menuItem.disabled && !menuItem.hidden
    );

    const groupedActions = getGroupedActions({
      actions: menuItemsFiltered,
    });

    return Object.entries(groupedActions).map(
      ([, groupActions], groupIndex, groupArray) =>
        groupActions?.map((action, actionIndex) => {
          const {
            ariaLabel,
            disabled,
            endIcon,
            key,
            label,
            onClick,
            startIcon,
            subItems,
          } = action;

          const startIconAndLabel = (
            <>
              {startIcon && <Icon color="inherit" iconName={startIcon} />}
              <Styled.Label className="Label">{label}</Styled.Label>
            </>
          );

          const leftIcon = startIcon ? (
            <Icon color="inherit" iconName={startIcon} />
          ) : null;

          const isLastInGroup = actionIndex === groupActions.length - 1; // Check this is the last action in group
          const isLastGroup = groupIndex === groupArray.length - 1; // Check if this is the last group

          return (
            <Box key={key}>
              {subItems?.length ? (
                <NestedMenuItem
                  key={key}
                  label={label}
                  leftIcon={leftIcon}
                  parentMenuOpen
                >
                  {processMenuItems(subItems)}
                </NestedMenuItem>
              ) : (
                <Styled.MenuItem
                  aria-label={ariaLabel}
                  data-testid={`${key}`}
                  disabled={disabled}
                  onClick={(event) => {
                    onClose && onClose();
                    if (onClick) {
                      onClick(event);
                    }
                  }}
                >
                  {startIconAndLabel}
                  {endIcon && <Icon color="inherit" iconName={endIcon} />}
                </Styled.MenuItem>
              )}
              {isLastInGroup && !isLastGroup && <Divider />}
            </Box>
          );
        })
    );
  };

  return showMenu ? (
    <Styled.Menu
      anchorPosition={position}
      anchorReference="anchorPosition"
      data-testid="context-menu"
      id="folder-menu"
      onClick={stopPropagation}
      onClose={onClose}
      onContextMenu={preventAndClose}
      open={open}
      variant={variant}
    >
      {menuItems ? processMenuItems(menuItems) : children}
    </Styled.Menu>
  ) : null;
};

export default ContextMenu;
