import { useTranslation } from 'react-i18next';

import { useUserFolderPermissions } from '@pro4all/documents/data-access';
import { Folder } from '@pro4all/graphql';
import { ActionProps, useCentralActions } from '@pro4all/shared/actions';
import { useRouting } from '@pro4all/shared/routing-utils';

import { useFolderPath } from '../utils/useFolderPath';

export const useEditFolderPermissionsAction = (folder: Folder) => {
  const { t } = useTranslation();
  const { goTo, searchParams } = useRouting();
  const { ACTION_EDIT_PERMISSIONS } = useCentralActions();

  const { docsRoute, projectId } = useFolderPath();
  const path = decodeURIComponent(searchParams.get('folderPath')).replace(
    ',',
    '/'
  );

  const { hasFolderPermission } = useUserFolderPermissions({
    folderId: folder?.id,
  });

  const toFolderPermissions = () => {
    goTo(docsRoute, {
      params: {
        path,
        projectId,
      },
      searchParams: {
        action: 'manageFolderPermissions',
        permissionForFolderPath: path,
      },
    });
  };

  const editFolderPermissionsMenuItem: ActionProps = {
    ...ACTION_EDIT_PERMISSIONS,
    dataTestId: 'permissions-folder',
    disabled: !hasFolderPermission('UpdateFolder'),
    key: 'permissions-folder',
    onClick: toFolderPermissions,
  };

  return { editFolderPermissionsMenuItem };
};
