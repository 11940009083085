import { useTranslation } from 'react-i18next';

import { ReportOptions, TemplateOptions } from '@pro4all/shared/types';

export const defaultTemplate: TemplateOptions = {
  hiddenSectionsAndFields: [],
  imageColumn: 'bTwo',
  showDescription: true,
  showLinkedSnags: true,
};

export const useGetInitialReportOptions = () => {
  const { t } = useTranslation();

  const initialReportOptions: ReportOptions = {
    bgId: '',
    bgIdFrontPage: '',
    compactLists: true,
    enableCreatedBy: true,
    enableCreatedOn: true,
    enableEndDate: true,
    enableFrontPage: true,
    enableLogo: true,
    enableProjectDescription: true,
    enableProjectName: true,
    enableProjectNumber: true,
    enableStartDate: true,
    enableTitle: true,
    footerText: t('Generated with Prostream'),
    inlineLists: true,
    logoId: '',
    showEmptyAnswers: true,
    showPageNumbers: true,
    templates: { default: defaultTemplate },
  };

  return initialReportOptions;
};
