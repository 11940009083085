import React from 'react';
import { Navigate } from 'react-router-dom';

import { AuthService } from '@pro4all/authentication/src/services/auth-service';

export const SignIn = () => {
  const isAuthenticated = AuthService.isAuthenticated();

  if (!isAuthenticated) {
    AuthService.signinRedirectAction();
    return <span>Redirecting..</span>;
  }

  return <Navigate to="/" />;
};
