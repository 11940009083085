import React, { FC } from 'react';
import { RouteProps } from 'react-router-dom';

import { AuthService } from '@pro4all/authentication/src/services/auth-service';
import { AuthType } from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import { useProjectContext } from '@pro4all/projects/ui/context';
import { AppPermissionType, useAppPermissions } from '@pro4all/shared/identity';
import { BigMessageNoAccess } from '@pro4all/shared/ui/messages';
import { SsoEnforcedPage } from '@pro4all/shared/ui/sso-fail-page';

type PrivateRouteProps = RouteProps & {
  appPermission?: AppPermissionType;
};

export const PrivateRoute: FC<PrivateRouteProps> = ({
  element,
  appPermission,
}) => {
  const isAuthenticated = AuthService.isAuthenticated();

  const { enforceSSO, userAuthType } = useOrganizationContext();
  const { isExternalUserInProject } = useProjectContext();
  const { hasAppPermission } = useAppPermissions();

  if (!isAuthenticated) {
    AuthService.signinRedirectAction();
    return <span>Redirecting..</span>;
  }

  const tokenData = AuthService.getAccessTokenDecoded();
  if (enforceSSO && tokenData?.authenticationType === 'REFRESH_TOKEN') {
    AuthService.logout();
  }

  const ssoAuthError = enforceSSO && userAuthType !== AuthType.Sso;

  if (userAuthType && ssoAuthError && !isExternalUserInProject) {
    return <SsoEnforcedPage />;
  }

  const hasPermission = !appPermission || hasAppPermission(appPermission);

  if (!hasPermission) {
    return <BigMessageNoAccess />;
  }
  return <>{(isExternalUserInProject || userAuthType) && element}</>;
};
