import React, { createContext, useContext, useEffect, useState } from 'react';

import { useLocalStorage } from '@pro4all/shared/hooks';

import { DataViewType } from './types';

type DataViewContextValue = {
  availableViews: DataViewType[];
  currentView: DataViewType;
  setCurrentView: (view: DataViewType) => void;
  viewKey: string;
};

const DataViewContext = createContext({
  availableViews: [],
  currentView: null,
  setCurrentView: null,
  viewKey: '',
});

export function useDataViewContext() {
  return useContext<DataViewContextValue>(DataViewContext);
}

export function DataViewContextProvider({
  children,
  initialView,
  viewKey,
  views,
}: {
  children: React.ReactNode;
  initialView: DataViewType;
  viewKey: string;
  views: DataViewType[];
}) {
  const { setLocalStorageItem, getLocalStorageItem } =
    useLocalStorage<DataViewType>({
      key: `prostream-viewmode-${viewKey}`,
    });
  const resolveView = () => {
    const localStorageKey = viewKey ? getLocalStorageItem() : {};
    const view =
      typeof localStorageKey === 'object' ? initialView : localStorageKey;
    return view;
  };

  const [currentView, setCurrentView] = useState<DataViewType>(resolveView());
  const [availableViews] = useState<DataViewType[]>(views);

  useEffect(() => {
    setCurrentView(resolveView());
  }, [viewKey, initialView]);

  useEffect(() => {
    setLocalStorageItem(currentView);
  }, [currentView, setLocalStorageItem]);

  const dataViewContextValue = {
    availableViews,
    currentView,
    setCurrentView,
    viewKey,
  };

  return (
    <DataViewContext.Provider value={dataViewContextValue}>
      {children}
    </DataViewContext.Provider>
  );
}
