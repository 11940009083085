import React from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { datadogRum } from '@datadog/browser-rum';
import styled from 'styled-components';

import { ScopeType } from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import { TrackingEvent } from '@pro4all/shared/config';
import { useContextScopedOrganizationId } from '@pro4all/shared/identity';
import { Box, useTheme } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { customColors } from '@pro4all/shared/themes';
import { CenteredContents } from '@pro4all/shared/ui/layout';
import { Link } from '@pro4all/shared/ui/link';
import { Logo } from '@pro4all/shared/ui/logos';
import { Shape } from '@pro4all/shared/ui/shapes';
import { Text } from '@pro4all/shared/ui/typography';
import { useAnalytics } from '@pro4all/shared/vendor';

export const AppFallback: React.FC = ({ children }) => (
  <ErrorBoundary
    FallbackComponent={FallbackComponent}
    onError={(error, errorInfo) => {
      console.error(error);
      datadogRum.addError(
        {
          ...error,
          name: `Blue screen | ${error.name}`,
        },
        {
          errorInfo: errorInfo.componentStack,
        }
      );
    }}
  >
    {children}
  </ErrorBoundary>
);

const FallbackComponent: React.FC<FallbackProps> = ({
  error,
  resetErrorBoundary,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    goTo,
    url,
    params: { projectId },
  } = useRouting();

  const { track } = useAnalytics();

  const getContextScopedOrganizationId = useContextScopedOrganizationId();
  const organizationId = getContextScopedOrganizationId();
  const { organizationRoles, projectsMe } = useOrganizationContext();
  const projectMeData = projectsMe?.find(
    (project) => project?.projectId === projectId
  );
  const { roles: rolesProject } = projectMeData || {};

  const toMyOverview = () => {
    goTo('myOverview');
    resetErrorBoundary();
  };

  const toProject = () => {
    goTo('proj', { params: { projectId } });
    resetErrorBoundary();
  };

  track(TrackingEvent.ErrorOcurred, {
    error,
    organizationId,
    projectId,
    scopeRole: projectId
      ? rolesProject?.[0]?.name
      : organizationRoles?.[0]?.name,
    scopeType: projectId ? ScopeType.Project : ScopeType.Organization,
    url,
  });

  return (
    <Box
      bgcolor={theme.palette.primary.main}
      display="flex"
      flex="auto"
      flexDirection="column"
      height="100vh"
      overflow="hidden"
    >
      <Box p={1}>
        <Logo logoColor="negativeWhite" />
      </Box>
      <Box display="flex" flex="auto" flexDirection="row">
        <CenteredContents>
          <ShapeWrap>
            <Shape
              aria-hidden={false}
              color="silver"
              fontSize="inherit"
              mono
              role="presentation"
              type="build"
            />
          </ShapeWrap>

          <CustomTitle variant="h2">{t('Something went wrong')} :(</CustomTitle>
          <CustomLabel variant="h5">
            {t(
              'If you run into this issue more often, please contact support.'
            )}
          </CustomLabel>
          <ActionsWrap>
            {projectId && (
              <Link color="secondary" onClick={toProject} startIcon="project">
                {t('Back to')} {t('project')}
              </Link>
            )}
            <Link color="secondary" onClick={toMyOverview} startIcon="barChart">
              {t('Back to')} {t('My overview')}
            </Link>
          </ActionsWrap>
        </CenteredContents>
      </Box>
    </Box>
  );
};

const ShapeWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 240px;
  max-width: 600px;

  > .MuiSvgIcon-root {
    display: block;
    width: 600px;
    height: auto;
    margin-bottom: ${({ theme }) => theme.spacing(3)};
  }
`;

const ActionsWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin: ${({ theme }) => theme.spacing(3)} 0;
`;

const CustomTitle = styled(Text)`
  && {
    color: ${customColors.white};
  }
`;

const CustomLabel = styled(Text)`
  && {
    color: ${customColors.white};
    margin-bottom: '1rem';
  }
`;
