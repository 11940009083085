import dayjs from 'dayjs';

import { toReference } from '@pro4all/communication/data-access';
import { MessageFormBase } from '@pro4all/communication/ui/general';
import {
  AttachmentInput,
  CommentStatus,
  CommentThread,
  CommentThreadDocument,
  Message,
  MessageFragmentFragmentDoc,
  MutationEditCommentArgs,
  Reference,
  ReferenceInput,
  useAddCommentMutation,
  useEditCommentMutation,
  User,
} from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';

import { useCommentOptimisticResponse } from './useCommentOptimisticResponse';

type SubmitArgs = {
  commentReferences?: ReferenceInput[];
  createdAt?: Date;
  onError: () => void;
  onSuccess: () => void;
  references: ReferenceInput[];
  target: Reference;
  threadId: MutationEditCommentArgs['threadId'];
  values: MessageFormBase;
};

interface Props {
  messageId?: string;
  targetId: string;
}

export const useSubmit = ({ messageId, targetId }: Props) => {
  const { userDisplayName, userEmail, userFirstName, userLastName, userId } =
    useOrganizationContext();

  const updatedTableCache = useCommentOptimisticResponse();

  const me: User = {
    displayName: userDisplayName || 'unknown',
    email: userEmail || 'unknown',
    firstName: userFirstName || 'unknown',
    id: userId || 'unknown',
    lastName: userLastName || 'unknown',
  };

  const [editComment] = useEditCommentMutation({
    update: (cache, { data }) => {
      if (data?.editComment && !data.editComment.resolved) {
        const newData: Message | null = cache.readFragment({
          fragment: MessageFragmentFragmentDoc,
          id: `Message:${data.editComment.id}`,
          optimistic: true,
        });

        if (newData) {
          cache.writeFragment({
            data: { ...data, ...newData },
            fragment: MessageFragmentFragmentDoc,
            id: `Message:${data.editComment.id}`,
          });
        }
      }
    },
  });

  const [addComment] = useAddCommentMutation({
    update: (cache, { data }) => {
      if (data?.addComment) {
        const existingThread: { commentThread: CommentThread } | null =
          cache.readQuery({
            query: CommentThreadDocument,
            variables: {
              targetId,
            },
          });

        const newComment: Message = {
          ...data.addComment,
          createdBy: {
            ...data.addComment.createdBy,
            email: me.email,
            firstName: me.firstName,
            lastName: me.lastName,
          },
          read: false,
          references: data.addComment.references?.map((ref) => ({
            ...ref,
            referenceData: null,
          })),
        };

        if (existingThread) {
          cache.writeQuery({
            data: {
              commentThread: {
                id: existingThread.commentThread.id,
                messages: [
                  ...(existingThread.commentThread.messages || []),
                  newComment,
                ],
                targetId,
              },
            },
            query: CommentThreadDocument,
            variables: {
              targetId,
            },
          });
        }
        updatedTableCache({ status: CommentStatus.AllCommentsRead, targetId });
      }
    },
  });

  const submitEdit = async ({
    references,
    onError,
    target,
    values,
    threadId,
    createdAt,
    onSuccess,
  }: SubmitArgs) => {
    const { body, attachments } = values;
    try {
      const attachmentInput: AttachmentInput[] =
        attachments?.map(({ fileId, fileName, fileSize }) => ({
          fileId,
          fileName,
          fileSize,
        })) || [];

      await editComment({
        optimisticResponse: {
          editComment: {
            __typename: 'Message',
            attachments: attachments || [],
            bcc: null,
            body,
            cc: null,
            createdAt,
            createdBy: me,
            deleted: false,
            fromId: me?.id || '',
            id: messageId || '',
            parentId: null,
            read: false,
            references,
            resolved: false,
            subject: null,
            threadId,
            to: null,
            updatedAt: new Date(),
          },
        },
        variables: {
          attachments: attachmentInput,
          body,
          createdAt,
          messageId,
          references: references?.map(toReference) || [],
          target,
          threadId,
        },
      });
      onSuccess();
    } catch (e) {
      onError();
    }
  };

  const submitNew = async ({
    onSuccess,
    references,
    onError,
    target,
    values,
  }: SubmitArgs) => {
    const { body, attachments } = values;
    if (!body) {
      onError();
      return;
    }

    try {
      await addComment({
        optimisticResponse: {
          addComment: {
            __typename: 'Message',
            attachments: attachments || [],
            body,
            createdAt: dayjs().toISOString(),
            createdBy: me,
            deleted: false,
            fromId: me?.id || '',
            id: 'optimistic_message',
            read: false,
            references: references?.map(toReference) || [],
            resolved: false,
            threadId: '',
            updatedAt: null,
          },
        },
        variables: {
          attachments,
          body,
          references,
          target,
        },
      });
      onSuccess();
    } catch (e) {
      onError();
    }
  };

  return messageId ? submitEdit : submitNew;
};
