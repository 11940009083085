import styled from 'styled-components';

import { Box } from '@pro4all/shared/mui-wrappers';
import { Icon } from '@pro4all/shared/ui/icons';

export const IconWrapper = styled.span`
  display: inline-flex;
  margin-right: ${({ theme }) => theme.spacing(1)};
  align-items: center;
  line-height: normal;
  position: relative;
  top: 2px;
`;

export const StyledIcon = styled(Icon)`
  font-size: 1.25rem;
  display: flex;
  line-height: 1;
`;

export const TitleContainer = styled(Box)`
  display: flex;
  align-items: center;
  height: 100%;
  line-height: normal;
`;
