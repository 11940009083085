import React from 'react';
import BaseTable, { SortOrder } from 'react-base-table';
import styled from 'styled-components';

import { customColors } from '@pro4all/shared/themes';
import { Icon } from '@pro4all/shared/ui/icons';
import { Column } from '@pro4all/shared/ui/layout';

import './baseTable.css';

import {
  SortIndicatorProps,
  StyledTableProps,
  TableCellProps,
  TableHeaderProps,
} from '../types';

import { ToggleCheckAll } from './ToggleCheckAll';

export const RowIconWrapRight = styled.div`
  position: fixed;
  right: 0;
  display: none;
  align-items: center;
  margin-left: auto;
  padding-right: ${({ theme }) => theme.spacing(1)};
  background: transparent;
`;

export const TableContent = styled(Column)`
  && {
    flex: 1;
    background: ${customColors.white};
    z-index: 1;
  }
`;

export const StyledTable = styled(BaseTable)<StyledTableProps>`
  --darkGrey: ${customColors.grey500};
  --grey: ${customColors.grey300};
  --lightGrey: ${customColors.grey300};
  font-size: ${({ theme }) => theme.typography.body1.fontSize};
  .BaseTable__row-cell:hover ${RowIconWrapRight} {
    display: flex;
  }

  .row-selected ${RowIconWrapRight} {
    background: transparent;
  }

  .BaseTable__header-row {
    height: ${({ headerHeight }) => `${headerHeight}px`};
    padding: 0;
  }

  .BaseTable__header {
    font-size: ${({ theme }) => theme.typography.h6.fontSize};
    font-weight: ${({ theme }) => theme.typography.h6.fontWeight};
    border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
  }

  /* We don't want the (by react-base-table) automatically calculated width value on BaseTable__row.
   * Because that causes an issue at scrolling that the scrolled in columns are not styled (onHover f.i. lacks the grey background).
   * I cannot find another way to overwrite this auto calculated width than via the !important instruction.
   */
  .BaseTable__row {
    width: ${({ folderTree }) =>
      folderTree ? '100% !important' : 'auto !important'};
  }

  /* padding-right MUST be 0 because we want to manage this padding from FilterHeader */
  .BaseTable__header-cell {
    padding: 0 1px 0 0;
  }

  .BaseTable__row-cell {
    padding: 0 0 0 8px;
  }

  .BaseTable__header-cell,
  .BaseTable__row-cell {
    justify-content: flex-start;
    position: relative;

    &,
    & > * {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .BaseTable__header-cell:first-of-type,
  .BaseTable__row-cell:first-of-type {
    padding-left: ${({ theme }) => theme.spacing(3)};
  }

  .BaseTable__table-frozen-left
    .BaseTable__body
    .BaseTable__row-cell:last-of-type {
    border-right: 1px solid var(--lightGrey);
  }

  .BaseTable__table-frozen-right
    .BaseTable__body
    .BaseTable__row-cell:first-of-type {
    border-left: 1px solid var(--lightGrey);
  }

  .BaseTable__row:hover,
  .BaseTable__row--hovered {
    background-color: var(--grey);
    ${({ clickable }) => clickable && `cursor: pointer;`}
  }

  .BaseTable__row-expanded {
    border-bottom: 1px solid ${customColors.grey500};
  }

  .row-selected,
  .row-selected:hover {
    background-color: ${({ theme, takeSecondaryThemeColor }) =>
      takeSecondaryThemeColor
        ? theme.palette.secondary.light
        : theme.palette.primary.light};
  }
`;

const Header = styled.div.attrs({ className: 'BaseTable__header-cell' })<{
  center?: boolean;
  width: number;
}>`
  display: flex;
  flex: 0 0 ${({ width }) => `${width}px`};
  justify-content: ${({ center }) => (center ? 'center' : 'flex-start')};
`;

export const TableHeader = ({
  cells,
  columns,
  onToggleAllRows,
}: TableHeaderProps) => (
  <>
    {cells.map((cell, index) => {
      const {
        align = 'center',
        key,
        headerComponent,
        width,
      } = columns[index] || {};
      return key === 'selected' || key === 'selectedSwitch' ? (
        <Header center={align === 'center'} key={key} width={width}>
          <ToggleCheckAll onToggleAllRows={onToggleAllRows} type={key} />
        </Header>
      ) : headerComponent ? (
        <Header center={align === 'center'} key={key} width={width}>
          {headerComponent}
        </Header>
      ) : (
        cell
      );
    })}
  </>
);

export const TableCell = ({ column, rowData, rowIndex }: TableCellProps) => {
  let finalValue: string | number | React.ReactNode = '';

  if (column.render) {
    finalValue = column.render(rowData, rowIndex);
  } else if (column.getValue) {
    finalValue = column.getValue(rowData);
  } else {
    const value = rowData[column.key];

    if (typeof value === 'string' || typeof value === 'number') {
      finalValue = value;
    }
  }

  return finalValue;
};

const SortIcon = styled(Icon).attrs({
  iconName: 'arrowDownward',
})<{ $sortOrder: SortOrder }>`
  margin-left: 0.3em;
  font-size: 1em;
  transform: rotate(${({ $sortOrder }) => ($sortOrder === 'asc' ? 180 : 0)}deg);
  transition-duration: 0.2s;
  transition-property: transform;
`;

export const SortIndicator = ({ sortOrder, className }: SortIndicatorProps) => (
  <SortIcon $sortOrder={sortOrder} className={className} />
);
