import React, { useState } from 'react';

import { CollectionFolderContextProvider } from '@pro4all/documents/ui/folders';
import { DialogMode, MyQueriesDialog } from '@pro4all/documents/ui/search';
import { SearchBar, useTopTen } from '@pro4all/search/ui';
import { useDocumentSearchContext } from '@pro4all/shared/documents-search-context';
import { Box } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { SearchEntities } from '@pro4all/shared/search-utils';

import { DmsContent } from './DmsContent';
import { DocumentsSearchResults } from './DocumentsSearchResults';

export const Documents: React.FC<{ editSavedSearch?: boolean }> = ({
  editSavedSearch,
}) => {
  const { searchParams } = useRouting();

  const { handleSearch, handleSelect, setContextQuery } =
    useDocumentSearchContext();
  const topTen = useTopTen({
    type: SearchEntities.Document,
  });

  const [dialogMode, setDialogMode] = useState<DialogMode | null>(null);
  if (editSavedSearch && !dialogMode) setDialogMode(DialogMode.Edit);

  const [currentQuery, setCurrentQuery] = useState<string | null>(null);
  const myQueriesOpen = Boolean(dialogMode);
  const closeDialog = () => setDialogMode(null);

  const isResultsPage = searchParams.get('search') !== null;

  const onBack = () => {
    searchParams.clear();
  };

  return (
    <CollectionFolderContextProvider>
      <MyQueriesDialog
        mode={dialogMode}
        onClose={closeDialog}
        onSearch={handleSearch}
        open={myQueriesOpen}
        scope={SearchEntities.Document}
        setCurrentQuery={setCurrentQuery}
        setMode={setDialogMode}
        topTen={topTen}
      />
      <Box sx={{ marginBottom: 2 }}>
        <SearchBar
          currentQuery={currentQuery}
          onBack={onBack}
          onSearch={handleSearch}
          onSelect={handleSelect}
          openMyQueries={() => setDialogMode(DialogMode.Default)}
          setContextQuery={setContextQuery}
          setCurrentQuery={setCurrentQuery}
          showSavedSearches
          topTen={topTen}
          type={SearchEntities.Document}
        />
      </Box>
      {isResultsPage ? (
        <DocumentsSearchResults
          onSaveSearch={() => setDialogMode(DialogMode.Create)}
        />
      ) : (
        <DmsContent />
      )}
    </CollectionFolderContextProvider>
  );
};
