import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';

import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { isSubmitDisabled } from '@pro4all/shared/forms';
import { useIsAdminInContext } from '@pro4all/shared/identity';
import { Box, Link, Typography } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { FormFooter } from '@pro4all/shared/ui/form';
import {
  FormikCheckbox,
  FormikForm,
  FormikInput,
} from '@pro4all/shared/ui/formik';
import { DiscardWrapper } from '@pro4all/shared/ui/messages';
import { FormWrapper } from '@pro4all/shared/ui/wrappers';

import { FormFields } from './types';
import { useOrganizationFormConfig } from './useOrganizationFormConfig';
import { useSubmit } from './useSubmit';

export const OrganizationForm = ({
  initialValues,
}: {
  initialValues: FormFields;
}) => {
  const { t } = useTranslation();
  const { getField, validationSchema } = useOrganizationFormConfig();
  const nameField = getField('name');
  const enableAiField = getField('settings.ai_admin_settings');
  const enableOrganizationAiField = getField(
    'settings.ai_organization_settings'
  );

  const { searchParams } = useRouting();
  const handleClose = () => searchParams.clear();

  const onSubmit = useSubmit();

  const aiAdminSettings = useFeatureFlag('ai-admin-settings');
  const isAdmin = useIsAdminInContext();

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ dirty, errors, isSubmitting, values }) => (
        <DiscardWrapper dirty={dirty}>
          <FormikForm>
            <FormWrapper>
              <Box sx={{ mt: 1 }}>
                <FormikInput label={nameField.label} name={nameField.name} />
              </Box>

              {aiAdminSettings && isAdmin && (
                <>
                  <FormikCheckbox
                    data-testid="ai-settings-checkbox"
                    label={enableAiField.label}
                    name={enableAiField.name}
                  />
                  <Typography fontStyle="italic">
                    {t(
                      'By enabling the Prostream AI features, you confirm that you have read and consent to the '
                    )}
                    <Link
                      data-testid="ai-terms-conditions"
                      style={{ cursor: 'pointer' }}
                    >
                      {t('terms and conditions')}
                    </Link>{' '}
                    {t(' governing the use of AI functionality.')}
                  </Typography>
                  <Typography>
                    {t(
                      "Activating 'Prostream AI functionality' grants you access to Prostream AI capabilities and signifies your agreement to the associated terms. Once enabled, you can independently activate AI features for each project and for the organization as a whole."
                    )}
                  </Typography>
                </>
              )}
              {aiAdminSettings && values.settings.ai_admin_settings === true && (
                <>
                  <FormikCheckbox
                    label={enableOrganizationAiField.label}
                    name={enableOrganizationAiField.name}
                  />
                  <Typography>
                    {t(
                      "Enable the AI features for your organization's messages and documents or where the AI features can further be used within the organization scope. This does not implicate your organization's projects."
                    )}
                  </Typography>
                </>
              )}
              <FormFooter
                disableSubmit={isSubmitDisabled({
                  dirty,
                  errors,
                  isSubmitting,
                })}
                onClose={handleClose}
                pb={3}
                pt={2}
                px={3}
                showCancel
              />
            </FormWrapper>
          </FormikForm>
        </DiscardWrapper>
      )}
    </Formik>
  );
};
