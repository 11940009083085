import React, { useState } from 'react';
import { ColorResult, SketchPicker } from 'react-color';
import { useField } from 'formik';
import styled from 'styled-components';

import { InputProps, Popover } from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';
import { Button } from '@pro4all/shared/ui/buttons';

const StyledPopover = styled(Popover)`
  && {
    .MuiPaper-root {
      border-radius: 20px;
    }
  }
`;

const PaletteButton = styled(Button)`
  && {
    &.MuiButton-root {
      min-width: 0;
      padding: 5px;
    }
  }
`;

const ColorPreview = styled.div`
  width: 100%;
  height: 20px;
`;

const StyledInput = styled.input
  .withConfig({
    shouldForwardProp: (prop) => !['hasError'].includes(prop),
  })
  .attrs({ disabled: true, type: 'color' })<{
  defaultColor?: string;
  hasError?: boolean;
}>`
  width: ${({ theme }) => theme.spacing(3)};
  height: ${({ theme }) => theme.spacing(3)};
  padding: 3px;
  border-radius: 100%;
  cursor: pointer;
  border-color: ${({ defaultColor, value, hasError, theme }) => {
    if (value) {
      return value;
    } else if (defaultColor) {
      return defaultColor;
    } else if (hasError) {
      return theme.palette.error.main;
    }
    return customColors.grey500;
  }};
  border: 1px solid;
  pointer-events: none;

  &::-webkit-color-swatch-wrapper {
    padding: 0;
    border-radius: 100%;
  }
  &::-webkit-color-swatch {
    border: 0;
    border-radius: 100%;
  }
  &::-moz-color-swatch-wrapper {
    padding: 0;
    border-radius: 100%;
  }
  &::-moz-color-swatch {
    border: 0;
    border-radius: 100%;
  }
`;

interface FormikColorPickerProps {
  inputProps?: InputProps['inputProps'];
  name: string;
  title?: string;
}

export const FormikColorPicker: React.FC<FormikColorPickerProps> = ({
  name,
  inputProps,
}) => {
  const [field, meta, helpers] = useField({ name });

  const hasError = Boolean(meta.touched) && Boolean(meta.error);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const [currentColor, setCurrentColor] = useState(
    customColors.purpleMonoHighlight
  );

  const onClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const onColorChange = (newColor: ColorResult) => {
    setCurrentColor(newColor.hex);
  };

  const onColorChangeComplete = (newColor: ColorResult) => {
    helpers.setTouched(true);
    helpers.setValue(newColor.hex);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const onEnter = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      setAnchorEl(event.currentTarget);
    }
  };

  return (
    <>
      <PaletteButton
        aria-label={`${name}-palette-button`}
        onClick={onClick}
        onKeyDown={onEnter}
        role="button"
        variant="text"
      >
        <StyledInput
          {...field}
          {...inputProps}
          hasError={hasError}
          value={field.value || customColors.purpleMonoHighlight}
        />
      </PaletteButton>
      <StyledPopover
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'center',
        }}
        aria-label="palette-dialog"
        onClose={onClose}
        open={Boolean(anchorEl)}
        role="dialog"
        transformOrigin={{
          horizontal: 'left',
          vertical: 'top',
        }}
      >
        <SketchPicker
          color={currentColor}
          disableAlpha
          onChange={onColorChange}
          onChangeComplete={onColorChangeComplete}
        />
        <ColorPreview style={{ background: currentColor }} />
      </StyledPopover>
    </>
  );
};
