import React from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes as RouterDomRoutes } from 'react-router-dom';

import { Routes } from '@pro4all/shared/config';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { SubTabs, Tab } from '@pro4all/shared/ui/general';
import { Column, Main } from '@pro4all/shared/ui/layout';

import { useGetSignatureHandler } from './SignatureForm/useGetSignatureHandler';
import { SupportForm } from './SupportForm/SupportForm';
import { ApiKeysTableProviderWrapper } from './api-keys';
import * as Styled from './MyProfile.styles';
import { MyProfileTabContent } from './MyProfileTabContent';
import { SignatureForm } from './SignatureForm';

export const MyProfile: React.FC = () => {
  const { t } = useTranslation();
  const ffApiKeyManagement = useFeatureFlag('api-key-management');

  const base = Routes.profileBaseUrl;

  const { signatureBody, signatureId, signatureIsLoading } =
    useGetSignatureHandler();

  return (
    <Main>
      <Column>
        <SubTabs base={`${base}`}>
          <Tab
            data-testid="my-profile-tab"
            label={t('My profile')}
            value={Routes.myProfile}
          />
          <Tab
            data-testid="my-signature-tab"
            label={t('Signature')}
            value={Routes.mySignature}
          />
          {ffApiKeyManagement && (
            <Tab
              data-testid="my-api-key-management-tab"
              label={t('API key management')}
              value={Routes.myApiKeyManagement}
            />
          )}
          <Tab
            data-testid="my-support-tab"
            label={t('Support')}
            value={Routes.mySupport}
          />
        </SubTabs>
        <RouterDomRoutes>
          <Route element={<MyProfileTabContent />} path={Routes.myProfile} />
          <Route
            element={
              <SignatureForm
                signatureBody={signatureBody}
                signatureId={signatureId as string}
                signatureIsLoading={signatureIsLoading}
              />
            }
            path={Routes.mySignature}
          />
          <Route
            element={<ApiKeysTableProviderWrapper />}
            path={Routes.myApiKeyManagement}
          />
          <Route
            element={
              <Styled.StyledBox>
                <SupportForm />
              </Styled.StyledBox>
            }
            path={Routes.mySupport}
          />
        </RouterDomRoutes>
      </Column>
    </Main>
  );
};
