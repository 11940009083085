import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

import { Button } from '@pro4all/shared/ui/buttons';

import { FormFields } from '../../types';

import { Container } from './CreateSerieSection.styles';
import { CreateSeriesModal } from './CreateSeriesModal';
import { SeriesCard } from './SeriesCard';
import { SeriesHeader } from './SeriesHeader';

export const CreateSeriesSection: React.FC = () => {
  const [isCreateSerieModalOpen, setIsCreateSerieModalOpen] = useState(false);
  const { values, setFieldValue } = useFormikContext<FormFields>();
  const { t } = useTranslation();

  const handleOpenModal = () => setIsCreateSerieModalOpen(true);
  const handleCloseModal = () => setIsCreateSerieModalOpen(false);

  const user = values.participant;

  const participant = user
    ? {
        displayName: user.label || '',
        email: user.userOption?.email || '',
        id: user.id || '',
        isAdmin: user.userOption?.isAdmin || false,
        organization: user.userOption?.organization,
      }
    : null;

  return (
    <Container>
      <SeriesHeader />
      <Button
        color="primary"
        onClick={handleOpenModal}
        startIcon="serieRounded"
        variant="text"
      >
        {t('Create series')}
      </Button>

      {isCreateSerieModalOpen && (
        <CreateSeriesModal
          onClose={handleCloseModal}
          open={isCreateSerieModalOpen}
        />
      )}
      {values.seriesFrequency &&
        values.seriesEndDate &&
        values.seriesPlanAhead &&
        values.seriesPlanAhead > 0 && (
          <SeriesCard
            onRemove={() => {
              setFieldValue('seriesEndDate', '');
              setFieldValue('seriesFrequency', null);
              setFieldValue('seriesPlanAhead', 0);
            }}
            serieData={{
              endDate: values.seriesEndDate,
              frequency: values.seriesFrequency,
              planAhead: values.seriesPlanAhead,
              seriesTitle: values.name,
              startDate: values.endTime,
            }}
            user={participant}
          />
        )}
    </Container>
  );
};
