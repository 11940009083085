import { customColors } from './customColors';

export const muiStyleOverridesPave = {
  MuiButton: {
    styleOverrides: {
      contained: {
        '&.Mui-disabled:not(.BypassOverride)': {
          backgroundColor: `${customColors.grey300} !important`,
        },
        '&:hover:not(.BypassOverride)': {
          backgroundColor: `${customColors.paveSecondaryDark} !important`,
        },
        '&:not(.BypassOverride)': {
          backgroundColor: `${customColors.paveSecondaryMain} !important`,
        },
      },
      outlined: {
        '&.FilterButton': {
          border: `1px solid ${customColors.greenAccentBackground} !important`,
          color: `${customColors.greenAccentBackground} !important`,
        },
        '&:hover': {
          backgroundColor: `${customColors.grey300} !important`,
        },
        border: `1px solid ${customColors.black} !important`,
      },
      root: {
        color: `${customColors.black}`,
      },
    },
  },
  MuiDrawer: {
    styleOverrides: {
      paperAnchorLeft: {
        // Only override these styles if both side navigation drawers have the same background color
        '--item-active-color': `${customColors.black} !important`,
        '--item-color': `${customColors.white} !important`, // Override the CSS variable
        backgroundColor: `${customColors.paveBlack} !important`,
      },
    },
  },
  MuiList: {
    styleOverrides: {
      root: {
        // Only override this if both side navigation drawers have the same background color
        '.nav-link.active:not(.nav-group .nav-link.active)': {
          backgroundColor: `${customColors.paveSecondaryMain} !important`,
        },
      },
    },
  },
};
