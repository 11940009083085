import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useClipboard } from 'use-clipboard-copy';

import { Serie } from '@pro4all/graphql';
import { ActionProps, useCentralActions } from '@pro4all/shared/actions';
import { useAppPermissions } from '@pro4all/shared/identity';

interface UseSeriesSidebarButtonsProps {
  currentSerie: Serie | null;
}

export const useSeriesSidebarButtons = ({
  currentSerie,
}: UseSeriesSidebarButtonsProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { ACTION_DELETE, ACTION_SHARE } = useCentralActions();
  const { hasAppPermission } = useAppPermissions();
  const userCannotReadProject = !hasAppPermission('ProjectRead');
  const clipboard = useClipboard({
    onSuccess() {
      enqueueSnackbar(t('Link has been copied!'));
    },
  });

  const actions: ActionProps[] = [
    {
      ...ACTION_SHARE,
      dataTestId: 'share-serie',
      disabled: userCannotReadProject,
      key: 'share-serie',
      onClick: () => clipboard.copy(window.location.href),
    },
    {
      ...ACTION_DELETE,
      dataTestId: 'delete-serie',
      disabled: userCannotReadProject,
      key: 'delete-serie',
      onClick: () => {
        // TODO: Implement delete functionality
        console.log('Delete serie:', currentSerie?.id);
      },
    },
  ];

  return actions;
};
