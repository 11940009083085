import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@pro4all/shared/mui-wrappers';
import { generateRoute } from '@pro4all/shared/routing-utils';
import { customColors } from '@pro4all/shared/themes';
import { Text } from '@pro4all/shared/ui/typography';

import { DangerButton } from '../../view/detail/Banners.styles';
import { Link, LiteIcon } from '../ProjectStatus.styles';
import { useProjectObjects } from '../useProjectObjects';

export const Linked = () => {
  const { t } = useTranslation();
  const { projectObject, unlinkProject } = useProjectObjects();

  const objectId = projectObject?.id ?? '';
  const projectId = projectObject?.projectId ?? '';
  const project = projectObject?.project;

  const projectsRoute = generateRoute('projectObjectTbq', {
    params: {
      objectId: objectId,
      projectId: projectId,
    },
  });

  const label = (
    <Box display="flex" pl={1}>
      <Text>
        {projectsRoute && projectObject?.projectId && project ? (
          <>
            {t('This object is linked to')}
            <Link to={projectsRoute}>{project.name}</Link>
          </>
        ) : (
          <>{t('The object is linked to a deleted project')}</>
        )}
      </Text>
    </Box>
  );

  return (
    <>
      <LiteIcon
        color={customColors.purpleMonoHighlight}
        iconName="objectLinkActive"
        label={label}
      />
      <DangerButton
        color="inherit"
        onClick={() => unlinkProject(objectId, projectId)}
        startIcon="removeCircle"
      >
        {t('Remove from project')}
      </DangerButton>
    </>
  );
};
