import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';

export const TBQBrandcheck: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      d="M11.7 6.50414L16.2597 9.00614L20.1605 6.75407L11.7 2L3.20276 6.75407L7.10351 9.00614L11.7 6.50414Z"
      fill={customColors.greenMonoHighlight}
    />
    <path
      d="M16.4978 9.27598L20.3986 7.02391V7.02118L16.4978 9.27325V9.27598Z"
      fill={customColors.white}
    />
    <path
      d="M20.3986 16.1967V7.2699L16.4978 9.52258V13.848L20.3986 16.1967Z"
      fill={customColors.tbqPrimaryDark}
    />
    <path d="M11.696 22L11.7 21.9979V19.6601L11.696 22Z" fill="white" />
    <path
      d="M10.9277 17.1385L6.90347 14.8164V14.8143V9.65753L3.00332 7.40546L3 17.0673H3.00332L10.9204 21.6372L10.9277 17.1385Z"
      fill={customColors.tbqPrimaryMain}
    />
    <path
      d="M20.4915 17.0195L11.7117 11.7333L11.696 21.7679L20.4915 17.0195Z"
      fill="#8011C1" // We don't have a similar looking color in the theme, so we're using the hex code here in case of exception.
    />
  </SvgIcon>
);
