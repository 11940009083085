import { useSnackbar } from 'notistack';

import { SnackbarDocumentDeleted } from '@pro4all/documents/ui/snackbars';
import {
  DocumentVersion,
  useDeleteDocumentVersionMutation,
} from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';

import { DocumentActionProps } from '../../types';
import { useDocumentSelection } from '../../useDocumentSelection';

import { useCacheHelpers } from './useCacheHelpers';

export const useHandleDeleteVersion = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [deleteVersion] = useDeleteDocumentVersionMutation();
  const { searchParams } = useRouting();
  const documentId = searchParams.get('id');

  const { deselectVersion } = useDocumentSelection();

  const {
    getCachedDocument,
    getCachedDocumentVersions,
    restoreVersionsCallback,
    updateCacheDocument,
    updateCacheDocumentVersions,
  } = useCacheHelpers();

  const handleDeleteVersion = async ({
    selectionVersion,
  }: Pick<DocumentActionProps, 'selectionVersion'>) => {
    const resp = await deleteVersion({
      variables: {
        documentId: documentId ?? '',
        versionId: selectionVersion[0].id,
      },
    });

    if (resp?.data?.deleteDocumentVersion) {
      const cachedDocumentVersions = getCachedDocumentVersions()
        .documentVersion as DocumentVersion[];
      const cachedDocument = getCachedDocument().document;

      const filteredVersions = cachedDocumentVersions.filter(
        (version) => version.id !== selectionVersion[0].id
      );

      updateCacheDocumentVersions({
        updatedDocumentsVersions: filteredVersions,
      });

      updateCacheDocument({
        selectionVersion,
        updatedDocumentsVersions: filteredVersions,
      });

      deselectVersion();

      const deletedMessage = (
        <SnackbarDocumentDeleted
          documentId={documentId ?? ''}
          documentName={cachedDocument.name}
          restoreItems={() => restoreVersionsCallback({ selectionVersion })}
          versionId={selectionVersion[0].id}
        />
      );

      enqueueSnackbar(deletedMessage);
    }
  };

  return { handleDeleteVersion };
};
