import { Template } from '@pro4all/graphql';
import { OptimisticResponseProvider } from '@pro4all/shared/ui/table';

import { TemplatesTasks } from './TemplatesTasks';

export const TemplatesTasksProviderWrapper = () => (
  <OptimisticResponseProvider<Template>>
    <TemplatesTasks />
  </OptimisticResponseProvider>
);
