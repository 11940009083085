import { TemplateService, TemplateType } from '@pro4all/graphql';
import { Column, Main } from '@pro4all/shared/ui/layout';

import { ScreenTBQTemplatesProviderWrapper } from '../components/screens/ScreenTBQTemplatesProviderWrapper';

import { MetaDataContextProvider } from './MetaDataContext';

export const OrganizationTBQ = () => (
  <MetaDataContextProvider
    templateService={TemplateService.QualityControl}
    templateType={TemplateType.Tbq}
  >
    <Main>
      <Column>
        <ScreenTBQTemplatesProviderWrapper />
      </Column>
    </Main>
  </MetaDataContextProvider>
);
