import { Task } from '@pro4all/graphql';
import { OptimisticResponseProvider } from '@pro4all/shared/ui/table';

import { TBQTasks, TBQType } from '../../tbq';

export const TbqTasksProviderWrapper = ({
  procedureId,
  routeOrObjectProjectId,
}: {
  procedureId: string;
  routeOrObjectProjectId: string;
}) => (
  <OptimisticResponseProvider<Task>>
    <>
      <TBQType />
      <TBQTasks
        procedureId={procedureId}
        projectId={routeOrObjectProjectId ?? ''}
      />
    </>
  </OptimisticResponseProvider>
);
