import { useTranslation } from 'react-i18next';

import { Routes } from '@pro4all/shared/config';
import { useMatchAny } from '@pro4all/shared/hooks';
import { Chip, useTheme } from '@pro4all/shared/mui-wrappers';
import {
  updateLsLastVisitedProjectRoute,
  useRouting,
} from '@pro4all/shared/routing-utils';
import { useTakeSecondaryThemeColor } from '@pro4all/shared/themes';
import { useIsMobileScreen } from '@pro4all/shared/themes';
import { Icon } from '@pro4all/shared/ui/icons';

import {
  CollectionContainer,
  LeftSideContainer,
  NameText,
} from './CollectionFolder.styles';
import { useCollectionFolderContext } from './CollectionFolderContext';

export const CollectionFolder = () => {
  const theme = useTheme();
  const { takeSecondaryThemeColor } = useTakeSecondaryThemeColor();
  const { documentsCount } = useCollectionFolderContext();
  const { t } = useTranslation();
  const isMobileScreen = useIsMobileScreen();

  const {
    goTo,
    params: { projectId },
    searchParams,
  } = useRouting();

  const matchCollection = useMatchAny([
    Routes.orgCollectionFolderUrl,
    Routes.projectCollectionFolderUrl,
  ]);

  const isSelected = Boolean(matchCollection);

  const handleOnClick = () => {
    searchParams.clear();
    if (projectId) {
      updateLsLastVisitedProjectRoute({
        overwriteCurrentValue: true,
        projectId,
        route: 'projectCollectionFolderUrl',
      });
    }
    goTo(projectId ? 'projectCollectionFolderUrl' : 'orgCollectionFolderUrl', {
      params: { projectId },
    });
  };

  return (
    !isMobileScreen && (
      <CollectionContainer
        onClick={handleOnClick}
        selected={isSelected}
        takeSecondaryThemeColor={takeSecondaryThemeColor}
      >
        <LeftSideContainer>
          <Icon
            htmlColor={isSelected ? theme.palette.primary.dark : ''}
            iconName="collectionBin"
          />
          <NameText selected={isSelected} variant="body1">
            {t('Collection folder')}
          </NameText>
        </LeftSideContainer>
        <Chip label={documentsCount} size="small"></Chip>
      </CollectionContainer>
    )
  );
};

export default CollectionFolder;
