import { Integration } from '@pro4all/graphql';
import { OptimisticResponseProvider } from '@pro4all/shared/ui/table';

import { IntegrationsTable } from './IntegrationsTable';

export const IntegrationsTableProviderWrapper = () => (
  <OptimisticResponseProvider<Integration>>
    <IntegrationsTable />
  </OptimisticResponseProvider>
);
