import { useCallback } from 'react';

import { Routes } from '@pro4all/shared/config';
import { useRouting } from '@pro4all/shared/routing-utils';

import { encodePath } from './encodePath';

export function useFolderPath() {
  const {
    goTo,
    params: { name: smartFolderName, path: fullPath, projectId },
  } = useRouting();

  const path = fullPath?.split('/').filter(Boolean) ?? [];
  const docsRoute: keyof typeof Routes = projectId ? 'docTable' : 'docTable';

  const openFolder = useCallback(
    (path: string) => {
      goTo(projectId ? 'projectDocTableUrl' : 'orgDms', {
        params: { path, projectId },
      });
    },
    [goTo, docsRoute, projectId]
  );

  return {
    docsRoute,
    encodedPath: encodePath(path),
    openFolder,
    path,
    projectId,
    smartFolderName,
  };
}
