import { AppFallback } from '@pro4all/shared/ui/fallback';

import { AppRoutes } from './AppRoutes';
import { GlobalModals } from './GlobalModals';
import { Init } from './Init';
import { Providers } from './Providers';

const App = () => (
  <Providers>
    <Init />
    <AppFallback>
      <AppRoutes />
    </AppFallback>
    <GlobalModals />
  </Providers>
);

export default App;
