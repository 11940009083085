import { useTranslation } from 'react-i18next';

import { SentOrInbox } from '@pro4all/graphql';
import { createTabValue, Routes } from '@pro4all/shared/config';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { Box } from '@pro4all/shared/mui-wrappers';
import {
  generateRoute,
  updateLsLastVisitedProjectRoute,
  useRouting,
} from '@pro4all/shared/routing-utils';
import { SubTabs, Tab } from '@pro4all/shared/ui/general';
import { useMessageContext } from '@pro4all/shared/ui/messages';
import { useOptimisticResponseContext } from '@pro4all/shared/ui/table';

export const MessageTabs = () => {
  const { t } = useTranslation();
  const { params } = useRouting();

  const { projectId = '' } = params;
  const { resetInitialItems } = useOptimisticResponseContext();
  const { setCurrentTab } = useMessageContext();
  const hasDraftFeature = useFeatureFlag('draft-messages');

  const base = projectId
    ? `${generateRoute('projectMessagesBaseUrl', {
        params: { projectId },
      })}/`
    : Routes.organizationMessagesUrl;
  return (
    <Box>
      <SubTabs base={base}>
        <Tab
          data-testid="message-inbox-tab"
          label={t('All')}
          onClick={() => {
            setCurrentTab(SentOrInbox.All); // Reference to back to the same tab
            resetInitialItems(); // To reset itemsInitial taken from other tab.
            updateLsLastVisitedProjectRoute({
              overwriteCurrentValue: true,
              projectId,
              route: 'projectAllMessages',
            });
          }}
          value={createTabValue(Routes.all)}
        />
        <Tab
          data-testid="message-inbox-tab"
          label={t('Inbox')}
          onClick={() => {
            setCurrentTab(SentOrInbox.Inbox); // Reference to back to the same tab
            resetInitialItems(); // To reset itemsInitial taken from other tab.
            updateLsLastVisitedProjectRoute({
              overwriteCurrentValue: true,
              projectId,
              route: 'projectMessagesInbox',
            });
          }}
          value={createTabValue(Routes.inbox)}
        />
        <Tab
          data-testid="message-sent-tab"
          label={t('Sent')}
          onClick={() => {
            setCurrentTab(SentOrInbox.Sent); // Reference to back to the same tab
            resetInitialItems(); // To reset itemsInitial taken from other tab.
            updateLsLastVisitedProjectRoute({
              overwriteCurrentValue: true,
              projectId,
              route: 'projectMessagesSent',
            });
          }}
          value={createTabValue(Routes.sent)}
        />
        {hasDraftFeature && (
          <Tab
            data-testid="message-draft-tab"
            label={t('Draft')}
            onClick={() => {
              setCurrentTab(SentOrInbox.Draft); // Reference to back to the same tab
              resetInitialItems(); // To reset itemsInitial taken from other tab.
              updateLsLastVisitedProjectRoute({
                overwriteCurrentValue: true,
                projectId,
                route: 'projectMessagesSent',
              });
            }}
            value={createTabValue(Routes.draft)}
          />
        )}
      </SubTabs>
    </Box>
  );
};
