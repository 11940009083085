import { TFunction } from 'i18next';

import { Option } from '@pro4all/shared/types';

export const getFrequencyOptions = (t: TFunction): Option[] => [
  { id: 'Daily', label: t('Daily') },
  { id: 'Weekly1', label: t('Weekly') },
  { id: 'Weekly2', label: t('Every 2 weeks') },
  { id: 'Monthly', label: t('Monthly') },
  { id: 'Yearly', label: t('Yearly') },
];
