import { Navigate, Route, Routes as RouterDomRoutes } from 'react-router-dom';

import { PrivateRoute } from '@pro4all/authentication/src/components';
import { Logout } from '@pro4all/authentication/src/routes/logout';
import { LogoutCallback } from '@pro4all/authentication/src/routes/logout-callback';
import { SignIn } from '@pro4all/authentication/src/routes/signin';
import { SilentRenew } from '@pro4all/authentication/src/routes/silent-renew';
import { Sso } from '@pro4all/authentication/src/routes/sso';
import { AuthService } from '@pro4all/authentication/src/services/auth-service';
import { PubliclySharedMessages } from '@pro4all/communication/ui/messages';
import { OfficeHostPage } from '@pro4all/documents/ui/office';
import { SharedDocsContextProvider } from '@pro4all/documents/ui/share';
import {
  PubliclySharedDocuments,
  SharedDocuments,
} from '@pro4all/documents/ui/views';
import { Organization, OrganizationNavigation } from '@pro4all/organization/ui';
import { ProjectsProviderWrapper } from '@pro4all/projects/ui/views';
import {
  ConfirmEmail,
  FinalizeInvitation,
  Registration,
} from '@pro4all/registration/ui';
import { MoveUser } from '@pro4all/settings/ui';
import { Routes } from '@pro4all/shared/config';
import { Box } from '@pro4all/shared/mui-wrappers';
import {
  Page404Authenticated,
  Page404NotAuthenticated,
} from '@pro4all/shared/ui/page-404';
import { Support } from '@pro4all/support';

import RedirectDeprecatedRoutes from './redirects/RedirectDeprecatedRoutes';
import { routeRedirects } from './redirects/redirects';

export const AppRoutes = () => (
  <RouterDomRoutes>
    {/* Handle redirects for deprecated routes */}
    {Object.entries(routeRedirects).map(([oldRoute, newRoute]) => (
      <Route
        element={<RedirectDeprecatedRoutes newRoute={newRoute} />}
        key={oldRoute}
        path={`${oldRoute}`}
      />
    ))}

    <Route element={<Navigate to={Routes.myProjectsOverview} />} path="/" />

    <Route element={<Registration />} path={Routes.registration} />

    <Route element={<ConfirmEmail />} path={Routes.confirmEmail} />

    <Route element={<FinalizeInvitation />} path={Routes.finalizeInvitation} />

    <Route
      element={<PubliclySharedDocuments />}
      path={Routes.publiclySharedDocs}
    />
    <Route
      element={<PubliclySharedDocuments />}
      path={Routes.revokePublicLink}
    />
    <Route
      element={<PubliclySharedMessages />}
      path={Routes.publicAttachments}
    />
    <Route
      element={<PrivateRoute element={<MoveUser />} />}
      path={Routes.moveUser}
    />
    <Route
      element={
        <PrivateRoute element={<ProjectsProviderWrapper />}></PrivateRoute>
      }
      path={Routes.proj}
    />
    <Route element={<Organization />} path={Routes.org} />
    <Route
      element={<PrivateRoute element={<OfficeHostPage />}></PrivateRoute>}
      path={Routes.office}
    />
    {[Routes.docsSharedByInvite, Routes.docsSharedInScope].map((path) => (
      <Route
        element={
          <PrivateRoute
            element={
              <SharedDocsContextProvider>
                <SharedDocuments />
              </SharedDocsContextProvider>
            }
          />
        }
        key={path}
        path={path}
      />
    ))}
    <Route
      element={<PrivateRoute element={<Support />}></PrivateRoute>}
      path={Routes.organizationSupport}
    />

    <Route element={<SignIn />} path="/signin" />
    <Route element={<Sso />} path="/signin-oidc" />
    <Route element={<Logout />} path="/logout" />
    <Route element={<LogoutCallback />} path="/logout-callback" />
    <Route element={<SilentRenew />} path="/silentrenew" />

    <Route
      element={
        AuthService.isAuthenticated() ? (
          <Box display="flex" height="100%">
            <OrganizationNavigation orgName="" />
            <Page404Authenticated />
          </Box>
        ) : (
          <Page404NotAuthenticated />
        )
      }
      path="*"
    />
  </RouterDomRoutes>
);
