import React from 'react';

import { Status, Task } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';
import { StatusChip } from '@pro4all/shared/ui/chips';
import { Card } from '@pro4all/shared/ui/general';
import { Icon } from '@pro4all/shared/ui/icons';
import { UserTag } from '@pro4all/shared/ui/identity-card';
import { MiddleEllipsis } from '@pro4all/shared/ui/middle-ellipsis';
import { Timestamp } from '@pro4all/shared/ui/timestamp';

import {
  Container,
  MetaContainer,
  MetaItem,
  ScrollContainer,
  TitleContainer,
} from '../styles/series.styles';
import {
  filterPlannedTasks,
  getCardStyle,
  isTaskExpired,
} from '../utils/series.utils';

interface SeriesTasksListProps {
  onlyPlannedTasks?: boolean;
  showFilters?: boolean;
  tasks?: Task[];
}

export const SeriesTasksList: React.FC<SeriesTasksListProps> = ({
  tasks = [],
  onlyPlannedTasks = false,
}) => {
  const { searchParams } = useRouting();

  const getCardTitle = (task: Task) => (
    <TitleContainer>
      <MiddleEllipsis text={task.name || ''} />
    </TitleContainer>
  );

  const getCardMeta = (task: Task) => (
    <MetaContainer>
      <StatusChip status={task.status || Status.ToDo} />
      {task.assignment?.[0] && <UserTag user={task.assignment[0]} />}
      {task.endTime && (
        <MetaItem isExpired={isTaskExpired(task)}>
          <Icon iconName="calendar" />
          <Timestamp date={task.endTime} />
        </MetaItem>
      )}
    </MetaContainer>
  );

  const handleTaskClick = (taskId: string) => {
    searchParams.set({ action: 'viewTask', id: taskId });
  };

  const filteredTasks = onlyPlannedTasks ? filterPlannedTasks(tasks) : tasks;

  return (
    <ScrollContainer>
      <Container>
        {filteredTasks.map((task) => (
          <Card
            cardStyle={getCardStyle(task.status)}
            iconName={task.status === Status.Done ? 'taskDone' : 'qcTask'}
            key={task.id}
            meta={getCardMeta(task)}
            onClick={() => handleTaskClick(task.id)}
            titleWithElement={getCardTitle(task)}
          />
        ))}
      </Container>
    </ScrollContainer>
  );
};
