import { BasePropsHierarchyItem, SortItemsProps } from '../types';

export const sortItems = <HierarchyItem extends BasePropsHierarchyItem>({
  allItems,
  level,
  itemsOnEqualLevel,
}: SortItemsProps<HierarchyItem>) => {
  const responseItems = [] as HierarchyItem[];

  // The starting item is the item that has no previousNodeId or previousNodeId is not in filter.
  const startingItem = itemsOnEqualLevel.find(
    (item) =>
      !item.previousNodeId ||
      !itemsOnEqualLevel.find((itemY) => itemY.id === item.previousNodeId)
  ) as HierarchyItem;

  responseItems.push(
    ...processItem<HierarchyItem>({ allItems, item: startingItem, level })
  );

  let nextItem = itemsOnEqualLevel.find(
    (item) => item?.previousNodeId === startingItem?.id
  );
  while (nextItem) {
    const id = nextItem.id;
    responseItems.push(
      ...processItem<HierarchyItem>({ allItems, item: nextItem, level })
    );
    nextItem = itemsOnEqualLevel?.find((item) => item?.previousNodeId === id);
  }
  return responseItems;
};

const processItem = <HierarchyItem extends BasePropsHierarchyItem>({
  allItems,
  item,
  level,
}: { item: HierarchyItem } & Pick<
  SortItemsProps<HierarchyItem>,
  'allItems' | 'level'
>) => {
  const responseItems = [] as HierarchyItem[];

  const childItems = allItems.filter(
    (childItem) => childItem.parentNodeId === item?.id
  );

  responseItems.push({
    ...item,
    hasChildren: Boolean(childItems.length),
    level,
  });

  // Append child items in the right order.
  if (childItems.length) {
    responseItems.push(
      ...sortItems<HierarchyItem>({
        allItems,
        itemsOnEqualLevel: childItems,
        level: level + 1,
      })
    );
  }

  return responseItems;
};
