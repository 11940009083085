import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';

import {
  DocumentAction,
  QualityControlInstance,
  Task,
  VisualContext,
} from '@pro4all/graphql';
import { Document } from '@pro4all/graphql';
import { ActionProps } from '@pro4all/shared/actions';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { Box } from '@pro4all/shared/mui-wrappers';
import { Card } from '@pro4all/shared/ui/general';
import { IconName } from '@pro4all/shared/ui/icons';
import { Icon } from '@pro4all/shared/ui/icons';
import { Text } from '@pro4all/shared/ui/typography';
import {
  isDocument,
  isQualityControlInstance,
  LinkedInstance,
  LinkedInstanceType,
  sortByDeleted,
} from '@pro4all/workflow/ui/utils';

import CardMeta from '../CardMeta';
import { CustomIconComponent } from '../task-properties/CustomIconComponent';

interface Props {
  drawings: VisualContext[];
  linkInstances: QualityControlInstance[] | Document[];
  removeLinkInstance: (instance: Document | QualityControlInstance) => void;
  task?: Task;
  title?: DocumentAction;
}

function InstancesToLink({
  linkInstances,
  drawings,
  task,
  removeLinkInstance,
  title,
}: Props) {
  const { t } = useTranslation();

  const getMenuItems = (
    instance: QualityControlInstance | Document
  ): ActionProps[] =>
    linkInstances.length > 1
      ? [
          {
            onClick: () => removeLinkInstance(instance),
            startIcon: 'close',
          },
        ]
      : [];

  const labelIcons: { [key in LinkedInstanceType]: IconName } = {
    Document: 'file',
    QualityControlInstance: 'snag',
    SearchDocument: 'snag',
  };
  const labelTitles: { [key in LinkedInstanceType]: string } = {
    Document: t('Documents'),
    QualityControlInstance: t('Snags to solve'),
    SearchDocument: t('Snags to solve'),
  };

  const cardIcons: { [key in LinkedInstanceType]: IconName } = {
    Document: 'file',
    QualityControlInstance: 'flame',
    SearchDocument: 'flame',
  };

  const getCardTitle = (instance: LinkedInstance): string => {
    let cardTitle = '';

    if (isQualityControlInstance(instance) || isDocument(instance)) {
      cardTitle = instance.name ?? '';
    }

    return cardTitle;
  };

  const getCardMeta = (instance: LinkedInstance) => {
    if (isDocument(instance) && instance?.deletedAt) return null;
    else if (isQualityControlInstance(instance) || isDocument(instance)) {
      return <CardMeta instance={instance} />;
    } else {
      return;
    }
  };

  const isSelectIconEnabled = useFeatureFlag('customericons');

  return (
    <>
      <div>
        <Box mt={5} mx={0}>
          <Label>
            <Icon
              iconName={
                labelIcons[linkInstances[0]?.['__typename'] ?? 'Document']
              }
              label={
                <Text variant="h4">
                  {title ??
                    labelTitles[linkInstances[0]?.['__typename'] ?? 'Document']}
                </Text>
              }
            />
          </Label>
          {sortByDeleted(linkInstances)?.map((instance: LinkedInstance) => (
            <Card
              cardStyle={
                (isDocument(instance) || isQualityControlInstance(instance)) &&
                instance?.deletedAt
                  ? 'error'
                  : 'default'
              }
              disabledButtonBorder
              iconComponent={
                isQualityControlInstance(instance) ? (
                  <CustomIconComponent
                    currentTask={task}
                    instance={instance}
                    type="snag"
                  />
                ) : undefined
              }
              iconName={
                !isSelectIconEnabled
                  ? cardIcons[linkInstances[0]?.['__typename'] ?? 'Document']
                  : undefined
              }
              key={instance?.id}
              menuItems={
                isDocument(instance) || isQualityControlInstance(instance)
                  ? getMenuItems(instance)
                  : []
              }
              meta={getCardMeta(instance)}
              title={
                instance?.name
                  ? getCardTitle(instance)
                  : t('No permissions to view this instance')
              }
            />
          ))}
        </Box>
      </div>
      {drawings.length > 0 && (
        <div>
          <Box mt={5} mx={0}>
            <Label>
              <Icon iconName="map" label={<Text>{t('Drawing')}</Text>} />
            </Label>
            {drawings.map((drawing) => (
              <Card
                cardStyle={drawing?.deletedAt ? 'error' : 'default'}
                disabledButtonBorder
                iconName="map"
                key={uuid()}
                title={drawing?.name}
              />
            ))}
          </Box>
        </div>
      )}
    </>
  );
}

export const Label = styled(Box)`
  && {
    display: block;
    margin-bottom: 1rem;
  }
`;

export default InstancesToLink;
