/* eslint-disable simple-import-sort/imports */
import React, { useEffect, useRef } from 'react';
import {
  Path,
  UseFormGetValues,
  UseFormSetValue,
  PathValue,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { FileData } from '@pro4all/authentication/src/services/authenticated-api-service';
import { CommunicationService } from '@pro4all/communication/data-access';
import { Attachment } from '@pro4all/graphql';
import { useContextScopedOrganizationId } from '@pro4all/shared/identity';
import { useFileUploadContext } from '@pro4all/shared/ui/file-upload';
import { tryDecodeString } from '@pro4all/shared/utils';

import { MessageFormBase } from '../types';

interface Props<T extends MessageFormBase> {
  onInput?: () => void;
  onSuccess?: () => void;
}

type UploadProgress = { [id: string]: number } | null;

export function useUpload<T extends MessageFormBase>({
  onInput,
  onSuccess,
}: Props<T>) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const getContextScopedOrganizationId = useContextScopedOrganizationId();
  const organizationId = getContextScopedOrganizationId();

  const uploadProgress = useRef<UploadProgress>(null);
  const setUploadProgress = (value: UploadProgress) =>
    (uploadProgress.current = value);

  const { hideProgress, isUploading, reset, showProgress, upload } =
    useFileUploadContext() || {};

  // Disable progressbar on mount
  useEffect(() => {
    hideProgress && hideProgress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Enable progressbar on unmount
  useEffect(
    () => () => {
      reset && reset();
      showProgress && showProgress();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const resetProgress = () => setUploadProgress(null);

  const handleUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
    setValue: UseFormSetValue<T>,
    getValues: UseFormGetValues<T>
  ) => {
    const attachments = getValues().attachments;
    const newFiles = getValues().newFiles;
    setUploadProgress({});

    if (!event.target.files) {
      resetProgress();
      return;
    }
    onInput && onInput();

    const files = Array.from(event.target.files);

    // Makes sure file names are not already present in Form 'attachments'
    const uploadedFiles = (attachments || []).map((at) =>
      tryDecodeString(at.fileName)
    );
    const nextFiles = files.filter(
      (file) => !uploadedFiles.includes(file.name)
    );
    if (!nextFiles.length) return;
    //@TODO: Check if we can infer types correctlyt
    setValue(
      'newFiles' as Path<T>,
      [...(newFiles || []), ...nextFiles] as PathValue<T, Path<T>>,
      {
        shouldValidate: true,
      }
    );

    const failed: FileData[] = [];
    const uploaded: FileData[] = [];

    try {
      if (!upload) {
        enqueueSnackbar(t('Something went wrong'));
        console.error('Upload context missing.');
        return;
      }

      upload(nextFiles, async (file) => {
        const uploadResult = await CommunicationService.uploadAttachment({
          file,
          onProgress: (progress) => {
            const nextProgress = Math.max(0, Math.min(progress, 1));
            const currentFileProgress =
              (uploadProgress.current && uploadProgress.current[file.name]) ||
              0;

            if (currentFileProgress < nextProgress || nextProgress === 0) {
              return setUploadProgress({
                ...uploadProgress.current,
                [file.name]: nextProgress,
              });
            } else {
              return setUploadProgress({
                ...uploadProgress.current,
              });
            }
          },
          organizationId,
        });

        uploadResult.data.errorCode
          ? failed.push(uploadResult)
          : uploaded.push(uploadResult);

        // TODO: Add a more descriptive message of the files did not upload
        if (failed.length > 0) enqueueSnackbar(t('Something went wrong'));

        const uploadedAttachments = uploaded.map(toAttachment);

        const setUploadAttachments: NonNullable<
          MessageFormBase['attachments']
        > = [...(attachments || []), ...uploadedAttachments];
        //@TODO: Check if we can infer types correctly
        setValue(
          'attachments' as Path<T>,
          [...(attachments || []), ...setUploadAttachments] as PathValue<
            T,
            Path<T>
          >,
          {
            shouldValidate: true,
          }
        );

        onSuccess && onSuccess();
      });
      resetProgress();
    } catch (error) {
      enqueueSnackbar(t('Something went wrong'));
      resetProgress();
    }
  };

  return { handleUpload, isUploading, resetProgress, uploadProgress };
}

const toAttachment = (fileData: FileData): Attachment => ({
  fileId: fileData.data.id,
  fileName: fileData.data.fileName || '',
  fileSize: fileData.data.fileSize || 0,
});
