import React from 'react';
import { Navigate, Route, Routes as RouterDomRoutes } from 'react-router-dom';

import { useDocument } from '@pro4all/documents/data-access';
import {
  useFolderTreeContextInner,
  useFolderTreeContextOuter,
} from '@pro4all/documents/ui/folders';
import {
  CollectionTable,
  DocumentTable,
  SmartFolderTable,
  VersionPane,
} from '@pro4all/documents/ui/table';
import { Routes } from '@pro4all/shared/config';
import { generateRoute, useRouting } from '@pro4all/shared/routing-utils';
import {
  BigMessageNoAccess,
  BigMessageNoFolders,
} from '@pro4all/shared/ui/messages';

export const TableRoutes: React.FC = () => {
  const { loading } = useFolderTreeContextInner();
  const {
    state: { folderTree },
  } = useFolderTreeContextOuter();
  const {
    searchParams,
    params: { projectId },
  } = useRouting();

  const documentId = searchParams.get('id');
  const versionId = searchParams.get('versionid');

  const { document, version } = useDocument();

  if (
    !loading &&
    documentId &&
    document === null &&
    versionId &&
    version === null
  )
    return <BigMessageNoAccess />;

  if (!loading && !folderTree?.length) return <BigMessageNoFolders />;

  const redirect = projectId
    ? `${generateRoute('projectDocumentsBaseUrl', {
        params: { projectId },
      })}`
    : '';

  return (
    <RouterDomRoutes>
      <Route
        element={<Navigate to={redirect} />}
        path={Routes.projectDmsBase}
      />
      {[Routes.collectionTable].map((route) => (
        <Route element={<CollectionTable />} key={route} path={route} />
      ))}

      {[Routes.smartFolderTable].map((route) => (
        <Route element={<SmartFolderTable />} key={route} path={route} />
      ))}

      {folderTree?.length &&
        [Routes.docTable].map((route) => (
          <Route
            element={
              <>
                <DocumentTable />
                <VersionPane />
              </>
            }
            key={route}
            path={route}
          />
        ))}
    </RouterDomRoutes>
  );
};
