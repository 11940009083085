import React from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { FrontPageProps, ReportOptions } from '@pro4all/shared/types';
import { firstCharToUpperCase } from '@pro4all/shared/utils';

import {
  Content,
  FirstPage,
  Instance,
  List,
  Logo,
  ReportPageTitle,
} from './elements';

export const FrontPage = ({
  companyLogo,
  date,
  project,
  reportOptions,
  user,
}: FrontPageProps) => {
  const { t } = useTranslation();

  const { enableLogo, enableTitle, showEmptyAnswers } = reportOptions || {};

  const values: { key: string; label?: string; value?: string }[] = [
    { key: 'projectName', label: t('Project name'), value: project.name },
    {
      key: 'projectNumber',
      label: t('Project number'),
      value: project.projectNumber,
    },
    {
      key: 'startDate',
      label: t('Project start date'),
      value: project?.startDate ? dayjs(project.startDate).format('ll') : '-',
    },
    { key: 'endDate', label: t('Project end date') },
    {
      key: 'projectDescription',
      label: t('Project description'),
      value: project.description || undefined,
    },
    {
      key: 'createdOn',
      label: t('Created on'),
      value: dayjs(date).format('lll'),
    },
    { key: 'createdBy', label: t('Created by'), value: user.displayName },
  ];

  return (
    <FirstPage>
      {enableLogo && (
        <Logo>
          <img alt="logo" src={companyLogo} />
        </Logo>
      )}
      <Content>
        {enableTitle && <ReportPageTitle>{t('Report')}</ReportPageTitle>}
        <List compactLists inlineLists={reportOptions.inlineLists}>
          {values.map(({ key, label, value }) => {
            const enableKey = `enable${firstCharToUpperCase(
              key
            )}` as keyof ReportOptions;
            if ((!showEmptyAnswers && !value) || !reportOptions[enableKey])
              return null;

            return (
              <Instance.Item key={key}>
                <Instance.Label>{label}</Instance.Label>
                <Instance.Value>{value || '-'}</Instance.Value>
              </Instance.Item>
            );
          })}
        </List>
      </Content>
    </FirstPage>
  );
};
