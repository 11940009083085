import React from 'react';
import { useTranslation } from 'react-i18next';
import { ApolloError } from '@apollo/client';

import { useRouting } from '@pro4all/shared/routing-utils';
import { Button } from '@pro4all/shared/ui/buttons';
import { BigMessage } from '@pro4all/shared/ui/messages';

export const Error = ({ error }: { error: ApolloError }) => {
  const { t } = useTranslation();
  return (
    <BigMessage
      shapeName="flow1"
      text={t(error.message)}
      title={t(error.name)}
    />
  );
};

export const NoObject = () => {
  const { t } = useTranslation();
  return (
    <BigMessage
      shapeName="build"
      text={t('Select an object to show details')}
      title={t('No object selected')}
    />
  );
};

export const NoObjects = () => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();

  return (
    <BigMessage
      shapeName="build"
      text={t(
        'Keep track of physical assets by saving data to digital objects.'
      )}
      title={t('No objects found')}
    >
      <Button onClick={() => searchParams.set('action', 'editObjects')}>
        {t('Create your first object.')}
      </Button>
    </BigMessage>
  );
};

export const NoProjectObjects = () => {
  const { t } = useTranslation();
  const { goTo } = useRouting();

  const toOrgObjects = () => goTo('objectsUrl');
  const text = `${t('Link an object from your organization to begin.')}`;

  return (
    <BigMessage shapeName="build" text={text} title={t('No objects found')}>
      <Button
        label={t('Link organization object')}
        onClick={toOrgObjects}
        startIcon="deviceHub"
      />
    </BigMessage>
  );
};

export const NotFound = () => {
  const { t } = useTranslation();
  return (
    <BigMessage
      shapeName="build"
      text={t('Select another object.')}
      title={t('Object not found')}
    ></BigMessage>
  );
};
