import styled from 'styled-components';

export const Container = styled.div`
  padding: ${({ theme }) => theme.spacing(2.67)} 0
    ${({ theme }) => theme.spacing(1.33)} 0;

  article:first-of-type {
    margin-top: ${({ theme }) => theme.spacing(1.33)};
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1.33)};
  margin-bottom: ${({ theme }) => theme.spacing(1.33)};
`;
