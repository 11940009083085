import React from 'react';
import { useTranslation } from 'react-i18next';

import { User } from '@pro4all/graphql';
import { Box } from '@pro4all/shared/mui-wrappers';
import { Option } from '@pro4all/shared/types';
import { Icon } from '@pro4all/shared/ui/icons';
import { MiddleEllipsis } from '@pro4all/shared/ui/middle-ellipsis';
import { Text } from '@pro4all/shared/ui/typography';

import { seriesFormatDate } from '../utils/seriesFormatDate';

import {
  Card,
  CompletedTasks,
  EndDate,
  Frequency,
  GridContainer,
  Participant,
  PropertyName,
  PropertyValue,
  RemoveButton,
  SeriesTitle,
  StartDate,
  StyledUserTag,
  TitleContainer,
} from './SeriesCard.styled';

export interface SeriesCardProps {
  onRemove?: () => void;
  serieData: {
    completedTasks?: number;
    endDate: string;
    frequency: Option | null;
    planAhead: number;
    seriesTitle: string;
    startDate: string;
  };
  user: User | null;
}

export const SeriesCard: React.FC<SeriesCardProps> = ({
  serieData,
  user,
  onRemove,
}) => {
  const { t } = useTranslation();
  const { startDate, endDate, frequency, seriesTitle, planAhead, completedTasks } = serieData;

  return (
    <Card>
      <GridContainer>
        <Text style={{ gridColumn: '1 / 2' }}>
          <TitleContainer>
            <Icon iconName="calendar" sx={{ fontSize: '24px' }} />
            <SeriesTitle>
              <MiddleEllipsis text={seriesTitle ?? ''} />
            </SeriesTitle>
          </TitleContainer>
        </Text>
        <Participant style={{ gridColumn: '2 / 3' }}>
          {user ? <StyledUserTag user={user} /> : t('None')}
        </Participant>
        <CompletedTasks style={{ gridColumn: '3 / 4' }}>
          <PropertyName>{t('Completed tasks')}:</PropertyName>{' '}
          <PropertyValue>{completedTasks || 0}/{planAhead}</PropertyValue>
        </CompletedTasks>
        {onRemove && (
          <RemoveButton
            onClick={onRemove}
            style={{ gridColumn: '4 / 5', gridRow: '1 / 3' }}
          >
            <Icon iconName="close" />
          </RemoveButton>
        )}

        <StartDate style={{ gridColumn: '1 / 2' }}>
          <PropertyName>{t('Start date')}:</PropertyName>{' '}
          <PropertyValue>{seriesFormatDate(startDate || '')}</PropertyValue>
        </StartDate>
        <EndDate style={{ gridColumn: '2 / 3' }}>
          <PropertyName>{t('End date')}:</PropertyName>{' '}
          <PropertyValue>{seriesFormatDate(endDate || '')}</PropertyValue>
        </EndDate>
        <Frequency style={{ gridColumn: '3 / 4' }}>
          <Box alignItems="center" display="flex" gap={1}>
            <Icon iconName="sync" />
            <Text variant="body2">
              <PropertyName>{t('Frequency')}:</PropertyName>{' '}
              <PropertyValue>{frequency?.label}</PropertyValue>
            </Text>
          </Box>
        </Frequency>
      </GridContainer>
    </Card>
  );
};
