import { Route, Routes as RouterDomRoutes } from 'react-router-dom';

import { PrivateRoute } from '@pro4all/authentication/src/components';
import { ProjectAccessType } from '@pro4all/projects/ui/context';
import { Routes } from '@pro4all/shared/config';
import { generateRoute, useRouting } from '@pro4all/shared/routing-utils';
import {
  BigMessageNoAccess,
  BigMessageNoSubscriptionAccess,
} from '@pro4all/shared/ui/messages';

import { MetadataTabs } from '../components/tabs/MetadataTabs';

import { TemplatesTasks } from './projectTemplatesTasks/TemplatesTasks';
import { HierarchicalListsProviderWrapper } from './HierarchicalListsProviderWrapper';
import { ProjectDocumentsProviderWrapper } from './ProjectDocumentsProviderWrapper';
import { ProjectQualityControlProviderWrapper } from './ProjectQualityControlProviderWrapper';

export const ProjectTemplates = ({
  hasQualityControlLicense,
  hasQualityControlFeature,
  hasDmsLicense,
  projectAccess,
}: {
  hasDmsLicense: boolean;
  hasQualityControlFeature: boolean;
  hasQualityControlLicense: boolean;
  projectAccess: ProjectAccessType;
}) => {
  const {
    params: { projectId },
  } = useRouting();
  const base = `${generateRoute('projectTemplatesBaseUrl', {
    params: { projectId },
  })}/`;

  return (
    <>
      <MetadataTabs base={base} />
      <RouterDomRoutes>
        <Route
          element={
            <PrivateRoute
              element={
                hasDmsLicense ? (
                  <ProjectDocumentsProviderWrapper />
                ) : (
                  <BigMessageNoSubscriptionAccess />
                )
              }
            />
          }
          path={Routes.documentsPath}
        />

        <Route
          element={
            <PrivateRoute
              element={
                hasQualityControlLicense && hasQualityControlFeature ? (
                  <ProjectQualityControlProviderWrapper />
                ) : (
                  <BigMessageNoSubscriptionAccess />
                )
              }
            />
          }
          path={Routes.qualitycontrolPath}
        />

        <Route
          element={
            <PrivateRoute
              element={
                hasQualityControlLicense && hasQualityControlFeature ? (
                  <HierarchicalListsProviderWrapper
                    isProject
                    pathHierarchicalLists={Routes.screen}
                  />
                ) : (
                  <BigMessageNoSubscriptionAccess />
                )
              }
            />
          }
          path={Routes.metaDataHierarchicalLists}
        />

        <Route
          element={
            <PrivateRoute
              element={
                ((hasQualityControlLicense && hasQualityControlFeature) ||
                  hasDmsLicense) &&
                projectAccess === ProjectAccessType.ProjectUpdate ? (
                  <TemplatesTasks />
                ) : projectAccess === ProjectAccessType.ProjectUpdate ? (
                  <BigMessageNoSubscriptionAccess />
                ) : (
                  <BigMessageNoAccess />
                )
              }
            />
          }
          path={Routes.tasks}
        />
      </RouterDomRoutes>
    </>
  );
};
