import React from 'react';

import { useSideNavContext } from '@pro4all/shared/contexts';
import {
  Box,
  List,
  useMediaQuery,
  useTheme,
} from '@pro4all/shared/mui-wrappers';
import { useApplication } from '@pro4all/shared/themes';
import { IconButton } from '@pro4all/shared/ui/buttons';
import { Logo } from '@pro4all/shared/ui/logos';

import * as Styled from './SideNavigation.styles';
import { SubscriptionWarning } from './SubscriptionWarning';
import { Color } from './types';

export interface SideNavigationProps {
  color: Color;
}

export const SideNavigation: React.FC<SideNavigationProps> = ({
  children,
  color,
}) => {
  const {
    sideNavState,
    sideNavState: { isSideNavOpen },
    sideNavVisibleOnMobile,
    setSideNavState,
    setSideNavVisibleOnMobile,
  } = useSideNavContext();
  const { isThemeTbq, isThemePave } = useApplication();
  const theme = useTheme();
  const showDeviceUi = useMediaQuery(theme.breakpoints.down('md'));

  const logoTbq = (
    <Logo
      compactMode={!isSideNavOpen}
      includeLink
      logoColor={color === 'dark' ? 'negativeWhite' : 'negativeBlack'}
    />
  );

  const logoProstream = (
    <Logo
      compactMode={!isSideNavOpen}
      includeLink
      logoColor={color === 'dark' ? 'negativeWhite' : 'negativeBlack'}
    />
  );

  const logoPave = (
    <Logo
      compactMode={!isSideNavOpen}
      includeLink
      logoColor="paveWhiteYellow"
    />
  );

  const styledDrawer = (
    <Styled.StyledDrawer
      $colorVariant={color}
      $open={isSideNavOpen}
      anchor="left"
      className={`${isSideNavOpen ? 'open-side-nav' : 'closed-side-nav'}`}
      disableBackdropTransition
      disableSwipeToOpen
      onClose={() => {
        setSideNavVisibleOnMobile(false);
      }}
      onOpen={() => {
        setSideNavVisibleOnMobile(true);
      }}
      open={showDeviceUi ? sideNavVisibleOnMobile : true}
      role="navigation"
      variant={showDeviceUi ? 'temporary' : 'permanent'}
    >
      <List>{children}</List>
      <Box mt="auto">
        <SubscriptionWarning />
        <Styled.LogoContainer>
          {isThemeTbq ? logoTbq : isThemePave ? logoPave : logoProstream}
        </Styled.LogoContainer>
        <Box
          aria-label="toggle-drawer"
          className="toggle-drawer"
          onClick={() =>
            setSideNavState({
              ...sideNavState,
              isSideNavOpen: !isSideNavOpen,
            })
          }
        >
          <IconButton
            className="chevron"
            disableBorder
            iconName={
              isSideNavOpen ? 'chevronLeftRounded' : 'chevronRightRounded'
            }
          />
        </Box>
      </Box>
    </Styled.StyledDrawer>
  );

  return styledDrawer;
};
