import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useApplication } from '@pro4all/shared/themes';
import { customColors, StylingDefaults } from '@pro4all/shared/themes';
import { Button } from '@pro4all/shared/ui/buttons';
import { TextField } from '@pro4all/shared/ui/inputs';
import { Logo } from '@pro4all/shared/ui/logos';
import { Text } from '@pro4all/shared/ui/typography';

import { usePubliclySharedDocsContext } from '../publicly-shared-docs/PubliclySharedDocsContext';

export const PasswordPrompt: React.FC = () => {
  const { t } = useTranslation();
  const [password, setPassword] = useState(null);
  const { isThemePave, isThemePro4all } = useApplication();

  const { submitPassword, invalidPasswordError } =
    usePubliclySharedDocsContext();

  return (
    <StyledForm onSubmit={() => submitPassword(password)}>
      <Block>
        <LogoWrapper>
          <Logo
            logoColor={
              isThemePave
                ? 'paveBlueYellow'
                : isThemePro4all
                ? 'pro4allMonochromePurple'
                : 'negativeBlack'
            }
          />
        </LogoWrapper>
        <Heading>
          <Text variant="h1">{t('Safety first')}</Text>
        </Heading>
        {t('A password is required to access these documents.')}
        <Input>
          <TextField
            error={invalidPasswordError}
            helperText={
              invalidPasswordError &&
              t('Password is incorrect. Check your password and try again.')
            }
            label={t('Password')}
            name="password"
            onChange={(event) => setPassword(event.target.value.toString())}
            type="password"
          />
        </Input>
        <Button type="submit" variant="contained">
          {t('Show documents')}
        </Button>
      </Block>
    </StyledForm>
  );
};

const LogoWrapper = styled.div`
  align-self: flex-start;
  padding-bottom: ${({ theme }) => theme.spacing(3)};
`;

const StyledForm = styled.form`
  display: flex;
  height: 100%;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
`;

const Block = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  max-width: 50rem;
  padding: 1rem;
  background: ${customColors.white};

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 6rem;
    border-radius: ${StylingDefaults.borderRadius};
  }
`;

const Heading = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

const Input = styled.div`
  margin: ${({ theme }) => theme.spacing(5)} 0
    ${({ theme }) => theme.spacing(5)} 0;
`;
