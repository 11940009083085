import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import {
  FrequencyType,
  SerieLinks,
  Status,
  TaskType,
  useUpsertSerieMutation,
} from '@pro4all/graphql';
import { Option } from '@pro4all/shared/types';
import { toApiDate } from '@pro4all/shared/utils';

import { FormFields } from '../types';

type SeriesInput = {
  LinkResource?: SerieLinks[];
  description: string;
  endTime: string | null;
  name: string;
  participant: Option;
  procedureId: string;
  projectId: string;
  status: Status;
  taskId: string;
  type: TaskType;
  userId: string;
  values: FormFields;
};

export const useUpsertSerie = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [upsertSeries] = useUpsertSerieMutation();
  const { t } = useTranslation();

  const handleUpsertSeries = async (serieInput: SeriesInput) => {
    const {
      values,
      endTime,
      projectId,
      procedureId,
      taskId,
      userId,
      participant,
      description,
      name,
      status,
      type,
      LinkResource = [],
    } = serieInput;

    const seriesData = {
      LinkResource,
      frequency: {
        frequencyType:
          FrequencyType[
            values?.seriesFrequency?.label as keyof typeof FrequencyType
          ] || FrequencyType.Daily,
        // If the frequency value is "Every 2 weeks", set skip to 1; otherwise, set skip to 0.
        skip: values.seriesFrequency?.label === 'Every 2 weeks' ? 1 : 0,
        startAt: endTime ? toApiDate(endTime) : null,
        stopAt: toApiDate(values?.seriesEndDate ?? ''),
      },
      planAheadTasks: values.seriesPlanAhead ?? 0,
      projectId,
      targetProcedureId: procedureId,
      targetTaskId: taskId,
      taskTemplate: {
        assignment: participant ? [participant.id] : [],
        autoInProgress: false,
        canFail: true,
        createdAt: new Date().toISOString(),
        createdBy: userId,
        description,
        endTime: endTime ? toApiDate(endTime) : null,
        index: 0,
        name,
        status,
        taskId,
        type,
      },
      updatedBy: userId,
    };

    try {
      const response = await upsertSeries({
        variables: {
          ...seriesData,
        },
      });
      enqueueSnackbar(t('Series created successfully'), { variant: 'success' });
      return response.data;
    } catch (error) {
      enqueueSnackbar(t('Failed to create series'), { variant: 'error' });
      throw error;
    }
  };

  return { handleUpsertSeries };
};
