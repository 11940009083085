import React from 'react';

import {
  useDocument,
  useUserFolderPermissions,
} from '@pro4all/documents/data-access';
import { useDocumentsContext } from '@pro4all/documents/ui/share';
import { Document, DocumentVersion } from '@pro4all/graphql';
import { useSidePanelContext } from '@pro4all/shared/contexts';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { useRouting } from '@pro4all/shared/routing-utils';
import { DataViewType, useDataViewContext } from '@pro4all/shared/ui/data-view';
import {
  useOptimisticResponseContext,
  useSetItemInLocalState,
} from '@pro4all/shared/ui/table';

import { DocumentKeywordsProvider } from './document-properties/keywords/DocumentKeywordsProvider';
import { DocumentDetailsFullscreen } from './DocumentDetailsFullscreen';
import { DocumentDetailsSidebar } from './DocumentDetailsSidebar';
import { DocumentDetailsProps, SidebarContent } from './types';
import { useTaskDetails } from './useTaskDetails';

export const DocumentDetails = ({
  documentId,
  folder,
  getRootProps,
  isDragActive = false,
  isSmartFolder = false,
  onClose,
  sidebarActions,
  sidebarContent = SidebarContent.document,
  versionId,
}: DocumentDetailsProps) => {
  const { searchParams } = useRouting();
  const isSearchResults = Boolean(
    searchParams.get('search') || searchParams.get('filters')
  );
  const isFocusComment = Boolean(searchParams.get('focusComment'));
  const { currentView } = useDataViewContext();
  const { versionPaneExpanded } = useDocumentsContext();
  const { sidePanelIsFullscreen } = useSidePanelContext();
  const hasFullScreenView = useFeatureFlag('fullscreen-view');

  const { TaskDetails, createTask, editTask, viewTask } = useTaskDetails();

  const { document, version } = useDocument();

  const {
    state: { item },
  } = useOptimisticResponseContext<Document | DocumentVersion>();

  useSetItemInLocalState<Document | DocumentVersion>(version || document);

  const itemFallback = item || version || document;

  const showDocumentDetails = Boolean(
    (documentId || versionId) && !(editTask || viewTask)
  );

  const versionPaneCheck =
    Boolean(
      (sidebarContent === SidebarContent.document && !versionPaneExpanded) ||
        sidebarContent === SidebarContent.version
    ) ||
    isSmartFolder ||
    isSearchResults ||
    isFocusComment ||
    currentView === DataViewType.Cards;

  const { canUploadVersion, loadingFolderPermissions } =
    useUserFolderPermissions({
      folderId: folder?.id || itemFallback?.folderId || '',
    });

  const userCanUploadVersion = canUploadVersion(document);

  const isActive = isDragActive && userCanUploadVersion;

  return createTask || viewTask || editTask ? (
    <TaskDetails />
  ) : showDocumentDetails && versionPaneCheck ? (
    <DocumentKeywordsProvider>
      {sidePanelIsFullscreen && hasFullScreenView ? (
        <DocumentDetailsFullscreen
          documentId={documentId}
          folder={folder}
          getRootProps={getRootProps}
          isActive={isActive}
          itemFallback={itemFallback}
          sidebarActions={sidebarActions}
        />
      ) : (
        <DocumentDetailsSidebar
          folder={folder}
          getRootProps={getRootProps}
          isActive={isActive}
          itemFallback={itemFallback}
          loadingFolderPermissions={loadingFolderPermissions}
          onClose={onClose}
          sidebarActions={sidebarActions}
        />
      )}
    </DocumentKeywordsProvider>
  ) : null;
};
