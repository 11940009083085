import { useTranslation } from 'react-i18next';

import {
  DocumentAction,
  QcPermissionCategory,
  Task,
  TaskType,
} from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import { useQCPermissions } from '@pro4all/quality-control/data-access';
import { useRouting } from '@pro4all/shared/routing-utils';
import { titleCaseToSentenceCase } from '@pro4all/shared/utils';
import {
  canOnlyAssignTaskToSelf,
  useTaskStatuses,
} from '@pro4all/workflow/ui/utils';

import { FormFields } from './types';

export const useInitialValues = ({
  hasDmsLicense,
  hasQualityControlLicense,
  hasQualityControlFeatureFlag,
  task,
  taskType,
}: {
  hasDmsLicense: boolean;
  hasQualityControlFeatureFlag: boolean;
  hasQualityControlLicense: boolean;
  task?: Task;
  taskType: TaskType;
}) => {
  const { t } = useTranslation();
  const statuses = useTaskStatuses();
  const permissions = useQCPermissions({
    category: QcPermissionCategory.Procedure,
  });
  const { userEmail, userFirstName, userLastName, userId } =
    useOrganizationContext();
  const { searchParams } = useRouting();
  const creatingTask = searchParams.is('action', 'createTask');

  const getParticipant = () => {
    if (creatingTask && canOnlyAssignTaskToSelf(permissions, taskType)) {
      const meOption = {
        id: userId,
        label: `${userFirstName} ${userLastName} (${userEmail})`,
      };
      return meOption;
    } else
      return task?.assignment?.length
        ? {
            id: task.assignment[0].id,
            label: task?.assignment?.[0].displayName,
          }
        : null;
  };

  const initialValues: FormFields = {
    category: task?.taskCategoryId
      ? {
          id: task?.taskCategoryId,
          label: task?.taskCategoryName,
        }
      : null,
    deliverables: task?.deliverables,
    description: task?.description || '',
    documentAction: task?.documentAction
      ? {
          id: task?.documentAction,
          label: titleCaseToSentenceCase(
            task?.documentAction
          ) as DocumentAction,
        }
      : null,
    endTime: task?.endTime || null,
    formTemplates:
      task?.formTemplates?.map((template) => ({
        label: template.name || '',
        ...template,
      })) || [],

    name: task?.name || '',

    nextStatus: task
      ? { id: task.status, label: t(statuses[task?.status]?.label) }
      : { id: 'ToDo', label: t('Open') },
    // @Todo: Make AssignedUsers[] from BE consistent with FE. We only have a single assigned user per task
    participant: getParticipant(),
    seriesEndDate: null,
    seriesFrequency: null,
    seriesPlanAhead: null,
    snagTemplates:
      task?.snagTemplates?.map((template) => ({
        label: template.name || '',
        ...template,
      })) || [],
    type: initialTaskTypeBasedOnContext(
      taskType,
      hasDmsLicense,
      hasQualityControlLicense,
      hasQualityControlFeatureFlag,
      task
    ),
    visualContexts:
      task?.visualContexts?.map((vc) => ({
        label: vc?.name || '',
        ...vc,
      })) || [],
  };

  return initialValues;
};

const initialTaskTypeBasedOnContext = (
  taskType: TaskType,
  hasDmsLicense: boolean,
  hasQualityControlLicense: boolean,
  hasQualityControlFeatureFlag: boolean,
  task?: Task
): TaskType => {
  if (task) return task.type;
  if (
    hasDmsLicense &&
    (!hasQualityControlLicense || !hasQualityControlFeatureFlag)
  )
    return TaskType.Document;
  if (
    hasQualityControlLicense &&
    hasQualityControlFeatureFlag &&
    !hasDmsLicense &&
    !taskType
  )
    return TaskType.QualityControl;
  return taskType;
};
