import React from 'react';
import styled from 'styled-components';

import { customColors } from '@pro4all/shared/themes';
import { Icon } from '@pro4all/shared/ui/icons';

export const QRCode = () => (
  <Wrapper>
    <div>
      <Icon iconName="qr" />
    </div>
  </Wrapper>
);

const Wrapper = styled.div`
  align-items: center;
  background: ${customColors.pendingBg};
  display: flex;
  flex-wrap: wrap;
  font-weight: 500;
  height: 100%;
  justify-content: center;
  text-align: center;
`;
