import { createContext, useContext, useState } from 'react';

import {
  ObjectNode,
  ObjectQueryResult,
  useObjectQuery,
} from '@pro4all/graphql';
import { ApiConfig } from '@pro4all/shared/config';

export interface Context extends Omit<ObjectQueryResult, 'data'> {
  id: ObjectNode['id'] | undefined;
  object: ObjectNode | undefined;
  projectId?: string;
  setObjectId: React.Dispatch<
    React.SetStateAction<ObjectNode['id'] | undefined>
  >;
}

const ObjectDetailContext = createContext<Context | undefined>(undefined);

export const useObjectDetailContext = () => {
  const context = useContext(ObjectDetailContext);
  if (!context) throw Error('Object detail context not initialized.');
  return context;
};

export const ObjectDetailProvider: React.FC = ({ children }) => {
  const [id, setObjectId] = useState<ObjectNode['id'] | undefined>(undefined);

  const { data, ...queryResult } = useObjectQuery({
    fetchPolicy: 'cache-and-network',
    pollInterval: 5000 * ApiConfig.pollEnabled,
    skip: !id,
    variables: { id },
  });

  const object = (data?.object ?? undefined) as ObjectNode | undefined;
  const projectId = object?.projectId ?? '';

  return (
    <ObjectDetailContext.Provider
      value={{ ...queryResult, id, object, projectId, setObjectId }}
    >
      {children}
    </ObjectDetailContext.Provider>
  );
};
