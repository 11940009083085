import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { Task, useDeleteTbqScansItemsMutation } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';
import { ItemChangedMessage } from '@pro4all/shared/ui/messages';

import { useCacheHelpers } from './useCacheHelpers';

export const useDeleteTbqScan = ({
  procedureId,
  taskIds,
  tbqFormIds,
  linkedSnagIds,
}: {
  linkedSnagIds: string[];
  procedureId: string;
  taskIds: string[];
  tbqFormIds: string[];
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [deleteTbqScansItems] = useDeleteTbqScansItemsMutation();
  const { params } = useRouting();
  const objectId = params.objectId;
  const { getCachedObjectDetail, updateTasksInCache } =
    useCacheHelpers(objectId);

  const handleDelete = async () => {
    try {
      const result = await deleteTbqScansItems({
        variables: {
          linkedSnagIds,
          procedureId,
          taskIds,
          tbqFormIds,
        },
      });
      const data = result.data?.deleteTBQScansItems;

      if ((data?.successIds?.length ?? 0) > 0) {
        const messageTemplate = `{{successNumber}} item(s) deleted.`;
        const messageVariables = {
          successNumber: data?.successIds?.length,
        };

        const cachedObjectDetail = getCachedObjectDetail();
        const updatedTasks = cachedObjectDetail.object.tasks.filter(
          (task: Task) => !taskIds.includes(task.id)
        );
        updateTasksInCache(updatedTasks);

        enqueueSnackbar(
          <ItemChangedMessage>
            {t(messageTemplate, messageVariables)}
          </ItemChangedMessage>
        );
      }
      if ((data?.errorIds?.length ?? 0) > 0) {
        const messageTemplate = `{{errorNumber}} item(s) could not be deleted.`;
        const messageVariables = {
          errorNumber: data?.errorIds?.length,
        };
        enqueueSnackbar(
          <ItemChangedMessage>
            {t(messageTemplate, messageVariables)}
          </ItemChangedMessage>,
          {
            variant: 'error',
          }
        );
      }
    } catch (_) {
      enqueueSnackbar(t('Failed to delete items'), {
        variant: 'error',
      });
    }
    return true;
  };

  return {
    handleDelete,
  };
};
