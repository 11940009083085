import { Project } from '@pro4all/graphql';
import { OptimisticResponseProvider } from '@pro4all/shared/ui/table';

import { Objects } from './Objects';

export const ObjectsProviderWrapper = () => (
  <OptimisticResponseProvider<Project>>
    <Objects />
  </OptimisticResponseProvider>
);
