import React, { useEffect } from 'react';
import { Route, Routes as RouterDomRoutes } from 'react-router-dom';

import { PrivateRoute } from '@pro4all/authentication/src/components';
import { MessagesProviderWrapper } from '@pro4all/communication/ui/messages';
import { SharedDocsContextProvider } from '@pro4all/documents/ui/share';
import { Documents, SharedDocuments } from '@pro4all/documents/ui/views';
import { OrganizationTemplates } from '@pro4all/metadata/ui';
import { MyOverview } from '@pro4all/my-overview/ui';
import { MyProfile } from '@pro4all/my-profile/ui';
import { ObjectsProviderWrapper } from '@pro4all/objects/ui';
import { useVerifyOpenCdeRequest } from '@pro4all/opencde/context';
import { useOrganizationContext } from '@pro4all/organization/context';
import {
  OrganizationAccess,
  OrganizationMembers,
  OrganizationOrganization,
} from '@pro4all/settings/ui';
import { useSubscriptionHelpers } from '@pro4all/settings/ui/src/subscription';
import { Routes } from '@pro4all/shared/config';
import { useSubscriptionRestriction } from '@pro4all/shared/hooks';
import { useScreenTooSmallForPage } from '@pro4all/shared/hooks/mobile-hooks';
import { Box } from '@pro4all/shared/mui-wrappers';
import { ContentLayout, MainLayout } from '@pro4all/shared/ui/layout';
import { BigMessageNoSubscriptionAccess } from '@pro4all/shared/ui/messages';
import { TopBar } from '@pro4all/shared/ui/top-bar';

import { OrganizationNavigation } from '../components/navigation/OrganizationNavigation';

import { ProjectsProviderWrapper } from './ProjectsProviderWrapper';

export const Organization: React.FC = () => {
  const { isExpired } = useSubscriptionHelpers();
  const BigMessageScreenTooSmall = useScreenTooSmallForPage();
  const verifyOpenCdeRequest = useVerifyOpenCdeRequest();

  useEffect(() => {
    verifyOpenCdeRequest();
  }, [verifyOpenCdeRequest]);

  const { userOrganizationName } = useOrganizationContext();

  const { contextScopedOrganizationSubscriptionLevel } =
    useOrganizationContext();

  const { hasDmsLicense, hasGeneralProjectLicense } =
    useSubscriptionRestriction(contextScopedOrganizationSubscriptionLevel);

  return (
    <Box display="flex">
      <OrganizationNavigation orgName={userOrganizationName} />
      <MainLayout>
        <ContentLayout>
          <TopBar />
          {BigMessageScreenTooSmall || (
            <RouterDomRoutes>
              {[Routes.profile].map((route) => (
                <Route
                  element={<PrivateRoute element={<MyProfile />} />}
                  key={route}
                  path={route}
                />
              ))}
              <Route
                element={
                  <PrivateRoute
                    appPermission="OrganizationRead"
                    element={<MyOverview />}
                  />
                }
                path={Routes.myOverview}
              />
              <Route
                element={
                  <PrivateRoute
                    appPermission="ProjectUpdate"
                    element={
                      !isExpired && hasGeneralProjectLicense ? (
                        <ProjectsProviderWrapper />
                      ) : (
                        <BigMessageNoSubscriptionAccess />
                      )
                    }
                  />
                }
                path={Routes.projects}
              />
              {[Routes.viewMessage, Routes.organizationMessages].map((path) => (
                <Route
                  element={
                    <PrivateRoute
                      appPermission="OrganizationRead"
                      element={<MessagesProviderWrapper />}
                    />
                  }
                  key={path}
                  path={path}
                />
              ))}

              {[Routes.baseDocs].map((path) => (
                <Route
                  element={
                    <PrivateRoute
                      appPermission="OrganizationRead"
                      element={
                        !isExpired && hasDmsLicense ? (
                          <Documents />
                        ) : (
                          <BigMessageNoSubscriptionAccess />
                        )
                      }
                    />
                  }
                  key={path}
                  path={path}
                />
              ))}

              {!isExpired && (
                <Route
                  element={
                    <PrivateRoute
                      appPermission="OrganizationRead"
                      element={<ObjectsProviderWrapper />}
                    />
                  }
                  path={Routes.objects}
                />
              )}

              <Route
                element={
                  <PrivateRoute
                    appPermission="OrganizationUpdate"
                    element={<OrganizationTemplates />}
                  />
                }
                path={Routes.organizationTemplates}
              />

              <Route
                element={
                  <PrivateRoute
                    appPermission="OrganizationUpdate"
                    element={<OrganizationMembers />}
                  />
                }
                path={Routes.usersGroups}
              />

              <Route
                element={
                  <PrivateRoute
                    appPermission="OrganizationUpdate"
                    element={
                      !isExpired && hasDmsLicense ? (
                        <OrganizationAccess />
                      ) : (
                        <BigMessageNoSubscriptionAccess />
                      )
                    }
                  />
                }
                path={Routes.access}
              />

              <Route
                element={
                  <PrivateRoute
                    appPermission="OrganizationUpdate"
                    element={<OrganizationOrganization />}
                  />
                }
                path={Routes.configSettings}
              />

              {[Routes.docsSharedByInvite, Routes.docsSharedInScope].map(
                (path) => (
                  <Route
                    element={
                      <PrivateRoute
                        element={
                          <SharedDocsContextProvider>
                            <SharedDocuments />
                          </SharedDocsContextProvider>
                        }
                      />
                    }
                    key={path}
                    path={path}
                  />
                )
              )}
            </RouterDomRoutes>
          )}
        </ContentLayout>
      </MainLayout>
    </Box>
  );
};
