import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

import { Option } from '@pro4all/shared/types';

import { FormFields } from '../../types';
import { generateSeriesTasks } from '../utils/generateSeriesTasks';

export const usePlanAheadOptions = () => {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<FormFields>();
  const [selectedOption, setSelectedOption] = useState<Option | undefined>(
    undefined
  );

  const tasksAhead = useMemo(
    () =>
      generateSeriesTasks(
        values.endTime,
        values?.seriesEndDate ?? '',
        values.seriesFrequency as Option
      ).length,
    [values.endTime, values.seriesEndDate, values.seriesFrequency]
  );

  const planAheadOptions = useMemo(() => {
    const options: Option[] = [];
    const MAX_OPTIONS = 14;

    for (let i = 1; i <= MAX_OPTIONS; i++) {
      options.push({
        disabled: i > tasksAhead,
        id: i.toString(),
        label: i === 1 ? `${i} ${t('task')}` : `${i} ${t('tasks')}`,
      });
    }

    return options;
  }, [tasksAhead, t]);

  useEffect(() => {
    if (
      values.endTime &&
      values.seriesEndDate &&
      values.seriesFrequency &&
      tasksAhead > 0
    ) {
      setFieldValue('seriesPlanAhead', tasksAhead);

      const matchingOption = planAheadOptions.find(
        (opt) => opt.id === tasksAhead.toString()
      );

      if (matchingOption) {
        setSelectedOption(matchingOption);
      }
    }
  }, [
    values.endTime,
    values.seriesEndDate,
    values.seriesFrequency,
    tasksAhead,
    planAheadOptions,
    setFieldValue,
  ]);

  const handleSelectChange = (option: Option) => {
    if (option && option.id) {
      const numValue = parseInt(option.id, 10);
      if (!isNaN(numValue)) {
        setFieldValue('seriesPlanAhead', numValue);
        setSelectedOption(option);
      }
    }
  };

  return {
    handleSelectChange,
    maxTasksAhead: tasksAhead,
    planAheadOptions,
    selectedOption,
  };
};
