import { QualityControlInstance } from '@pro4all/graphql';
import { ResultsMain } from '@pro4all/quality-control/ui/results';
import { Box } from '@pro4all/shared/mui-wrappers';
import { SnagFormSearchContextProvider } from '@pro4all/shared/snags-and-forms-search-context';
import { OptimisticResponseProvider } from '@pro4all/shared/ui/table';

export const ResultsProviderWrapper = ({
  projectId,
}: {
  projectId: string;
}) => (
  <OptimisticResponseProvider<QualityControlInstance>>
    <Box>
      <SnagFormSearchContextProvider>
        <ResultsMain projectId={projectId} />
      </SnagFormSearchContextProvider>
    </Box>
  </OptimisticResponseProvider>
);
