import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { QualityControlInstance } from '@pro4all/graphql';
import { Routes } from '@pro4all/shared/config';
import { useMatchAny } from '@pro4all/shared/hooks';
import { Box, useMediaQuery, useTheme } from '@pro4all/shared/mui-wrappers';
import { Button } from '@pro4all/shared/ui/buttons';

import { useResultActions } from './useResultActions';

const ButtonsContainer = styled(Box)`
  && {
    padding: 0 ${({ theme }) => theme.spacing(3)};
    & > :not(:last-child) {
      margin-right: ${({ theme }) => theme.spacing(1)};
    }
  }
`;

export const ResultButtons = ({
  result,
}: {
  result: QualityControlInstance;
}) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const showOnMapRoutes = useMatchAny([
    Routes.projectQualityControlResultsUrl,
    Routes.projectQualityControlTasksUrl,
    Routes.projectObjectResults,
    Routes.projectObjectTasks,
    Routes.orgObjectResults,
    Routes.objectTasks,
  ]);

  const showInListRoutes = useMatchAny([
    Routes.projectQualityControlDrawingUrl,
    Routes.orgObjectDrawing,
    Routes.projectObjectDrawing,
  ]);

  const { centerOnMap, showInTable, showOnMap } = useResultActions(result);
  return (
    <ButtonsContainer>
      {showOnMapRoutes &&
        result?.visualContext &&
        !result?.visualContext?.deletedAt && (
          <Button onClick={() => showOnMap(result.page ?? 1)} startIcon="map">
            {t('Show on drawing')}
          </Button>
        )}
      {!isMobile && showInListRoutes && (
        <>
          <Button onClick={showInTable} startIcon="list">
            {t('Show in table')}
          </Button>
          <Button onClick={centerOnMap} startIcon="myLocation">
            {t('Center on drawing')}
          </Button>
        </>
      )}
    </ButtonsContainer>
  );
};
