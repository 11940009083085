import React from 'react';
import styled from 'styled-components';

import { Task } from '@pro4all/graphql';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';

import { SeriesHeader } from './SeriesHeader';
import { TaskSeriesCardContainer } from './TaskSeriesCardContainer';

interface TaskSeriesSectionProps {
  task: Task;
}

export const TaskSeriesSection: React.FC<TaskSeriesSectionProps> = ({
  task,
}) => {
  const hasSeriesFeature = useFeatureFlag('task-series');
  const showSeriesCard = hasSeriesFeature && task.serieId;

  if (!showSeriesCard) return null;

  return (
    <SeriesContainer>
      <SeriesHeader />
      <TaskSeriesCardContainer isViewMode task={task} />
    </SeriesContainer>
  );
};

const SeriesContainer = styled.div`
  margin: ${({ theme }) => theme.spacing(3)} ${({ theme }) => theme.spacing(3)}
    0;
`;
