import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { DomainToClaim } from '@pro4all/graphql';
import { Box } from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';
import { PlainTable } from '@pro4all/shared/ui/plain-table';

const TableBox = styled(Box)`
  && {
    margin: 3% 0%;
  }
`;

const StatusLabel = styled.p<{ color: string }>`
  color: ${(props) => props.color};
`;

type OrganizationDomainTablesProps = {
  domainsToClaim: DomainToClaim[];
};

export const OrganizationDomainTables = ({
  domainsToClaim,
}: OrganizationDomainTablesProps) => {
  const { t } = useTranslation();

  const domainsColumns = [t('Domain'), t('Status'), t('Value')];

  const returnStatus = useMemo(
    () => (status: number) => {
      switch (status) {
        case 0:
          return (
            <StatusLabel color={customColors.orangeMonoHighlight}>
              {t('Verification in progress')}
            </StatusLabel>
          );
        case 1:
          return (
            <StatusLabel color={customColors.greenAccentBackground}>
              {t('Verified')}
            </StatusLabel>
          );
        case 2:
          return (
            <StatusLabel color={customColors.errorHover}>
              {t('Removed by other organization')}
            </StatusLabel>
          );
        case 3:
          return (
            <StatusLabel color={customColors.errorAlert}>
              {t('Rejected')}
            </StatusLabel>
          );
        default:
          return (
            <StatusLabel color={customColors.grey600}>
              {t('Unknown status')}
            </StatusLabel>
          );
      }
    },
    [t]
  );

  const domainsData = domainsToClaim
    ? domainsToClaim.map((item: DomainToClaim) => ({
        [t('Domain')]: <p>{item.domain}</p>,
        [t('Status')]: returnStatus(item.status),
        [t('Value')]: <p>{item.dnsValue}</p>,
      }))
    : [];

  return (
    <TableBox>
      <PlainTable columns={domainsColumns} data={domainsData} />
    </TableBox>
  );
};
