import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';
import { Option } from '@pro4all/shared/types';
import {
  ButtonsBoolean,
  SearchableSelectProps,
} from '@pro4all/shared/ui/inputs';

import { UploaderEditorTypes } from '../types';

type Props = Pick<
  UploaderEditorTypes,
  'errorString' | 'label' | 'updateProvider' | 'warningString' | 'value'
>;

const EditorBoolFieldComponent = ({
  errorString,
  label,
  updateProvider,
  value,
  warningString,
}: Props) => {
  const { t } = useTranslation();

  const selectOptions = useMemo(
    () => [
      { id: 'true', label: t('Yes') },
      { id: 'false', label: t('No') },
    ],
    [t]
  ) as Option[];

  const getDefaultValue = useCallback(
    (value: string) =>
      selectOptions.find((opt) => value?.toString() === opt?.id) as Option,
    [selectOptions]
  );

  const [currentValue, setCurrentValue] = useState(
    value ? getDefaultValue(value) : { id: '', label: '' }
  );

  // In case value is changed in the provider, we have to set the updated value else it won't re-render.
  useEffect(() => {
    if (value === '') {
      setCurrentValue({ id: '', label: '' });
    } else if (value) setCurrentValue(getDefaultValue(value));
  }, [getDefaultValue, value]);

  const handleChange: SearchableSelectProps['onChange'] = (
    selectedOption: Option
  ) => {
    if (selectedOption) {
      updateProvider(selectedOption.id);
      setCurrentValue(selectedOption);
    } else {
      updateProvider('');
      setCurrentValue({ id: '', label: '' });
    }
  };

  return (
    <Box>
      {label && (
        <Box
          sx={{
            background: customColors.white,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          <Typography variant="caption">{label}</Typography>
        </Box>
      )}
      <ButtonsBoolean
        error={errorString ? true : false}
        handleChange={handleChange}
        noWrap
        options={selectOptions}
        tooltipTitle={errorString || warningString}
        value={currentValue}
        warning={warningString ? true : false}
      />
    </Box>
  );
};

const skipUpdate = (prevProps: Props, nextProps: Props) =>
  prevProps.value === nextProps.value &&
  prevProps.errorString === nextProps.errorString &&
  prevProps.warningString === nextProps.warningString;

export const EditorBoolField = React.memo(EditorBoolFieldComponent, skipUpdate);
