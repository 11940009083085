import { FieldDefinition } from '@pro4all/graphql';
import { OptimisticResponseProvider } from '@pro4all/shared/ui/table';

import { ScreenFieldDefinitions } from './ScreenFieldDefinitions';

export const ScreenFieldDefinitionsProviderWrapper = ({
  includeOrgItems = true,
  isProject = false,
}: {
  includeOrgItems?: boolean;
  isProject?: boolean;
}) => (
  <OptimisticResponseProvider<FieldDefinition>>
    <ScreenFieldDefinitions
      includeOrgItems={includeOrgItems}
      isProject={isProject}
    />
  </OptimisticResponseProvider>
);
