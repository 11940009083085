import * as variables from '../style-dictionary-dist/variables';

export const colorsGeneric = {
  ai100: '  #ffe7a8',
  ai200: ' #ffcd7b',
  ai300: ' #f5b05b',
  ai400: ' #f5c55b',
  ai500: ' #c87a08',
  black: '#000000',
  blueAccent: '#4074d9',
  errorAlert: '#CF6679',
  errorBg: '#F5E0E4',
  errorHover: '#894A56',
  greenAccentBackground: '#2E7D32',
  greenLight: '#EDF7ED',
  greenMonoHighlight: '#38E5C4',
  greenShapeShadow: '#07847B',
  grey100: '#FAFAFA',
  grey200: '#F7F7F7',
  grey300: '#EEEEEE',
  grey400: '#DDDDDD',
  grey500: '#AFAFAF',
  grey600: '#848484',
  grey700: variables.GlobalCharcoalGrey,
  grey800: '#424242',
  grey900: variables.GlobalNotBlack,
  orangeAccent: '#E25314',
  orangeMonoHighlight: '#E8754D',
  orangeShapeShadow: '#AF3F12',
  pendingBg: '#CCF0F8',
  pendingHover: '#006379',
  purpleAccent: '#7E78F9',
  purpleMonoHighlight: '#6656FC',
  purpleShapeShadow: '#28237A',
  silverMonoHighlight: '#D6DEE5',
  silverShapeShadow: '#C3CAD1',
  stillBluePurple: '#2C2D56',
  success: '#36CCBA',
  successBg: '#7bdcb5',
  successHover: '#006B5E',
  warning: '#F5B05B',
  warningDark: '#8F4F00',
  warningLight: '#FCEFD6',
  white: variables.GlobalJustWhite,
  yellowAccent: '#F2F55B',
};
