import React from 'react';
import { Navigate, Route, Routes as RouterDomRoutes } from 'react-router-dom';

import { ScopeType } from '@pro4all/graphql';
import { useHierarchicalLists } from '@pro4all/metadata/data-access';
import { Routes } from '@pro4all/shared/config';
import { useIsAdminInContext } from '@pro4all/shared/identity';
import { generateRoute, useRouting } from '@pro4all/shared/routing-utils';
import { Column, Main } from '@pro4all/shared/ui/layout';
import { BigMessageNoAccess } from '@pro4all/shared/ui/messages';

import { HierarchicalListFrame } from '../components/hierarchical-lists/HierarchicalListFrame';
import { RedirectToOrganizationHierarchicalLists } from '../components/hierarchical-lists/RedirectToOrganizationHierarchicalLists';
import { ScreenFieldHierarchicalLists } from '../components/screens/ScreenFieldHierarchicalLists';

import { HierarchicalListsProps } from './types';
import { useIncludeOrgItems } from './useIncludeOrgItems';

export const HierarchicalLists = ({
  isProject = false,
  pathHierarchicalLists,
}: HierarchicalListsProps) => {
  const includeOrgItems = useIncludeOrgItems();
  const isAdminInContext = useIsAdminInContext();

  const { searchParams } = useRouting();
  const action = searchParams.get('action');
  const { selectedHierarchicalList } = useHierarchicalLists({
    includeOrgItems,
  });

  const redirectToOrganizationSection = Boolean(
    pathHierarchicalLists === Routes.projectMetaDataHierarchicalLists &&
      selectedHierarchicalList?.scope === ScopeType.Organization
  );

  const hierarchicalListsRoutes = Array.isArray(pathHierarchicalLists)
    ? pathHierarchicalLists
    : [pathHierarchicalLists];

  const {
    params: { projectId },
  } = useRouting();
  const redirect = projectId
    ? `${generateRoute('projectMetaDataHierarchicalLists', {
        params: { projectId },
      })}`
    : '';

  return action === 'createHierarchicalList' ? (
    <HierarchicalListFrame includeOrgItems />
  ) : action === 'editHierarchicalList' ? (
    redirectToOrganizationSection ? (
      <RedirectToOrganizationHierarchicalLists
        selectedHierarchicalList={selectedHierarchicalList}
      />
    ) : (
      <HierarchicalListFrame
        includeOrgItems
        selectedHierarchicalList={selectedHierarchicalList}
      />
    )
  ) : (
    <Main>
      <Column>
        <RouterDomRoutes>
          {hierarchicalListsRoutes.map((route) => (
            <Route
              element={
                isAdminInContext ? (
                  <ScreenFieldHierarchicalLists
                    includeOrgItems={includeOrgItems}
                    isProject={isProject}
                  />
                ) : (
                  <BigMessageNoAccess />
                )
              }
              path={route}
            />
          ))}
          {projectId && <Route element={<Navigate to={redirect} />} path="/" />}
        </RouterDomRoutes>
      </Column>
    </Main>
  );
};
