import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';

export const PlaceSnag: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      clipRule="evenodd"
      d="M9.66667 15.3333C13.3486 15.3333 16.3333 12.3486 16.3333 8.66667C16.3333 4.98477 13.3486 2 9.66667 2C5.98477 2 3 4.98477 3 8.66667C3 12.3486 5.98477 15.3333 9.66667 15.3333ZM9.6669 11.1669C11.0476 11.1669 12.1669 10.0476 12.1669 8.6669C12.1669 7.28619 11.0476 6.1669 9.6669 6.1669C8.28619 6.1669 7.1669 7.28619 7.1669 8.6669C7.1669 10.0476 8.28619 11.1669 9.6669 11.1669Z"
      fill={customColors.grey700}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M8 14.5H11.3333V17.8333C11.3333 17.8333 10.0833 22 9.66667 22C9.25 22 8 17.8333 8 17.8333V14.5Z"
      fill={customColors.grey700}
      fillRule="evenodd"
    />
    <path
      d="M14 20H16V22C16 22.55 16.45 23 17 23C17.55 23 18 22.55 18 22V20H20C20.55 20 21 19.55 21 19C21 18.45 20.55 18 20 18H18V16C18 15.45 17.55 15 17 15C16.45 15 16 15.45 16 16V18H14C13.45 18 13 18.45 13 19C13 19.55 13.45 20 14 20Z"
      fill={customColors.grey700}
    />
  </SvgIcon>
);
