import React from 'react';
import {
  Navigate,
  Route,
  Routes as RouterDomRoutes,
  useMatch,
} from 'react-router-dom';

import { TemplateService, TemplateType } from '@pro4all/graphql';
import { Routes } from '@pro4all/shared/config';

import { MetaDataContextProvider } from './MetaDataContext';
import { MetaDataMain } from './MetaDataMain';

export const OrganizationDocuments = () => {
  const routeTemplates = useMatch(Routes.orgMetadataTemplates);
  const routeItems = useMatch(Routes.orgMetadataFieldTemplates);

  const templateType = routeTemplates
    ? TemplateType.Document
    : routeItems
    ? TemplateType.Field
    : undefined;
  const templateService = TemplateService.Documents;

  return (
    <MetaDataContextProvider
      templateService={templateService}
      templateType={templateType}
    >
      <RouterDomRoutes>
        <Route
          element={<Navigate to={Routes.orgMetadataTemplates} />}
          path="/"
        />
        {[Routes.templatesMain].map((path) => (
          <Route
            element={
              <MetaDataMain
                pathFields={Routes.metaDataDocumentsFields}
                pathTemplates={Routes.metaDataDocumentScreenTemplates}
              />
            }
            key={path}
            path={path}
          />
        ))}
      </RouterDomRoutes>
    </MetaDataContextProvider>
  );
};
