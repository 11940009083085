import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { Routes } from '@pro4all/shared/config';
import { SideNavigationContext } from '@pro4all/shared/contexts';
import { ListItemIcon, ListItemText } from '@pro4all/shared/mui-wrappers';
import { Icon } from '@pro4all/shared/ui/icons';
import { StyledTooltip } from '@pro4all/shared/ui/side-navigation';
import { Text } from '@pro4all/shared/ui/typography';

const StyledNavLink = styled(NavLink)`
  display: flex;
  padding: ${({ theme }) => theme.spacing(1)}
    ${({ theme }) => theme.spacing(1.33)};

  > .MuiTypography-root {
    flex-shrink: 0;
    margin: 0;
    padding: ${({ theme }) => theme.spacing(1)};
  }
  > .MuiListItemIcon-root {
    align-items: center;
    min-width: ${({ theme }) => theme.spacing(3)};
  }

  > span {
    display: inline-flex;
  }
`;

export const ExitProjectButton: React.FC = () => {
  const { t } = useTranslation();
  const {
    setSideNavVisibleOnMobile,
    sideNavState: { isSideNavOpen },
  } = useContext(SideNavigationContext);

  const label = t('Exit project');

  return (
    <StyledNavLink
      data-testid="exit-project-button"
      onClick={() => setSideNavVisibleOnMobile(false)}
      to={Routes.myProjectsOverview}
    >
      <StyledTooltip
        $compact
        placement="right"
        title={isSideNavOpen ? '' : label}
      >
        <ListItemIcon>
          <Icon color="primary" iconName="exitProject" />
        </ListItemIcon>
      </StyledTooltip>
      {isSideNavOpen && (
        <Text variant="h6">
          <ListItemText disableTypography primary={label} />
        </Text>
      )}
    </StyledNavLink>
  );
};
