export const environment = {
  authentication: {
    // IO
     authUrl: 'https://auth.prostream.dev',
     clientId: '98d44a3c-1e7f-414c-b51a-de7b79f4fc1f',
     issuer: 'https://auth.prostream.dev/',

    // Production
    //authUrl: 'https://auth.prostream.app',
    //clientId: '2494894f-eed7-4b87-bd08-d3139b707526',
    //issuer: 'https://auth.prostream.app/',

    publicUrl: window.location.origin,
  },
  production: false,
};
