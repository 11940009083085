import { useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';

import { AuthService } from '@pro4all/authentication/src/services/auth-service';
import { useSessionStorage } from '@pro4all/shared/hooks';
import { useRouting } from '@pro4all/shared/routing-utils';

const StyledDiv = styled.div`
  height: 100%;
  width: 100%;
`;

export const OfficeHostPage = () => {
  const formRef = useRef<HTMLFormElement>(null);
  const { searchParams } = useRouting();
  const accessTokenValue = AuthService.getRawAccessTokenFromLocalStorage();

  const accessTokenTTLValue = AuthService.parseJwt().exp * 1000; // exp is in seconds, however we need to convert it to milliseconds.
  const { sessionStorageItem: officeActionUrl } = useSessionStorage({
    key: `online-office-url-${searchParams.get('versionId')}`,
  });

  const officeUrlWithLanguage = useMemo(() => {
    const userLanguage = searchParams.get('ui') || 'en-US';
    const url = new URL(officeActionUrl as string);

    ['ui', 'rs'].forEach((param) => {
      if (url.searchParams.has(param)) {
        url.searchParams.set(param, userLanguage);
      }
    });

    return url.toString();
  }, [officeActionUrl, searchParams]);

  useEffect(() => {
    if (formRef.current) {
      const frameholder = document.getElementById('frameholder');
      const office_frame = document.createElement('iframe');
      office_frame.name = 'office_frame';
      office_frame.id = 'office_frame';

      // The title should be set for accessibility
      office_frame.title = 'Office Frame';

      // This attribute allows true fullscreen mode in slideshow view
      // when using PowerPoint's 'view' action.
      office_frame.setAttribute('allowfullscreen', 'true');

      office_frame.setAttribute('height', '100%');
      office_frame.setAttribute('width', '100%');

      // The sandbox attribute is needed to allow automatic redirection to the O365 sign-in page in the business user flow
      office_frame.setAttribute(
        'sandbox',
        'allow-scripts allow-downloads allow-same-origin allow-forms allow-popups allow-top-navigation allow-popups-to-escape-sandbox'
      );
      if (frameholder) frameholder.appendChild(office_frame);
      formRef.current.submit();
    }
  }, []);

  return (
    <StyledDiv>
      <form
        action={officeUrlWithLanguage}
        id="office_form"
        method="post"
        name="office_form"
        ref={formRef}
        target="office_frame"
      >
        <input name="access_token" type="hidden" value={accessTokenValue} />
        <input
          name="access_token_ttl"
          type="hidden"
          value={accessTokenTTLValue}
        />
      </form>
      <span id="frameholder"></span>
    </StyledDiv>
  );
};
