import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { useSnackbar } from 'notistack';

import { useUserFolderPermissions } from '@pro4all/documents/data-access';
import {
  Document,
  FinalizationState,
  Tag,
  useCreateTagsMutation,
  useSetDocumentTagsMutation,
  useTagsQuery,
} from '@pro4all/graphql';
import { useGetDocumentTagsData } from '@pro4all/shared/hooks';
import { Box } from '@pro4all/shared/mui-wrappers';
import { Option } from '@pro4all/shared/types';
import { FormikSearchableMultiSelect } from '@pro4all/shared/ui/formik';
import { ResponseWrapper } from '@pro4all/shared/ui/response-wrapper';
import { useOptimisticResponseContext } from '@pro4all/shared/ui/table';
import { getExistingOptions } from '@pro4all/shared/utils';

import { useDocumentTagsConfig } from './useDocumentTagsConfig';

interface FormValues {
  tags: Option[];
}

interface Props {
  document: Document;
}

export const DocumentTags: React.FC<Props> = ({ document }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [setDocumentTags] = useSetDocumentTagsMutation();
  const [createTags] = useCreateTagsMutation();
  const allTags = useRef<Tag[]>([]);
  const {
    editItems,
    state: { items },
  } = useOptimisticResponseContext<Document>();

  const getDocumentTagsData = useGetDocumentTagsData();

  const documentIsFinalized = document.state === FinalizationState.Finalized;

  const { canUpdateDocumentOrVersion } = useUserFolderPermissions({
    folderId: document?.folderId ?? '',
  });

  const { error, data } = useTagsQuery({
    fetchPolicy: 'cache-and-network',
  });

  const allTagOptions: Option[] =
    data?.tags.map((tag) => ({
      id: tag?.id ?? '',
      label: tag?.name ?? '',
    })) ?? [];

  const initialValues: FormValues = {
    tags: document?.tags?.map((tag) => ({
      id: tag?.id ?? '',
      label: tag?.name ?? '',
    })) as Option[],
  };

  useEffect(() => {
    allTags.current = data ? data?.tags : [];
  }, [data]);

  const createAndSetDocumentTags = async (values: FormValues) => {
    const { newTagsToCreate, tagIds } = getDocumentTagsData({
      allTags: allTags.current,
      tags: values.tags,
    });

    let createdTagIds: string[] = [];
    let somethingWentWrong = false;
    try {
      if (newTagsToCreate.length) {
        const { data } = await createTags({
          variables: {
            tags: newTagsToCreate,
          },
        });
        createdTagIds = data?.createTags?.successful
          ? (data?.createTags?.successful as string[])
          : [];

        if (data?.createTags?.unsuccessful?.length) {
          somethingWentWrong = true;
        }
      }

      const allTagIds = [...new Set([...tagIds, ...createdTagIds])];

      const { data } = await setDocumentTags({
        variables: {
          documentId: document.id,
          tagIds: allTagIds,
        },
      });
      if (data?.setDocumentTags?.unsuccessful?.length) {
        somethingWentWrong = true;
      }

      const newTagsMapped: Tag[] = newTagsToCreate.map((tag) => ({
        id: tag.id,
        name: tag.label,
      }));
      const existingTagsMapped: Tag[] = getExistingOptions(values.tags).map(
        (tag) => ({ id: tag.id, name: tag.label })
      );

      editItems(
        items
          .filter((item) => item.id === document.id)
          .map((item) => ({
            ...item,
            tags: existingTagsMapped.concat(newTagsMapped),
          }))
      );
    } catch (e) {
      somethingWentWrong = true;
    }

    if (somethingWentWrong) {
      enqueueSnackbar(
        `${t('Something went wrong while saving your tags')}. ${t(
          'Please try again'
        )}.`
      );
    }
  };

  const { getField, validationSchema } = useDocumentTagsConfig();
  const tagsField = getField('tags');

  return (
    <ResponseWrapper error={error} isLoading={!data}>
      {data?.tags && (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={createAndSetDocumentTags}
          validationSchema={validationSchema}
        >
          {({ handleSubmit, isSubmitting }) => (
            <Form>
              <Box pb={2} px={3}>
                <FormikSearchableMultiSelect
                  canAddNewOptions
                  disabled={
                    !canUpdateDocumentOrVersion({
                      document: document ? document : null,
                      version: null,
                    }) ||
                    documentIsFinalized ||
                    isSubmitting ||
                    !data
                  }
                  label={tagsField?.label ?? ''}
                  limitTags={5}
                  name={tagsField?.name ?? ''}
                  onBlur={() => handleSubmit()}
                  options={allTagOptions}
                  placeholder={t('Add tags')}
                />
              </Box>
            </Form>
          )}
        </Formik>
      )}
    </ResponseWrapper>
  );
};
