import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router';
import styled from 'styled-components';

import { AuthService } from '@pro4all/authentication/src/services/auth-service';
import {
  QualityControlInstance,
  ResourceType,
  TaskType,
  TbqAnswer,
  TbqModuleType,
  TbqRieResult,
  useGetTaskLinkQualityControlIntanceLinksQuery,
} from '@pro4all/graphql';
import { DrawingRouterState } from '@pro4all/quality-control/data-access';
import { getDrawingRoute } from '@pro4all/quality-control/utils';
import { Routes } from '@pro4all/shared/config';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { Box } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Card } from '@pro4all/shared/ui/general';
import { Icon } from '@pro4all/shared/ui/icons';
import { Text } from '@pro4all/shared/ui/typography';
import {
  isTbqBrandcheck,
  isTbqRie,
  isVisualContext,
  TBQLinkedInstance,
} from '@pro4all/workflow/ui/utils';

import { TaskKey, TBQCardConfig, TBQLinkedInstancesProps } from './types';

export const TBQLinkedInstances: React.FC<TBQLinkedInstancesProps> = ({
  currentTask,
  filteredRieQuestions,
  taskType,
}) => {
  const { t } = useTranslation();
  const hasQualityControlFeatureFlag = useFeatureFlag('qualitycontrol');
  const { searchParams, goTo, url, params: routeParams } = useRouting();
  const { userId } = AuthService.getProfile();
  const projectId = currentTask.project?.id;
  const taskId = currentTask?.id;
  const assignedUser = currentTask?.assignment?.[0]?.id;
  const formInstanceId = searchParams.get('id') || '';
  const isTbq =
    currentTask.type === TaskType.Tbq || currentTask.type === TaskType.TbqScan;

  const { data: tbqFormInstanceLink } =
    useGetTaskLinkQualityControlIntanceLinksQuery({
      fetchPolicy: 'cache-and-network',
      skip: !isTbq,
      variables: { id: taskId, resourceType: ResourceType.TbqInstance },
    });

  const tbqFormInstanceId =
    tbqFormInstanceLink?.getTaskLinkQualityControlIntanceLinks || [];

  const isWorkflow = useMatch(Routes.projectTasks);

  const goToDrawings = (visualContextId: string) => {
    goTo(getDrawingRoute(routeParams), {
      params: {
        objectId: routeParams.objectId,
        projectId,
        visualContextId: visualContextId,
      },
      searchParams: {
        formInstanceId:
          tbqFormInstanceId.length > 0
            ? tbqFormInstanceId[0] ?? ''
            : formInstanceId,
        id: visualContextId,
        placeSnag: isTbq || taskType === TaskType.QualityControl,
        taskId,
      },
      state: {
        previousPageName: isWorkflow ? 'workflow' : 'tasks',
        previousPageUrl: url,
      } as DrawingRouterState,
    });
  };

  const cardConfigs: TBQCardConfig[] = [
    {
      condition: filteredRieQuestions && filteredRieQuestions.length > 0,
      iconName: 'questionMark',
      key: 'tbqLinkedQuestions',
      label: () => t('Linked questions'),
      meta: (instance) => {
        let resultName = '';
        let questionCategory = '';
        if (instance && isTbqRie(instance)) {
          resultName = instance.result.name;
          questionCategory = instance.category;
        } else if (instance && isTbqBrandcheck(instance)) {
          resultName = instance.reference ?? '';
          questionCategory = instance.categoryName;
        }

        return `${resultName}, ${questionCategory}`;
      },
    },
    {
      cardClick: () => {
        GetTBQQuestionaireInstance();
      },
      condition:
        (taskType === TaskType.Tbq || taskType === TaskType.TbqScan) &&
        hasQualityControlFeatureFlag &&
        tbqFormInstanceId.length > 0,
      iconName: 'tbq',
      key: 'tbqFormInstances',
      label: () => t('Questionnaire'),
    },
    //these are drawings that are actually linked to the task themselves
    {
      cardClick: (id) => goToDrawings(id),
      iconName: 'map',
      key: 'visualContexts',
      label: () => t('Drawings'),
    },
  ];

  const determineMenuItems = (
    card: TBQLinkedInstance,
    cardLink: string,
    cardConfig?: TBQCardConfig
  ) => {
    if (['tbqLinkedQuestions'].includes(cardLink)) return [];
    else
      return [
        {
          onClick: () => cardConfig?.iconClick?.(card.id),
          startIcon: cardConfig?.startIcon ?? 'arrowForward',
        },
      ];
  };

  const determineCardTitle = (
    card: TbqRieResult | TbqAnswer | QualityControlInstance
  ) => {
    if (isTbqRie(card)) {
      return card.section.name;
    } else if (isTbqBrandcheck(card)) {
      return card.sectionText;
    } else if (isVisualContext(card)) {
      return card.name;
    } else if (taskType === TaskType.Tbq || taskType === TaskType.TbqScan) {
      return 'TBQ';
    }

    return (card as QualityControlInstance)?.name ?? '';
  };

  const showCards = (cardConfig: TBQCardConfig) => {
    const cardLink = cardConfig.key;

    if (cardLink === 'tbqFormInstances') {
      return cardConfig.condition !== false && tbqFormInstanceId.length > 0;
    }
    const dataToMap = currentTask?.[cardLink as TaskKey] || [];

    return cardConfig.condition !== false && dataToMap?.length > 0;
  };

  const GetTBQQuestionaireInstance = () => {
    let tbqScanType = 'generic';
    switch (currentTask.tbq?.tbqModuleType) {
      case TbqModuleType.Brandcheck:
        tbqScanType = 'Brandcheck';
        break;
      case TbqModuleType.Rie:
        tbqScanType = 'rie';
        break;
      case TbqModuleType.Generic:
      default:
        tbqScanType = 'generic';
        break;
    }

    searchParams.set({
      action: userId === assignedUser ? 'editResult' : 'viewResult',
      id: tbqFormInstanceId[0] ?? '',
      taskId,
      tbqScanType,
    });
  };

  return (
    <TaskProperties>
      <>
        {cardConfigs.map((cardConfig) => {
          const cardLink = cardConfig.key;

          const dataToMap =
            cardLink === 'tbqLinkedQuestions'
              ? filteredRieQuestions
              : tbqFormInstanceId.length > 0 && cardLink === 'tbqFormInstances'
              ? tbqFormInstanceId
              : currentTask?.[cardLink as TaskKey] || [];

          return (
            <div key={cardLink}>
              {showCards(cardConfig) ? (
                <Box mb={5} mt={5} mx={3}>
                  <Label>
                    <Icon
                      iconName={cardConfig?.iconName ?? 'clipboard'}
                      label={
                        <Text variant="h4">{t(cardConfig?.label() ?? '')}</Text>
                      }
                    />
                  </Label>
                  {dataToMap.map((card: TBQLinkedInstance) => {
                    if (!card) return null;
                    return (
                      <Card
                        cardStyle="default"
                        iconName={cardConfig?.iconName ?? 'clipboard'}
                        key={card.id}
                        menuItems={determineMenuItems(
                          card,
                          cardLink,
                          cardConfig
                        )}
                        meta={cardConfig?.meta && cardConfig.meta(card)}
                        onClick={() => {
                          cardConfig?.cardClick &&
                            cardConfig?.cardClick(
                              card.id,
                              (card as QualityControlInstance).path ?? ''
                            );
                        }}
                        title={determineCardTitle(card) ?? 'TBQ'}
                      />
                    );
                  })}
                </Box>
              ) : null}
            </div>
          );
        })}
      </>
    </TaskProperties>
  );
};

export const TaskProperties = styled.div`
  margin: 1rem 0;
`;

export const Label = styled(Box)`
  && {
    display: block;
    margin-bottom: 1rem;
  }
`;
