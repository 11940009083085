import React from 'react';
import { Navigate, Route, Routes as RouterDomRoutes } from 'react-router-dom';

import { Routes } from '@pro4all/shared/config';

import { HierarchicalListsProviderWrapper } from './HierarchicalListsProviderWrapper';

export const OrganizationHierarchicalLists = () => (
  <RouterDomRoutes>
    <Route element={<Navigate to={Routes.orgHierarchicalLists} />} path="/" />
    <Route
      element={
        <HierarchicalListsProviderWrapper pathHierarchicalLists="hierarchy" />
      }
      path={Routes.templatesMain}
    />
  </RouterDomRoutes>
);
