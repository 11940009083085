import React from 'react';
import { useTranslation } from 'react-i18next';

import { useOrganizationContext } from '@pro4all/organization/context';
import { useProjectContext } from '@pro4all/projects/ui/context';
import { Routes } from '@pro4all/shared/config';
import { useSubscriptionRestriction } from '@pro4all/shared/hooks';
import {
  generateRoute,
  updateLsLastVisitedProjectRoute,
  useRouting,
} from '@pro4all/shared/routing-utils';
import { SubTabs, Tab } from '@pro4all/shared/ui/general';

export const ProjectSettingsTabs: React.FC = () => {
  const { t } = useTranslation();
  const { goTo, params } = useRouting();
  const { projectId } = params;
  const { isExternalUserInProject } = useProjectContext();

  const base = `${generateRoute('projectSettingsBaseUrl', {
    params: { projectId },
  })}/`;

  const { contextScopedOrganizationSubscriptionLevel } =
    useOrganizationContext();
  const { hasDmsLicense } = useSubscriptionRestriction(
    contextScopedOrganizationSubscriptionLevel
  );

  return (
    <SubTabs base={base}>
      <Tab
        data-testid="project-settings-project-tab"
        label={t('Project')}
        onClick={() => {
          goTo('projectSettingsUrl', {
            params: { projectId },
          });
          updateLsLastVisitedProjectRoute({
            overwriteCurrentValue: true,
            projectId: projectId || '',
            route: 'projectSettingsUrl',
          });
        }}
        value={Routes.projectSettings}
      />
      {!isExternalUserInProject && (
        <Tab
          data-testid="project-settings-connections-tab"
          label={t('Connections')}
          onClick={() => {
            goTo('connectionsSettingsUrl', {
              params: { projectId },
            });
            updateLsLastVisitedProjectRoute({
              overwriteCurrentValue: true,
              projectId: projectId || '',
              route: 'connectionsSettingsUrl',
            });
          }}
          value={Routes.connections}
        />
      )}
      {!isExternalUserInProject && hasDmsLicense && (
        <Tab
          data-testid="project-settings-documents-tab"
          label={t('Documents')}
          onClick={() => {
            goTo('projectDocumentSettingsUrl', {
              params: { projectId },
            });
            updateLsLastVisitedProjectRoute({
              overwriteCurrentValue: true,
              projectId: projectId || '',
              route: 'projectDocumentSettingsUrl',
            });
          }}
          value={Routes.documents}
        />
      )}
    </SubTabs>
  );
};
