import styled from 'styled-components';

import { Box } from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';

export const Container = styled(Box)`
  && {
    display: flex;
    color: ${customColors.white};
    padding: ${({ theme }) => theme.spacing(1)}
      ${({ theme }) => theme.spacing(3)} 0 ${({ theme }) => theme.spacing(3)};
    align-items: center;
    justify-content: space-between;
  }
`;
