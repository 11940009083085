import React, { SyntheticEvent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { ReferenceType } from '@pro4all/graphql';
import {
  Option,
  RenderCustomInputProps,
  RenderCustomOptionProps,
} from '@pro4all/shared/types';
import {
  _MultiSelect as MultiSelect,
  isOption,
} from '@pro4all/shared/ui/inputs';
import { pasteHandlerEmails } from '@pro4all/shared/utils/email';

import { MessageFormFields, RecipientField } from '../types';

import { InputWrap, StyledLabel } from './Header.styles';

export const UserSelect: React.FC<{
  displayErrorMessage: (email: string) => void;
  handleDebounceDraftSave: () => void;
  label: string;
  name: keyof MessageFormFields;
  noOptionsText?: string;
  options: Option[];
  renderCustomInput?: (props: RenderCustomInputProps) => React.ReactNode;
  renderCustomOption?: (props: RenderCustomOptionProps) => React.ReactNode;
}> = ({
  displayErrorMessage,
  label,
  name,
  noOptionsText,
  options,
  renderCustomInput,
  renderCustomOption,
  handleDebounceDraftSave,
}) => {
  const { control, setValue, trigger } = useFormContext<MessageFormFields>();

  const toOption = (value: RecipientField): Option =>
    options.find((opt) => opt.id === value.id) ?? {
      id: value.id,
      inputValue: value.email,
      label: value.email,
      type: ReferenceType.Email,
    };

  const getEmailOption = (email: string) =>
    options.find((opt) => opt.inputValue === email) ?? {
      email,
      id: email,
      type: ReferenceType.Email,
    };

  const updateValue = (values: (Option & { email?: string })[]) => {
    const normalizedEmails = values.map(({ email, id, label, type }) => ({
      email: email || label,
      id,
      type: type || ReferenceType.Email,
    })) as RecipientField[];

    setValue(name, normalizedEmails, { shouldValidate: true });
  };

  const onChangeHandler = (e: SyntheticEvent, values: (string | Option)[]) => {
    const updatedValues = values.map((value) =>
      isOption(value)
        ? {
            email: value.inputValue,
            id: value.id,
            type: value.type || ReferenceType.Email,
          }
        : { email: value, id: value, type: ReferenceType.Email }
    ) as RecipientField[];

    setValue(name, updatedValues, { shouldValidate: true });
    handleDebounceDraftSave();
  };

  return (
    <InputWrap>
      <StyledLabel>{label}</StyledLabel>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <MultiSelect
            autoCompleteProps={{
              getOptionLabel: (option) =>
                isOption(option) ? option.label : option,
              isOptionEqualToValue: (option, value) =>
                (isOption(option) ? option.id : option) ===
                (isOption(value) ? value.id : value),
              onChange: onChangeHandler,
              options,
              value: Array.isArray(field.value)
                ? (field.value as RecipientField[]).map(toOption)
                : [],
            }}
            color="default"
            inputRef={field.ref}
            noOptionsText={noOptionsText}
            onPaste={(event) =>
              pasteHandlerEmails({
                currentValues: Array.isArray(field.value)
                  ? (field.value as RecipientField[]).map(toOption)
                  : [],
                event,
                updateValuesCallback: updateValue,
              })
            }
            renderCustomInput={renderCustomInput}
            renderCustomOption={renderCustomOption}
          />
        )}
      />
    </InputWrap>
  );
};
