import React from 'react';
import {
  Navigate,
  Route,
  Routes as RouterDomRoutes,
  useMatch,
} from 'react-router-dom';

import { TemplateService, TemplateType } from '@pro4all/graphql';
import { Routes } from '@pro4all/shared/config';

import { MetaDataContextProvider } from './MetaDataContext';
import { MetaDataMain } from './MetaDataMain';

export const OrganizationQualityControl = () => {
  const routeForms = useMatch(Routes.orgQualityFormTemplates);
  const routeSnags = useMatch(Routes.orgQualitySnagTemplates);
  const routeTemplates = useMatch(Routes.metaDataQualityControlTemplates);
  const routeItems = useMatch(Routes.orgQualityFieldTemplates);

  const templateService = TemplateService.QualityControl;
  const templateType = routeForms
    ? TemplateType.Form
    : routeSnags
    ? TemplateType.Snag
    : routeTemplates
    ? TemplateType.Section
    : routeItems
    ? TemplateType.Field
    : undefined;

  const qualityControlRoutesTemplates = [Routes.formTemplates, Routes.snags];

  const qualityControlRoutesItems = [Routes.metaDataQualityControlFields];

  const qualityControlRoutesReportConfigurations = [
    Routes.metaDataQualityControlReportConfigurations,
  ];

  return (
    <MetaDataContextProvider
      templateService={templateService}
      templateType={templateType}
    >
      <RouterDomRoutes>
        <Route
          element={<Navigate to={Routes.orgQualityFormTemplates} />}
          path="/"
        />
        {[Routes.templatesMain].map((path) => (
          <Route
            element={
              <MetaDataMain
                pathFields={qualityControlRoutesItems}
                pathReportConfigurations={
                  qualityControlRoutesReportConfigurations
                }
                pathTemplates={qualityControlRoutesTemplates}
              />
            }
            key={path}
            path={path}
          />
        ))}
      </RouterDomRoutes>
    </MetaDataContextProvider>
  );
};
