import styled from 'styled-components';

import { customColors } from '@pro4all/shared/themes';

export const Main = styled.div<{ scootUp?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  ${({ scootUp }) => scootUp && `padding-top: 12px;`}
`;

export const NameWrap = styled.div<{
  $inactive?: boolean;
}>`
  display: flex;
  flex: 1;
  min-width: 0;
  color: ${({ $inactive }) => ($inactive ? customColors.grey500 : 'unset')};

  .MuiSvgIcon-root {
    margin-right: 1rem;
  }
  white-space: nowrap;
`;

export const MatchWrap = styled.div`
  font-weight: 500;
  cursor: pointer;
`;

export const HighlightedSpan = styled.span<{ $highlightColor: string }>`
  border-radius: 4px;
  background-color: ${({ $highlightColor }) => $highlightColor};
`;

export const OverflowText = styled.div<{ $flexShrink?: number }>`
  text-overflow: ellipsis;
  overflow: hidden;
  flex: ${({ $flexShrink }) =>
    $flexShrink ? `0 ${$flexShrink} content;` : '1'};
`;
