import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useBlocker } from 'react-router-dom';
import { Location } from 'history';

import { Dialog } from '@pro4all/shared/ui/dialog';
import { IconName } from '@pro4all/shared/ui/icons';
import { Text } from '@pro4all/shared/ui/typography';

interface Props {
  bypassRouterPrompt: boolean;
  iconName?: IconName;
  message: string;
  onConfirm?: () => void;
  onSubmit?: () => void;
  submitDisabled?: boolean;
  title: string;
}

export const NavigateAwayPrompt: React.FC<Props> = ({
  bypassRouterPrompt,
  iconName = 'warning',
  message,
  onConfirm,
  onSubmit,
  submitDisabled = false,
  title,
}) => {
  const { t } = useTranslation();

  const [showPromptModal, setShowPromptModal] = useState(false);
  const [nextLocation, setNextLocation] = useState<Location<unknown> | null>(
    null
  );
  const block = useRef(!bypassRouterPrompt);

  // Handle in-app navigation with useBlocker
  const blocker = useBlocker(({ nextLocation }) => {
    if (block.current) {
      setShowPromptModal(true);
      setNextLocation(nextLocation);
      return true; // Block navigation
    }
    return false; // Allow navigation
  });

  // Effect to add beforeunload event listener for browser/tab closing
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (block.current) {
        const confirmationMessage = t('Changes you made may not be saved.');
        event.preventDefault();
        event.returnValue = confirmationMessage; // event.returnValue is deprecated. However, it's still necessary to set it for some browsers to display the confirmation dialog.
        return confirmationMessage; // For some browser
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [t]);

  const handleConfirmNavigation = () => {
    if (nextLocation) {
      block.current = false;
      blocker.proceed?.(); // Proceed with navigation if blocked
      setShowPromptModal(false);
      onConfirm && onConfirm();
    }
  };

  const handleCloseModal = () => {
    setShowPromptModal(false);
    blocker.reset?.(); // Reset the blocker if user cancels
  };

  return (
    <Dialog
      confirmLabel={t('Leave')}
      discard
      iconName={iconName}
      name="routerPrompt"
      onClose={handleCloseModal}
      onConfirm={handleConfirmNavigation}
      onSubmit={() => {
        onSubmit?.();
        handleConfirmNavigation();
      }}
      open={showPromptModal}
      submitDisabled={submitDisabled}
      submitLabel={t('Save changes')}
      title={t(title)}
    >
      <Text>{t(message)}</Text>
    </Dialog>
  );
};
