import { ActionProps } from '@pro4all/shared/actions';
import { Box } from '@pro4all/shared/mui-wrappers';
import { ActionBar, ButtonOrientation } from '@pro4all/shared/ui/action-bar';
import { ShapeColor, ShapeType } from '@pro4all/shared/ui/shapes';

import { InfoHeader } from './InfoHeader';
import * as Styled from './InfoPage.styles';
import { InfoTopBar } from './InfoTopBar';
import { PageType } from './types';
import { useGetInfoPageUI } from './useGetInfoPageUI';

interface InfoPageProps {
  actions?: ActionProps[];
  buttonOrientation?: ButtonOrientation;
  description: JSX.Element | string;
  shapeColorCustom?: ShapeColor;
  shapeTypeCustom?: ShapeType;
  title: string;
  type?: PageType;
}

export const InfoPage = ({
  actions,
  buttonOrientation = 'horizontal',
  description,
  shapeColorCustom,
  shapeTypeCustom,
  title,
  type = 'success',
}: InfoPageProps) => {
  const { bgElement, color, iconName, shapeColor, shapeType } =
    useGetInfoPageUI(type);

  return (
    <>
      {bgElement}
      <Styled.Container>
        <InfoTopBar logoColor="negativeBlack" />
        <Styled.InfoContainer>
          <Styled.Block>
            <div>
              <InfoHeader color={color} iconName={iconName} title={title} />
              <Box mb={2}>{description}</Box>
            </div>
            <Styled.ActionsContainer>
              <Styled.Actions $color={color}>
                <ActionBar
                  buttonOrientation={buttonOrientation}
                  flexEnd
                  mainActions={actions}
                />
              </Styled.Actions>
            </Styled.ActionsContainer>
          </Styled.Block>
          <Styled.BackgroundShape>
            <Styled.StyledShape
              color={shapeColorCustom || shapeColor}
              type={shapeTypeCustom || shapeType}
            />
          </Styled.BackgroundShape>
        </Styled.InfoContainer>
      </Styled.Container>
    </>
  );
};
