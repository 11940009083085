import React from 'react';

import { useTasksBySerieQuery } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';

import { SeriesTasksList } from './SeriesTasksList';

interface Props {
  serieId: string;
}

export const PlannedTasks: React.FC<Props> = ({ serieId }) => {
  const { searchParams } = useRouting();
  const isViewSerie = searchParams.is('action', 'viewSerie');

  const { data, loading } = useTasksBySerieQuery({
    skip: !isViewSerie,
    variables: {
      serieId,
    },
  });

  if (loading) return null;

  return <SeriesTasksList onlyPlannedTasks tasks={data?.tasksBySerie} />;
};
