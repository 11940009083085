import { useCallback, useLayoutEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import { RouteParams, Routes, TrackingEvent } from '@pro4all/shared/config';
import { useSideNavContext } from '@pro4all/shared/contexts';
import {
  generateRoute,
  updateLsLastVisitedProjectRoute,
  useRouting,
} from '@pro4all/shared/routing-utils';
import { useAnalytics } from '@pro4all/shared/vendor';

import { NavGroup } from './NavGroup';
import { NavItem } from './NavItem';
import { MenuItem, MenuSubItem } from './types';

interface Props {
  menuItems: MenuItem[];
}

export const NavigationItems = ({ menuItems }: Props) => {
  const [sideNavStateNP, setSideNavStateNP] = useState<string[]>([]);

  const { setSideNavVisibleOnMobile } = useSideNavContext();
  const { track } = useAnalytics();
  const { params } = useRouting();
  const projectId = params.projectId;
  const {
    sideNavState: { isSideNavOpen },
  } = useSideNavContext();

  const SideNavItem = ({ item }: { item: MenuItem | MenuSubItem }) => (
    <NavItem
      count={item.messageCount}
      countColor={'countColor' in item ? item.countColor : undefined}
      dataTestId={item.dataTestId}
      iconName={'icon' in item ? item.icon : null}
      label={item.displayName}
      onClick={() => {
        updateLsLastVisitedProjectRoute({
          projectId: projectId ?? '',
          route: item.route,
        });
        track(TrackingEvent.NavigationSelected, item.trackPayload);
        setSideNavVisibleOnMobile(false);
      }}
      to={
        projectId
          ? generateRoute(item.route, {
              params: { projectId },
            })
          : Routes[item.route as keyof typeof Routes]
      }
    />
  );

  const location = useLocation();
  const url = location.pathname;

  const checkSubItems = useCallback(
    (subItems: MenuSubItem[]) =>
      subItems.some((subItem) =>
        compareUrls({ params, route: subItem.route, url })
      ),
    [url]
  );

  useLayoutEffect(() => {
    const mItem = menuItems.find((item) => checkSubItems(item.subItems ?? []));

    setSideNavStateNP(mItem?.id ? [mItem?.id] : []);
  }, []);

  const compareUrls = ({
    route,
    url,
    params,
  }: {
    params: RouteParams;
    route: keyof typeof Routes;
    url: string;
  }): boolean => {
    const generateRouteUrl = generateRoute(route, { params });
    return url.includes(generateRouteUrl);
  };

  return (
    <>
      {menuItems.map((item) => {
        const { condition } = item;
        return condition === undefined || condition === true ? (
          (item?.subItems?.length ?? 0) > 0 ? (
            <NavGroup
              count={isSideNavOpen ? item.messageCount : undefined}
              dataTestId={item.dataTestId}
              iconName={item.icon}
              id={item.id}
              key={item.id}
              label={item.displayName}
              route={item.route}
              setSideNavStateNP={setSideNavStateNP}
              sideNavStateNP={sideNavStateNP}
              to={
                projectId
                  ? generateRoute(item.route, {
                      params: { projectId },
                    })
                  : Routes[item.route as keyof typeof Routes]
              }
            >
              {isSideNavOpen &&
                item.subItems?.map((subItem) => {
                  const { condition } = subItem;
                  return condition === undefined || condition === true ? (
                    <SideNavItem item={subItem} key={uuid()} />
                  ) : null;
                })}
            </NavGroup>
          ) : (
            <SideNavItem item={item} key={uuid()} />
          )
        ) : null;
      })}
    </>
  );
};
