import { Frequency, Serie } from '@pro4all/graphql';
import { RenderOptionType } from '@pro4all/shared/types';
import { FilterHeaderType } from '@pro4all/shared/ui/filtering';
import { UserTag } from '@pro4all/shared/ui/identity-card';
import { ColumnProps } from '@pro4all/shared/ui/table';
import { FilterHeader } from '@pro4all/shared/ui/table-column-filtering';
import { getFormattedDate, Timestamp } from '@pro4all/shared/ui/timestamp';

export const useSeriesColumns = () => {
  const columns: ColumnProps<Serie>[] = [
    {
      filterable: true,
      getValue: (serie) => serie.taskTemplate?.name,
      headerComponent: (
        <FilterHeader<Serie, {}>
          defaultWidth={300}
          filterType={FilterHeaderType.Text}
          label="Title"
          minWidth={120}
          propertyId="taskTemplate"
          pullFromLocalStorage
        />
      ),
      key: 'taskTemplate.name',
      width: 300,
    },
    {
      filterable: true,
      getValue: (serie) => {
        const type = serie.frequency?.frequencyType;
        return type ? type.charAt(0).toUpperCase() + type.slice(1) : '';
      },
      headerComponent: (
        <FilterHeader<Serie, {}>
          defaultWidth={150}
          filterType={FilterHeaderType.Select}
          label="Frequency"
          minWidth={120}
          propertyId="frequency"
        />
      ),
      key: 'frequency.frequencyType',
      width: 150,
    },
    {
      filterable: true,
      getValue: (serie) => serie.completedTasks,
      headerComponent: (
        <FilterHeader<Serie, {}>
          defaultWidth={150}
          filterType={FilterHeaderType.Number}
          label="Completed tasks"
          minWidth={120}
          propertyId="completedTasks"
        />
      ),
      key: 'completedTasks',
      render: (serie) =>
        `${serie.completedTasks || 0}/${serie.planAheadTasks || 0}`,
      width: 150,
    },
    {
      filterable: true,
      getValue: (serie: Serie) => {
        const date = serie?.frequency?.StartAt;
        return date ? getFormattedDate(date).label : '';
      },
      headerComponent: (
        <FilterHeader<Serie, Frequency>
          defaultWidth={200}
          filterType={FilterHeaderType.Date}
          label="Start date"
          minWidth={120}
          propertyId="frequency"
          subPropertyId="StartAt"
        />
      ),
      key: 'frequency.startAt',
      render: (serie) => {
        const date = serie?.frequency?.StartAt;
        return date && <Timestamp date={date} format="lll" />;
      },
      width: 200,
    },
    {
      filterable: true,
      getValue: (serie: Serie) => {
        const date = serie?.frequency?.StopAt;
        return date ? getFormattedDate(date).label : '';
      },
      headerComponent: (
        <FilterHeader<Serie, Frequency>
          defaultWidth={200}
          filterType={FilterHeaderType.Date}
          label="End date"
          minWidth={120}
          propertyId="frequency"
          subPropertyId="StopAt"
        />
      ),
      key: 'frequency.stopAt',
      render: (serie) => {
        const date = serie?.frequency?.StopAt;
        return date && <Timestamp date={date} format="lll" />;
      },
      width: 200,
    },
    {
      filterable: true,
      getValue: (serie: Serie) => serie.createdBy || '',
      headerComponent: (
        <FilterHeader<Serie, string>
          defaultWidth={150}
          filterType={FilterHeaderType.Select}
          label="Created by"
          minWidth={150}
          propertyId="createdBy"
          renderOption={RenderOptionType.UserCard}
        />
      ),
      key: 'createdBy',
      render: ({ createdByUser }) =>
        createdByUser ? <UserTag user={createdByUser} /> : null,
      width: 120,
    },
  ];

  return columns;
};
