import { Folder, useFolderByPathQuery } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';

export const useGetFolder = () => {
  const { path, projectId, '*': rest } = useRouting().params;
  const fullPath = rest ? `${path}/${rest}` : path;
  const { data, refetch, error } = useFolderByPathQuery({
    fetchPolicy: 'network-only', // Do NOT change to cache-and-network, because then table column filtering will not work properly when swithching between folder.
    // fetchPolicy: 'cache-and-network',
    variables: { path: fullPath ?? '/', projectId },
  });

  return {
    error,
    folder: data?.folder as Folder,
    loading: !data,
    path,
    refetch,
  };
};
