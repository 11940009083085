import React from 'react';
import { useTranslation } from 'react-i18next';

import type { ApiKey } from '@pro4all/graphql';
import { ActionProps } from '@pro4all/shared/actions';
import { useRouting } from '@pro4all/shared/routing-utils';
import { useTableCheck } from '@pro4all/shared/ui/table';
import { TableActionBar } from '@pro4all/shared/ui/table-action-bar';

import { useApiKeyContext } from './ApiKeyContext';

export const ApiKeysActionBar = () => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();
  const { checkedRows: selectedApiKeys } = useTableCheck<ApiKey>();
  const { setShowRevokeWarning } = useApiKeyContext();

  const mainActions: ActionProps[] = [
    {
      ariaLabel: t('Create API key'),
      dataTestId: 'create-api-key',
      key: 'create-api-key',
      label: t('Create API key'),
      onClick: () => searchParams.set('action', 'createApiKey'),
      startIcon: 'add',
    },
  ];

  const altActions: ActionProps[] = [
    {
      ariaLabel: t('Revoke'),
      dataTestId: 'revoke-api-key',
      disabled: selectedApiKeys.length > 1,
      key: 'revoke-api-key',
      label: t('Revoke'),
      onClick: () => setShowRevokeWarning(true),
      startIcon: 'delete',
    },
  ];

  return (
    <TableActionBar
      altActions={altActions}
      dataTestid="api-keys-action-bar"
      mainActions={mainActions}
      search
    />
  );
};
