import React from 'react';

import {
  PublicLinkError,
  PubliclySharedDocsContextProvider,
  PubliclySharedDocsTable,
} from '@pro4all/documents/ui/share';
import { Document } from '@pro4all/graphql';
import { ProstreamCta } from '@pro4all/shared/assets-sharing';
import { Box } from '@pro4all/shared/mui-wrappers';
import { InfoTopBar } from '@pro4all/shared/ui/infopages';
import { Background, MainLayout } from '@pro4all/shared/ui/layout';
import { OptimisticResponseProvider } from '@pro4all/shared/ui/table';

export const PubliclySharedDocuments: React.FC = () => (
  <OptimisticResponseProvider<Document>>
    <PubliclySharedDocsContextProvider>
      <MainLayout>
        <Background />
        <InfoTopBar logoColor="negativeWhite" />
        <Box display="flex" flex={1} p={3} pt={2}>
          <PublicLinkError />
          <PubliclySharedDocsTable />
        </Box>
        <ProstreamCta />
      </MainLayout>
    </PubliclySharedDocsContextProvider>
  </OptimisticResponseProvider>
);
