import React from 'react';
import styled from 'styled-components';

import { Routes } from '@pro4all/shared/config';
import { useLocalStorage } from '@pro4all/shared/hooks';
import { useTheme } from '@pro4all/shared/mui-wrappers';
import {
  updateLsLastVisitedOrganizationFolder,
  updateLsLastVisitedProjectFolder,
  useRouting,
} from '@pro4all/shared/routing-utils';
import { customColors } from '@pro4all/shared/themes';
import { Icon } from '@pro4all/shared/ui/icons';
import { MiddleEllipsis } from '@pro4all/shared/ui/middle-ellipsis';

const Wrap = styled.div<{ $color: string }>`
  display: flex;
  min-height: 20px;
  min-width: 20px;
  align-items: center;
  padding-right: 32px;
  gap: 4px;
  color: ${({ $color }) => $color};
`;

const Path = styled.div<{ $canNavigateToFolder: boolean }>`
  width: 100%;
  &:hover {
    cursor: ${({ $canNavigateToFolder }) =>
      $canNavigateToFolder ? 'pointer' : 'default'};
  }
`;

interface Props {
  canNavigateToFolder?: boolean;
  endLength?: number;
  id: string;
  path: string;
  pathIds: string[];
  setExpandedRowKeys?: (pathIds: string[]) => void;
  updateExpandedKeysInLocalStorage?: boolean;
}

export const FilePath: React.FC<Props> = ({
  updateExpandedKeysInLocalStorage = false,
  canNavigateToFolder = true,
  endLength = 6,
  id,
  path,
  pathIds,
  setExpandedRowKeys,
}) => {
  const {
    params: { projectId },
    goTo,
  } = useRouting();

  const theme = useTheme();

  const {
    setLocalStorageItem: setLocalStorageExpandedKeys,
    getLocalStorageItem: getLocalStorageExpandedKeys,
  } = useLocalStorage<string[]>({
    key: `prostream-docs.${projectId ? `${projectId}.` : ''}expandedRowKeys`,
  });

  const toFolder = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (!path) return;
    // To automatically expand the subfolders in case they're not expanded yet, if the document resides in a nested folder.
    if (updateExpandedKeysInLocalStorage) {
      // This is for the context that the `folderTree` is not on screen yet (f.i. a private shared links table)
      // So we need to update the local storage.
      // Then once the `folderTree` is loaded, it will read the expanded keys from the local storage.
      const currentLocalStorageExpandedKeys = getLocalStorageExpandedKeys();
      const updatedLocalStorageExpandedKeys = pathIds
        ? Array.isArray(currentLocalStorageExpandedKeys)
          ? [...getLocalStorageExpandedKeys(), ...pathIds.slice(0, -1)]
          : pathIds.slice(0, -1)
        : [];
      setLocalStorageExpandedKeys(updatedLocalStorageExpandedKeys);
    } else {
      // This is for the context that the `folderTree` is already on screen (f.i. collection folder, smartfolder).
      // It needs state update to re-render the tree.
      setExpandedRowKeys && setExpandedRowKeys(pathIds);
    }
    const route = projectId ? 'projectDocTableUrl' : 'orgDms';
    if (projectId) {
      updateLsLastVisitedProjectFolder({
        params: { path: `${Routes.documentTable}${path}` },
        projectId,
        route: route,
      });
    } else {
      updateLsLastVisitedOrganizationFolder({
        params: { path: `${Routes.documentTable}${path}` },
      });
    }
    goTo(route, {
      params: { path: path, projectId: projectId },
      searchParams: { id },
    });
  };

  const color = customColors.grey900;
  const folderColor = theme.palette.warning.main;

  return (
    <Wrap $color={color} onClick={canNavigateToFolder ? toFolder : undefined}>
      {path && <Icon htmlColor={folderColor} iconName="folder" />}
      <Path $canNavigateToFolder={canNavigateToFolder}>
        <MiddleEllipsis endLength={endLength} text={path} />
      </Path>
    </Wrap>
  );
};
