import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  FormLinkedSnag,
  QcPermissionCategory,
  QualityControlInstance,
  TaskFragmentFragment,
  TbqAnswer,
  TbqRieResult,
} from '@pro4all/graphql';
import { useQCPermissions } from '@pro4all/quality-control/data-access';
import { ActionProps } from '@pro4all/shared/actions';
import { PhotoProvider } from '@pro4all/shared/contexts';
import { AnswerEditContextProvider } from '@pro4all/shared/qc-sources/answer-edit-context';
import { Sidebar } from '@pro4all/shared/ui/sidebar';

import { HeaderAndActionBar } from './HeaderAndActionBar';
import { PropertiesContent } from './PropertiesContent';
import { QuestionContent } from './QuestionContent';
import { TaskContent } from './TaskContent';
import { TBQQuestionSidebarWrapper } from './TBQQuestionSidebar.styled';
import { useTbqQuestionSidebarLogic } from './useTbqQuestionSidebarLogic';

interface TbqQuestionSidebarProps {
  linkedSnags?: FormLinkedSnag[];
  linkedTasks?: TaskFragmentFragment[];
  onClose: () => void;
  qcInstance?: QualityControlInstance;
  question?: TbqRieResult | TbqAnswer;
}

export enum QuestionSidebarTabs {
  PROPERTIES = 'properties',
}

export const TbqQuestionSidebar: React.FC<TbqQuestionSidebarProps> = ({
  question,
  linkedTasks,
  linkedSnags,
  onClose,
  qcInstance,
}) => {
  const { t } = useTranslation();
  const {
    currentTab,
    completedTasksCount,
    allTasksCount,
    questionTitle,
    questionFields,
    changeTabs,
    searchParams,
  } = useTbqQuestionSidebarLogic(linkedTasks, qcInstance, question);

  const { tbqresolveCreateAll } = useQCPermissions({
    category: QcPermissionCategory.Procedure,
    taskProjectId: qcInstance?.projectId ?? '',
  });

  const sidebarActions: ActionProps[] = [
    tbqresolveCreateAll && {
      ariaLabel: t('Resolve tbq task'),
      key: 'new-resolve-task',
      label: t('New resolve task'),
      onClick: () => {
        changeTabs('createTask');
        searchParams.set('taskType', 'TBQResolve');
      },
      startIcon: 'newTbqResolveTask',
    },
  ].filter(Boolean) as ActionProps[];

  const parametersCheck = Boolean(question);

  return (
    <Sidebar onClose={onClose} open={parametersCheck} wide>
      <TBQQuestionSidebarWrapper>
        <HeaderAndActionBar
          changeTabs={changeTabs}
          currentTab={currentTab ?? ''}
          sidebarActions={sidebarActions}
          title={questionTitle}
        />
        {currentTab === QuestionSidebarTabs.PROPERTIES && (
          <>
            <PropertiesContent
              allTasksCount={allTasksCount}
              completedTasksCount={completedTasksCount}
              question={question}
            />
            <PhotoProvider>
              <AnswerEditContextProvider>
                <QuestionContent
                  linkedSnags={linkedSnags}
                  questionFields={questionFields}
                  t={t}
                />
              </AnswerEditContextProvider>
            </PhotoProvider>
            <TaskContent linkedTasks={linkedTasks} />
          </>
        )}
      </TBQQuestionSidebarWrapper>
    </Sidebar>
  );
};
