import React from 'react';

export type TableGridItem = {
  bottomSection?: React.ReactNode;
  description?: string;
  id: string;
  imageSection?: React.ReactNode;
  title: string;
  versionId?: string;
};

export const THUMBNAIL_HEIGHT = 140;
export const SCROLL_DEBOUNCE = 250;
