import React from 'react';

import {
  EntityTypeTranslation,
  ItemChangedMessage,
  MessageAction,
} from '@pro4all/shared/ui/messages';

export const SnackbarSmartFolderCreatedOrUpdated = ({
  smartFolderName,
  isUpdate,
}: {
  isUpdate: boolean;
  smartFolderName: string;
}) => {
  const suffix = isUpdate ? MessageAction.Update : MessageAction.Create;

  return (
    <ItemChangedMessage
      description={suffix}
      entityName={smartFolderName}
      entityTypeTranslation={EntityTypeTranslation.SmartFolder}
    />
  );
};
