import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  QcPermissionCategory,
  TbqAnswer,
  TbqModuleType,
  TbqRieResult,
} from '@pro4all/graphql';
import { useQCPermissions } from '@pro4all/quality-control/data-access';
import { ActionProps } from '@pro4all/shared/actions';
import { useRouting } from '@pro4all/shared/routing-utils';
import { useTableCheck } from '@pro4all/shared/ui/table';
import { TableActionBar } from '@pro4all/shared/ui/table-action-bar';
import { isTbqRie } from '@pro4all/workflow/ui/utils';

interface QuestionsResultActionBarProps {
  instanceId?: string;
  projectId: string;
  setBrandcheckSelectedRows?: (brandcheckSelectedRows: TbqAnswer[]) => void;
  setSelectedRows?: (selectedRows: TbqRieResult[]) => void;
}
export const QuestionsResultActionBar: React.FC<
  QuestionsResultActionBarProps
> = ({ setSelectedRows, setBrandcheckSelectedRows, instanceId, projectId }) => {
  const { searchParams } = useRouting();
  const { t } = useTranslation();
  const { checkedRows } = useTableCheck<TbqRieResult | TbqAnswer>();
  const isTBQQuestionSidebarOpen = searchParams.is(
    'view',
    'view-question-sidebar'
  );
  const { tbqresolveCreateAll } = useQCPermissions({
    category: QcPermissionCategory.Procedure,
    taskProjectId: projectId,
  });

  useEffect(() => {
    if (checkedRows && checkedRows.length > 0) {
      if (isTbqRie(checkedRows[0]) && setSelectedRows) {
        setSelectedRows(checkedRows as TbqRieResult[]);
      } else if (setBrandcheckSelectedRows) {
        setBrandcheckSelectedRows(checkedRows as TbqAnswer[]);
      }
    }
  }, [checkedRows, setSelectedRows, setBrandcheckSelectedRows]);

  const handleCloseQuestionSidebar = () => {
    searchParams.delete('selectedId');
    searchParams.delete('action');
    searchParams.delete('view');
    searchParams.delete('id');
  };

  const openResultForm = () => {
    if (isTBQQuestionSidebarOpen) handleCloseQuestionSidebar();
    searchParams.set({
      action: 'editResult',
      id: instanceId ?? '',
      tbqScanType: TbqModuleType.Brandcheck,
    });
    searchParams.set('edit-tbq', 'edit-tbq-dashboard');
  };

  const mainAction: ActionProps[] = [
    {
      ariaLabel: t('Edit scan questionnaire'),
      key: 'visible-action',
      label: t('Edit scan questionnaire'),
      onClick: () => openResultForm(),
      startIcon: 'tbq',
    },
  ];

  const altActions = [
    tbqresolveCreateAll &&
      checkedRows.length > 0 && {
        ariaLabel: t('Create resolve task'),
        key: 'multi-resolve-questions',
        label: t('Create task'),
        onClick: () => {
          searchParams.set('action', 'createTask');
          searchParams.set('taskType', 'TBQResolve');
        },
        startIcon: 'newTbqResolveTask',
      },
  ].filter(Boolean) as ActionProps[];

  return (
    <TableActionBar
      altActions={altActions}
      dataTestid="tbq-tasks-action-bar"
      mainActions={mainAction}
      search
    />
  );
};
