import {
  useAddFavoriteProjectMutation,
  useRemoveFavoriteProjectMutation,
} from '@pro4all/graphql';

export const useFavoriteProject = () => {
  const [addFavoriteProject] = useAddFavoriteProjectMutation();
  const [removeFavoriteProject] = useRemoveFavoriteProjectMutation();

  const toggleFavorite = ({
    id,
    isFavorite,
  }: {
    id: string;
    isFavorite: boolean;
  }) => {
    if (isFavorite) {
      removeFavoriteProject({
        optimisticResponse: {
          removeFavoriteProject: id,
        },
        update: (cache) => {
          cache.modify({
            fields: {
              isFavorite: () => false,
            },
            id: cache.identify({ __typename: 'Project', id }),
          });
        },
        variables: { projectId: id },
      });
    } else {
      addFavoriteProject({
        optimisticResponse: {
          addFavoriteProject: id,
        },
        update: (cache) => {
          cache.modify({
            fields: {
              isFavorite: () => true,
            },
            id: cache.identify({ __typename: 'Project', id }),
          });
        },
        variables: { projectId: id },
      });
    }
  };

  return { toggleFavorite };
};
