import { Member } from '@pro4all/graphql';
import { OptimisticResponseProvider } from '@pro4all/shared/ui/table';

import { ProjectUserManagement } from './ProjectUserManagement';

export const ProjectUserManagementProviderWrapper = () => (
  <OptimisticResponseProvider<Member>>
    <ProjectUserManagement />
  </OptimisticResponseProvider>
);
