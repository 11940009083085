import React from 'react';

import { Routes } from '@pro4all/shared/config';
import { Box, useTheme } from '@pro4all/shared/mui-wrappers';
import {
  generateRoute,
  updateLsLastVisitedOrganizationFolder,
  updateLsLastVisitedProjectFolder,
} from '@pro4all/shared/routing-utils';
import { useTakeSecondaryThemeColor } from '@pro4all/shared/themes';
import { Icon } from '@pro4all/shared/ui/icons';
import { Text } from '@pro4all/shared/ui/typography';

import * as Styled from './SmartFolder.styles';

interface Props {
  name: string;
  onClick?: (event: React.MouseEvent) => void;
  onContextMenu?: (event: React.MouseEvent) => void;
  projectId?: string;
  resetInitialItems: () => void;
  selected?: boolean;
}

export const SmartFolder: React.FC<Props> = ({
  name,
  onContextMenu,
  projectId,
  resetInitialItems,
  selected = false,
}) => {
  const theme = useTheme();
  const { takeSecondaryThemeColor } = useTakeSecondaryThemeColor();

  return (
    <Styled.FolderLink
      onClick={() => {
        resetInitialItems(); // This is for column filtering. To reset the 'itemsInitial' state prop.
        const path = `${Routes.smartFolderTablePathBase}${name}`;
        if (projectId) {
          updateLsLastVisitedProjectFolder({
            params: { path },
            projectId,
            route: 'projectSmartFolderTableUrl',
          });
        } else {
          updateLsLastVisitedOrganizationFolder({
            params: { path },
          });
        }
      }}
      onContextMenu={onContextMenu}
      selected={selected}
      takeSecondaryThemeColor={takeSecondaryThemeColor}
      to={generateRoute(
        projectId ? 'projectSmartFolderTableUrl' : 'orgSmartFolderTableUrl',
        {
          params: {
            name,
            projectId,
          },
        }
      )}
    >
      <Icon
        htmlColor={
          selected ? theme.palette.primary.main : theme.palette.warning.main
        }
        iconName={selected ? 'smartFolderOutline' : 'smartFolder'}
      />
      <Box marginLeft={theme.spacing(1)}>
        <Text>{name}</Text>
      </Box>
    </Styled.FolderLink>
  );
};

export default SmartFolder;
