import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';

export const Unlink: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M4.11873 3.63002C3.72873 3.24002 3.09873 3.24002 2.70873 3.63002C2.31873 4.02002 2.31873 4.65002 2.70873 5.04002L5.10873 7.44002C3.16873 8.24002 1.83873 10.21 2.01873 12.48C2.22873 15.12 4.58873 17.07 7.22873 17.07H10.0487C10.5687 17.07 10.9987 16.64 10.9987 16.12C10.9987 15.6 10.5687 15.17 10.0487 15.17H7.15873C5.52873 15.17 4.05873 13.98 3.90873 12.35C3.75873 10.63 5.01873 9.18002 6.65873 9.00002L8.75873 11.1C8.32873 11.19 7.99873 11.56 7.99873 12.02V12.12C7.99873 12.64 8.42873 13.07 8.94873 13.07H10.7287L12.9987 15.34V17.07H14.7287L18.0287 20.37C18.4187 20.76 19.0487 20.76 19.4387 20.37C19.8287 19.98 19.8287 19.35 19.4387 18.96L4.11873 3.63002ZM21.9387 11.3C21.5687 8.83002 19.3187 7.07002 16.8187 7.07002H13.9487C13.4287 7.07002 12.9987 7.50002 12.9987 8.02002C12.9987 8.54002 13.4287 8.97002 13.9487 8.97002H16.8487C18.4487 8.97002 19.8887 10.11 20.0687 11.7C20.2387 13.13 19.4287 14.39 18.2187 14.92L19.6187 16.32C21.2487 15.3 22.2587 13.41 21.9387 11.3ZM15.0487 11.07C15.5687 11.07 15.9987 11.5 15.9987 12.02V12.12C15.9987 12.28 15.9487 12.43 15.8787 12.56L14.3887 11.07H15.0487Z"
        fill={customColors.grey700}
        fillRule="evenodd"
      />
    </svg>
  </SvgIcon>
);
