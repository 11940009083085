import React from 'react';

import { LogoImageProps } from './types';

export const LogoProstream = ({ compactMode, logoColor }: LogoImageProps) => {
  let colorIcon = '';
  let colorIconSquare = '';
  let colorIconCircle = '';
  switch (logoColor) {
    case 'pro4allPrimaryPurple':
    case 'pro4allMonochromePurple':
    case 'pro4allWhitePurple4':
      colorIcon = '#523be1';
      colorIconSquare = '#37ccba';
      colorIconCircle = '#e15313';
      break;
    case 'pro4allWhiteOrange4':
    case 'pro4allPrimaryOrange':
      colorIcon = '#e15313';
      colorIconSquare = '#37ccba';
      colorIconCircle = '#523be1';
      break;
    case 'pro4allMonochromeGreen':
      colorIcon = '#37ccba';
      colorIconSquare = '#523be1';
      colorIconCircle = '#e15313';
      break;
    case 'negativeBlack':
      colorIcon = '#414042';
      colorIconSquare = '#414042';
      colorIconCircle = '#414042';
      break;
    case 'negativeWhite':
    default:
      colorIcon = '#FFFFFF';
      colorIconSquare = '#FFFFFF';
      colorIconCircle = '#FFFFFF';
      break;
  }

  let colorText = '';
  switch (logoColor) {
    case 'pro4allPrimaryPurple':
    case 'pro4allPrimaryOrange':
    case 'negativeBlack':
      colorText = '#414042';
      break;
    case 'pro4allMonochromePurple':
      colorText = '#523be1';
      break;
    case 'pro4allMonochromeGreen':
      colorText = '#37ccba';
      break;
    case 'pro4allWhitePurple4':
    case 'pro4allWhiteOrange4':
    case 'negativeWhite':
    default:
      colorText = '#FFFFFF';
      break;
  }

  const logoIcon = (
    <g>
      <rect
        fill={colorIconSquare}
        height="10.78"
        width="10.78"
        x="176.28"
        y="42"
      />
      <path
        d="M187.05,1.36v29.86h-10.78v-13.53l-13.06,13.53h12.74c2.98,0,5.39,2.41,5.39,5.39h0c0,2.98-2.41,5.39-5.39,5.39h-24.69c-1.29,0-2.34-1.05-2.34-2.34v-7.47c0-.62.23-1.21.65-1.67L176.28,1.36h10.78Z"
        fill={colorIcon}
      />
      <rect
        fill={colorIconCircle}
        height="10.78"
        rx="5.39"
        ry="5.39"
        width="10.78"
        x="187.06"
        y="31.22"
      />
    </g>
  );

  const logoText = (
    <g>
      <g>
        <path
          d="M21.66,35.42H7.45v17.27H0V1.05h21.48c7.98,0,17.71,5.35,17.71,17.27,0,10.87-9.56,17.1-17.53,17.1ZM21.92,7.89H7.45v20.96h13.94c4.73,0,10.7-4.3,10.7-10.52,0-6.57-5.26-10.43-10.17-10.43Z"
          fill={colorText}
        />
        <path
          d="M71.36,32.18c4.21,6.49,9.12,13.94,13.24,20.52h-8.33s-11.22-17.62-13.33-20.26h-9.82v20.26h-7.37l-.09-51.64h23.06c8.15,0,16.48,5.7,16.48,15.52,0,8.15-6.93,14.73-13.85,15.6ZM69,7.45h-15.87v18.33h15.87c4.74,0,9.21-4.3,9.21-9.2,0-5.44-4.47-9.12-9.21-9.12Z"
          fill={colorText}
        />
        <path
          d="M115.81,53.66c-14.03,0-27-10.78-27-26.83S101.78,0,115.81,0s27.09,10.87,27.09,26.92-12.98,26.74-27.09,26.74ZM115.81,6.66c-11.4,0-19.9,9.12-19.9,20.26s8.5,19.9,19.9,19.9,19.81-8.94,19.81-19.9-8.42-20.26-19.81-20.26Z"
          fill={colorText}
        />
      </g>
      <g>
        <path
          d="M216.14,43.99l-3.77,8.79h-12.39l-.08-1.09L222.33,1.05h9.79l21.42,50.63.09,1.09h-12.81l-3.43-8.79h-21.26ZM232.71,32.52l-5.86-14.4-6.03,14.4h11.88Z"
          fill={colorText}
        />
        <path
          d="M272.87,1.14v39.5h23.96v12.14h-36.93V1.14h12.97Z"
          fill={colorText}
        />
        <path
          d="M316.15,1.14v39.5h23.96v12.14h-36.93V1.14h12.97Z"
          fill={colorText}
        />
      </g>
    </g>
  );

  return (
    <svg
      fill="none"
      height="28"
      viewBox={compactMode ? '0 0 40 28' : '0 0 140 28'}
      width={compactMode ? '40' : '140'}
    >
      <g transform="scale(0.40) translate(0, 0)">
        {compactMode ? (
          <g transform="translate(-140, 0)">{logoIcon}</g>
        ) : (
          <>
            {logoIcon}
            {logoText}
          </>
        )}
      </g>
    </svg>
  );
};
