import React, { useEffect, useState } from 'react';
import { useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { Route, Routes as RouterDomRoutes } from 'react-router-dom';
import { Formik } from 'formik';

import { Template, TemplateService, ValueTypeName } from '@pro4all/graphql';
import {
  getFileUrlByTemplateId,
  TMuiIcon,
} from '@pro4all/shared/composed-snag-form-pin';
import { DndField, DndTypes, Routes } from '@pro4all/shared/config';
import { StorageKeys } from '@pro4all/shared/config';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { isSubmitDisabled } from '@pro4all/shared/forms';
import { Box } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { SelectSnagIcon, TFileParams } from '@pro4all/shared/select-snag-icon';
import { Button } from '@pro4all/shared/ui/buttons';
import { FormFooter } from '@pro4all/shared/ui/form';
import { FormikForm, FormikInput } from '@pro4all/shared/ui/formik';
import { VisibilityToggle } from '@pro4all/shared/ui/visibility-toggle';

import { useMetaDataContext } from '../../../views/MetaDataContext';
import { useExportTemplate } from '../../hooks/useExportTemplate';
import { FieldCustomCard } from '../FieldCustomCard';
import { FieldSidebar } from '../FieldSidebar';
import { NewCards } from '../NewCards';
import { NewCardSectionPasteInRoot } from '../NewCardSectionPasteInRoot';
import { Section } from '../Section';
import { SectionSidebar } from '../SectionSidebar';
import {
  StyledActionZone,
  StyledButton,
  StyledFormDropZone,
  StyledFormWrapper,
  StyledTemplateForm,
  StyledTitleInput,
} from '../Styles';
import {
  useFieldContext,
  useFieldSidebarContext,
  useSectionSidebarContext,
} from '../TemplateMutationContext';

import { useFilterInvalidConditions } from './useFilterInvalidConditions';
import { useTemplateFormConfig } from './useTemplateFormConfig';
import { useTemplateSubmit } from './useTemplateSubmit';
import { useUpdateIndicativeFields } from './useUpdateIndicativeFields';

const styleCustomCardsWrapper = { flexGrow: 1 };

export const TemplateForm = ({
  selectedTemplate,
}: {
  selectedTemplate?: Template;
}) => {
  const { displayNames, setDisplayNames, templateService, templateType } =
    useMetaDataContext();
  const { isOpenFieldSidebar } = useFieldSidebarContext();
  const { isOpenSectionSidebar } = useSectionSidebarContext();

  const { t } = useTranslation();
  const { searchParams } = useRouting();
  const { appendField, fieldDefinitions, reusableFields } = useFieldContext();

  const fieldDefinitionsReusable = fieldDefinitions.filter(
    (fieldDefinition) =>
      fieldDefinition.type !== ValueTypeName.StandardItem &&
      fieldDefinition.fieldDefinitionId
  );
  const fieldDefinitionReusableIds = fieldDefinitionsReusable.map(
    (field) => field.fieldDefinitionId
  );
  const reusableFieldIds = reusableFields.map((field) => field.id);
  // Check if there is a fieldDefinitionId that is not included in reusableFieldIds.
  const someFieldsHaveBeenDeleted = fieldDefinitionReusableIds.some(
    (fieldDefinitionReusableId) =>
      !reusableFieldIds.includes(fieldDefinitionReusableId)
  );

  const filterInvalidConditions = useFilterInvalidConditions();
  const setToggleIndicativeField = useUpdateIndicativeFields();

  const onClose = () => searchParams.clear();

  const isMetaDataDms = templateService === TemplateService.Documents;

  const inititalValues = {
    name: sessionStorage.getItem(StorageKeys.TEMPLATE_NAME) || '',
  };

  const [selectedCustomIconImage, setSelectedCustomIconImage] = useState<
    File | undefined
  >();

  const [selectedTemplateIcon, setSelectedTemplateIcon] = useState<
    TMuiIcon | undefined
  >(selectedTemplate?.icon?.name as TMuiIcon);

  const exportTemplate = useExportTemplate();
  const { onSubmit, isLoading } = useTemplateSubmit({
    selectedCustomIconImage,
    selectedTemplate,
    selectedTemplateIcon,
  });

  const [, drop] = useDrop({
    accept: [DndTypes.NOT_INCLUDED_FIELD],
    drop: (field: DndField, monitor) => {
      // If the source was dropped on one of the child fields, don't return a thing.
      if (!monitor.didDrop()) {
        appendField(field.fieldDefinition);
      }
    },
  });

  const { getField, validationSchema } = useTemplateFormConfig();
  const nameField = getField('name');

  useEffect(() => {
    // Put the displayName setting in sessionStorage so we also use it in the config download filename form.
    localStorage.setItem(
      StorageKeys.TEMPLATE_DISPLAY_NAME,
      displayNames?.toString()
    );
  }, [displayNames]);

  useEffect(() => {
    setSelectedTemplateIcon(selectedTemplate?.icon?.name as TMuiIcon);
  }, [selectedTemplate?.icon?.name]);

  const isSelectIconEnabled = useFeatureFlag('customericons');

  const handleIconSelect = (iconName?: TMuiIcon, fileParams?: TFileParams) => {
    if (iconName) {
      setSelectedTemplateIcon(iconName);
    } else {
      setSelectedCustomIconImage(fileParams?.file);
      setSelectedTemplateIcon(undefined);
    }
  };

  return (
    <StyledTemplateForm data-testid="create-template-form">
      <Formik
        initialValues={inititalValues}
        onSubmit={(values) => onSubmit(values, false)}
        validationSchema={validationSchema}
      >
        {({ errors, isSubmitting, values }) => {
          // Put the template name in sessionStorage so we also use it in the config download filename form.
          if (values.name)
            sessionStorage.setItem(StorageKeys.TEMPLATE_NAME, values.name);

          return (
            <FormikForm>
              <StyledFormWrapper>
                <StyledTitleInput>
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      gap: 2,
                    }}
                  >
                    {isSelectIconEnabled && (
                      <RouterDomRoutes>
                        {[
                          Routes.projectMetadataQualitySnagsUrl,
                          Routes.projectMetadataQualityForms,
                          Routes.snags,
                          Routes.metaDataQualityControlForms,
                        ].map((route) => (
                          <Route
                            element={
                              <Box>
                                <SelectSnagIcon
                                  currentSelectedFile={getFileUrlByTemplateId(
                                    selectedTemplate?.id
                                  )}
                                  onSelect={handleIconSelect}
                                  selectedIcon={selectedTemplateIcon}
                                />
                              </Box>
                            }
                            key={route}
                            path={route}
                          />
                        ))}
                      </RouterDomRoutes>
                    )}
                    <FormikInput
                      autoFocus
                      label={nameField.label}
                      name={nameField.name}
                      variant="standard"
                    />
                  </Box>
                </StyledTitleInput>
                <SectionSidebar isOpen={isOpenSectionSidebar} />
                <FieldSidebar isOpen={isOpenFieldSidebar} />
                <StyledActionZone>
                  {isMetaDataDms && selectedTemplate && selectedTemplate.id && (
                    <StyledButton
                      aria-label={t('Configure download name')}
                      data-testid="configure-downloadname"
                      disabled={!values.name}
                      onClick={() =>
                        searchParams.set('configureDownload', 'true')
                      }
                      startIcon="formatShapes"
                    >
                      {t('Configure download name')}
                    </StyledButton>
                  )}
                  {selectedTemplate && (
                    <StyledButton
                      aria-label={t('Export template')}
                      data-testid="export-template"
                      disabled={!values.name}
                      onClick={() =>
                        exportTemplate({
                          fieldDefinitions: reusableFields.map(
                            ({ __typename, id, name, scope }) => ({
                              __typename,
                              id,
                              name,
                              scope,
                            })
                          ),
                          selectedTemplate,
                          templateType,
                        })
                      }
                      startIcon="export"
                    >
                      {t('Export template')}
                    </StyledButton>
                  )}

                  <VisibilityToggle
                    label="Show display name"
                    toggle={() => setDisplayNames(!displayNames)}
                    toggleValue={displayNames}
                  />
                </StyledActionZone>

                <Box>
                  {fieldDefinitions.map((fieldDefinition) =>
                    fieldDefinition.type === ValueTypeName.Section ? (
                      <Section
                        displayNames={displayNames}
                        filterInvalidConditions={filterInvalidConditions}
                        isMetaDataDms={isMetaDataDms}
                        key={fieldDefinition.id}
                        section={fieldDefinition}
                        setToggleIndicativeField={setToggleIndicativeField}
                      />
                    ) : (
                      <FieldCustomCard
                        displayNames={displayNames}
                        fieldDefinition={fieldDefinition}
                        filterInvalidConditions={filterInvalidConditions}
                        isMetaDataDms={isMetaDataDms}
                        key={fieldDefinition.id}
                        setToggleIndicativeField={setToggleIndicativeField}
                      />
                    )
                  )}
                </Box>

                <Box sx={{ mb: 1 }}>
                  <NewCards />
                </Box>
                <NewCardSectionPasteInRoot />
                {drop(
                  <div style={styleCustomCardsWrapper}>
                    <StyledFormDropZone />
                  </div>
                )}
              </StyledFormWrapper>
              <FormFooter
                disableSubmit={
                  isSubmitDisabled({
                    errors,
                    isSubmitting,
                  }) ||
                  someFieldsHaveBeenDeleted ||
                  isLoading
                }
                onClose={onClose}
                pt={2}
                sticky
                submitLabel={t('Save as draft')}
              >
                <Button
                  data-testid="save-instance"
                  disabled={
                    isSubmitDisabled({
                      errors,
                      isSubmitting,
                    }) ||
                    someFieldsHaveBeenDeleted ||
                    isLoading
                  }
                  onClick={() => onSubmit(values, true)}
                  startIcon="publish"
                  type="button"
                  variant="contained"
                >
                  {t('Save and publish')}
                </Button>
              </FormFooter>
            </FormikForm>
          );
        }}
      </Formik>
    </StyledTemplateForm>
  );
};
