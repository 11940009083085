// hooks/useMessageForm.ts
import { useForm, UseFormReturn } from 'react-hook-form';

import { resolver } from '../message-utils/formHelpers';
import { MessageFormFields } from '../types';

export const useMessageForm = (
  defaultValues: MessageFormFields
): UseFormReturn<MessageFormFields> =>
  useForm<MessageFormFields>({
    defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver,
    shouldFocusError: true,
    shouldUnregister: false,
  });
