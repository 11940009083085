import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { FormikDate, FormikSelect } from '@pro4all/shared/ui/formik';
import { Select } from '@pro4all/shared/ui/inputs';

import { getFrequencyOptions } from '../helpers/frequencyOptions';
import { usePlanAheadOptions } from '../hooks/usePlanAheadOptions';

import {
  Description,
  FormControl,
  FormRow,
  StyledDialogContent,
} from './CreateSeriesModal.styles';

export const CreateSeriesModalForm: React.FC = () => {
  const { t } = useTranslation();
  const frequencyOptions = getFrequencyOptions(t);

  const { planAheadOptions, selectedOption, handleSelectChange } =
    usePlanAheadOptions();

  const selectValue = selectedOption?.id || '';

  return (
    <StyledDialogContent>
      <Description>
        {t(
          'Create new series allows you to plan ahead tasks. You can set a max of 14 tasks.'
        )}
      </Description>
      <FormRow>
        <FormControl>
          <FormikDate
            label={t('Start date')}
            minDate={dayjs()}
            name="endTime"
          />
        </FormControl>
        <FormControl>
          <FormikDate
            label={t('End date')}
            minDate={dayjs()}
            name="seriesEndDate"
          />
        </FormControl>
      </FormRow>
      <FormControl>
        <FormikSelect
          label={t('Frequency')}
          name="seriesFrequency"
          options={frequencyOptions}
        />
      </FormControl>
      <FormControl>
        <Select
          label={t('Plan ahead')}
          name="planAhead"
          onChange={handleSelectChange}
          options={planAheadOptions}
          updateValue
          value={selectValue}
        />
      </FormControl>
    </StyledDialogContent>
  );
};
