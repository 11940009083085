import React from 'react';
import { Route, Routes as RouterDomRoutes } from 'react-router-dom';

import { Routes } from '@pro4all/shared/config';
import { useIsAdminInContext } from '@pro4all/shared/identity';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Column, Main } from '@pro4all/shared/ui/layout';
import { BigMessageNoAccess } from '@pro4all/shared/ui/messages';

import { ProjectGroupManagementProviderWrapper } from '../components/group-management/project/ProjectGroupManagementProviderWrapper';
import { ProjectUsersGroupsTabs } from '../components/tabs/ProjectUsersGroupsTabs';
import { ProjectUserManagementProviderWrapper } from '../components/user-management/project/ProjectUserManagementProviderWrapper';

export const ProjectMembers = () => {
  const isAdminInContext = useIsAdminInContext();
  const {
    params: { projectId },
  } = useRouting();

  return (
    <Main sx={{ overflow: 'hidden' }}>
      <Column>
        <ProjectUsersGroupsTabs />
        <RouterDomRoutes>
          <Route
            element={
              <>
                {isAdminInContext ? (
                  <ProjectGroupManagementProviderWrapper />
                ) : (
                  <BigMessageNoAccess />
                )}
              </>
            }
            path={Routes.groups}
          ></Route>
          <Route
            element={
              <>
                {isAdminInContext ? (
                  <ProjectUserManagementProviderWrapper />
                ) : (
                  <BigMessageNoAccess />
                )}
              </>
            }
            path={Routes.members}
          ></Route>
        </RouterDomRoutes>
      </Column>
    </Main>
  );
};
