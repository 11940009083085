import { customColors } from '@pro4all/shared/themes';

export const SphereViewerStylesheet = `
    .psv-navbar--open {
        background: none;
        height: 34px;
        justify-content: end;
        padding: 8px;
        right: 0;
        top: 0;
        width: auto;
        position: absolute;
        border-bottom: 1px solid ${customColors.grey900};
        border-top: 1px solid ${customColors.grey900};
    }
    .psv-button:focus:not(:focus-visible), .psv-button:focus, .psv-button, .psv-button:hover, .psv-button:disabled {
        color: ${customColors.grey600};
    }
    .psv-button {
        background-color: ${customColors.white};
        border: 1px solid currentColor;
        border-radius: 30px;
        color: ${customColors.grey600};
        filter: none;
        margin: 4px;
        padding: 4px;
        transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
    .psv-button:hover:not(:disabled) {
        background-color: ${customColors.grey300};
    }
    .psv-button:disabled {
        cursor: default;
        opacity: 0.5;
    }
    .psv-button-svg {
        height: 14px;
        padding: 3px;
        width: 14px;
    }
    .psv-container:not(.psv--is-touch) .psv-button--hover-scale:not(.psv-button--disabled):hover .psv-button-svg {
        transform: none;
    }
`;
