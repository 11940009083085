import React from 'react';

import { useApplication } from '@pro4all/shared/themes';
import { Link } from '@pro4all/shared/ui/link';

import { LogoPave } from './LogoPave';
import { LogoProstream } from './LogoProstream';
import { LogoTbq } from './LogoTbq';
import { LogoComponentProps } from './types';

export const Logo = ({
  logoColor,
  includeLink = false,
  compactMode = false,
}: LogoComponentProps) => {
  const { isThemePave, isThemeTbq } = useApplication();

  // Helper function to wrap content in a link if needed
  const wrapInLink = ({
    content,
    href,
  }: {
    content: JSX.Element;
    href: string;
  }) => {
    if (!includeLink) return content;
    return (
      <Link href={href} target="_blank">
        {content}
      </Link>
    );
  };

  if (isThemePave) {
    return wrapInLink({
      content: <LogoPave compactMode={compactMode} logoColor={logoColor} />,
      href: 'https://pmgnet.de/en/pave',
    });
  }

  if (isThemeTbq) {
    return wrapInLink({
      content: <LogoTbq compactMode={compactMode} logoColor={logoColor} />,
      href: 'https://tbq.nl',
    });
  }

  // Default to Prostream logo
  return wrapInLink({
    content: <LogoProstream compactMode={compactMode} logoColor={logoColor} />,
    href: 'https://prostream.app',
  });
};
