import { useTranslation } from 'react-i18next';

import {
  QcPermissionCategory,
  TemplateType,
  useTaskQuery,
} from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import { useQCPermissions } from '@pro4all/quality-control/data-access';
import { ActionProps } from '@pro4all/shared/actions';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { useRouting } from '@pro4all/shared/routing-utils';
import { ContextMenu } from '@pro4all/shared/ui/context-menu';

import { useMapLinkingContext } from './MapLinkingContext';

export const MapContextMenu = () => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();
  const taskId = searchParams.get('taskId');
  const {
    setIsContextMenuOpen,
    setIsSelectTemplateDialogOpen,
    setSelectedTemplateType,
  } = useMapLinkingContext();

  const { userId } = useOrganizationContext();

  const isSelectIconEnabled = useFeatureFlag('customericons');

  const handleContextMenuSelection = (selection: TemplateType) => {
    // Define if the user selected snag template or form template
    setSelectedTemplateType(selection);
    setIsContextMenuOpen(false);
    setIsSelectTemplateDialogOpen(true);
  };
  const {
    formCreateAll,
    snagCreateAll,
    snagCreateAssigned,
    formCreateAssigned,
  } = useQCPermissions({
    category: QcPermissionCategory.Qcs,
  });

  const { data: taskData } = useTaskQuery({
    fetchPolicy: 'cache-and-network',
    skip: !taskId,
    variables: { id: taskId },
  });

  const canCreateForm =
    formCreateAll ||
    (formCreateAssigned && taskData?.task?.assignment?.[0]?.id === userId);
  const canCreateSnag =
    snagCreateAll ||
    (snagCreateAssigned && taskData?.task?.assignment?.[0]?.id === userId);

  const defaultMenu = [
    canCreateSnag && {
      ariaLabel: t('Add snag'),
      dataTestId: 'add-snag',
      key: 'add-snag',
      label: t('Add snag'),
      onClick: () => handleContextMenuSelection(TemplateType.Snag),
      startIcon: isSelectIconEnabled ? 'staticSnagIcon' : 'snag',
    },
    canCreateForm && {
      ariaLabel: t('Add form'),
      dataTestId: 'add-form-template',
      key: 'add-form-template',
      label: t('Add form'),
      onClick: () => handleContextMenuSelection(TemplateType.Form),
      startIcon: isSelectIconEnabled ? 'staticFormIcon' : 'addFormTemplate',
    },
  ].filter(Boolean) as ActionProps[];

  return (
    <ContextMenu
      data-testid="snags-template-context-menu"
      menuItems={defaultMenu}
      onClose={() => setIsContextMenuOpen(false)}
      open
    />
  );
};
