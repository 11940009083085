import styled from 'styled-components';

import { Button } from '@pro4all/shared/ui/buttons';

export const Banner = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.primary.light};
  border-radius: 0.5rem;
  color: ${({ theme }) => theme.palette.primary.contrastText};
  display: flex;
  flex-grow: 0;
  justify-content: space-between;
  margin: 1rem 1rem 0;
  padding: 1rem;
`;

export const Svg = styled.svg`
  margin-right: 0.5rem;
  vertical-align: middle;
`;

export const DangerButton = styled(Button)`
  && {
    color: ${({ theme }) => theme.palette.error.main};
  }
`;
