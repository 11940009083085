import React from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Formik } from 'formik';

import { Task, TaskType, TemplateType } from '@pro4all/graphql';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { Box } from '@pro4all/shared/mui-wrappers';
import { FormFooter } from '@pro4all/shared/ui/form';
import {
  FormikDate,
  FormikInput,
  FormikSelect,
  FormikTextarea,
} from '@pro4all/shared/ui/formik';
import { Text } from '@pro4all/shared/ui/typography';
import { FormWrapper } from '@pro4all/shared/ui/wrappers';
import { canOnlyAssignTaskToSelf } from '@pro4all/workflow/ui/utils';

import { CreateSeriesSection } from './series/components/CreateSeriesSection';
import { DocumentActionSelect } from './DocumentActionSelect';
import InstancesToLink from './InstancesToLink';
import { ParticipantSelect } from './ParticipantSelect';
import { TaskCategorySelect } from './TaskCategorySelect';
import * as Styled from './TaskForm.styles';
import { TemplateSelect } from './TemplateSelect';
import { useTaskFormHelpers } from './useTaskFormHelpers';
import { removeLinkInstance } from './utils';
import { VisualContextSelect } from './VisualContextSelect';

interface Props {
  onClose: () => void;
  procedureId?: string;
  projectId: string;
  task?: Task;
  taskType: TaskType;
}

export const TaskForm: React.FC<Props> = ({
  onClose,
  procedureId,
  projectId,
  task,
  taskType,
}) => {
  const hasSeriesFeature = useFeatureFlag('task-series');
  const { t } = useTranslation();

  const {
    availableStatuses,
    checkDisableStatus,
    descriptionField,
    documentTask,
    drawings,
    endTimeField,
    formTemplatesField,
    hasQualityControlFeatureFlag,
    hasQualityControlLicense,
    initialValues,
    inputRef,
    isEditMode,
    linkInstances,
    nameField,
    nextStatusField,
    onSubmit,
    permissions,
    resolveTask,
    routeCreate,
    setDeletedLinkedInstance,
    setDrawings,
    setLinkInstances,
    snagTemplatesField,
    validationSchema,
    visualContextsField,
  } = useTaskFormHelpers({
    procedureId,
    task,
    taskType,
  });

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onReset={() => inputRef.current.focus()}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ values, errors, isSubmitting, dirty }) => (
        <Styled.StyledFormikForm>
          <FormWrapper>
            <Text variant="h4">{t('General')}</Text>
            <FormikInput
              autoFocus
              id={nameField?.name}
              inputRef={inputRef}
              label={nameField?.label}
              name={nameField?.name}
            />
            <FormikTextarea
              id={descriptionField?.name}
              label={descriptionField?.label}
              name={descriptionField?.name}
              rows={4}
            />
            <FormikDate
              label={endTimeField?.label}
              minDate={dayjs()}
              name={endTimeField?.name || ''}
            />
            {isEditMode && (
              <FormikSelect
                id={nextStatusField?.name}
                label={nextStatusField?.label}
                name={nextStatusField?.name}
                options={availableStatuses}
              />
            )}

            <ParticipantSelect
              disabled={canOnlyAssignTaskToSelf(permissions, taskType)}
              projectId={projectId ?? ''}
            />

            <TaskCategorySelect
              taskType={
                taskType
                  ? taskType
                  : resolveTask
                  ? TaskType.Resolve
                  : documentTask
                  ? TaskType.Document
                  : TaskType.QualityControl
              }
            />

            {hasQualityControlFeatureFlag &&
              hasQualityControlLicense &&
              values.type === 'QualityControl' && (
                <Box mt={1}>
                  <>
                    {hasSeriesFeature && <CreateSeriesSection />}
                    <TemplateSelect
                      id={formTemplatesField?.name || ''}
                      label={formTemplatesField?.label || ''}
                      task={task}
                      tooltip={t(
                        'The participant of this task can complete forms of the types you select'
                      )}
                      type={TemplateType.Form}
                    />
                    <TemplateSelect
                      id={snagTemplatesField?.name || ''}
                      label={snagTemplatesField?.label || ''}
                      task={task}
                      tooltip={t(
                        'The participant of this task can only place snags of the types you select'
                      )}
                      type={TemplateType.Snag}
                    />
                  </>
                  <VisualContextSelect
                    id={visualContextsField?.name || ''}
                    projectId={projectId || task?.project?.id || ''}
                    tooltip={t(
                      'Link drawings to a task so the participant can place snags on it'
                    )}
                  />
                </Box>
              )}

            {taskType === TaskType.Document && <DocumentActionSelect />}

            {(taskType === TaskType.Resolve ||
              taskType === TaskType.Document) &&
              linkInstances?.length > 0 && (
                <InstancesToLink
                  drawings={drawings}
                  linkInstances={linkInstances}
                  removeLinkInstance={(instance) =>
                    removeLinkInstance({
                      instance,
                      linkInstances,
                      onClose,
                      setDeletedLinkedInstance,
                      setDrawings,
                      setLinkInstances,
                    })
                  }
                  task={task}
                  title={values?.documentAction?.label}
                />
              )}
          </FormWrapper>

          <FormFooter
            cancelLabel={t(isEditMode ? 'Cancel' : 'Go back')}
            disableSubmit={checkDisableStatus(errors, isSubmitting, dirty)}
            onClose={onClose}
            pb={3}
            pt={2}
            px={3}
            showCancel={!routeCreate || documentTask}
            sticky
          />
        </Styled.StyledFormikForm>
      )}
    </Formik>
  );
};
