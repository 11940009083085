import { Group } from '@pro4all/graphql';
import { OptimisticResponseProvider } from '@pro4all/shared/ui/table';

import { ProjectGroupManagement } from './ProjectGroupManagement';

export const ProjectGroupManagementProviderWrapper = () => (
  <OptimisticResponseProvider<Group>>
    <ProjectGroupManagement />
  </OptimisticResponseProvider>
);
