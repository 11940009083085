import { LockType, useDocumentsQuery } from '@pro4all/graphql';
import { useClientRedirectContext } from '@pro4all/shared/contexts';

export const usePollingDocuments = () => {
  const { setDocumentIdsForPolling, documentIdsForPolling } =
    useClientRedirectContext();

  useDocumentsQuery({
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const documents = data?.documents;
      if (!documents?.length) return;

      const nonLockedIds = documents
        .filter((doc) => doc?.lockType === LockType.None)
        .map((doc) => doc?.id);

      if (nonLockedIds.length) {
        setDocumentIdsForPolling(
          documentIdsForPolling.filter(
            (id: string) => !nonLockedIds.includes(id)
          )
        );
      }
    },
    pollInterval: 1000,
    skip: !documentIdsForPolling?.length,
    variables: { documentIds: documentIdsForPolling ?? [] },
  });
};
