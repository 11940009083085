import React from 'react';

import { LogoImageProps } from './types';

export const LogoPave = ({ compactMode, logoColor }: LogoImageProps) => {
  let colorIcon = '';
  let colorText = '';
  switch (logoColor) {
    case 'paveBlueYellow':
      colorIcon = '#FC0';
      colorText = '#003D58';
      break;
    case 'paveWhiteBlue':
      colorIcon = '#003D58';
      colorText = '#FFFFFF';
      break;
    case 'negativeBlack':
      colorIcon = '#FFCB00';
      colorText = '#414042';
      break;
    case 'paveWhiteYellow':
    default:
      colorIcon = '#FFCB00';
      colorText = '#FFFFFF';
      break;
  }

  const logoIcon = (
    <>
      <rect
        fill={colorIcon}
        height="19.53"
        transform="translate(-37.97 25.42) rotate(-30)"
        width="54.44"
        x="1.24"
        y="73.8"
      />
      <rect
        fill={colorIcon}
        height="54.44"
        transform="translate(-39.43 98.83) rotate(-60)"
        width="19.53"
        x="56.11"
        y="56.35"
      />
      <rect fill={colorIcon} height="43.72" width="19.53" x="37.4" y="77.86" />
      <rect
        fill={colorIcon}
        height="19.53"
        transform="translate(-10.89 38.22) rotate(-30)"
        width="54.44"
        x="38.66"
        y="29.67"
      />
      <rect
        fill={colorIcon}
        height="54.43"
        transform="translate(-19.92 44.36) rotate(-60)"
        width="19.53"
        x="18.69"
        y="12.22"
      />
      <rect fill={colorIcon} height="43.72" width="19.53" x="37.4" y="1.41" />
    </>
  );

  const logoText = (
    <path
      d="m672.57,119.87v-25.32h-90.11v-22.16h73.86v-25.32h-73.86v-19.84h90.11V2.11h-119.87v117.76h119.87Zm-216.1,2.11L534.98,2.11h-34.4l-44.11,74.07L412.58,2.11h-34.61l78.51,119.87Zm-133.79-74.92l18.15,29.12h-36.51l18.36-29.12Zm45.37,72.81h33.13L322.68,0l-78.51,119.87h32.92l11.4-18.36h68.16l11.4,18.36ZM200.28,27.43c16.88,0,20.26,9.5,20.26,14.77s-3.38,14.98-20.26,14.98h-41.78v-29.75h41.78Zm7.6,55.08c33.55,0,43.68-25.32,43.68-40.31S241.43,2.11,207.87,2.11h-79.14v117.76h29.76v-37.35h49.38Z"
      fill={colorText}
    />
  );

  return (
    <svg
      fill="none"
      height="40"
      viewBox={compactMode ? '0 0 30 40' : '0 0 140 40'}
      width={compactMode ? '30' : '140'}
    >
      <g transform="scale(0.18) translate(0, 0)">
        {logoIcon}
        {!compactMode && logoText}
      </g>
    </svg>
  );
};
