import React, { useMemo } from 'react';

import { Task, TaskType } from '@pro4all/graphql';
import { useFetchTasks } from '@pro4all/quality-control/data-access';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Column, ContentRow } from '@pro4all/shared/ui/layout';
import { OptimisticResponseProvider } from '@pro4all/shared/ui/table';
import { TasksMain, TaskTablePostFix } from '@pro4all/shared/ui/tasks';

import { TasksActionBar } from './TasksActionBar';

export const DocumentTasks: React.FC = () => {
  const { params } = useRouting();

  const typeFilter = [TaskType.Document];

  const { loading, data } = useFetchTasks({ typeFilter });

  const tasks = useMemo(
    () => data?.project?.mainProcedure?.tasks || [],
    [data?.project?.mainProcedure?.tasks]
  ) as Task[];

  const tasksOverview = (
    <OptimisticResponseProvider<Task>>
      <TasksMain
        TasksActionBar={<TasksActionBar dataViewToggle />}
        hideProject
        hideUser={false}
        loading={loading}
        mainProcedureId={data?.project?.mainProcedure?.id}
        noTasksText="Have they been completed? On a roll! Need new ones? Create them."
        noTasksTitle="You don't have any document tasks yet"
        postfix={TaskTablePostFix.DMS}
        tasks={tasks}
        typeFilter={typeFilter}
      />
    </OptimisticResponseProvider>
  );

  return (
    <ContentRow sx={{ overflow: 'hidden' }}>
      <Column>{tasksOverview}</Column>
    </ContentRow>
  );
};
