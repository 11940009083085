import React from 'react';
import { useTranslation } from 'react-i18next';

import { Serie, useTasksBySerieQuery } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Tab, Tabs } from '@pro4all/shared/ui/general';
import { ResponseWrapper } from '@pro4all/shared/ui/response-wrapper';
import { Sidebar } from '@pro4all/shared/ui/sidebar';

import { useSeriesSidebarButtons } from '../useSeriesSidebarButtons';

import { SeriesProperties } from './SeriesProperties';
import { SeriesTasksTab } from './SeriesTasksTab';

enum TabValues {
  Properties = 'properties',
  Tasks = 'tasks',
}

interface Props {
  series: Serie[];
}

export const SeriesSidebar = ({ series }: Props) => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();
  const [currentTab, setCurrentTab] = React.useState<TabValues>(
    TabValues.Properties
  );
  const isViewSerie = searchParams.is('action', 'viewSerie');

  const serieId = searchParams.get('id');
  const selectedSerie = series.find((serie) => serie.id === serieId);

  const { data, loading } = useTasksBySerieQuery({
    skip: !isViewSerie || !serieId,
    variables: {
      serieId: serieId || '',
    },
  });

  const handleClose = () => {
    searchParams.delete('action');
    searchParams.delete('id');
  };

  const handleTabChange = (_: React.SyntheticEvent, value: TabValues) => {
    setCurrentTab(value);
  };

  const sidebarActions = useSeriesSidebarButtons({
    currentSerie: selectedSerie || null,
  });

  return (
    <Sidebar onClose={handleClose} open={isViewSerie && Boolean(serieId)} wide>
      <ResponseWrapper isLoading={loading}>
        {selectedSerie && (
          <>
            <Sidebar.Header
              icon="calendar"
              title={selectedSerie.taskTemplate?.name || t('Serie details')}
            >
              <Sidebar.SidebarActionBar sidebarActions={sidebarActions} />
              <Tabs onChange={handleTabChange} value={currentTab}>
                <Tab
                  data-testid="series-sidebar-properties-tab"
                  key="properties"
                  label={t('Properties')}
                  value={TabValues.Properties}
                />
                <Tab
                  data-testid="series-sidebar-tasks-tab"
                  key="tasks"
                  label={t('Tasks')}
                  value={TabValues.Tasks}
                />
              </Tabs>
            </Sidebar.Header>

            {currentTab === TabValues.Properties && (
              <SeriesProperties serie={selectedSerie} />
            )}

            {currentTab === TabValues.Tasks && (
              <SeriesTasksTab tasks={data?.tasksBySerie} title={t('Tasks')} />
            )}
          </>
        )}
      </ResponseWrapper>
    </Sidebar>
  );
};
