import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';

export const Insight = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M19.1666 8.33317C19.1666 9.24984 18.4166 9.99984 17.4999 9.99984C17.3499 9.99984 17.2083 9.98317 17.0749 9.9415L14.1083 12.8998C14.1499 13.0332 14.1666 13.1832 14.1666 13.3332C14.1666 14.2498 13.4166 14.9998 12.4999 14.9998C11.5833 14.9998 10.8333 14.2498 10.8333 13.3332C10.8333 13.1832 10.8499 13.0332 10.8916 12.8998L8.76658 10.7748C8.63325 10.8165 8.48325 10.8332 8.33325 10.8332C8.18325 10.8332 8.03325 10.8165 7.89992 10.7748L4.10825 14.5748C4.14992 14.7082 4.16658 14.8498 4.16658 14.9998C4.16658 15.9165 3.41658 16.6665 2.49992 16.6665C1.58325 16.6665 0.833252 15.9165 0.833252 14.9998C0.833252 14.0832 1.58325 13.3332 2.49992 13.3332C2.64992 13.3332 2.79159 13.3498 2.92492 13.3915L6.72492 9.59984C6.68325 9.4665 6.66658 9.3165 6.66658 9.1665C6.66658 8.24984 7.41658 7.49984 8.33325 7.49984C9.24992 7.49984 9.99992 8.24984 9.99992 9.1665C9.99992 9.3165 9.98325 9.4665 9.94158 9.59984L12.0666 11.7248C12.1999 11.6832 12.3499 11.6665 12.4999 11.6665C12.6499 11.6665 12.7999 11.6832 12.9333 11.7248L15.8916 8.75817C15.8499 8.62484 15.8333 8.48317 15.8333 8.33317C15.8333 7.4165 16.5833 6.6665 17.4999 6.6665C18.4166 6.6665 19.1666 7.4165 19.1666 8.33317Z"
      fill={customColors.grey700}
    />
    <path
      d="M3.33325 4.1665L3.95825 6.0415L5.83325 6.6665L3.95825 7.2915L3.33325 9.1665L2.70825 7.2915L0.833252 6.6665L2.70825 6.0415L3.33325 4.1665Z"
      fill={customColors.grey700}
    />
    <path
      d="M12.5001 1.6665L13.3334 4.1665L15.8334 4.99984L13.3334 5.83317L12.5001 8.33317L11.6667 5.83317L9.16675 4.99984L11.6667 4.1665L12.5001 1.6665Z"
      fill={customColors.grey700}
    />
  </SvgIcon>
);
