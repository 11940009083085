import React from 'react';
import { Route, Routes as RouterDomRoutes } from 'react-router-dom';

import { useProjectContext } from '@pro4all/projects/ui/context';
import { ConnectionsTableProviderWrapper } from '@pro4all/projects/ui/settings/src/connections';
import { ProjectSettingsFormEdit } from '@pro4all/projects/ui/settings/src/settings';
import { ProjectFormEdit } from '@pro4all/projects/ui/sidebar';
import { ProjectSidebar } from '@pro4all/projects/ui/sidebar';
import { Routes } from '@pro4all/shared/config';
import { useIsAdminInContext } from '@pro4all/shared/identity';
import { Main } from '@pro4all/shared/ui/layout';
import { BigMessageNoAccess } from '@pro4all/shared/ui/messages';

import { ProjectsActionBar } from './ProjectsActionBar';
import * as Styled from './ProjectSettings.styled';
import { ProjectSettingsTabs } from './ProjectSettingsTabs';

export const ProjectSettings = () => {
  const { isExternalUserInProject } = useProjectContext();
  const isAdminInContext = useIsAdminInContext();

  return (
    <>
      <ProjectSettingsTabs />
      <RouterDomRoutes>
        <Route
          element={
            !isAdminInContext ? (
              <BigMessageNoAccess />
            ) : (
              <Main>
                <ProjectSidebar />
                <Styled.Wrapper>
                  <ProjectsActionBar />
                  <ProjectFormEdit />
                </Styled.Wrapper>
              </Main>
            )
          }
          path={Routes.projectSettings}
        />

        <Route
          element={
            isExternalUserInProject || !isAdminInContext ? (
              <BigMessageNoAccess />
            ) : (
              <ConnectionsTableProviderWrapper />
            )
          }
          path={Routes.connections}
        />

        <Route
          element={
            isExternalUserInProject || !isAdminInContext ? (
              <BigMessageNoAccess />
            ) : (
              <Main p={2}>
                <Styled.Wrapper>
                  <ProjectSettingsFormEdit />
                </Styled.Wrapper>
              </Main>
            )
          }
          path={Routes.documents}
        />
      </RouterDomRoutes>
    </>
  );
};
