import { Task } from '@pro4all/graphql';
import { OptimisticResponseProvider } from '@pro4all/shared/ui/table';

import { TBQResolveTasks } from './TBQResolveTasks';

export const TBQResolveTasksProviderWrapper = ({
  procedureId,
  projectId,
}: {
  procedureId: string;
  projectId: string;
}) => (
  <OptimisticResponseProvider<Task>>
    <TBQResolveTasks procedureId={procedureId} projectId={projectId} />
  </OptimisticResponseProvider>
);
